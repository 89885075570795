import { Container, Img, Text } from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';
import FutureForwardLogo from '../../assets/images/FutureForwardLogo.svg';
import { useLogout } from '../../components/Logout';

type TimeOut = ReturnType<typeof setTimeout>;

export const LogoutScreen: React.FunctionComponent = () => {
  const logout = useLogout();
  const timerRef = useRef<TimeOut | undefined>(undefined);

  useEffect(() => {
    timerRef.current = setTimeout(() => {
      logout();
    }, 2000);
    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  return (
    <Container className="body" centerContent={true}>
      <Img mb={'3em'} src={FutureForwardLogo} />
      <Text display="inline-block" color="ff.white" fontSize="1.7em" fontWeight="bold">
        Logging out...
      </Text>
    </Container>
  );
};
