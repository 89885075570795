type FormsType = {
  EarlyReader: string;
  EmergentReader: string;
  HomeVisit: string;
  Observation: string;
  ContactLog: string;
  HomeVisitAttendance: string;
  HomeVisitFamilyRequest: string;
  HomeVisitLevelOfEngagement: string;
  HomeVisitNewStudent: string;
  HomeVisitTeacherRequest: string;
  PhoneScripts: string;
};

export const Forms: FormsType = {
  EarlyReader: 'https://cdn.platform.futureforwardliteracy.org/Lesson%20Plan%20-%20Early%20Reader%20-%20blank_v1.pdf',
  EmergentReader: 'https://cdn.platform.futureforwardliteracy.org/Lesson%20Plan%20-%20Emergent%20Reader%20-%20blank_v2.pdf',
  HomeVisit: 'https://cdn.platform.futureforwardliteracy.org/FutureForward_HomeVisit_v1.pdf',
  Observation: 'https://cdn.platform.futureforwardliteracy.org/FutureForward_Observation_v1.pdf',
  ContactLog: 'https://cdn.platform.futureforwardliteracy.org/FutureForward_ContactLog_v1.pdf',
  HomeVisitAttendance: 'https://cdn.platform.futureforwardliteracy.org/FutureForward_HomeVisit_Attendance_v1.pdf',
  HomeVisitFamilyRequest: 'https://cdn.platform.futureforwardliteracy.org/FutureForward_HomeVisit_FamilyRequest_v1.pdf',
  HomeVisitLevelOfEngagement: 'https://cdn.platform.futureforwardliteracy.org/FutureForward_HomeVisit_LevelOfEngagement_v1.pdf',
  HomeVisitNewStudent: 'https://cdn.platform.futureforwardliteracy.org/FutureForward_HomeVisit_NewStudent_v1.pdf',
  HomeVisitTeacherRequest: 'https://cdn.platform.futureforwardliteracy.org/FutureForward_HomeVisit_TeacherRequest_v1.pdf',
  PhoneScripts: 'https://cdn.platform.futureforwardliteracy.org/FutureForward_PhoneScripts_v1.pdf',
};
