import { Box } from '@chakra-ui/react';
import * as React from 'react';
import { EmergentLetterFocusModuleData } from '../LessonPlanContent/EmergentLetterFocusModule';
import { PrintableInputs } from '../PrintableInputs';

export interface IPrintableEmergentLetterFocusModuleProps {
  data: EmergentLetterFocusModuleData;
}

export const PrintableEmergentLetterFocusModule: React.FunctionComponent<IPrintableEmergentLetterFocusModuleProps> = ({ data }) => (
  <Box css={{ breakInside: 'avoid-page' }}>
    <PrintableInputs.Input width="100%" minWidth={10} label="Letter/s" children={data.letters} />
    <PrintableInputs.Input width="100%" minWidth={10} label="Notes" children={data.notes} />
  </Box>
);
