import { Box, Stack } from '@chakra-ui/react';
import { FFRoles, UserDto } from '@edanalytics/ff_be_se';
import { useAtom } from 'jotai';
import { userInfoAtom } from '../ffApi';
import { SchoolServiceAtoms } from '../services/School';
import { MonthYearFormat } from './Utils';
import { FfRouterLink as Link } from '../components/FfRouterLink';

export interface ITutorSlugProps {
  user: UserDto;
}

export const TutorSlug: React.FunctionComponent<ITutorSlugProps> = ({ user }) => {
  const [userInfo] = useAtom(userInfoAtom);
  const [school] = useAtom(SchoolServiceAtoms.getCurrentSchool);
  const canAssign = userInfo.roleId === FFRoles.FEC || userInfo.roleId === FFRoles.IC || userInfo.roleId === FFRoles.Staff;

  return (
    <Stack isInline spacing={4} align="center">
      <Box>Tutor since {MonthYearFormat(user.createdAt)}</Box>
      <Box>{user.languages}</Box>
      {canAssign && (
        <Link cursor="pointer" marginLeft="5.9em" fontSize={13} textDecoration="underline" to={`/schools/${school?.id}/tutors/assign`}>
          Assign Tutor
        </Link>
      )}
    </Stack>
  );
};
