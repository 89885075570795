import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { getLessonStatusTypeString, PatchLessonPlanDto, SessionLessonPlanDto, FFRoles } from '@edanalytics/ff_be_se';
import { atom, useAtom, useAtomValue } from 'jotai';
import _ from 'lodash';
import { RESET, useUpdateAtom } from 'jotai/utils';
import { RowSelectedCb } from '../../components/FfTable/FfTable';
import { byUserSort, studentSort } from '../../components/FfTable/Filters';
import { FfTable } from '../../components/FfTable';
import { DebugCard } from '../../components/DebugCard';
import {
  LessonPlanLink,
  StudentLink,
  TutorLink,
  ObservationLink,
  CompactFormatDateString,
  LessonPlanTimeString,
  MakeUpLessonPlanLink,
} from '../../components/Utils';
import { LessonPlanService } from '../../services/LessonPlan/LessonPlanService';
import { Empty } from '../../components/Empty';
import { SchoolServiceAtoms } from '../../services/School';
import { isEmpty } from '../../utils/utils';
import { persistNavAtom } from '../../atoms/navAtom';
import { getLessonPlanHighlights } from '../../components/LessonPlanContent/LessonPlanUtils';
import { getLessonPlanTypeString } from '../../components/LessonPlanContent/models/LessonPlanTypeEnum';
import { atomApiWithNavAndRead } from '../../utils/async-atom';
import { whoAmIatom } from '../../services/Identity';

const lessonPlansAtom = atomApiWithNavAndRead((get, nav) => {
  const schoolId = nav?.schoolId as number;
  return get(LessonPlanService).getAllSavedForSchool(schoolId);
});

const deleteLessonPlansAtom = atom(null, async (get, set, newValue: { schoolId: number; lessonPlanIds: PatchLessonPlanDto[] }) => {
  await get(LessonPlanService).patchLessonPlans(newValue.schoolId, newValue.lessonPlanIds);
  return set(lessonPlansAtom, RESET);
});

export const LessonPlansScreen: React.FunctionComponent = () => {
  const [school] = useAtom(SchoolServiceAtoms.getCurrentSchool);
  const [lessonPlans] = useAtom(lessonPlansAtom);
  const [navAtomProps] = useAtom(persistNavAtom);
  const [lessonPlanIds, setLessonPlanIds] = React.useState<PatchLessonPlanDto[]>([]);
  const [processing, setProcessing] = React.useState(false);
  const [selectedRowIndexes, setSelectedRowIndexes] = React.useState<number[]>([]);
  const user = useAtomValue(whoAmIatom);
  const isEnabled = user?.roleId !== FFRoles.Tutor;
  const deleteLessonPlans = useUpdateAtom(deleteLessonPlansAtom);
  const toast = useToast();

  const rowSelectCb = (rows: RowSelectedCb) => {
    setLessonPlanIds([...Object.values(rows.data).map((r: SessionLessonPlanDto) => ({ id: r.id, isDeleted: true } as PatchLessonPlanDto))]);
    setSelectedRowIndexes(Object.keys(rows.data).map((k) => Number(k)));
  };

  const columns: ColumnDef<any>[] = [
    {
      accessorFn: (row) => row.student,
      id: 'fullName',
      cell: (info: any) => StudentLink(info.getValue()),
      header: () => 'Student',
      filterFn: 'studentNode',
      sortingFn: studentSort,
    },
    {
      accessorFn: (row) => row,
      id: 'byUserId',
      cell: (info: any) => (school ? TutorLink(school, info.getValue().byUser) : ''),
      header: () => 'Tutor',
      filterFn: 'tutorNode',
      sortingFn: byUserSort,
    },
    {
      accessorKey: 'startDateTime',
      cell: (info: any) => CompactFormatDateString(info.getValue()),
      id: 'startDateTime',
      header: () => 'Lesson Date',
      filterFn: 'date',
    },
    {
      accessorKey: 'startTime',
      accessorFn: (row) => row,
      cell: (info: any) => LessonPlanTimeString(info.getValue()),
      id: 'startTime',
      header: () => 'Lesson Time',
      enableColumnFilter: false,
    },
    {
      accessorKey: 'lessonStatus',
      cell: (info: any) => getLessonStatusTypeString(info.getValue()),
      id: 'status',
      header: () => 'Status',
      enableColumnFilter: false,
    },
    {
      accessorKey: 'lessonPlanType',
      cell: (info: any) => getLessonPlanTypeString(info.getValue()),
      id: 'type',
      header: () => 'Lesson Type',
      enableColumnFilter: false,
    },
    {
      // accessorKey: 'isMakeUp',
      accessorFn: (row) => row,
      cell: (info: any) => info.getValue()?.isMakeUp && MakeUpLessonPlanLink(info.getValue()),
      id: 'isMakeUp',
      header: () => 'Is Make Up',
      enableColumnFilter: false,
    },
    {
      accessorFn: (row) => row,
      id: 'notes',
      cell: (info: any) => (
        <Tooltip
          bg="white"
          color={'ff.blue'}
          p="1em"
          placement="top"
          hasArrow
          rounded="sm"
          label={getLessonPlanHighlights(info.getValue())}
        >
          <Box w={225} textOverflow="ellipsis" overflow="hidden" fontStyle="italic" cursor="pointer">
            {getLessonPlanHighlights(info.getValue())}
          </Box>
        </Tooltip>
      ),
      header: () => 'Notes',
      enableSorting: false,
      enableColumnFilter: false,
    },
    {
      accessorFn: (row) => row,
      cell: (info: any) => !!school && !!info.getValue() && ObservationLink(info.getValue(), school, info.getValue().byUser),
      id: 'linkObserve',
      header: () => 'Observation',
      enableColumnFilter: false,
    },
    {
      accessorFn: (row) => row,
      id: 'link',
      cell: (info: any) => LessonPlanLink(info.getValue()),
      header: '',
      enableColumnFilter: false,
    },
  ];

  const sortedLessonPlans = _.sortBy(lessonPlans, (lp) => lp.startDateTime);

  const DeleteButtonWithConfirmation = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = React.useRef<HTMLButtonElement>(null);

    const performDelete = async () => {
      onClose();
      setProcessing(true);
      await deleteLessonPlans({ schoolId: school?.id as number, lessonPlanIds });
      setSelectedRowIndexes([]);
      setLessonPlanIds([]);
      setProcessing(false);
      toast({ description: 'Lesson plan deleted', status: 'success' });
    };

    return (
      <>
        {isEnabled && (
          <Button disabled={processing || selectedRowIndexes.length === 0} isLoading={processing} variant={'outline'} onClick={onOpen}>
            Delete Selected
          </Button>
        )}
        <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete {selectedRowIndexes.length} Lesson {selectedRowIndexes.length > 1 ? 'Plans' : 'Plan'}
              </AlertDialogHeader>
              <AlertDialogBody>Are you sure? You can't undo this action afterwards.</AlertDialogBody>
              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>
                <Button colorScheme="red" onClick={performDelete} ml={3}>
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
    );
  };

  return (
    <Container maxWidth="100%">
      <Flex mb="1em">
        <Box flex={1}>
          <Heading color={'ff.blue'} mb="1em">
            All Lesson Plans
          </Heading>
        </Box>
        <Box textAlign="right" flex={0}>
          <DeleteButtonWithConfirmation />
        </Box>
      </Flex>
      {!isEmpty(lessonPlans) ? (
        <FfTable
          variant="card"
          columns={columns}
          data={sortedLessonPlans}
          enableRowSelection={isEnabled}
          selectedRowIndexes={selectedRowIndexes}
          rowSelectedCb={(rows: any) => rowSelectCb(rows)}
        />
      ) : (
        <Empty description="No lesson plans." label="Return to dashboard" path={`/schools/${school?.id}/dashboard`} />
      )}
      <DebugCard
        data={[
          { name: 'selectedRowIndexes', data: selectedRowIndexes },
          { name: 'lessonPlanIds', data: lessonPlanIds },
          { name: 'school', data: school },
          { name: 'navAtomProps', data: navAtomProps },
          { name: 'sortedLessonPlans', data: sortedLessonPlans },
        ]}
      />
    </Container>
  );
};
