import { FormControl, FormLabel, Switch } from '@chakra-ui/react';
import { useDebug } from '../hooks/useDebug';

export const ToggleDebug: React.FunctionComponent = () => {
  const [debug, setDebug] = useDebug();

  const onToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDebug(e.target.value === 'true' ? 'false' : 'true');
  };

  return (
    <FormControl display="flex" alignItems="center">
      <FormLabel htmlFor="debug" mb="0" cursor="pointer" fontSize="sm">
        Inline debugging
      </FormLabel>
      <Switch id="debug" title="Debug" isChecked={debug === 'true'} value={debug} onChange={onToggle} />
    </FormControl>
  );
};
