import React from 'react';
import { PrintableEarlyRunningRecordModule } from '../LessonPlanPrintableContent/PrintableEarlyRunningRecordModule';
import { PrintableEarlySirModule } from '../LessonPlanPrintableContent/PrintableEarlySirModule';
import { PrintableEarlyTutorReadAloudModule } from '../LessonPlanPrintableContent/PrintableEarlyTutorReadAloudModule';
import { PrintableEarlyWarmupModule } from '../LessonPlanPrintableContent/PrintableEarlyWarmupModule';
import { PrintableEarlyWordplayModule } from '../LessonPlanPrintableContent/PrintableEarlyWordplayModule';
import { PrintableEarlyWritingModule } from '../LessonPlanPrintableContent/PrintableEarlyWritingModule';
import { PrintableEmergentBiweeklyAssessmentModule } from '../LessonPlanPrintableContent/PrintableEmergentBiweeklyAssessmentModule';
import { PrintableEmergentLetterFocusModule } from '../LessonPlanPrintableContent/PrintableEmergentLetterFocusModule';
import { PrintableEmergentPaSkillModule } from '../LessonPlanPrintableContent/PrintableEmergentPaSkillModule';
import { PrintableEmergentSirModule } from '../LessonPlanPrintableContent/PrintableEmergentSirModule';
import { PrintableEmergentTutorReadAloudModule } from '../LessonPlanPrintableContent/PrintableEmergentTutorReadAloudModule';
import { PrintableEmergentWarmupModule } from '../LessonPlanPrintableContent/PrintableEmergentWarmupModule';
import { PrintableEmergentWordplayModule } from '../LessonPlanPrintableContent/PrintableEmergentWordplayModule';
import { IPrintableModuleProps } from '../LessonPlanPrintableContent/PrintableModuleUtils';
import { EarlyRunningRecordModule } from './EarlyRunningRecordModule';
import { EarlySirModule } from './EarlySirModule';
import { EarlyTutorReadAloudModule } from './EarlyTutorReadAloudModule';
import { EarlyWarmupModule } from './EarlyWarmupModule';
import { EarlyWordplayModule } from './EarlyWordplayModule';
import { EarlyWritingModule } from './EarlyWritingModule';
import { EmergentBiweeklyAssessmentModule } from './EmergentBiweeklyAssessmentModule';
import { EmergentLetterFocusModule } from './EmergentLetterFocusModule';
import { EmergentPaSkillModule } from './EmergentPaSkillModule';
import { EmergentSirModule } from './EmergentSirModule';
import { EmergentTutorReadAloudModule } from './EmergentTutorReadAloudModule';
import { EmergentWarmupModule } from './EmergentWarmupModule';
import { EmergentWordplayModule } from './EmergentWordplayModule';
import { IGenericLessonModuleProps } from './LessonPlanUtils';
import { LessonPlanModuleTypes } from './models/LessonPlanModuleEnum';
import { EmergentWordplayModuleV2 } from './EmergentWordplayModuleV2';
import { EmergentWordplayModuleV3 } from './EmergentWordplayModuleV3';
import { PrintableEmergentWordplayModuleV2 } from '../LessonPlanPrintableContent/PrintableEmergentWordplayModuleV2';
import { PrintableEmergentWordplayModuleV3 } from '../LessonPlanPrintableContent/PrintableEmergentWordplayModuleV3';

export const LessonPlanPrintableModuleConfig: Record<
  LessonPlanModuleTypes,
  Record<number, React.FunctionComponent<IPrintableModuleProps>>
> = {
  [LessonPlanModuleTypes.EarlyRunningRecord]: {
    1: PrintableEarlyRunningRecordModule,
  },
  [LessonPlanModuleTypes.EarlyStudentInstructionalReading]: {
    1: PrintableEarlySirModule,
  },
  [LessonPlanModuleTypes.EarlyWarmup]: {
    1: PrintableEarlyWarmupModule,
  },
  [LessonPlanModuleTypes.EarlyTutorReadAloud]: {
    1: PrintableEarlyTutorReadAloudModule,
  },
  [LessonPlanModuleTypes.EarlyWordplay]: {
    1: PrintableEarlyWordplayModule,
  },
  [LessonPlanModuleTypes.EarlyWriting]: {
    1: PrintableEarlyWritingModule,
  },
  [LessonPlanModuleTypes.EmergentBiweeklyAssessment]: {
    1: PrintableEmergentBiweeklyAssessmentModule,
  },
  [LessonPlanModuleTypes.EmergentLetterFocus]: {
    1: PrintableEmergentLetterFocusModule,
  },
  [LessonPlanModuleTypes.EmergentPaSkill]: {
    1: PrintableEmergentPaSkillModule,
  },
  [LessonPlanModuleTypes.EmergentStudentInstructionalReading]: {
    1: PrintableEmergentSirModule,
  },
  [LessonPlanModuleTypes.EmergentTutorReadAloud]: {
    1: PrintableEmergentTutorReadAloudModule,
  },
  [LessonPlanModuleTypes.EmergentWarmup]: {
    1: PrintableEmergentWarmupModule,
  },
  [LessonPlanModuleTypes.EmergentWordplay]: {
    1: PrintableEmergentWordplayModule,
    2: PrintableEmergentWordplayModuleV2,
    3: PrintableEmergentWordplayModuleV3,
  },
  [LessonPlanModuleTypes.EmergentWriting]: {
    1: PrintableEmergentLetterFocusModule,
  },
};

export const LessonPlanModuleConfig: Record<LessonPlanModuleTypes, Record<number, React.FunctionComponent<IGenericLessonModuleProps>>> = {
  [LessonPlanModuleTypes.EarlyRunningRecord]: {
    1: EarlyRunningRecordModule,
  },
  [LessonPlanModuleTypes.EarlyStudentInstructionalReading]: {
    1: EarlySirModule,
  },
  [LessonPlanModuleTypes.EarlyWarmup]: {
    1: EarlyWarmupModule,
  },
  [LessonPlanModuleTypes.EarlyTutorReadAloud]: {
    1: EarlyTutorReadAloudModule,
  },
  [LessonPlanModuleTypes.EarlyWordplay]: {
    1: EarlyWordplayModule,
  },
  [LessonPlanModuleTypes.EarlyWriting]: {
    1: EarlyWritingModule,
  },
  [LessonPlanModuleTypes.EmergentBiweeklyAssessment]: {
    1: EmergentBiweeklyAssessmentModule,
  },
  [LessonPlanModuleTypes.EmergentLetterFocus]: {
    1: EmergentLetterFocusModule,
  },
  [LessonPlanModuleTypes.EmergentPaSkill]: {
    1: EmergentPaSkillModule,
  },
  [LessonPlanModuleTypes.EmergentStudentInstructionalReading]: {
    1: EmergentSirModule,
  },
  [LessonPlanModuleTypes.EmergentTutorReadAloud]: {
    1: EmergentTutorReadAloudModule,
  },
  [LessonPlanModuleTypes.EmergentWarmup]: {
    1: EmergentWarmupModule,
  },
  [LessonPlanModuleTypes.EmergentWordplay]: {
    1: EmergentWordplayModule,
    2: EmergentWordplayModuleV2,
    3: EmergentWordplayModuleV3,
  },
  [LessonPlanModuleTypes.EmergentWriting]: {
    1: EmergentLetterFocusModule,
  },
};
