import { Flex } from '@chakra-ui/react';
import { lessonNumber26Options, unitNumberOptions } from '../LessonPlanContent/LessonPlanUtils';
import { PrintableInputs } from '../PrintableInputs';
import { EmergentWordplayModuleDataV2 } from '../LessonPlanContent/EmergentWordplayModuleV2';

export interface IPrintableEmergentWordplayModuleProps {
  data: EmergentWordplayModuleDataV2;
}

const listOfCheckBoxes = [
  { value: '1', key: 'Picture Sort', checked: false },
  { value: '2', key: 'Sight Word Practice', checked: false },
  { value: '3', key: 'Shared Reading Passage', checked: false },
];

const mapOptions = (data: any) =>
  listOfCheckBoxes.map((obj: any) => ({
    ...obj,
    checked: data.activities?.toString().includes(obj.value),
  }));

export const PrintableEmergentWordplayModuleV3: React.FunctionComponent<IPrintableEmergentWordplayModuleProps> = ({ data }) => {
  const options = mapOptions(data);
  return (
    <>
      <Flex wrap="wrap" align="top" width="100%" css={{ breakInside: 'avoid-page' }}>
        <>
          <PrintableInputs.Checkboxes css={{ flex: '20% 1 1' }} label="Activities" options={options} />
        </>
        <PrintableInputs.Textarea minLines={2} label="Notes" css={{ width: '100%' }} children={data.notes} />
      </Flex>
    </>
  );
};
