// import { FamilyIntSummaryDto } from '@edanalytics/ff_be_se';
import { ColumnDef } from '@tanstack/react-table';
import { useAtom, useAtomValue } from 'jotai';
import { StudentDto } from '@edanalytics/ff_be_se';
import { studentSort } from '../FfTable/Filters';
import { FfTable } from '../FfTable';
import { Empty } from '../Empty';
import { FamilyInteractionServiceAtoms } from '../../services/FamilyInteraction';
import { persistNavAtom } from '../../atoms/navAtom';
import { FamilyInteractionLink, StudentLink, TableFormatDateTimeString } from '../Utils';

export interface IFamilyInteractionListProps {
  children?: any;
}
interface FamilyIntSummaryDto {
  id: number;
  student: StudentDto;
  reasonForInteraction: string;
  firstAttemptDate: Date;
  numAttempts: number;
  lastAttemptSuccessful: boolean;
}

export const FamilyInteractionList: React.FunctionComponent<IFamilyInteractionListProps> = () => {
  const [familyInteractionsData] = useAtom(FamilyInteractionServiceAtoms.getAllFamilyInterSummariesBySchool);
  const navAtomProps = useAtomValue(persistNavAtom);
  const familyInteractions = familyInteractionsData ?? [];
  const columns: ColumnDef<FamilyIntSummaryDto>[] = [
    {
      accessorFn: (row) => row.student,
      id: 'fullName',
      cell: (info: any) => StudentLink(info.getValue()),
      header: () => 'Student',
      filterFn: 'studentNode',
      sortingFn: studentSort,
    },
    {
      accessorKey: 'firstAttemptDate',
      cell: (info: any) => TableFormatDateTimeString(info.getValue()),
      id: 'firstAttemptDate',
      header: () => 'Interaction Start Date',
      filterFn: 'date',
    },
    {
      accessorKey: 'numAttempts',
      cell: (info: any) => info.getValue(),
      id: 'numAttempts',
      header: () => 'Attempts',
    },
    {
      accessorKey: 'lastAttemptSuccessful',
      cell: (info: any) => (info.getValue() ? `Successful` : `Unsuccessful`),
      id: 'lastAttemptSuccessful',
      header: () => 'Status',
    },
    {
      accessorKey: 'reasonForInteraction',
      cell: (info: any) => info.getValue(),
      id: 'reasonForInteraction',
      header: () => 'Reason for Interaction',
    },
    {
      accessorKey: 'id',
      id: 'id',
      cell: (info: any) => FamilyInteractionLink(info.getValue(), navAtomProps.schoolId),
      header: '',
      enableColumnFilter: false,
    },
  ];

  return (
    <>
      {familyInteractions.length ? (
        <FfTable columns={columns} data={familyInteractions} />
      ) : (
        <Empty
          description="No family interactions"
          label="Add Family Interaction"
          path={`/schools/${navAtomProps.schoolId}/family-interaction/create`}
        />
      )}
    </>
  );
};
