import { Box, Button, Container, Flex, Heading } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FamilyInteractionList } from '../../components/FamilyInteraction/FamilyInteractionList';
import { SchoolServiceAtoms } from '../../services/School';

export const AllInteractionsScreen: React.FunctionComponent = () => {
  const [school] = useAtom(SchoolServiceAtoms.getCurrentSchool);
  const nav = useNavigate();

  return (
    <Container maxWidth="100%">
      <Flex mb="2em" mt="2em">
        <Box flex={1}>
          <Heading color={'ff.blue'}>All Family Interactions</Heading>
        </Box>
        <Box textAlign="right" flex={0}>
          <Button variant={'cta'} onClick={() => nav(`/schools/${school?.id}/family-interaction/create`)} w="200px">
            Add Family Interaction
          </Button>
        </Box>
      </Flex>
      <FamilyInteractionList />
    </Container>
  );
};
