import { Box, Button, Container, Flex, Heading, Menu, MenuButton, MenuItem, MenuList, Stack, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import _, { isEmpty } from 'lodash';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';
import { DebugCard } from '../../components/DebugCard';
import { GroupType, SessionsAccordion } from '../../components/SessionsAccordion';
import { FfRouterLink as Link } from '../../components/FfRouterLink';
import { FrequentlyUsedList } from '../../components/FrequentlyUsedList';
import { LessonPlanServiceAtoms } from '../../services/LessonPlan/LessonPlanService';
import { SchoolServiceAtoms } from '../../services/School';
import { Forms } from '../../assets/forms/forms';
import { FfDivider } from '../../components/FfDivider';
import { FamilyEventsAccordion } from '../../components/FamilyEvents/FamilyEventsAccordion';
import { FamilyEventServiceAtoms } from '../../services/FamilyEvent/FamilyEventService';
import { EngagementSummaryList } from '../../components/FamilyInteraction/EngagementSummaryList';

export const Dashboard: React.FunctionComponent = () => {
  const [school] = useAtom(SchoolServiceAtoms.getCurrentSchool);
  const nowDateString = new Date().toISOString();
  const [familyEvents] = useAtom(FamilyEventServiceAtoms.getAllFamilyEvents);
  const upcomingFamilyEvents = _.sortBy(familyEvents, (fe) => fe.startDate).filter((fe) => nowDateString <= fe.endDate);
  const [lessonPlans] = useAtom(LessonPlanServiceAtoms.getScheduledSchoolLessonPlans);
  const [groupType, setGroupType] = useState<GroupType>('tutor');
  const nav = useNavigate();
  const handleChange = (e: any) => {
    setGroupType(e.target.value);
  };

  const groupTypes = [
    {
      value: 'date' as GroupType,
      name: 'Date',
    },
    {
      value: 'tutor' as GroupType,
      name: 'Tutor',
    },
  ];

  return (
    <Container maxWidth="100%">
      <Flex mb="1em">
        <Box flex={1}>
          <Heading color={'ff.blue'}>FEC Dashboard</Heading>
        </Box>
        <Box textAlign="right" flex={0}>
          <FrequentlyUsedList
            items={[
              {
                path: Forms.PhoneScripts,
                name: 'Phone Scripts',
                showPrinter: true,
                isExternal: true,
              },
              {
                path: Forms.Observation,
                name: 'Observation Form',
                showPrinter: true,
                isExternal: true,
              },
              {
                path: Forms.EmergentReader,
                name: 'Blank Emergent Reader Lesson Plan',
                showPrinter: true,
                isExternal: true,
              },
              {
                path: Forms.EarlyReader,
                name: 'Blank Early Reader Lesson Plan',
                showPrinter: true,
                isExternal: true,
              },
              {
                path: `/schools/${school?.id}/lesson-plans/`,
                name: 'See All Past Lesson Plans',
              },
            ]}
          />
        </Box>
      </Flex>

      {school && (
        <>
          <Flex mb="1em">
            <Box flex={1}>
              <Heading color={'ff.blue'}>Upcoming Events</Heading>
            </Box>
            <Box justifyContent="right" flex={0}>
              <Button variant={'cta'} onClick={() => nav(`/schools/${school?.id}/family-events/create`)}>
                Add An Event
              </Button>
            </Box>
          </Flex>

          <FfDivider marginBottom="0" height="1px" borderBottomWidth="0" />
          <FamilyEventsAccordion familyEvents={upcomingFamilyEvents} isFirstItemOpen={true} />
        </>
      )}
      {school && (
        <>
          <Flex mb="1em">
            <Box flex={1}>
              <Heading display="inline-block" mr="1em" color={'ff.blue'}>
                This Month's Engagement
              </Heading>
              <Link to={`/schools/${school?.id}/family-interactions`} textDecoration="underline">
                See All Family Interactions
              </Link>
            </Box>
          </Flex>
          <EngagementSummaryList />
        </>
      )}
      {school && lessonPlans && (
        <>
          <Flex mb="1em">
            <Box flex={1}>
              <Heading display="inline-block" mr="1em" color={'ff.blue'}>
                Session Schedule
              </Heading>
              <Link to={`/schools/${school?.id}/students`} textDecoration="underline">
                See All Students
              </Link>
            </Box>
          </Flex>
          <Stack direction="row">
            <Box mb="1em">See schedule by</Box>
            <Box>
              <Menu autoSelect={true}>
                <MenuButton as={Button} rightIcon={<ChevronDownIcon />} bg="white" size="sm">
                  {groupTypes.find((x) => x.value === groupType)?.name}
                </MenuButton>
                <MenuList color="gray" fontSize="sm">
                  {groupTypes.map((type, index) => (
                    <MenuItem
                      key={index}
                      defaultChecked={type.value === groupType}
                      id={type.value}
                      value={type.value}
                      onClick={handleChange}
                    >
                      {type.name}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </Box>
          </Stack>
          <SessionsAccordion school={school} lessonPlans={lessonPlans} groupType={groupType} showObserveLinks={false} />
        </>
      )}
      {isEmpty(lessonPlans) && <Text>Nothing on deck</Text>}
      <DebugCard data={[{ name: 'lessonPlans', data: lessonPlans }]} />
    </Container>
  );
};
