import { PostSessionObservationDto, SessionObservationDto, PutSessionObservationDto } from '@edanalytics/ff_be_se';
import { atom, Getter } from 'jotai';
import { apiClientAtom } from '../ffApi';

const sessionObservationFactory = (get: Getter) => ({
  postSessionObservation: async (
    // Follow
    schoolId: number,
    tutorId: number,
    lessonPlanId: number,
    post: PostSessionObservationDto,
  ): Promise<SessionObservationDto> => {
    const resp = await get(apiClientAtom).post(
      `/schools/${schoolId}/tutors/${tutorId}/lesson-plans/${lessonPlanId}/session-observations`,
      post,
    );
    return resp.data as SessionObservationDto;
  },

  updateSessionObservation: async (
    // Follow
    sessionObsId: number,
    schoolId: number,
    tutorId: number,
    lessonPlanId: number,
    post: PutSessionObservationDto,
  ): Promise<SessionObservationDto> => {
    const resp = await get(apiClientAtom).put(
      `/schools/${schoolId}/tutors/${tutorId}/lesson-plans/${lessonPlanId}/session-observations/${sessionObsId}`,
      post,
    );
    return resp.data as SessionObservationDto;
  },
});

const SessionObservationService = atom(sessionObservationFactory);

export { SessionObservationService };
