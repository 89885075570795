import { StudentDto, UserDto } from '@edanalytics/ff_be_se';

const getMonWedFriAssignments = (student: StudentDto, users: UserDto[]) => [
  {
    studentId: student.id,
    userId: users[0].id,
    dayOfWeek: 1,
    startMinute: 540,
    endMinute: 570,
    student,
    user: users[0],
  },
  {
    studentId: student.id,
    userId: users.length > 1 ? users[1].id : users[0].id,
    dayOfWeek: 3,
    startMinute: 540,
    endMinute: 570,
    student,
    user: users.length > 1 ? users[1] : users[0],
  },
  {
    studentId: student.id,
    userId: users[0].id,
    dayOfWeek: 5,
    startMinute: 540,
    endMinute: 570,
    student,
    user: users[0],
  },
];

const getTueThuAssignments = (student: StudentDto, users: UserDto[]) => [
  {
    studentId: student.id,
    userId: users[0].id,
    dayOfWeek: 2,
    startMinute: 690,
    endMinute: 720,
    student,
    user: users[0],
  },
  {
    studentId: student.id,
    userId: users.length > 1 ? users[1].id : users[0].id,
    dayOfWeek: 4,
    startMinute: 690,
    endMinute: 720,
    student,
    user: users.length > 1 ? users[1] : users[0],
  },
];

export const generateSampleScheduleData = (users: UserDto[], students: StudentDto[]) =>
  students?.map((student, index) => ({
    studentId: student.id,
    student,
    assignments: index % 2 ? { ...getTueThuAssignments(student, users) } : { ...getMonWedFriAssignments(student, users) },
  }));

export const generateSampleSessionsData = (users: UserDto[], students: StudentDto[]) => {
  const result: any = [];
  users.forEach((user, index) => {
    const student = students[index];
    result.push({
      id: index,
      userId: user.id,
      studentId: student?.id,
      dayOfWeek: 1,
      startMinute: 540,
      endMinute: 570,
      startTime: '2022-02-15T09:00:00.000',
      endTime: '2022-02-15T09:30:00.000',
      student,
      user,
      sessionLessonPlan: {
        id: 1,
        startTime: '2022-02-15T09:00:00.000',
        attendanceStatus: 'TUTOR_ABSENCE',
        lessonByUserId: user.id,
        lessonSubmittedByUserId: null,
      },
    });
    result.push({
      id: index + 1,
      userId: user.id,
      studentId: student?.id,
      dayOfWeek: 1,
      startMinute: 540,
      endMinute: 570,
      startTime: '2022-05-30T09:00:00.000',
      endTime: '2022-05-30T09:30:00.000',
      student,
      user,
      sessionLessonPlan: {
        id: 1,
        startTime: '2022-05-30T09:00:00.000',
        attendanceStatus: 'ATTENDED',
        lessonByUserId: user.id,
        lessonSubmittedByUserId: user.id,
      },
    });
    result.push({
      id: index + 2,
      userId: user.id,
      studentId: student?.id,
      dayOfWeek: 1,
      startMinute: 540,
      endMinute: 570,
      startTime: '2022-06-01T09:00:00.000',
      endTime: '2022-06-01T09:30:00.000',
      student,
      user,
      sessionLessonPlan: {
        id: 1,
        startTime: '2022-06-01T09:00:00.000',
        attendanceStatus: 'ATTENDED',
        lessonByUserId: user.id,
        lessonSubmittedByUserId: user.id,
      },
    });
    result.push({
      id: index + 3,
      userId: user.id,
      studentId: student?.id,
      dayOfWeek: 1,
      startMinute: 540,
      endMinute: 570,
      startTime: '2022-06-03T09:00:00.000',
      endTime: '2022-06-03T09:30:00.000',
      student,
      user,
      sessionLessonPlan: {
        id: 1,
        startTime: '2022-06-03T09:00:00.000',
        attendanceStatus: 'STUDENT_ABSENCE',
        lessonByUserId: user.id,
        lessonSubmittedByUserId: user.id,
      },
    });
  });

  return result;
};

export const frequentlyUsedListExample = [
  {
    path: '/users',
    name: 'Staff Directory',
  },
  {
    path: '/nowhere',
    name: 'Blank Lesson Plan',
  },
];

export const frequentlyUsedListExampleStaff = [
  {
    path: '/users',
    name: 'Staff Directory',
  },
];
