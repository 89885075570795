import { Center, Heading, Img, Stack, VStack } from '@chakra-ui/react';
import { useAtomValue } from 'jotai';
import React, { Key } from 'react';
import { useLocation } from 'react-router-dom';
import FutureForwardLogo from '../../assets/images/FutureForwardLogo.svg';
import { persistNavAtom } from '../../atoms/navAtom';
import config from '../../config';
import { resolveHomePage } from '../../Routes';
import { whoAmIatom } from '../../services/Identity';
import { FfRouterLink as Link } from '../FfRouterLink';
import { ToolBoxMenu } from '../Menus/ToolBoxMenu';
import { PreloadNav } from '../Preload/PreloadNav';
import { SchoolSelect } from '../SchoolSelect';
import { SearchWidget } from '../SearchWidget';
import { ToggleDebug } from '../ToggleDebug';

function HeadingInternal() {
  const user = useAtomValue(whoAmIatom);
  return (
    <>
      {user && (
        <Heading size="lg" color={'ff.water'} style={{ fontWeight: '500' }}>
          Welcome, {user.firstName}
        </Heading>
      )}
      <SearchWidget />
    </>
  );
}

export const FallbackHeader: React.FunctionComponent = () => (
  <Center bg={'ff.blue'}>
    <Stack maxW="1200px" w="100%" color={'ff.goals'} direction="row" align="center" p="3em" pr="1em" justify="space-between">
      <Stack>
        <Link to="/">
          <Img height={130} src={FutureForwardLogo} />
        </Link>
      </Stack>
      <Stack width="auto">
        <Heading size="lg" color={'ff.water'} style={{ fontWeight: '500' }}>
          Welcome
        </Heading>
      </Stack>
      {config.debugging && (
        <Stack>
          <VStack align={'left'}>
            <ToggleDebug />
          </VStack>
        </Stack>
      )}
      <Stack textAlign="left" align={'left'} w="275px"></Stack>
    </Stack>
  </Center>
);

export const Header: React.FunctionComponent<{ providerKey: Key }> = (props) => {
  const location = useLocation();
  const navAtomProps = useAtomValue(persistNavAtom);
  const home = location.pathname.localeCompare('/schools') === 0 ? '/schools' : resolveHomePage(navAtomProps);

  return (
    <Center bg={'ff.blue'}>
      <Stack maxW="1200px" w="100%" color={'ff.goals'} direction="row" align="center" p="3em" pr="1em" justify="space-between">
        <Stack>
          <Link to={home}>
            <Img height={130} src={FutureForwardLogo} />
          </Link>
        </Stack>
        <Stack width="auto">
          <PreloadNav providerKey={props.providerKey}>
            <HeadingInternal />
          </PreloadNav>
        </Stack>
        {config.debugging && (
          <Stack>
            <VStack align={'left'}>
              <ToggleDebug />
            </VStack>
          </Stack>
        )}
        <Stack textAlign="left" align={'left'} w="275px">
          <PreloadNav providerKey={101}>
            <ToolBoxMenu />
            <SchoolSelect />
          </PreloadNav>
        </Stack>
      </Stack>
    </Center>
  );
};
