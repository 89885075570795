import React from 'react';
import { Box, Text, SimpleGrid, Heading, useTheme } from '@chakra-ui/react';
import { StudentContactDto, StudentDto } from '@edanalytics/ff_be_se';
import { formatPhoneNumber } from './Utils';

export interface IStudentDetailProps {
  student: StudentDto;
}

export const StudentDetailExtended: React.FunctionComponent<IStudentDetailProps> = ({ student }) => {
  const primaryContact = student.studentContacts?.filter((p) => p.primary === true).pop() as StudentContactDto;
  const secondaryContact = student.studentContacts?.filter((s) => s.primary === false).pop() as StudentContactDto;
  const theme = useTheme();
  const studentDetails = [
    { label: 'Primary Contact', value: `${primaryContact?.firstName || ''} ${primaryContact?.lastName || ''}` },
    { label: 'Primary Contact Relationship', value: primaryContact?.relationship || '' },
    { label: 'Primary Contact Main Phone', value: formatPhoneNumber(primaryContact?.phone as string) || '' },
    { label: 'Primary Contact Alt Phone', value: formatPhoneNumber(primaryContact?.altPhone as string) || '' },
    { label: 'Primary Contact Email', value: primaryContact?.email || '' },
    { label: 'Primary Address', value: primaryContact?.address || '' },
    { label: 'Photo Consent?', value: student.photoConsent || '' },
    { label: 'Languages', value: student.languages || '' },
    { label: 'Secondary Contact', value: (secondaryContact?.firstName || '') + (secondaryContact?.lastName || '') },
    { label: 'Secondary Contact Relationship', value: secondaryContact?.relationship || '' },
    { label: 'Secondary Contact Main Phone', value: formatPhoneNumber(secondaryContact?.phone as string) || '' },
    { label: 'Secondary Contact Alt Phone', value: formatPhoneNumber(secondaryContact?.altPhone as string) || '' },
    { label: 'Secondary Contact Email', value: secondaryContact?.email || '' },
    { label: 'Secondary Address', value: secondaryContact?.address || '' },
    { label: 'Allergies', value: student.allergies || '' },
    { label: 'Program Consent?', value: student.programConsent || '' },
  ];
  return (
    <SimpleGrid columns={2} spacingX={'3em'}>
      {studentDetails.map((value, i: number) => (
        <Box key={i}>
          <Heading w={'max-content'} fontSize={'medium'}>
            {value.label}
          </Heading>
          <Text fontFamily={theme.fonts.body} fontSize={'medium'}>
            {value.value}
          </Text>
        </Box>
      ))}
    </SimpleGrid>
  );
};
