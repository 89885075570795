import { Box, Flex } from '@chakra-ui/react';
import { EarlyRunningRecordModuleData } from '../LessonPlanContent/EarlyRunningRecordModule';
import { comprehensionOptions, readingLevelOptions } from '../LessonPlanContent/LessonPlanUtils';
import { PrintableInputs } from '../PrintableInputs';

export interface IPrintableEarlyRunningRecordModuleProps {
  data: EarlyRunningRecordModuleData;
}

export const PrintableEarlyRunningRecordModule: React.FunctionComponent<IPrintableEarlyRunningRecordModuleProps> = ({ data }) => (
  <Box css={{ breakInside: 'avoid-page' }} width="100%">
    <PrintableInputs.Input minWidth={10} width="100%" label="Title" children={data.title} />
    <Flex direction="row" alignItems="top" width="100%">
      <PrintableInputs.Input
        css={{ flexGrow: 0.5 }}
        minWidth={7}
        width="4em"
        label="Reading Level"
        children={readingLevelOptions.find((option) => option.value === data.readingLevel)?.key}
      />
      <PrintableInputs.Options
        css={{ flexGrow: 0.5 }}
        label="Comprehension"
        options={comprehensionOptions.map((option) => ({ ...option, checked: data.comprehension === option.value }))}
      />
    </Flex>
    <PrintableInputs.Input minWidth={20} width="4em" label="Score" children={data.score} />
  </Box>
);
