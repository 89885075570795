import React from 'react';
import { Box, Flex, Stack } from '@chakra-ui/react';
import { Form } from 'formik';
import { FfButtonTypes } from '../../models/Button';
import { FfFormikButton } from '../Formik/FfFormikButton';
import { FfFormikForm } from '../Formik/FfFormikForm';
import { FfFormikInput } from '../Formik/FfFormikInput';
import { FfFormikSelect } from '../Formik/FfFormikSelect';
import { getScoreRangeString, letterOptions, readingLevelOptions } from './LessonPlanUtils';
import { GenericModuleData } from './models/genericLessonModule';
import { LessonPlanModuleTitles, LessonPlanModuleTypes } from './models/LessonPlanModuleEnum';

export class EmergentBiweeklyAssessmentModuleData extends GenericModuleData {
  constructor() {
    super(LessonPlanModuleTypes.EmergentBiweeklyAssessment, LessonPlanModuleTitles.EmergentBiweeklyAssessment, 300, 1);
  }

  public title?: string;

  public letterId?: number;

  public letterSounds?: number;

  public readingLevel?: number;

  public score?: number;
}

export interface IEmergentBiweeklyAssessmentModuleProps {
  data: EmergentBiweeklyAssessmentModuleData;
  isDisabled?: boolean;
  formRef?: any;
  onSave: (values: any) => void;
  onChange: () => void;
}

export const EmergentBiweeklyAssessmentModule: React.FunctionComponent<IEmergentBiweeklyAssessmentModuleProps> = ({
  data,
  isDisabled,
  formRef,
  onSave,
  onChange,
}) => {
  const [scoreRangeString, setScoreRangeString] = React.useState('');

  const onScoreChanged = (e: any) => setScoreRangeString(getScoreRangeString(e.target.value));

  React.useEffect(() => {
    setScoreRangeString(getScoreRangeString(data?.score ?? 0));
  }, []);

  return (
    <FfFormikForm
      formRef={formRef}
      initialValues={data}
      validationClass={EmergentBiweeklyAssessmentModuleData}
      submit={(values) => Promise.resolve(onSave(values))}
      onChange={onChange}
    >
      <Form>
        <Stack>
          <Box p="2em" bg={'ff.blue'}>
            <Box mb="2em" color="ff.white">
              Note: This section is not completed every lesson. If not assessing, complete Writing section.
            </Box>
            <Box p="2em" bg="ff.white">
              <FfFormikInput id="title" label="Title" type="text" isDisabled={isDisabled} placeholder="Insert title here" />
              <Stack direction="row">
                <FfFormikSelect id="letterId" label="Letter ID" isDisabled={isDisabled} options={letterOptions} />
                <FfFormikSelect id="letterSounds" label="Letter Sounds" isDisabled={isDisabled} options={letterOptions} />
                <FfFormikSelect id="readingLevel" label="Reading Level" isDisabled={isDisabled} options={readingLevelOptions} />
                <FfFormikInput id="score" type="number" label="Score" width="75px" isDisabled={isDisabled} onChange={onScoreChanged} />
                {scoreRangeString && (
                  <Box fontSize="16px" minW="200px" pt="34px">
                    % - {scoreRangeString}
                  </Box>
                )}
              </Stack>
            </Box>
          </Box>
        </Stack>
        <Flex textAlign="right" mt="1em">
          <FfFormikButton type={FfButtonTypes.Submit} variant={'outline'} isDisabled={isDisabled}>
            Save and Continue
          </FfFormikButton>
        </Flex>
      </Form>
    </FfFormikForm>
  );
};
