import { FormControl, FormErrorMessage, FormLabel, Input, StyleProps } from '@chakra-ui/react';
import { Field } from 'formik';
import { ReactNode } from 'react';

export interface FfFormikPhoneProps extends StyleProps {
  label: ReactNode;
  id: string;
  placeholder?: string;
  isDisabled?: boolean;
}

export const FfFormikPhone: React.FunctionComponent<FfFormikPhoneProps> = (props: FfFormikPhoneProps) => {
  const { ...rest } = props;
  return (
    <Field id={props.id} name={props.id}>
      {({ field, form, meta }: { field: any; form: any; meta: any }) => (
        <FormControl isInvalid={meta.error && meta.touched}>
          <FormLabel htmlFor={props.id}>{props.label}</FormLabel>
          <Input
            type="text"
            onBlur={form.handleBlur}
            isDisabled={props.isDisabled ? props.isDisabled : false}
            alt={`${props.label} Input`}
            placeholder={props.placeholder ? props.placeholder : ''}
            value={field.value}
            onChange={(e) => {
              form.setFieldValue(props.id, e.target.value);
            }}
            {...rest}
          />
          {meta.error && meta.touched && <FormErrorMessage>{meta.error}</FormErrorMessage>}
        </FormControl>
      )}
    </Field>
  );
};
