import { cloneDeep } from 'lodash';
import { EarlyRunningRecordModuleData } from '../EarlyRunningRecordModule';
import { EarlySirModuleData } from '../EarlySirModule';
import { EarlyTutorReadAloudModuleData } from '../EarlyTutorReadAloudModule';
import { EarlyWarmupModuleData } from '../EarlyWarmupModule';
import { EarlyWordplayModuleData } from '../EarlyWordplayModule';
import { EarlyWritingModuleData } from '../EarlyWritingModule';
import { EmergentBiweeklyAssessmentModuleData } from '../EmergentBiweeklyAssessmentModule';
import { EmergentLetterFocusModuleData } from '../EmergentLetterFocusModule';
import { EmergentPaSkillModuleData } from '../EmergentPaSkillModule';
import { EmergentSirModuleData } from '../EmergentSirModule';
import { EmergentTutorReadAloudModuleData } from '../EmergentTutorReadAloudModule';
import { EmergentWarmupModuleData } from '../EmergentWarmupModule';
import { EmergentWordplayModuleDataV3 } from '../EmergentWordplayModuleV3';
import { EmergentWritingModuleData } from '../EmergentWritingModule';

const moduleSets: { [key: string]: any } = {
  EarlyReader: [
    {
      lessonContentCode: 0,
      json: new EarlyWarmupModuleData(),
    },
    {
      lessonContentCode: 1,
      json: new EarlyRunningRecordModuleData(),
    },
    {
      lessonContentCode: 2,
      json: new EarlyWordplayModuleData(),
    },
    {
      lessonContentCode: 3,
      json: new EarlySirModuleData(),
    },
    {
      lessonContentCode: 4,
      json: new EarlyWritingModuleData(),
    },
    {
      lessonContentCode: 5,
      json: new EarlyTutorReadAloudModuleData(),
    },
  ],
  EmergentReader: [
    {
      lessonContentCode: 20,
      json: new EmergentWarmupModuleData(),
    },
    {
      lessonContentCode: 21,
      json: new EmergentLetterFocusModuleData(),
    },
    {
      lessonContentCode: 22,
      json: new EmergentPaSkillModuleData(),
    },
    {
      lessonContentCode: 23,
      json: new EmergentSirModuleData(),
    },
    {
      lessonContentCode: 24,
      json: new EmergentWordplayModuleDataV3(),
    },
    {
      lessonContentCode: 25,
      json: new EmergentWritingModuleData(),
    },
    {
      lessonContentCode: 26,
      json: new EmergentBiweeklyAssessmentModuleData(),
    },
    {
      lessonContentCode: 27,
      json: new EmergentTutorReadAloudModuleData(),
    },
  ],
};

export const getModuleSet = (moduleType: string) => cloneDeep(moduleSets[moduleType]);
