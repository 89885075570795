import { EditIcon } from '@chakra-ui/icons';
import { Avatar, Box, Flex, Heading, Spacer, Stack } from '@chakra-ui/react';
import { FFRoles, UserDto } from '@edanalytics/ff_be_se';
import { useAtom } from 'jotai';
import Spaceguy from '../../assets/images/Spaceguy.svg';
import { UserSlug } from './UserSlug';
import { FfRouterLink as Link } from '../FfRouterLink';
import { SchoolServiceAtoms } from '../../services/School';
import { userInfoAtom } from '../../ffApi';

export interface IUserBannerProps {
  user: UserDto;
  isEditLinkEnabled?: boolean;
}

export const UserBanner: React.FunctionComponent<IUserBannerProps> = ({ user, isEditLinkEnabled }) => {
  const [school] = useAtom(SchoolServiceAtoms.getCurrentSchool);
  const [userInfo] = useAtom(userInfoAtom);
  return (
    <Flex gap={5} align="center" fontSize="12px">
      <Box flex={0} textAlign="center" alignItems="center">
        <Avatar bg={'ff.blue'} size="lg" src={Spaceguy} mb=".2em" />
        <Heading color={'ff.blue'} style={{ fontSize: '.9em' }}>
          STAFF
        </Heading>
      </Box>
      <Box>
        <Stack direction="row" spacing={5}>
          <Heading mb=".25em">{user.fullName}</Heading>
          {isEditLinkEnabled && (
            <Link to={`/users/${user.id}/edit`}>
              <EditIcon fontSize={'1.3em'} />
            </Link>
          )}
        </Stack>
        <UserSlug user={user} />
      </Box>
      <Spacer />
      <Box>
        <Link
          cursor="pointer"
          textDecoration="underline !important"
          color="#ff0000"
          to={userInfo.roleId === FFRoles.Staff ? '/users' : `/schools/${school?.id}/users`}
        >
          See All Staff
        </Link>
      </Box>
    </Flex>
  );
};
