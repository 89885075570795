/* eslint-disable @typescript-eslint/no-unused-vars */
import { Container } from '@chakra-ui/react';
import React from 'react';
import { useAtom, Provider } from 'jotai';
import { SchoolServiceAtoms } from '../services/School';
import { FfErrorBoundary } from '../components/FfErrorBoundary';

export const IntentionalError: React.FunctionComponent = () => {
  const [intentionalError] = useAtom(SchoolServiceAtoms.intentionalErrorEndpoint);

  return <Container maxWidth="100%">I'm an intentional error page, you should never see this text!</Container>;
};
