import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  useDisclosure,
  BackgroundProps,
  AlertDialogCloseButton,
  Box,
} from '@chakra-ui/react';
import React from 'react';

export interface FfAlertDialogProps {
  onClose: () => void;
  header: string;
  body: React.ReactElement;
  footer?: React.ReactElement;
  open?: boolean;
  bg?: BackgroundProps['bg'];
  showCloseButton?: boolean;
}

export const FfAlertDialog: React.FunctionComponent<FfAlertDialogProps> = (props: FfAlertDialogProps) => {
  const [bootstrapped, setBootstrapped] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  // eslint-disable-next-line no-debugger
  React.useEffect(() => {
    // toggle alert state
    if (!bootstrapped) {
      return;
    }
    if (props.open) {
      onOpen();
    } else {
      onClose();
    }
  }, [props.open]);

  // we need a color for the close button that accounts for props.bg, and be generic enough to work for all bg colors
  const darkBackground = props.bg === 'ff.blue' || props.bg === 'ff.goals' || props.bg === 'ff.lightBlue';
  const closeColor = darkBackground ? 'ff.white' : 'ff.blue';
  const headerColor = darkBackground ? 'ff.goals' : 'ff.blue';

  React.useEffect(() => {
    setBootstrapped(true);
  }, []);
  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={props.onClose}>
      <AlertDialogOverlay border="ff.goals 1px solid">
        <AlertDialogContent bg={props.bg}>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            <Box color={headerColor}>{props.header}</Box>
          </AlertDialogHeader>
          {props.showCloseButton && <AlertDialogCloseButton color={closeColor} />}
          <AlertDialogBody>{props.body}</AlertDialogBody>
          {props.footer && <AlertDialogFooter>{props.footer}</AlertDialogFooter>}
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
