import React from 'react';
import { HStack, Text } from '@chakra-ui/react';
import { UserDto } from '@edanalytics/ff_be_se';
import { useAtomValue } from 'jotai';
import { UserServiceAtoms } from '../../services/User';

export const UserList: React.FunctionComponent<{ userList?: UserDto[]; description: string }> = ({ userList, description }) => {
  const userCommas = userList?.map((u) => u.fullName)?.join(', ') ?? '';

  return (
    <>
      <HStack>
        <Text fontWeight="bold">{description}:</Text>
        <Text>{userCommas}</Text>
      </HStack>
    </>
  );
};

export const FecList: React.FunctionComponent<{}> = () => {
  const userList = useAtomValue(UserServiceAtoms.getCurrentFECs);
  return UserList({ userList, description: 'FEC' });
};
export const IcList: React.FunctionComponent<{}> = () => {
  const userList = useAtomValue(UserServiceAtoms.getCurrentICs);
  return UserList({ userList, description: 'IC' });
};
