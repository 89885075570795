/* eslint-disable no-nested-ternary */
import { ColumnDef } from '@tanstack/react-table';
import { useAtom, useAtomValue } from 'jotai';
import { EngagementSummaryDto, getEngagementString, StudentDto, EngagementRow } from '@edanalytics/ff_be_se';
import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Flex, Heading } from '@chakra-ui/react';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { FC, ReactNode } from 'react';
import { FfTable } from '../FfTable';
import { FfDivider } from '../FfDivider';
import { Empty } from '../Empty';
import { persistNavAtom } from '../../atoms/navAtom';
import { CreateFamilyInteractionButton, defaultCell, MonthYearFormat, ShortFormatDateTime } from '../Utils';
import { EngagementLinkCell, EngagementsTotalsCell, SuccessRateCell } from './EngagementSummaryList';
import { EngagementServiceAtoms } from '../../services/FamilyEngagement';

export interface IFamilyInteractionListProps {
  children?: any;
  student?: StudentDto;
}

interface IAccordionExpand {
  index: number;
  header: string;
  children: ReactNode | ReactNode[];
}

const AccordionExpand: FC<IAccordionExpand> = ({ index, children, header }) => (
  <AccordionItem key={index} borderBottom="1px solid #333" pb="0em">
    {({ isExpanded }) => (
      <>
        <Heading>
          <AccordionButton>
            <Box flex={0} marginRight="1em">
              {isExpanded ? <MinusIcon fontSize="12px" /> : <AddIcon fontSize="12px" />}
            </Box>
            <Box marginRight="1em">
              <Heading size="sm" style={{ fontWeight: '500' }}>
                {header}
              </Heading>
            </Box>
          </AccordionButton>
        </Heading>
        <AccordionPanel style={{ paddingTop: 0, paddingBottom: 0 }}>{children}</AccordionPanel>
      </>
    )}
  </AccordionItem>
);

export const EngagementSummaryAccordion: React.FunctionComponent<IFamilyInteractionListProps> = () => {
  const [engagementSummariesData] = useAtom(EngagementServiceAtoms.getAllEngagementSummaries);
  const navAtomProps = useAtomValue(persistNavAtom);
  const engagementSummaries = engagementSummariesData ?? [];
  const columns: ColumnDef<EngagementSummaryDto>[] = [
    {
      accessorFn: () => {},
      cell: () => 'Summary',
      id: 'Summary',
    },
    {
      accessorFn: (row) => row,
      cell: (info: any) => EngagementsTotalsCell(info.getValue() as EngagementSummaryDto),
      id: 'rates',
    },
    {
      accessorFn: (row) => row,
      cell: (info: any) => SuccessRateCell(info.getValue() as EngagementSummaryDto),
      id: 'successRate',
    },
  ];

  const detailColumns: ColumnDef<EngagementRow>[] = [
    defaultCell<EngagementRow>('date', (row) => ShortFormatDateTime(row.date as unknown as string)),
    defaultCell<EngagementRow>('engagementType', (row) => getEngagementString(row.engagementType)),
    defaultCell<EngagementRow>('success', (row) => (row.success === undefined ? '' : row.success ? 'Successful' : 'Unsuccessful')),
    defaultCell<EngagementRow>('notes', (row) => row.notes ?? ''),
    {
      accessorFn: (row) => row,
      cell: (info: any) => EngagementLinkCell(info.getValue() as EngagementRow),
      id: 'link',
    },
  ];

  const headerText = (txt: string) => MonthYearFormat(txt, engagementSummaries[0]?.lastContactDate.timeZone);
  return (
    <Box mb="3em">
      <Flex>
        <Box>
          <Heading color={'ff.blue'}>Family Engagement Log</Heading>
        </Box>
        <Box textAlign="right" flex={1}>
          <CreateFamilyInteractionButton {...navAtomProps} />
        </Box>
      </Flex>
      <FfDivider />
      {engagementSummaries.length ? (
        <Accordion allowMultiple={true} defaultIndex={[0]}>
          {engagementSummaries.map((eng, index) => (
            <AccordionExpand
              key={index}
              index={index}
              header={headerText(engagementSummaries[index].lastContactDate.date as unknown as string)}
            >
              <FfTable
                columns={detailColumns}
                data={engagementSummaries[index].allVisits}
                enablePagination={false}
                showHeaders={false}
                removeMarginBottomBorder={true}
              />
              <FfTable
                columns={columns}
                data={[engagementSummaries[index]]}
                enablePagination={false}
                showHeaders={false}
                removeMarginBottomBorder={true}
              />
            </AccordionExpand>
          ))}
        </Accordion>
      ) : (
        <Empty
          description="No family interactions"
          label="Add Family Interaction"
          path={`/schools/${navAtomProps.schoolId}/family-interaction/create`}
        />
      )}
    </Box>
  );
};
