export enum PaSkillTypes {
  PA1 = 1,
  PA2 = 2,
  PA3 = 3,
  PA4 = 4,
  PA5 = 5,
  PA6 = 6,
  PA7 = 7,
  PA8 = 8,
  PA9 = 9,
  PA10 = 10,
  PA11 = 11,
  PA12 = 12,
}

export const getPaSkillTypeString = (paSkill: PaSkillTypes): string => {
  switch (paSkill) {
    case PaSkillTypes.PA1:
      return 'PA Skill 1';
    case PaSkillTypes.PA2:
      return 'PA Skill 2';
    case PaSkillTypes.PA3:
      return 'PA Skill 3';
    case PaSkillTypes.PA4:
      return 'PA Skill 4';
    case PaSkillTypes.PA5:
      return 'PA Skill 5';
    case PaSkillTypes.PA6:
      return 'PA Skill 6';
    case PaSkillTypes.PA7:
      return 'PA Skill 7';
    case PaSkillTypes.PA8:
      return 'PA Skill 8';
    case PaSkillTypes.PA9:
      return 'PA Skill 9';
    case PaSkillTypes.PA10:
      return 'PA Skill 10';
    case PaSkillTypes.PA11:
      return 'PA Skill 11';
    case PaSkillTypes.PA12:
      return 'PA Skill 12';
    default:
      throw new Error('No defined value!');
  }
};
