/* eslint-disable @typescript-eslint/no-unused-vars */
import { FormControl, FormErrorMessage, FormLabel, Select, StyleProps } from '@chakra-ui/react';
import { Field, FieldProps, FormikProps } from 'formik';
import { ReactNode } from 'react';
import { FfFormikOption } from './FfFormikOption';

export interface FfFormikSelectProps<T = {}> extends StyleProps {
  label: ReactNode;
  options: FfFormikOption[];
  id: string;
  isDisabled?: boolean;
  placeholder?: string;
  value?: string | ReadonlyArray<string> | number | undefined;
  onChange?: (value: FfFormikOption, form: FormikProps<T>) => void | undefined | true;
}
// Note: (null ?? undefined) evaluates to undefined

/**
 * Return true in onChange handler to override default action.
 */
export const FfFormikSelect = <T = {},>(props: FfFormikSelectProps<T>) => {
  const { label, options, id, isDisabled, onChange, value, ...rest } = props;
  return (
    <Field id={props.id} name={props.id}>
      {({ form, field, meta }: FieldProps<T>) => (
        <FormControl isDisabled={props.isDisabled || form.isSubmitting} isInvalid={!!meta.error && meta.touched}>
          <FormLabel htmlFor={props.id}>{props.label}</FormLabel>
          <Select
            onBlur={form.handleBlur}
            name={props.id}
            value={value ?? (field.value as any) ?? undefined}
            placeholder={props.placeholder ?? 'Select...'}
            onChange={(e) => {
              // find the key in the options
              // const option = props.options.find((o) => o.key === (e.target.firstChild as any).innerHTML);
              const option = props.options[e.target.selectedIndex - 1];

              if (props.onChange) {
                const done = props.onChange(option, form);
                if (done) return;
              }
              form.setFieldValue(props.id, option?.value);
            }}
            {...rest}
          >
            {props.options.map((o, i) => (
              <option value={o.value} key={i}>
                {o.key}
              </option>
            ))}
          </Select>
          {meta.error && meta.touched && <FormErrorMessage>{meta.error}</FormErrorMessage>}
        </FormControl>
      )}
    </Field>
  );
};
