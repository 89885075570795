/* eslint-disable no-nested-ternary */
import { Box, Flex, FormControl, FormLabel, Stack, Text } from '@chakra-ui/react';
import { Form } from 'formik';
import { useAtom } from 'jotai';
import { RESET, useResetAtom } from 'jotai/utils';
import React, { useEffect } from 'react';
import { DateTimePicker } from '../DateTimePicker';
import { FfFormikButton } from '../Formik/FfFormikButton';
import { FfFormikForm } from '../Formik/FfFormikForm';
import { FfButtonTypes } from '../../models/Button';
import { enrollmentDialogState, editEnrollmentAtomApi, EntryDate } from '../../services/EnrollmentAtoms';
import { StudentServiceAtoms } from '../../services/Student';

/*
// Here are the cases:
// - From a list of students
//  - From links in the table
//    1. Activate a specific student, with the id of the student
//    2. Deactivate a specific student, with the id of the student and the id of the enrollment
//    3. Edit the enrollment dates of a specific student, with the id of the student and the ids of the enrollments
//  - From the bulk edit buttons, with student checkboxes
//    4. Activate a list of students, with the ids of the students. Only enter one date
//    5. Deactivate a list of students, with the ids of the students. Only enter one date
// - From a single student enrollment (from the student details page)
//  - From links in the table
//    6. Edit the enrollment dates of a specific student, with the id of the student 
//    7. Only if it is the last enrollment, allow to remove the exit date
//    8. Only if it is the last enrollment, add a delete link to remove the enrollment
//  - From the top buttons
//    9. Show Activate button if the student is deactivated. Only enter one date
//    10. Show Deactivate button if the student is activated. Only enter one date
*/

export const EditStudentEnrollmentForm: React.FunctionComponent = () => {
  const resetStudentEnrollment = useResetAtom(StudentServiceAtoms.getCurrentStudentEnrollment);
  const [dialogState, setDialogState] = useAtom(enrollmentDialogState);
  const [validationMessage, updateEnrollment] = useAtom(editEnrollmentAtomApi);

  const canRemoveExitDate = dialogState.isLastEnrollment && dialogState.mode === 'edit';

  const onChange = (values: { entryDate?: Date; exitDate?: Date }) => {
    if (values.entryDate !== dialogState?.entryDate || values.exitDate !== dialogState?.exitDate) {
      setDialogState({ ...dialogState!, entryDate: values.entryDate, exitDate: values.exitDate });
    }
  };

  const onSave = (values: EntryDate) => {
    updateEnrollment({ ...dialogState, entryDate: values.entryDate, exitDate: values.exitDate });
  };

  const resetForm = () => {
    updateEnrollment(RESET);
    resetStudentEnrollment();
    setDialogState(RESET);
  };

  useEffect(() => {
    if (validationMessage === 'Ok') {
      resetForm();
    }
  }, [dialogState, validationMessage]);

  const onCancel = resetForm;

  // for use of Formik
  const initialValues = { entryDate: dialogState?.entryDate, exitDate: dialogState?.exitDate };

  return (
    <FfFormikForm initialValues={initialValues} validationClass={EntryDate} submit={(values) => Promise.resolve(onSave(values))}>
      {({ values, isSubmitting, errors }) => {
        const editingEntryDate = dialogState?.mode === 'activate' || dialogState?.mode === 'edit';
        const editingExitDate = dialogState?.mode === 'deactivate' || dialogState?.mode === 'edit';
        const editingDates = editingEntryDate && editingExitDate;
        const hasEntryDate = dialogState?.entryDate !== null;
        const hasExitDate = dialogState?.exitDate !== null;

        let activeOrInActive = '';

        if (editingDates) {
          activeOrInActive = hasEntryDate && hasExitDate ? ' active and inactive' : hasEntryDate ? ' active' : ' inactive';
        } else if (editingEntryDate) {
          activeOrInActive = ' active';
        } else {
          activeOrInActive = ' inactive';
        }

        return (
          <Form>
            <Stack>
              <Box color={'ff.goals'} fontSize="xl" fontWeight="bold">
                {dialogState.studentName
                  ? `When would you like to mark ${dialogState.studentName} as`
                  : 'When would you like to mark these students as'}
                {activeOrInActive}?
              </Box>
              <Stack color={'ff.blue'} direction="row">
                {editingEntryDate && (
                  <>
                    <FormControl>
                      <FormLabel color={'ff.goals'} fontSize="sm" fontWeight="normal">
                        Active as of:
                      </FormLabel>
                      <div style={{ position: 'relative', zIndex: '100' }}>
                        <DateTimePicker
                          wrapperClassName="filter-date"
                          isClearable={false}
                          showIcon={false}
                          showTimeSelect={false}
                          dateFormat="MM/dd/yyyy"
                          placeholderText="Activation Date"
                          selectedDate={values.entryDate}
                          // filterDate={filterDate}
                          onChange={(value) => {
                            onChange({ ...values, entryDate: value });
                          }}
                        />
                      </div>
                    </FormControl>
                  </>
                )}
                {editingExitDate && (
                  <>
                    <FormControl>
                      <FormLabel color={'ff.goals'} fontSize="sm" fontWeight="normal">
                        Inactive as of:
                      </FormLabel>
                      <div style={{ position: 'relative', zIndex: '100' }}>
                        <DateTimePicker
                          wrapperClassName="filter-date"
                          isClearable={canRemoveExitDate}
                          showIcon={false}
                          showTimeSelect={false}
                          dateFormat="MM/dd/yyyy"
                          placeholderText="Deactivation Date"
                          selectedDate={values.exitDate}
                          // filterDate={filterDate}
                          onChange={(value) => {
                            onChange({ ...values, exitDate: value });
                          }}
                        />
                      </div>
                    </FormControl>
                  </>
                )}
              </Stack>

              <Text fontSize="xs" color={'ff.palePink'}>
                {validationMessage}
              </Text>
            </Stack>
            <Flex color={'ff.blue'} textAlign="center" mt="1em">
              <FfFormikButton type={FfButtonTypes.Button} variant={'solid'} onClick={onCancel}>
                Cancel
              </FfFormikButton>
              <FfFormikButton type={FfButtonTypes.Submit} variant={'cta'}>
                Save
              </FfFormikButton>
            </Flex>
          </Form>
        );
      }}
    </FfFormikForm>
  );
};
