import React from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import { RenderHTML } from './Utils';

interface IConfirmProps {
  headerText: string; // Delete Lesson Plan
  bodyText: string;
  continueText?: string; // Are you sure? You can't undo this action afterwards.
  cancelText?: string;
  active: boolean;
  okHandle: () => Promise<void> | void;
  cancelHandle: () => Promise<void> | void;
}

export const ConfirmAlert: React.FunctionComponent<IConfirmProps> = ({
  active,
  bodyText,
  headerText,
  okHandle,
  cancelHandle,
  cancelText,
  continueText,
}) => {
  // TODO: Not sure where this belongs
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  return (
    <AlertDialog isOpen={active} leastDestructiveRef={cancelRef} onClose={cancelHandle}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {headerText}
          </AlertDialogHeader>
          <AlertDialogBody>{RenderHTML(bodyText)}</AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={cancelHandle}>
              {cancelText ?? 'Cancel'}
            </Button>
            <Button colorScheme="red" onClick={okHandle} ml={3}>
              {continueText ?? 'Continue'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
