import { InfoOutlineIcon } from '@chakra-ui/icons';
import { PlacementWithLogical, Tooltip } from '@chakra-ui/react';
import React from 'react';

export interface FfTooltipProps {
  label: string;
  placement?: PlacementWithLogical | undefined;
  fontSize?: string;
}

export const FfTooltip: React.FunctionComponent<FfTooltipProps> = (props: FfTooltipProps) => (
  <Tooltip
    hasArrow
    placement={`${props.placement ? props.placement : 'top'}`}
    fontSize={`${props.fontSize ? props.fontSize : 'xs'}`}
    rounded="md"
    p="1em"
    mb="1em"
    label={props.label}
  >
    <InfoOutlineIcon fontSize="md" mr=".5em" />
  </Tooltip>
);
