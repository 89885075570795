import { useNavigate } from 'react-router-dom';
import { Box, Button, Flex, Heading } from '@chakra-ui/react';
import _ from 'lodash';
import { SchoolDto, StudentUserScheduleDto, UserDto } from '@edanalytics/ff_be_se';
import { ColumnDef } from '@tanstack/react-table';
import { FfTable } from './FfTable';
import { DebugCard } from './DebugCard';
import { SessionScheduleSlug } from './SessionScheduleSlug';
import { GradeLevelLabel, ReadingLevelLabel, StudentSummaryLink } from './Utils';

export interface IStudentScheduleTableProps {
  studentUserSchedules: StudentUserScheduleDto[];
  school: SchoolDto;
  user: UserDto;
}

export const StudentScheduleTable: React.FunctionComponent<IStudentScheduleTableProps> = ({ studentUserSchedules, school }) => {
  const sortedstudentUserSchedules = _.sortBy(studentUserSchedules, [(o) => o.dayOfWeek]);
  const groupedStudentUserSchedules = _.groupBy(sortedstudentUserSchedules, 'studentId');
  const nav = useNavigate();

  const columns: ColumnDef<any>[] = [
    {
      accessorFn: (row) => row.student,
      id: 'student',
      cell: (info: any) => StudentSummaryLink(school, info.getValue()),
      header: () => 'Student',
    },
    {
      accessorKey: 'gradeLevel',
      id: 'gradeLevel',
      cell: (info: any) => GradeLevelLabel(info.getValue()),
      header: () => 'Grade',
    },
    {
      accessorKey: 'readingLevel',
      id: 'level',
      cell: (info: any) => ReadingLevelLabel(info.getValue()),
      header: () => 'Level',
    },
    {
      accessorKey: 'day1',
      // todo: TutorLinks are changing visible URL but not routing (StudentLink works)
      cell: (info: any) => (info.getValue() ? <SessionScheduleSlug school={school} session={info.getValue()} /> : '-'),
      header: () => 'Day 1',
      id: 'day1',
      enableSorting: false,
    },
    {
      accessorKey: 'day2',
      cell: (info: any) => (info.getValue() ? <SessionScheduleSlug school={school} session={info.getValue()} /> : '-'),
      header: () => 'Day 2',
      id: 'day2',
      enableSorting: false,
    },
    {
      accessorKey: 'day3',
      cell: (info: any) => (info.getValue() ? <SessionScheduleSlug school={school} session={info.getValue()} /> : '-'),
      header: () => 'Day 3',
      id: 'day3',
      enableSorting: false,
    },
    {
      accessorKey: 'day4',
      cell: (info: any) => (info.getValue() ? <SessionScheduleSlug school={school} session={info.getValue()} /> : '-'),
      header: () => 'Day 4',
      id: 'day4',
      enableSorting: false,
    },
    {
      accessorKey: 'day5',
      cell: (info: any) => (info.getValue() ? <SessionScheduleSlug school={school} session={info.getValue()} /> : '-'),
      header: () => 'Day 5',
      id: 'day5',
      enableSorting: false,
    },
  ];

  const studentIds = Object.keys(groupedStudentUserSchedules);
  const tableData: any[] = [];

  studentIds.forEach((studentId) => {
    const listOfSessions = groupedStudentUserSchedules[studentId];

    const studentSession: any = {
      student: '',
      school: '',
      gradeLevel: '',
      readingLevel: '',
      day1: '',
      day2: '',
      day3: '',
      day4: '',
      day5: '',
    };

    listOfSessions.forEach((ses, idx) => {
      if (idx === 0) {
        studentSession.student = ses.student;
        studentSession.school = ses.student?.schoolId;
        studentSession.gradeLevel = ses.student?.gradeLevel;
        studentSession.readingLevel = ses.student?.readingLevel;
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const ddd = ses;
      switch (ses.dayOfWeek) {
        case 1:
          studentSession.day1 = {
            dayOfWeek: ses.dayOfWeek,
            startTime: ses.startTime,
            endTime: ses.endTime,
            tutor: ses.user,
          };
          break;
        case 2:
          studentSession.day2 = {
            dayOfWeek: ses.dayOfWeek,
            startTime: ses.startTime,
            endTime: ses.endTime,
            tutor: ses.user,
          };
          break;
        case 3:
          studentSession.day3 = {
            dayOfWeek: ses.dayOfWeek,
            startTime: ses.startTime,
            endTime: ses.endTime,
            tutor: ses.user,
          };
          break;
        case 4:
          studentSession.day4 = {
            dayOfWeek: ses.dayOfWeek,
            startTime: ses.startTime,
            endTime: ses.endTime,
            tutor: ses.user,
          };
          break;
        case 5:
          studentSession.day5 = {
            dayOfWeek: ses.dayOfWeek,
            startTime: ses.startTime,
            endTime: ses.endTime,
            tutor: ses.user,
          };
          break;
        default:
          break;
      }
    });

    tableData.push(studentSession);
  });

  return (
    <Box mb="3em">
      <Flex>
        <Box flex={1}>
          <Heading color={'ff.blue'}>Students - Standard Schedule</Heading>
        </Box>
        <Box justifyContent="right">
          <Button onClick={() => nav(`/schools/${school.id}/tutors/assign`)}>Revise Schedule</Button>
        </Box>
      </Flex>
      <Box>
        <FfTable columns={columns} data={tableData} />
        <DebugCard data={[{ name: 'tableData', data: tableData }]} />
      </Box>
    </Box>
  );
};
