import { Flex } from '@chakra-ui/react';
import { EmergentTutorReadAloudModuleData } from '../LessonPlanContent/EmergentTutorReadAloudModule';
import { PrintableInputs } from '../PrintableInputs';

export interface IPrintableEmergentTutorReadAloudModuleProps {
  data: EmergentTutorReadAloudModuleData;
}

export const PrintableEmergentTutorReadAloudModule: React.FunctionComponent<IPrintableEmergentTutorReadAloudModuleProps> = ({ data }) => (
  <Flex wrap="wrap" align="top" width="100%" css={{ breakInside: 'avoid-page' }}>
    <PrintableInputs.Input minWidth={10} css={{ width: '100%' }} label="Title" children={data.title} />
  </Flex>
);
