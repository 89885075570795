import { PatchLessonPlanDto } from '@edanalytics/ff_be_se';
import { atom, Getter } from 'jotai';
import { persistNavPropsAtom } from '../../atoms/navAtom';
import { apiClientAtom } from '../../ffApi';

const ObservationsServiceFactory = (get: Getter) => ({
  get: async (schoolId: number, studentId: number, id: number): Promise<any> => {
    const response = await get(apiClientAtom).get(`/schools/${schoolId}/students/${studentId}/lesson-plans/${id}/`);

    return response.data as any;
  },

  getAllForSchool: async (schoolId: number, params?: any): Promise<any[]> => {
    const response = await get(apiClientAtom).get(`/schools/${schoolId}/observations`, { params });
    return response.data.results as any[];
  },
  deleteObservations: async (schoolId: number, ids: PatchLessonPlanDto[]): Promise<any> => {
    // Follow
    const response = await get(apiClientAtom).patch(`/schools/${schoolId}/observations`, ids);
    return response.data as any;
  },
});

const ObservationsService = atom(ObservationsServiceFactory);

export { ObservationsService };

export const ObservationsServiceAtoms = {
  getCurrentLessonPlan: persistNavPropsAtom(async (get, nav) => {
    if (!nav.schoolId || !nav.studentId || !nav.lessonPlanId) return undefined;
    return ObservationsServiceFactory(get).get(nav.schoolId, nav.studentId, nav.lessonPlanId);
  }),
  getSchoolObservations: persistNavPropsAtom(async (get, nav) => {
    if (!nav.schoolId) return [];
    return ObservationsServiceFactory(get).getAllForSchool(nav.schoolId);
  }),
  deleteObservation: (patches: PatchLessonPlanDto[]) =>
    persistNavPropsAtom(async (get, nav) => ObservationsServiceFactory(get).deleteObservations(nav.schoolId!, patches)),
};
