/* eslint-disable no-nested-ternary */
import { atom, useAtomValue } from 'jotai';
import { isDate, isString } from 'lodash';
import { DateTime } from 'luxon';
import { persistNavAtom } from '../atoms/navAtom';

const offsetDateDt = (dateTime: DateTime, timeZone?: string) => {
  const withZone = timeZone ? dateTime.setZone(timeZone) : dateTime;
  return DateTime.fromISO(withZone.toISO({ includeOffset: false }));
};

const hoursDiffDt = (date: DateTime, timeZone: string) => offsetDateDt(date).diff(offsetDateDt(date, timeZone), 'hours').hours;

const addDiff = (sign: number, date: Date, timeZone: string) => {
  const dateTime = DateTime.fromJSDate(date);
  const diff = hoursDiffDt(dateTime, timeZone);
  return dateTime.plus({ hours: diff * sign }).toJSDate();
};

const toFromUi =
  (sign: number) =>
  (timeZone: string) =>
  (data: Date): Date => {
    const innerDate = isString(data) ? DateTime.fromISO(data).toJSDate() : data;
    return (Number.isNaN(innerDate.valueOf())
      ? undefined
      : isDate(data)
      ? addDiff(sign, innerDate, timeZone)
      : isString(data)
      ? addDiff(sign, innerDate, timeZone).toISOString()
      : undefined) as unknown as Date; /// SO LONG WE HAVE STRINGS DEFINED AS DATES WE NEED TO DO THIS
  };

const toUiWithTimezone = toFromUi(-1);
const fromUiWithTimezone = toFromUi(1);

export const useTimezone = () => {
  const navAtomProps = useAtomValue(persistNavAtom);
  const zone = navAtomProps.timezone;
  if (zone === undefined) return [undefined, undefined, undefined];

  const toUI = toUiWithTimezone(zone);
  const fromUi = fromUiWithTimezone(zone);
  return [navAtomProps.timezone, toUI, fromUi] as [string, typeof toUI, typeof fromUi];
};

export const useTimezoneFormat = (formatter: (dateStr: string, timezone?: string) => string) => {
  const navAtomProps = useAtomValue(persistNavAtom);
  return (dateStr: string) => formatter(dateStr, navAtomProps.timezone);
};

// https://github.com/moment/luxon/blob/7fae9c72a84b244dd1210013a71d3fab03ba21ea/docs/zones.md#keeplocaltime
// better luxon API implementation
// var rezoned = local.setZone("America/Los_Angeles", { keepLocalTime: true });
export const timeZoneMapAtom = atom((get) => {
  const { timezone } = get(persistNavAtom) ?? {};
  if (timezone === undefined) return { timezone: undefined, toUI: undefined, fromUi: undefined };

  const toUI = toUiWithTimezone(timezone);
  const fromUi = fromUiWithTimezone(timezone);
  return { timezone, toUI, fromUi } as { timezone: string; toUI: typeof toUI; fromUi: typeof fromUi };
});
