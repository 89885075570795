import { Stack } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import React from 'react';
import Background from '../../assets/images/background-stars-sparse.svg';
import { enrollmentDialogState } from '../../services/EnrollmentAtoms';
import { FfAlertDialog } from '../FfAlertDialog';
import { EditStudentEnrollmentForm } from './EditEnrollmentForm';

export const EnrollmentDialog: React.FunctionComponent<{}> = () => {
  const [dialogState, setDialogState] = useAtom(enrollmentDialogState);

  const onClose = () => {
    setDialogState(RESET);
  };

  return (
    <FfAlertDialog
      bg={'ff.blue'}
      header=""
      open={dialogState.mode !== 'closed'}
      onClose={() => onClose()}
      showCloseButton={true}
      body={
        <Stack
          maxW="1200px"
          w="100%"
          color={'ff.goals'}
          direction="column"
          align="center"
          p="1em"
          backgroundImage={Background}
          justify="space-between"
        >
          <EditStudentEnrollmentForm />
        </Stack>
      }
    />
  );
};
