import { useNavigate } from 'react-router-dom';
import { DateTime as DT } from 'luxon';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Button, Flex, Heading, List, ListItem } from '@chakra-ui/react';
import { SessionObservationDto, SchoolDto, UserDto } from '@edanalytics/ff_be_se';
import { useAtom } from 'jotai';
import _ from 'lodash';
import { DebugCard } from './DebugCard';
import { FfDivider } from './FfDivider';
import { ObservationSlug } from './ObservationSlug';
import { TutorServiceAtoms } from '../services/Tutor';
import { getStartTimeYearMonthKey } from '../utils/utils';

export interface IRecentObservationsGroupedAccordionProps {
  school: SchoolDto;
  tutor: UserDto;
}

const DateAccordionItem = (monthKey: any, data: any, index: number) => (
  <AccordionItem key={index} borderTop="0px solid #333">
    {({ isExpanded }) => (
      <>
        <Heading>
          <AccordionButton>
            <Box flex={0} marginRight="1em">
              {isExpanded ? <MinusIcon fontSize="12px" /> : <AddIcon fontSize="12px" />}
            </Box>
            <Box marginRight="1em">
              <Heading size="sm" style={{ fontWeight: '500' }}>
                {DT.fromFormat(monthKey, 'yyyy-MM').toFormat('MMMM yyyy')}
              </Heading>
            </Box>
          </AccordionButton>
        </Heading>
        <AccordionPanel style={{ paddingTop: 0, paddingBottom: 0 }}>
          <List style={{ marginLeft: '1.75em' }}>
            {data?.map((observation: SessionObservationDto, sIndex: number) => (
              <ListItem key={sIndex}>
                <ObservationSlug observation={observation} />
              </ListItem>
            ))}
          </List>
        </AccordionPanel>
      </>
    )}
  </AccordionItem>
);

export const RecentObservationsGroupedAccordion: React.FunctionComponent<IRecentObservationsGroupedAccordionProps> = ({
  school,
  tutor,
}) => {
  const nav = useNavigate();
  const [observations] = useAtom(TutorServiceAtoms.getCurrentTutorObservations);
  const sortedObservations = _.sortBy(observations, [(o) => o.createdAt]).reverse();
  const groupedObservations = _.groupBy(
    sortedObservations,
    getStartTimeYearMonthKey((s) => s.createdAt),
  );

  return (
    groupedObservations && (
      <Box mb="3em">
        <Flex>
          <Box>
            <Heading color={'ff.blue'}>Recent Observations</Heading>
          </Box>
          <Box textAlign="right" flex={1}>
            <Button onClick={() => nav(`/schools/${school.id}/tutors/${tutor.id}/observe`)}>Start Observation</Button>
          </Box>
        </Flex>
        <FfDivider />
        <Accordion allowMultiple={true} defaultIndex={[0]}>
          {groupedObservations &&
            Object.keys(groupedObservations).map((dateKey: string, index: number) =>
              DateAccordionItem(dateKey, groupedObservations[dateKey], index),
            )}
        </Accordion>
        <DebugCard data={[{ name: 'observations', data: observations }]} />
      </Box>
    )
  );
};
