import { LessonPlanModuleDto } from '@edanalytics/ff_be_se';
import { LessonPlanHighlightFieldLabels, LessonPlanHighlightFields, LessonPlanModuleTypes } from './LessonPlanModuleEnum';
import { getReadingLevelTypeString } from './ReadingLevelEnum';

const getHighlightValue = (field: LessonPlanHighlightFields, modules: LessonPlanModuleDto[]) => {
  switch (field) {
    case LessonPlanHighlightFields.Warmup:
      return (
        modules.find((m) => m.json.moduleKey === LessonPlanModuleTypes.EarlyWarmup)?.json.activity ??
        modules.find((m) => m.json.moduleKey === LessonPlanModuleTypes.EmergentWarmup)?.json.activity
      );
    case LessonPlanHighlightFields.RunningRecord:
      return (
        modules.find((m) => m.json.moduleKey === LessonPlanModuleTypes.EarlyRunningRecord)?.json.title ??
        modules.find((m) => m.json.moduleKey === LessonPlanModuleTypes.EmergentBiweeklyAssessment)?.json.title
      );
    case LessonPlanHighlightFields.ReadingLevel: {
      const readingLevel =
        modules.find((m) => m.json.moduleKey === LessonPlanModuleTypes.EarlyRunningRecord)?.json.readingLevel ??
        modules.find((m) => m.json.moduleKey === LessonPlanModuleTypes.EmergentBiweeklyAssessment)?.json.readingLevel;
      return readingLevel ? getReadingLevelTypeString(readingLevel) : undefined;
    }
    case LessonPlanHighlightFields.OtherNotes:
      return (
        modules.find((m) => m.json.moduleKey === LessonPlanModuleTypes.EarlyTutorReadAloud)?.json.additionalNotes ??
        modules.find((m) => m.json.moduleKey === LessonPlanModuleTypes.EmergentTutorReadAloud)?.json.additionalNotes
      );
    default:
      return undefined;
  }
};

// Construct highlights array in display order, omitting null value items
export const constructHighlightsJson = (modules: LessonPlanModuleDto[]) =>
  [
    {
      key: LessonPlanHighlightFields.Warmup,
      label: LessonPlanHighlightFieldLabels.Warmup,
      value: getHighlightValue(LessonPlanHighlightFields.Warmup, modules),
    },
    {
      key: LessonPlanHighlightFields.RunningRecord,
      label: LessonPlanHighlightFieldLabels.RunningRecord,
      value: getHighlightValue(LessonPlanHighlightFields.RunningRecord, modules),
    },
    {
      key: LessonPlanHighlightFields.ReadingLevel,
      label: LessonPlanHighlightFieldLabels.ReadingLevel,
      value: getHighlightValue(LessonPlanHighlightFields.ReadingLevel, modules),
    },
    {
      key: LessonPlanHighlightFields.OtherNotes,
      label: LessonPlanHighlightFieldLabels.OtherNotes,
      value: getHighlightValue(LessonPlanHighlightFields.OtherNotes, modules),
    },
  ].filter((x) => x.value);
