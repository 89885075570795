import { ColumnDef } from '@tanstack/react-table';
import { StudentDto } from '@edanalytics/ff_be_se';
import { studentSort } from './FfTable/Filters';
import { StudentLink } from './Utils';

export const defaultCell = <T = unknown>(name: keyof T, toString?: (row: T) => string) =>
  ({
    accessorFn: (row) => row,
    id: name,
    cell: (info: any) => (toString ? toString(info.getValue()) : info.getValue()[name]),
  } as ColumnDef<T>);

export const StudentColumn = <T extends { student?: StudentDto } = { student: StudentDto }>() =>
  ({
    accessorFn: (row) => row.student,
    id: 'fullName',
    cell: (info: any) => StudentLink(info.getValue()),
    header: () => 'Student',
    filterFn: 'studentNode',
    sortingFn: studentSort,
  } as ColumnDef<T>);
