import { Box, Container, Flex, Heading } from '@chakra-ui/react';
import React from 'react';
import { useAtom } from 'jotai';
import { DebugCard } from '../../components/DebugCard';
import { SessionsAccordion } from '../../components/SessionsAccordion';
import { LessonPlanServiceAtoms } from '../../services/LessonPlan/LessonPlanService';
import { Empty } from '../../components/Empty';
import { SchoolServiceAtoms } from '../../services/School';
import { isEmpty } from '../../utils/utils';
import { FrequentlyUsedList } from '../../components/FrequentlyUsedList';

export const TutorDashboardScreen: React.FunctionComponent = () => {
  const [school] = useAtom(SchoolServiceAtoms.getCurrentSchool);
  const [lessonPlans] = useAtom(LessonPlanServiceAtoms.getScheduledTutorLessonPlans);

  return (
    <Container maxWidth="100%">
      <Flex mb="1em">
        <Box flex={1}>
          <Heading color={'ff.blue'} pb="1em">
            Tutor Dashboard: This Week's Sessions
          </Heading>
        </Box>
        <Box textAlign="right" flex={0}>
          <FrequentlyUsedList
            items={[
              {
                path: `#`,
                name: 'Blank Emergent Reader Lesson Plan',
                showPrinter: true,
              },
              {
                path: `#`,
                name: 'Blank Early Reader Lesson Plan',
                showPrinter: true,
              },
              {
                path: `/schools/${school?.id}/lesson-plans/`,
                name: 'See All Past Lesson Plans',
              },
            ]}
          />
        </Box>
      </Flex>
      {school && lessonPlans && !isEmpty(lessonPlans) ? (
        <SessionsAccordion school={school} lessonPlans={lessonPlans} groupType="date" />
      ) : (
        <Empty description="No lesson plans." />
      )}
      <DebugCard data={[{ name: 'lessonPlans', data: lessonPlans }]} />
    </Container>
  );
};
