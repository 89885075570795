import { atom } from 'jotai';
import { RESET } from 'jotai/utils';

const parseSafe = (text: string) => {
  try {
    return JSON.parse(text);
  } catch {
    return undefined;
  }
};

export const atomStorageSession = <T>(storage_key: string, initial?: T) => {
  const refreshAtom = atom(0);

  const updateNewAtom = atom(
    async (get) => {
      get(refreshAtom);
      const textValue = sessionStorage.getItem(storage_key);
      return textValue !== null ? parseSafe(textValue) : initial;
    },
    (get, set, newValue: T | typeof RESET) => {
      if (newValue === RESET) {
        sessionStorage.removeItem(storage_key);
        set(refreshAtom, (a) => a + 1);
        return;
      }
      set(refreshAtom, (a) => a + 1);
      sessionStorage.setItem(storage_key, JSON.stringify(newValue));
    },
  );
  return updateNewAtom;
};
