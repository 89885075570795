import {
  FamilyInteractionDto,
  IFamilyInteraction,
  IFamilyInteractionAttempt,
  PostFamilyInteractionDto,
  PutFamilyInteractionDto,
} from '@edanalytics/ff_be_se';
import { atom, Getter } from 'jotai';
import { apiClientAtom } from '../ffApi';
import { timeZoneMapAtom } from '../hooks/useTimezone';
import { atomApiWithNavAndRead } from '../utils/async-atom';
// timeZoneMapAtom

const mapFamilyInteractionAttempts = (data: IFamilyInteractionAttempt[], timeZoneMap: ((date: Date) => Date) | undefined) => {
  if (timeZoneMap === undefined) return data;
  const mapOne = ({ interactionDate, ...rest }: IFamilyInteractionAttempt) => ({ interactionDate: timeZoneMap(interactionDate), ...rest });
  return data.filter((a) => a !== undefined).map(mapOne) as typeof data;
};

const mapFamilyInteractions = (data: IFamilyInteraction, timeZoneMap: ((date: Date) => Date) | undefined) => {
  if (timeZoneMap === undefined) return data;
  const mapOne = (row: IFamilyInteraction) => {
    if (row === undefined) return undefined;
    const { attempts, ...rest } = row;
    return { attempts: mapFamilyInteractionAttempts(attempts, timeZoneMap), ...rest };
  };
  return mapOne(data);
};

const mapTimeZoneAtom = atom((get) => {
  const { toUI, fromUi } = get(timeZoneMapAtom);
  return {
    toUI: (data: IFamilyInteraction) => mapFamilyInteractions(data, toUI),
    fromUi: (data: IFamilyInteraction) => mapFamilyInteractions(data, fromUi),
  };
});

const familyInteractionServiceFactory = (get: Getter) => ({
  getFamilyInteractionsByStudent: async (schoolId: number, studentId: number): Promise<FamilyInteractionDto> => {
    const response = await get(apiClientAtom).get(`/schools/${schoolId}/students/${studentId}/family-interactions`);
    return get(mapTimeZoneAtom).toUI(response.data) as FamilyInteractionDto;
  },
  getFamilyInteraction: async (schoolId: number, id: number): Promise<FamilyInteractionDto> => {
    const response = await get(apiClientAtom).get(`/schools/${schoolId}/family-interactions/${id}`);
    return get(mapTimeZoneAtom).toUI(response.data) as FamilyInteractionDto;
  },
  getAllFamilyInterSummariesByStudent: async (schoolId: number, studentId: number): Promise<FamilyInteractionDto[]> => {
    const response = await get(apiClientAtom).get(
      `/schools/${schoolId}/family-interactions/${studentId}/family-interactions/list-summaries`,
    );
    return response.data.results as FamilyInteractionDto[];
  },
  getAllFamilyInterSummariesBySchool: async (schoolId: number): Promise<FamilyInteractionDto[]> => {
    const response = await get(apiClientAtom).get(`/schools/${schoolId}/family-interactions/list-summaries`);
    return response.data.results as FamilyInteractionDto[];
  },
  getAllFamilyInteractionsBySchool: async (schoolId: number): Promise<FamilyInteractionDto[]> => {
    const response = await get(apiClientAtom).get(`/schools/${schoolId}/family-interactions`);
    return response.data as FamilyInteractionDto[];
  },
  postFamilyInteractions: async (schoolId: number, interaction: PutFamilyInteractionDto): Promise<FamilyInteractionDto> => {
    const response = await get(apiClientAtom).post(
      `/schools/${schoolId}/students/${interaction.studentId}/family-interactions/`,
      get(mapTimeZoneAtom).fromUi(interaction as IFamilyInteraction),
    );
    return get(mapTimeZoneAtom).toUI(response.data) as FamilyInteractionDto;
  },
  putFamilyInteractions: async (schoolId: number, interaction: PostFamilyInteractionDto): Promise<FamilyInteractionDto> => {
    const response = await get(apiClientAtom).put(
      `/schools/${schoolId}/students/${interaction.studentId}/family-interactions/${interaction.id}`,
      get(mapTimeZoneAtom).fromUi(interaction as IFamilyInteraction),
    );
    return get(mapTimeZoneAtom).toUI(response.data) as FamilyInteractionDto;
  },
  deleteFamilyInteractions: async (schoolId: number, familyInteractionsId: number): Promise<void> => {
    await get(apiClientAtom).delete(`/schools/${schoolId}/family-interactions/${familyInteractionsId}`);
  },
});

const FamilyInteractionService = atom(familyInteractionServiceFactory);
export { FamilyInteractionService };

export const FamilyInteractionServiceAtoms = {
  getFamilyInteractionsById: atomApiWithNavAndRead(async (get, nav) => {
    if (!nav.schoolId || !nav.familyInteractionId) return undefined;
    const { attempts, ...rest } = await familyInteractionServiceFactory(get).getFamilyInteraction(nav.schoolId, nav.familyInteractionId);

    return { ...rest, attempts: attempts.map((a) => ({ ...a, interactionDate: new Date(a.interactionDate) })) } as FamilyInteractionDto;
  }),
  getAllFamilyInteractionsBySchool: atomApiWithNavAndRead(async (get, nav) => {
    if (!nav.schoolId) return undefined;
    return familyInteractionServiceFactory(get).getAllFamilyInteractionsBySchool(nav.schoolId);
  }),
  getAllFamilyInteractionsByStudent: atomApiWithNavAndRead(async (get, nav) => {
    if (!nav.schoolId || !nav.studentId) return undefined;
    return familyInteractionServiceFactory(get).getAllFamilyInterSummariesBySchool(nav.schoolId);
  }),
  getAllFamilyInterSummariesBySchool: atomApiWithNavAndRead(async (get, nav) => {
    if (!nav.schoolId) return undefined;
    return familyInteractionServiceFactory(get).getAllFamilyInterSummariesBySchool(nav.schoolId);
  }),
  // getCurrentStudents: navPropsAtom(async (get, nav) => {
  //   if (!nav.schoolId) return undefined;
  //   return studentServiceFactory(get).getStudents(nav.schoolId);
  // }),
};
