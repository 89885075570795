import { EarlyTutorReadAloudModuleData } from '../LessonPlanContent/EarlyTutorReadAloudModule';
import { PrintableInputs } from '../PrintableInputs';

export interface IPrintableEarlyTutorReadAloudModuleProps {
  data: EarlyTutorReadAloudModuleData;
}

export const PrintableEarlyTutorReadAloudModule: React.FunctionComponent<IPrintableEarlyTutorReadAloudModuleProps> = ({ data }) => (
  <PrintableInputs.Input w="100%" minWidth={10} label="Title" children={data.title} />
);
