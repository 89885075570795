import { Container, Heading } from '@chakra-ui/react';
import React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { FFRoles, UserDto } from '@edanalytics/ff_be_se';
import { useAtom } from 'jotai';
import { roleSort, userSort } from '../../components/FfTable/Filters';
import { FfTable } from '../../components/FfTable';
import { DebugCard } from '../../components/DebugCard';
import { NameTextList, RoleLabel, UserEditLink, UserLink, TutorLink } from '../../components/Utils';
import { UserServiceAtoms } from '../../services/User';
import { Empty } from '../../components/Empty';

export const StaffDirectoryScreen: React.FunctionComponent = () => {
  const [users] = useAtom(UserServiceAtoms.getCurrentUsers);

  const columns: ColumnDef<UserDto>[] = [
    {
      accessorFn: (row) => row,
      id: 'fullName',
      cell: (info: any) =>
        info.getValue().roleId === FFRoles.Tutor ? TutorLink(info.getValue().schools[0], info.getValue()) : UserLink(info.getValue()),
      header: () => 'Name',
      filterFn: 'user',
      sortingFn: userSort, // cell value not sortable with default sort fn
    },
    {
      accessorKey: 'email',
      id: 'email',
      cell: (info: any) => info.getValue(),
      header: () => 'Email',
      filterFn: 'email',
    },
    {
      accessorKey: 'schools',
      id: 'schools',
      cell: (info: any) => NameTextList(info.getValue()),
      header: () => 'School(s)',
      enableSorting: false,
      filterFn: 'schoolsNode',
    },
    {
      accessorFn: (row) => row.role,
      id: 'role',
      cell: (info: any) => RoleLabel(info.getValue()),
      header: () => 'Role',
      filterFn: 'roleNode',
      sortingFn: roleSort,
    },
    {
      accessorFn: (row) => row,
      id: 'edit',
      cell: (info: any) => UserEditLink(info.getValue()),
      header: '',
    },
  ];

  return (
    <Container maxWidth="100%">
      <Heading color={'ff.blue'} mb="1em">
        Staff Directory
      </Heading>
      {users ? <FfTable columns={columns} data={users} /> : <Empty description="No users." />}
      <DebugCard data={[{ name: 'users', data: users }]} />
    </Container>
  );
};
