/* eslint-disable max-len */
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Box, Flex, Stack } from '@chakra-ui/react';
import { Form } from 'formik';
import React from 'react';
import { FfButtonTypes } from '../../models/Button';
import { FfFormikButton } from '../Formik/FfFormikButton';
import { FfFormikForm } from '../Formik/FfFormikForm';
import { FfFormikInput } from '../Formik/FfFormikInput';
import { FfFormikSelect } from '../Formik/FfFormikSelect';
import { comprehensionOptions, getScoreRangeString, readingLevelOptions } from './LessonPlanUtils';
import { GenericModuleData } from './models/genericLessonModule';
import { LessonPlanModuleTitles, LessonPlanModuleTypes } from './models/LessonPlanModuleEnum';

export class EarlyRunningRecordModuleData extends GenericModuleData {
  constructor() {
    super(LessonPlanModuleTypes.EarlyRunningRecord, LessonPlanModuleTitles.EarlyRunningRecord, 240, 1);
  }

  public title?: string;

  public readingLevel?: number;

  public comprehension?: number;

  public score?: number;
}

export interface IEarlyRunningRecordModuleProps {
  data: EarlyRunningRecordModuleData;
  isDisabled?: boolean;
  formRef?: any;
  onSave: (values: any) => void;
  onChange: () => void;
}

export const EarlyRunningRecordModule: React.FunctionComponent<IEarlyRunningRecordModuleProps> = ({
  data,
  isDisabled,
  formRef,
  onSave,
  onChange,
}) => {
  const [scoreRangeString, setScoreRangeString] = React.useState('');

  const onScoreChanged = (e: any) => setScoreRangeString(getScoreRangeString(e.target.value));

  React.useEffect(() => {
    setScoreRangeString(getScoreRangeString(data?.score ?? 0));
  }, []);

  return (
    <FfFormikForm
      formRef={formRef}
      initialValues={data}
      validationClass={EarlyRunningRecordModuleData}
      submit={(values) => Promise.resolve(onSave(values))}
      onChange={onChange}
    >
      <Form>
        <Stack>
          <Box mb="1em">
            <InfoOutlineIcon mr="1em" />
            First time administering a running record? Have your student read approximately 100 words and note how many they read correctly
            on their own.
          </Box>
          <Box p="2em" bg={'ff.blue'}>
            <Box mb="2em" color="ff.white">
              Note: This section is not completed every lesson. If no Running Record, add 2 min to both Reading and Writing.
            </Box>
            <Box p="2em" bg="ff.white">
              <FfFormikInput id="title" label="Title" type="text" isDisabled={isDisabled} placeholder="Insert title here" />
              <Stack direction="row">
                <FfFormikSelect id="readingLevel" label="Reading Level" isDisabled={isDisabled} options={readingLevelOptions} />
                <FfFormikSelect id="comprehension" label="Comprehension" isDisabled={isDisabled} options={comprehensionOptions} />
                <FfFormikInput id="score" type="number" label="Score" width="75px" isDisabled={isDisabled} onChange={onScoreChanged} />
                {scoreRangeString && (
                  <Box fontSize="16px" minW="200px" pt="34px">
                    % - {scoreRangeString}
                  </Box>
                )}
              </Stack>
            </Box>
          </Box>
        </Stack>
        <Flex textAlign="right" mt="1em">
          <FfFormikButton type={FfButtonTypes.Submit} variant={'outline'} isDisabled={isDisabled}>
            Save and Continue
          </FfFormikButton>
        </Flex>
      </Form>
    </FfFormikForm>
  );
};
