import { Container, Heading } from '@chakra-ui/react';
import React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { UserDto } from '@edanalytics/ff_be_se';
import { useAtom } from 'jotai';
import { FfTable } from '../../components/FfTable';
import { TutorLink } from '../../components/Utils';
import { DebugCard } from '../../components/DebugCard';
import { UserServiceAtoms } from '../../services/User';
import { Empty } from '../../components/Empty';
import { isEmpty } from '../../utils/utils';
import { SchoolServiceAtoms } from '../../services/School';

export const TutorsScreen: React.FunctionComponent = () => {
  const [tutors] = useAtom(UserServiceAtoms.getCurrentTutors);
  const [school] = useAtom(SchoolServiceAtoms.getCurrentSchool);

  const columns: ColumnDef<UserDto>[] = [
    {
      accessorFn: (row) => row,
      id: 'fullName',
      cell: (info: any) => (school ? TutorLink(school, info.getValue()) : ''),
      header: () => 'Name',
      filterFn: 'tutor',
    },
    {
      accessorKey: 'email',
      id: 'email',
      cell: (info: any) => info.getValue(),
      header: () => 'Email',
      filterFn: 'email',
    },
    {
      accessorKey: 'languages',
      id: 'languages',
      cell: (info: any) => info.getValue(),
      header: () => 'Languages',
    },
  ];

  return (
    <Container maxWidth="100%">
      <Heading color={'ff.blue'} mb="1em">
        All Tutors
      </Heading>
      {!isEmpty(tutors) ? <FfTable columns={columns} data={tutors} /> : <Empty description="No tutors." />}
      <DebugCard
        data={[
          { name: 'school', data: school },
          { name: 'tutors', data: tutors },
        ]}
      />
    </Container>
  );
};
