import { Container, Heading } from '@chakra-ui/react';
import React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { UserDto } from '@edanalytics/ff_be_se';
import { useAtom } from 'jotai';
import _ from 'lodash';
import { FfTable } from '../../components/FfTable';
import { DebugCard } from '../../components/DebugCard';
import { UserServiceAtoms } from '../../services/User';
import { UserLink } from '../../components/Utils';
import { UserDeactivateButton } from '../../components/User/UserDeActivateButton';
import { UserActivateButton } from '../../components/User/UserActivateButton';

export const SchoolActivateUsersScreen: React.FunctionComponent = () => {
  const [users] = useAtom(UserServiceAtoms.getSchoolUsers);

  const columns: ColumnDef<UserDto>[] = [
    {
      accessorFn: (row) => row,
      id: 'fullName',
      cell: (info: any) => {
        const user = info.getValue() as UserDto;
        return user.active ? UserLink(user) : user.fullName;
      },
      header: () => 'Name',
    },
    {
      accessorKey: 'email',
      id: 'email',
      cell: (info: any) => info.getValue(),
      header: () => 'Email',
    },
    {
      accessorFn: (row) => row,
      id: 'activate',
      cell: (info: any) => {
        const user = info.getValue() as UserDto;
        return user.active ? (
          <UserDeactivateButton user={info.getValue() as UserDto} />
        ) : (
          <UserActivateButton user={info.getValue() as UserDto} />
        );
      },
      header: '',
    },
  ];

  const sortedUsers = _.sortBy(users, [(u) => u.lastName, (u) => u.createdAt]);

  return (
    <Container maxWidth="100%">
      <Heading color={'ff.blue'}>Activate Users</Heading>
      <FfTable columns={columns} data={sortedUsers} enablePagination={true} />
      <DebugCard data={[{ name: 'sortedUsers', data: sortedUsers }]} />
    </Container>
  );
};
