import { Flex } from '@chakra-ui/react';
import React from 'react';
import { EmergentBiweeklyAssessmentModuleData } from '../LessonPlanContent/EmergentBiweeklyAssessmentModule';
import { letterOptions, readingLevelOptions } from '../LessonPlanContent/LessonPlanUtils';
import { PrintableInputs } from '../PrintableInputs';

export interface IPrintableEmergentBiweeklyAssessmentModuleProps {
  data: EmergentBiweeklyAssessmentModuleData;
}

export const PrintableEmergentBiweeklyAssessmentModule: React.FunctionComponent<IPrintableEmergentBiweeklyAssessmentModuleProps> = ({
  data,
}) => (
  <Flex wrap="wrap" align="top" width="100%" css={{ breakInside: 'avoid-page' }}>
    <PrintableInputs.Input minWidth={10} css={{ width: '100%' }} label="Title" children={data.title} />
    <PrintableInputs.Input
      css={{ flex: 'auto 0.5 1' }}
      minWidth={4}
      label="Letter ID"
      children={letterOptions.find((option) => option.value === data.letterId)?.key}
    />
    <PrintableInputs.Input
      css={{ flex: 'auto 0.5 1' }}
      minWidth={4}
      label="Letter Sounds"
      children={letterOptions.find((option) => option.value === data.letterSounds)?.key}
    />
    <PrintableInputs.Input
      css={{ flex: 'auto 0.5 1' }}
      minWidth={4}
      label="Reading Level"
      children={readingLevelOptions.find((option) => option.value === data.readingLevel)?.key}
    />
    <PrintableInputs.Input css={{ flex: 'auto 0.5 1' }} minWidth={4} label="Score" children={data.score} />
  </Flex>
);
