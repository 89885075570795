import { Checkbox, Flex, Heading, Stack } from '@chakra-ui/react';
import { Form } from 'formik';
import React from 'react';
import { FfButtonTypes } from '../../models/Button';
import { FfFormikButton } from '../Formik/FfFormikButton';
import { FfFormikForm } from '../Formik/FfFormikForm';
import { FfFormikTextarea } from '../Formik/FfFormikTextarea';
import { GenericModuleData } from './models/genericLessonModule';
import { LessonPlanModuleTitles, LessonPlanModuleTypes } from './models/LessonPlanModuleEnum';
import { UnitNumberTypes2 } from './models/UnitNumberEnum';

export class EmergentWordplayModuleDataV3 extends GenericModuleData {
  constructor() {
    super(LessonPlanModuleTypes.EmergentWordplay, LessonPlanModuleTitles.EmergentWordplay, 420, 3);
  }

  public activities?: number[];

  public sightWords?: number;

  public title?: string;

  public notes?: string;
}

export interface IEmergentWordplayModuleProps {
  data: EmergentWordplayModuleDataV3;
  isDisabled?: boolean;
  formRef?: any;
  onSave: (values: any) => void;
  onChange: () => void;
}

export const EmergentWordplayModuleV3: React.FunctionComponent<IEmergentWordplayModuleProps> = ({
  data,
  isDisabled,
  formRef,
  onSave,
  onChange,
}) => {
  const handleChange = async (e: any) => {
    const formValues = formRef.current.values.activities ? formRef.current.values.activities : [];
    if (e.target.checked) {
      formValues.push(e.target.value);
      formRef.current.setFieldValue('activities', formValues);
    } else {
      const index = formValues.indexOf(e.target.value);
      formValues.splice(index, 1);
      await formRef.current.setFieldValue('activities', formValues);
    }
  };

  const isChecked = (value: any) => {
    if (data.activities?.toString().includes(value)) {
      return true;
    }
    return false;
  };

  return (
    <FfFormikForm
      formRef={formRef}
      initialValues={data}
      validationClass={EmergentWordplayModuleDataV3}
      submit={(values) => Promise.resolve(onSave(values))}
      onChange={onChange}
    >
      <Form>
        <Stack>
          <Heading as="h2" size="sm">
            Activities
          </Heading>
          <Flex>
            <Stack spacing={[1, 5]} direction={['column', 'row']}>
              <Checkbox
                isDisabled={isDisabled}
                key={'PICTURE_SORT'}
                defaultChecked={isChecked('1')}
                onChange={handleChange}
                value={UnitNumberTypes2.PICTURE_SORT}
              >
                Picture Sort
              </Checkbox>
              <Checkbox
                isDisabled={isDisabled}
                key={'SIGHT_WORD_PRACTICE'}
                defaultChecked={isChecked('2')}
                onChange={handleChange}
                value={UnitNumberTypes2.SIGHT_WORD_PRACTICE}
              >
                Sight Word Practice
              </Checkbox>
              <Checkbox
                isDisabled={isDisabled}
                key={'SHARED_READING_PASSAGE'}
                defaultChecked={isChecked('3')}
                onChange={handleChange}
                value={UnitNumberTypes2.SHARED_READING_PASSAGE}
              >
                Shared Reading Passage
              </Checkbox>
            </Stack>
          </Flex>
          <FfFormikTextarea noOfLines={10} id="notes" label="Notes" isDisabled={isDisabled} placeholder="Add your notes here" />
        </Stack>
        <Flex textAlign="right" mt="1em">
          <FfFormikButton type={FfButtonTypes.Submit} variant={'outline'} isDisabled={isDisabled}>
            Save and Continue
          </FfFormikButton>
        </Flex>
      </Form>
    </FfFormikForm>
  );
};
