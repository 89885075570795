import React, { useEffect } from 'react';

export interface ClickOutsideProps {
  cb: () => void;
  children: any;
}
export const ClickOutside: React.FunctionComponent<ClickOutsideProps> = (props: ClickOutsideProps) => {
  const node = React.createRef<HTMLDivElement>();
  const [, setFocused] = React.useState(false);
  const handleOutsideClick = (e: any) => {
    const thisNode = node.current;
    if (thisNode) {
      if (thisNode.contains(e.target)) {
        return;
      }
      setFocused(false);
      if (props.cb) {
        props.cb();
      }
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleOutsideClick, false);
    return () => {
      document.removeEventListener('click', handleOutsideClick, false);
    };
  });

  return (
    <div ref={node} className="click-outside">
      {props.children}
    </div>
  );
};
