import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Box, Flex, Stack } from '@chakra-ui/react';
import { Form } from 'formik';
import { FfButtonTypes } from '../../models/Button';
import { FfFormikButton } from '../Formik/FfFormikButton';
import { FfFormikForm } from '../Formik/FfFormikForm';
import { FfFormikTextarea } from '../Formik/FfFormikTextarea';
import { GenericModuleData } from './models/genericLessonModule';
import { LessonPlanModuleTitles, LessonPlanModuleTypes } from './models/LessonPlanModuleEnum';

export class EmergentWarmupModuleData extends GenericModuleData {
  constructor() {
    super(LessonPlanModuleTypes.EmergentWarmup, LessonPlanModuleTitles.EmergentWarmup, 60, 1);
  }

  public activity?: string;

  public notes?: string;
}

export interface IEmergentWarmupModuleProps {
  data: EmergentWarmupModuleData;
  isDisabled?: boolean;
  formRef?: any;
  onSave: (values: any) => void;
  onChange: () => void;
}

export const EmergentWarmupModule: React.FunctionComponent<IEmergentWarmupModuleProps> = ({
  data,
  isDisabled,
  formRef,
  onSave,
  onChange,
}) => (
  <FfFormikForm
    formRef={formRef}
    initialValues={data}
    validationClass={EmergentWarmupModuleData}
    submit={(values) => Promise.resolve(onSave(values))}
    onChange={onChange}
  >
    <Form>
      <Stack>
        <Box mb="1em">
          <InfoOutlineIcon mr="1em" />
          Keep it brief and focus on an activity your student has already completed or a skill they've already mastered.
        </Box>
        <Flex>
          <Box flex={1} mr="2em">
            <FfFormikTextarea id="activity" label="Activity" placeholder="Add your notes here" noOfLines={10} isDisabled={isDisabled} />
          </Box>
          <Box flex={1}>
            <FfFormikTextarea id="notes" label="Notes" placeholder="Add your notes here" noOfLines={10} isDisabled={isDisabled} />
          </Box>
        </Flex>
      </Stack>
      <Flex textAlign="right" mt="1em">
        <FfFormikButton type={FfButtonTypes.Submit} variant={'outline'} isDisabled={isDisabled}>
          Save and Continue
        </FfFormikButton>
      </Flex>
    </Form>
  </FfFormikForm>
);
