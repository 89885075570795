import React, { RefObject } from 'react';
import { Box, Container } from '@chakra-ui/react';
import { IDefaultLayoutProps } from '../Layouts/DefaultLayout';
import { Footer } from '../Layouts/Footer';
import { FallbackHeader } from '../Layouts/Header';
import { ErrorFallback } from './ErrorFallback';

export const FallbackDefaultLayout: React.FunctionComponent<
  Partial<Omit<IDefaultLayoutProps, 'providerKey'>> & { error: Error; resetErrorBoundary: () => void }
> = (props) => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const footerRef = React.useRef<HTMLDivElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [containerHeight, setContainerHeight] = React.useState('');

  const getRefBoundingRectHeight = (ref: RefObject<HTMLDivElement>): number => {
    if (ref.current) {
      return ref.current.getBoundingClientRect().height;
    }
    return 0;
  };

  const getContainerHeight = () => `calc(100vh - (${getRefBoundingRectHeight(headerRef)}px + ${getRefBoundingRectHeight(footerRef)}px))`;

  React.useEffect(() => {
    setContainerHeight(getContainerHeight());
  });

  return (
    <Box className="default">
      <div ref={headerRef}>
        <FallbackHeader />
      </div>
      <Box ref={containerRef} minHeight={containerHeight} bg={props.bg ?? 'ff.water'} className="body">
        <Container maxW={'1200px'}>
          <ErrorFallback error={props.error} resetErrorBoundary={props.resetErrorBoundary} />
        </Container>
      </Box>
      <div ref={footerRef}>
        <Footer />
      </div>
    </Box>
  );
};
