import { Box } from '@chakra-ui/react';
import * as React from 'react';
import { EmergentWarmupModuleData } from '../LessonPlanContent/EmergentWarmupModule';
import { PrintableInputs } from '../PrintableInputs';

export interface IPrintableEmergentWarmupModuleProps {
  data: EmergentWarmupModuleData;
}

export const PrintableEmergentWarmupModule: React.FunctionComponent<IPrintableEmergentWarmupModuleProps> = ({ data }) => (
  <Box css={{ breakInside: 'avoid-page' }}>
    <PrintableInputs.Input width="100%" minWidth={10} label="Activity" children={data.activity} />
    <PrintableInputs.Input width="100%" minWidth={10} label="Notes" children={data.notes} />
  </Box>
);
