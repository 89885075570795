import { Avatar, Box, Flex } from '@chakra-ui/react';
import { UserDto } from '@edanalytics/ff_be_se';

import Spaceguy from '../assets/images/Spaceguy.svg';

export interface ITutorAccordionSlugProps {
  user?: UserDto;
}

export const TutorAccordionSlug: React.FunctionComponent<ITutorAccordionSlugProps> = ({ user }) => (
  <Flex alignItems="center" textAlign="left" width="100%">
    <Box display="flex" flex={0} pr="1em">
      <Avatar bg={'ff.magenta'} size="sm" src={Spaceguy} mb=".2em" />
    </Box>
    <Box width="250px">{user?.fullName}</Box>
  </Flex>
);
