export enum LetterTypes {
  None = 0,
  A = 1,
  B = 2,
  C = 3,
  D = 4,
  E = 5,
  F = 6,
  G = 7,
  H = 8,
  I = 9,
  J = 10,
  K = 11,
  L = 12,
  M = 13,
  N = 14,
  O = 15,
  P = 16,
  Q = 17,
  R = 18,
  S = 19,
  T = 20,
  U = 21,
  V = 22,
  W = 23,
  X = 24,
  Y = 25,
  Z = 26,
}

export const getLetterTypeString = (letter: LetterTypes): string => {
  switch (letter) {
    case LetterTypes.None:
      return '0';
    case LetterTypes.A:
      return '1';
    case LetterTypes.B:
      return '2';
    case LetterTypes.C:
      return '3';
    case LetterTypes.D:
      return '4';
    case LetterTypes.E:
      return '5';
    case LetterTypes.F:
      return '6';
    case LetterTypes.G:
      return '7';
    case LetterTypes.H:
      return '8';
    case LetterTypes.I:
      return '9';
    case LetterTypes.J:
      return '10';
    case LetterTypes.K:
      return '11';
    case LetterTypes.L:
      return '12';
    case LetterTypes.M:
      return '13';
    case LetterTypes.N:
      return '14';
    case LetterTypes.O:
      return '15';
    case LetterTypes.P:
      return '16';
    case LetterTypes.Q:
      return '17';
    case LetterTypes.R:
      return '18';
    case LetterTypes.S:
      return '19';
    case LetterTypes.T:
      return '20';
    case LetterTypes.U:
      return '21';
    case LetterTypes.V:
      return '22';
    case LetterTypes.W:
      return '23';
    case LetterTypes.X:
      return '24';
    case LetterTypes.Y:
      return '25';
    case LetterTypes.Z:
      return '26';
    default:
      throw new Error('No defined value!');
  }
};
