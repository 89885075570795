import { Box, Button, Container, Flex, Heading, Menu, MenuButton, MenuItem, MenuList, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { isEmpty } from 'lodash';
import { useAtom } from 'jotai';
import { FrequentlyUsedList } from '../../components/FrequentlyUsedList';
import { LessonPlanServiceAtoms } from '../../services/LessonPlan/LessonPlanService';
import { GroupType, SessionsAccordion } from '../../components/SessionsAccordion';
import { SchoolServiceAtoms } from '../../services/School';
import { DebugCard } from '../../components/DebugCard';
import { Forms } from '../../assets/forms/forms';
import { FfErrorButtons } from '../../components/ErrorHandling/FfErrorButtons';

export const Dashboard: React.FunctionComponent = () => {
  const [school] = useAtom(SchoolServiceAtoms.getCurrentSchool);
  const [lessonPlans] = useAtom(LessonPlanServiceAtoms.getScheduledSchoolLessonPlans); // WPRK
  const [groupType, setGroupType] = React.useState<GroupType>('tutor');

  const handleChange = (e: any) => {
    setGroupType(e.target.value);
  };

  const groupTypes = [
    {
      value: 'date' as GroupType,
      name: 'Date',
    },
    {
      value: 'tutor' as GroupType,
      name: 'Tutor',
    },
  ];

  return (
    <Container maxWidth="100%">
      <Flex mb="1em">
        <Box flex={1}>
          <Heading color={'ff.blue'}>IC Dashboard: What's on Deck</Heading>
          <DebugCard>
            <FfErrorButtons />
          </DebugCard>
        </Box>
        <Box textAlign="right" flex={0}>
          <FrequentlyUsedList
            items={[
              {
                path: Forms.EmergentReader,
                name: 'Blank Emergent Reader Lesson Plan',
                showPrinter: true,
                isExternal: true,
              },
              {
                path: Forms.EarlyReader,
                name: 'Blank Early Reader Lesson Plan',
                showPrinter: true,
                isExternal: true,
              },
              {
                path: `/schools/${school?.id}/lesson-plans/`,
                name: 'See All Past Lesson Plans',
              },
              {
                path: `/schools/${school?.id}/observations`,
                name: 'See All Observations',
              },
            ]}
          />
        </Box>
      </Flex>
      {school && lessonPlans && (
        <>
          <Stack direction="row">
            <Box mb="1em">See schedule by</Box>
            <Box>
              <Menu autoSelect={true}>
                <MenuButton as={Button} rightIcon={<ChevronDownIcon />} bg="white" size="sm">
                  {groupTypes.find((x) => x.value === groupType)?.name}
                </MenuButton>
                <MenuList color="gray" fontSize="sm">
                  {groupTypes.map((type, index) => (
                    <MenuItem
                      key={index}
                      defaultChecked={type.value === groupType}
                      id={type.value}
                      value={type.value}
                      onClick={handleChange}
                    >
                      {type.name}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </Box>
          </Stack>
          <SessionsAccordion school={school} lessonPlans={lessonPlans} groupType={groupType} showObserveLinks={true} />
        </>
      )}

      {isEmpty(lessonPlans) && <Text>Nothing on deck</Text>}
      <DebugCard data={[{ name: 'lessonPlans', data: lessonPlans }]} />
    </Container>
  );
};
