import { Container, Stack, Text, Box, Img, HStack } from '@chakra-ui/react';
import React from 'react';
import Spaceguy from '../../assets/images/Spaceguy.svg';
import Planet from '../../assets/images/Planet.svg';
import FooterStars from '../../assets/images/FooterStars.svg';
import { EaLogo } from '../Logos/EaLogo';

export const Footer: React.FunctionComponent = () => (
  <Box position="sticky" bottom="0px" bgColor={'ff.blue'} w="100%">
    <Container maxWidth="1200px" color={'ff.goals'} w="100%" p="1em">
      <HStack
        p="2em"
        backgroundImage={FooterStars}
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
        backgroundSize="contain"
        spacing="8"
        flexWrap="nowrap"
        justify="space-between"
      >
        <Img height="85px" src={Spaceguy} />
        <Stack textAlign={'center'} fontSize="13px" spacing={0} m={0}>
          <Box fontStyle="italic">brought to you by</Box>
          <EaLogo width="275px" height="46px" />
          <Stack pb="1em" direction="row" justify="space-around" w="100%">
            <Text>
              <a href="https://www.edanalytics.org/privacy-policy/" target="_blank">
                PRIVACY POLICY
              </a>
            </Text>
            <Text>
              <a href="mailto:futureforward@edanalytics.org">HELP</a>
            </Text>
          </Stack>
          <Stack pb="1em" direction="row" justify="space-around" w="100%">
            <Text>
              <a href="https://forms.gle/ZRbHGN1ymsQTgSuJ8" target="_blank">
                SUBMIT USER FEEDBACK
              </a>
            </Text>
          </Stack>
          <Text fontSize="9px">&copy;2022 Education Analytics, Inc. All Rights Reserved.</Text>
        </Stack>
        <Img src={Planet} />
      </HStack>
    </Container>
  </Box>
);
