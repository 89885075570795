import React from 'react';
import { Box, Flex, Stack } from '@chakra-ui/react';
import { Form } from 'formik';
import { FfFormikButton } from '../Formik/FfFormikButton';
import { FfFormikForm } from '../Formik/FfFormikForm';
import { FfFormikSelect } from '../Formik/FfFormikSelect';
import { FfFormikTextarea } from '../Formik/FfFormikTextarea';
import { lessonNumberOptions, unitNumberOptions } from './LessonPlanUtils';
import { GenericModuleData } from './models/genericLessonModule';
import { LessonPlanModuleTitles, LessonPlanModuleTypes } from './models/LessonPlanModuleEnum';
import { FfFormikInput } from '../Formik/FfFormikInput';
import { FfButtonTypes } from '../../models/Button';

export class EmergentWordplayModuleDataV2 extends GenericModuleData {
  constructor() {
    super(LessonPlanModuleTypes.EmergentWordplay, LessonPlanModuleTitles.EmergentWordplay, 420, 2);
  }

  public activities?: number;

  public sightWords?: number;

  public title?: string;

  public notes?: string;
}

export interface IEmergentWordplayModuleProps {
  data: EmergentWordplayModuleDataV2;
  isDisabled?: boolean;
  formRef?: any;
  onSave: (values: any) => void;
  onChange: () => void;
}

export const EmergentWordplayModuleV2: React.FunctionComponent<IEmergentWordplayModuleProps> = ({
  data,
  isDisabled,
  formRef,
  onSave,
  onChange,
}) => (
  <FfFormikForm
    formRef={formRef}
    initialValues={data}
    validationClass={EmergentWordplayModuleDataV2}
    submit={(values) => Promise.resolve(onSave(values))}
    onChange={onChange}
  >
    <Form>
      <Stack>
        <Flex>
          <Box flex={1} mr="2em">
            <FfFormikSelect id="activities" label="Activities" isDisabled={isDisabled} options={unitNumberOptions} />
          </Box>
          <Box flex={1}>
            <FfFormikSelect id="sightWords" label="Sight Words" isDisabled={isDisabled} options={lessonNumberOptions} />
          </Box>
        </Flex>
        <FfFormikInput id="title" label="Title" type="text" isDisabled={isDisabled} placeholder="Insert title here" />
        <FfFormikTextarea noOfLines={10} id="notes" label="Notes" isDisabled={isDisabled} placeholder="Add your notes here" />
      </Stack>
      <Flex textAlign="right" mt="1em">
        <FfFormikButton type={FfButtonTypes.Submit} variant={'outline'} isDisabled={isDisabled}>
          Save and Continue
        </FfFormikButton>
      </Flex>
    </Form>
  </FfFormikForm>
);
