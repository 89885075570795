import { Circle, Tooltip } from '@chakra-ui/react';
import { AttendanceStatusTypes, Nullable, getLessonStatusTypeString, LessonStatusTypes } from '@edanalytics/ff_be_se';

export interface ILessonPlanStatusProps {
  lessonStatus: LessonStatusTypes | null;
  attendanceStatus?: Nullable<AttendanceStatusTypes>;
  tooltipHidden?: boolean;
}

export const LessonPlanStatus: React.FunctionComponent<ILessonPlanStatusProps> = (props) => {
  let innerColor = 'white';
  let innerBorder = '20px';
  const outerBorder = '30px';

  let label = props.lessonStatus ? getLessonStatusTypeString(props.lessonStatus) : '';

  switch (props.lessonStatus) {
    case undefined:
      innerColor = 'red';
      break;
    case LessonStatusTypes.InProgress:
      label = 'Lesson Plan In Progress';
      innerColor = 'ff.water';
      break;
    case LessonStatusTypes.Submitted:
      label = 'Lesson Plan Complete';
      innerColor = 'ff.green';
      break;
    case LessonStatusTypes.Absence:
      label = 'Absence Lesson Plan';
      innerColor = 'ff.pink';
      break;
    default:
      innerBorder = '28px';
      label = 'No Lesson Plan';
      break;
  }

  // Override lesson status indication if attendanceStatus is not favorable
  if (props.attendanceStatus && props.attendanceStatus === AttendanceStatusTypes.Holiday) {
    label = 'Holiday';
    innerColor = 'ff.blue';
  }

  return (
    <Tooltip hidden={props.tooltipHidden || false} hasArrow label={label} placement="top" rounded="md" p=".5em" mb=".4em">
      <Circle size={outerBorder} bg="gray" color="black">
        <Circle size={innerBorder} bg={innerColor} />
      </Circle>
    </Tooltip>
  );
};
