import { AttendanceStatusTypes, getAttendanceStatusTypeString, SessionLessonPlanDto } from '@edanalytics/ff_be_se';
import { ReactNode } from 'react';
import _ from 'lodash';
import { getLessonNumberTypeString, LessonNumberTypes } from './models/LessonNumberEnum';
import { getLetterTypeString, LetterTypes } from './models/LetterEnum';
import { getLinkedToTypeString, LinkedToTypes } from './models/LinkedToEnum';
import { getPaSkillTypeString, PaSkillTypes } from './models/PaSkillEnum';
import { getReadingComprehensionTypeString, ReadingComprehensionTypes } from './models/ReadingComprehensionEnum';
import { getReadingLevelTypeString, ReadingLevelTypes } from './models/ReadingLevelEnum';
import { getSortSkillTypeString, SortSkillTypes } from './models/SortSkillEnum';
import { UnitNumberOptions } from './models/UnitNumberEnum';
import { getEnumKeys } from '../../utils/utils';
import { getLessonPlanTypeString, LessonPlanTypes } from './models/LessonPlanTypeEnum';
import { GenericModuleData } from './models/genericLessonModule';

export interface LessonPlanHighlightItem {
  key: string;
  label: string;
  value: string;
}
export interface IGenericLessonModuleProps {
  data: GenericModuleData;
  isDisabled?: boolean;
  formRef?: any;
  onSave: (values: any) => void;
  onChange: () => void;
}

export const unitNumberOptions = UnitNumberOptions;

export const lessonNumberOptions = getEnumKeys(LessonNumberTypes).map((r) => ({
  value: r,
  key: `${getLessonNumberTypeString(r)}`,
}));

export const lessonNumber26Options = getEnumKeys(LessonNumberTypes)
  .map((r) => ({
    value: r,
    key: `${getLessonNumberTypeString(r)}`,
  }))
  .filter((r) => r.value <= 26);

export const paSkillOptions = getEnumKeys(PaSkillTypes).map((r) => ({
  value: r,
  key: `${getPaSkillTypeString(r)}`,
}));

export const readingLevelOptions = getEnumKeys(ReadingLevelTypes)
  .map((r) => ({
    value: r,
    key: `${getReadingLevelTypeString(r)}`,
  }))
  .filter((o) => o.value > ReadingLevelTypes.Unspecified);

export const comprehensionOptions = getEnumKeys(ReadingComprehensionTypes).map((r) => ({
  value: r,
  key: `${getReadingComprehensionTypeString(r)}`,
}));

export const sortSkillOptions = getEnumKeys(SortSkillTypes).map((r) => ({
  value: r,
  key: `${getSortSkillTypeString(r)}`,
}));

export const letterOptions = getEnumKeys(LetterTypes).map((r) => ({
  value: r,
  key: `${getLetterTypeString(r)}`,
}));

export const linkedToOptions = getEnumKeys(LinkedToTypes).map((r) => ({
  value: r,
  key: `${getLinkedToTypeString(r)}`,
}));

export const attendanceOptions = getEnumKeys(AttendanceStatusTypes).map((r) => ({
  value: r,
  key: `${getAttendanceStatusTypeString(r)}`,
}));

export const lessonPlanTypeOptions = getEnumKeys(LessonPlanTypes).map((r) => ({
  value: r,
  key: `${getLessonPlanTypeString(r)}`,
}));

export const yesNoOptions = [
  { value: 1, key: 'Yes' },
  { value: 0, key: 'No' },
];

export const lessonPlanTypeDefault = (readingLevelType?: number) => {
  switch (readingLevelType) {
    case ReadingLevelTypes.AA:
    case ReadingLevelTypes.A:
    case ReadingLevelTypes.B:
    case ReadingLevelTypes.C:
      return LessonPlanTypes.EmergentReader;
    case ReadingLevelTypes.D:
    case ReadingLevelTypes.E:
    case ReadingLevelTypes.F:
    case ReadingLevelTypes.G:
    case ReadingLevelTypes.H:
    case ReadingLevelTypes.I:
    case ReadingLevelTypes.J:
    case ReadingLevelTypes.K:
    case ReadingLevelTypes.L:
    case ReadingLevelTypes.M:
    case ReadingLevelTypes.N:
    case ReadingLevelTypes.O:
    case ReadingLevelTypes.P:
    case ReadingLevelTypes.Q:
    case ReadingLevelTypes.R:
    case ReadingLevelTypes.S:
    case ReadingLevelTypes.T:
    case ReadingLevelTypes.U:
    case ReadingLevelTypes.V:
    case ReadingLevelTypes.W:
    case ReadingLevelTypes.X:
    case ReadingLevelTypes.Y:
    case ReadingLevelTypes.Z:
      return LessonPlanTypes.EarlyReader;
    default:
      return undefined;
  }
};

export const getScoreRangeString = (score: number | string) => {
  const scoreNum = typeof score === 'number' ? score : parseInt(score, 10);
  switch (true) {
    case scoreNum < 90:
      return 'Frustrational';
    case scoreNum < 95:
      return 'Instructional';
    default:
      return 'Independent';
  }
};

export const getScoreColor = (score: number | string) => {
  const scoreNum = typeof score === 'number' ? score : parseInt(score, 10);
  switch (true) {
    case scoreNum < 90:
      return 'ff.orange';
    case scoreNum < 95:
      return 'ff.yellow';
    default:
      return 'ff.green';
  }
};

export const minutesToHoursMinutes = (totalMinutes: number) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return `${hours ? `${hours} ${hours > 1 ? 'hours' : 'hour'}${minutes ? ', ' : ''}` : ''}${minutes ? `${minutes} minutes` : ''}`;
};

export const timeDurationOptions = (start: number, end: number, step: number) =>
  _.range(start, end + step, step).map((d) => ({ value: d, key: minutesToHoursMinutes(d) }));

export const getLessonPlanHighlights = (lessonPlan: SessionLessonPlanDto, separator: ReactNode = '; ') =>
  lessonPlan?.highlights?.map((h: LessonPlanHighlightItem, index: number) => (
    <span key={index}>
      {index > 0 && separator}
      <strong>{h.label}</strong>: {h.value}
    </span>
  ));
