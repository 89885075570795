export enum LessonPlanModuleTypes {
  EarlyWarmup = 'EarlyWarmup',
  EarlyRunningRecord = 'EarlyRunningRecord',
  EarlyWordplay = 'EarlyWordplay',
  EarlyStudentInstructionalReading = 'EarlyStudentInstructionalReading',
  EarlyWriting = 'EarlyWriting',
  EarlyTutorReadAloud = 'EarlyTutorReadAloud',
  EmergentWarmup = 'EmergentWarmup',
  EmergentLetterFocus = 'EmergentLetterFocus',
  EmergentPaSkill = 'EmergentPaSkill',
  EmergentStudentInstructionalReading = 'EmergentStudentInstructionalReading',
  EmergentWordplay = 'EmergentWordplay',
  EmergentWriting = 'EmergentWriting',
  EmergentBiweeklyAssessment = 'EmergentBiweeklyAssessment',
  EmergentTutorReadAloud = 'EmergentTutorReadAloud',
}

export enum LessonPlanModuleTitles {
  EarlyWarmup = 'Warmup',
  EarlyRunningRecord = 'Running Record - Optional',
  EarlyWordplay = 'Wordplay',
  EarlyStudentInstructionalReading = 'Student Instructional Reading',
  EarlyWriting = 'Writing',
  EarlyTutorReadAloud = 'Tutor Read Aloud',
  EmergentWarmup = 'Warmup',
  EmergentLetterFocus = 'Letter Focus/Activities',
  EmergentPaSkill = 'PA Skill',
  EmergentStudentInstructionalReading = 'Student Instructional Reading',
  EmergentWordplay = 'Wordplay/Sight Words/Shared Reading',
  EmergentWriting = 'Writing',
  EmergentBiweeklyAssessment = 'Bi-Weekly Assessment',
  EmergentTutorReadAloud = 'Tutor Read Aloud',
}

export const getLessonPlanModuleTypeString = (module: LessonPlanModuleTypes): string => {
  switch (module) {
    case LessonPlanModuleTypes.EarlyWarmup:
      return 'Early Reader: Warmup';
    case LessonPlanModuleTypes.EarlyRunningRecord:
      return 'Early Reader: Running Record';
    case LessonPlanModuleTypes.EarlyWordplay:
      return 'Early Reader: Wordplay';
    case LessonPlanModuleTypes.EarlyStudentInstructionalReading:
      return 'Early Reader: Student Instructional Reading';
    case LessonPlanModuleTypes.EarlyWriting:
      return 'Early Reader: Writing';
    case LessonPlanModuleTypes.EarlyTutorReadAloud:
      return 'Early Reader: Tutor Read Aloud';
    case LessonPlanModuleTypes.EmergentWarmup:
      return 'Emergent Reader: Warmup';
    case LessonPlanModuleTypes.EmergentLetterFocus:
      return 'Emergent Reader: Letter Focus';
    case LessonPlanModuleTypes.EmergentPaSkill:
      return 'Emergent Reader: PA Skill';
    case LessonPlanModuleTypes.EmergentStudentInstructionalReading:
      return 'Emergent Reader: Student Instructional Reading';
    case LessonPlanModuleTypes.EmergentWordplay:
      return 'Emergent Reader: Wordplay';
    case LessonPlanModuleTypes.EmergentWriting:
      return 'Emergent Reader: Writing';
    case LessonPlanModuleTypes.EmergentBiweeklyAssessment:
      return 'Emergent Reader: Bi-Weekly Assessment';
    case LessonPlanModuleTypes.EmergentTutorReadAloud:
      return 'Emergent Reader: Tutor Read Aloud';
    default:
      throw new Error('No defined value!');
  }
};

export const getLessonPlanModuleTitleString = (module: LessonPlanModuleTypes): string => {
  switch (module) {
    case LessonPlanModuleTypes.EarlyWarmup:
      return 'Warmup';
    case LessonPlanModuleTypes.EarlyRunningRecord:
      return 'Running Record - Optional';
    case LessonPlanModuleTypes.EarlyWordplay:
      return 'Wordplay';
    case LessonPlanModuleTypes.EarlyStudentInstructionalReading:
      return 'Student Instructional Reading';
    case LessonPlanModuleTypes.EarlyWriting:
      return 'Writing';
    case LessonPlanModuleTypes.EarlyTutorReadAloud:
      return 'Tutor Read Aloud';
    case LessonPlanModuleTypes.EmergentWarmup:
      return 'Warmup';
    case LessonPlanModuleTypes.EmergentLetterFocus:
      return 'Letter Focus';
    case LessonPlanModuleTypes.EmergentPaSkill:
      return 'PA Skill';
    case LessonPlanModuleTypes.EmergentStudentInstructionalReading:
      return 'Student Instructional Reading';
    case LessonPlanModuleTypes.EmergentWordplay:
      return 'Wordplay';
    case LessonPlanModuleTypes.EmergentWriting:
      return 'Writing';
    case LessonPlanModuleTypes.EmergentBiweeklyAssessment:
      return 'Bi-Weekly Assessment';
    case LessonPlanModuleTypes.EmergentTutorReadAloud:
      return 'Tutor Read Aloud';
    default:
      throw new Error('No defined value!');
  }
};

export enum LessonPlanHighlightFields {
  Warmup = 'Warmup',
  RunningRecord = 'RunningRecord',
  ReadingLevel = 'ReadingLevel',
  OtherNotes = 'OtherNotes',
}

export enum LessonPlanHighlightFieldLabels {
  Warmup = 'Warmup',
  RunningRecord = 'Running Record',
  ReadingLevel = 'Reading Level',
  OtherNotes = 'Other Notes',
}
