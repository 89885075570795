import { useAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { storedAccessTokenAtom, storedUserInfoAtom } from '../ffApi';

export const useLogout = () => {
  const [, setAuthToken] = useAtom(storedAccessTokenAtom);
  const [, setStoredUser] = useAtom(storedUserInfoAtom);

  return () => {
    setStoredUser(RESET);
    setAuthToken(RESET);
    window.location.href = '/login';
  };
};
