import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Container,
  Flex,
  Heading,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { PatchLessonPlanDto, FFRoles } from '@edanalytics/ff_be_se';
import { atom, useAtom, useAtomValue } from 'jotai';
import _ from 'lodash';
import { RESET, useUpdateAtom } from 'jotai/utils';
import { FfTable, RowSelectedCb } from '../../components/FfTable/FfTable';
import { DebugCard } from '../../components/DebugCard';
import { StudentLink, TutorLink, ObservationLink, CompactFormatDateString, LessonPlanTimeString } from '../../components/Utils';
import { ObservationsService } from '../../services/Observations/ObservationsService';
import { Empty } from '../../components/Empty';
import { SchoolServiceAtoms } from '../../services/School';
import { isEmpty } from '../../utils/utils';
import { persistNavAtom } from '../../atoms/navAtom';
import { atomApiWithNavAndRead } from '../../utils/async-atom';
import { whoAmIatom } from '../../services/Identity';

const observationsAtom = atomApiWithNavAndRead((get, nav) => {
  const schoolId = nav?.schoolId as number;
  return get(ObservationsService).getAllForSchool(schoolId);
});

const deleteObservationsAtom = atom(null, async (get, set, newValue: { schoolId: number; observationIds: PatchLessonPlanDto[] }) => {
  await get(ObservationsService).deleteObservations(newValue.schoolId, newValue.observationIds);
  return set(observationsAtom, RESET);
});

export const ObservationsScreen: React.FunctionComponent = () => {
  const [school] = useAtom(SchoolServiceAtoms.getCurrentSchool);
  const [observations] = useAtom(observationsAtom);
  const [navAtomProps] = useAtom(persistNavAtom);
  const [processing, setProcessing] = React.useState(false);
  const [selectedRowIndexes, setSelectedRowIndexes] = React.useState<number[]>([]);
  const [observationIds, setObservationIds] = React.useState<PatchLessonPlanDto[]>([]);
  const user = useAtomValue(whoAmIatom);
  const isEnabled = user?.roleId !== FFRoles.Tutor;
  const deleteObservations = useUpdateAtom(deleteObservationsAtom);
  const toast = useToast();

  const rowSelectCb = (rows: RowSelectedCb) => {
    setObservationIds([...Object.values(rows.data).map((r: any) => ({ id: r.id, isDeleted: true } as PatchLessonPlanDto))]);
    setSelectedRowIndexes(Object.keys(rows.data).map((k) => Number(k)));
  };

  const DeleteButtonWithConfirmation = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = React.useRef<HTMLButtonElement>(null);

    const performDelete = async () => {
      onClose();
      setProcessing(true);
      await deleteObservations({ schoolId: school?.id as number, observationIds });
      setSelectedRowIndexes([]);
      setProcessing(false);
      toast({ description: 'Observation deleted', status: 'success' });
    };

    return (
      <>
        {isEnabled && (
          <Button disabled={processing || selectedRowIndexes.length === 0} isLoading={processing} variant={'outline'} onClick={onOpen}>
            Delete Selected
          </Button>
        )}
        <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete {selectedRowIndexes.length} {selectedRowIndexes.length > 1 ? 'Observations' : 'Observation'}
              </AlertDialogHeader>
              <AlertDialogBody>Are you sure? You can't undo this action afterwards.</AlertDialogBody>
              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>
                <Button colorScheme="red" onClick={performDelete} ml={3}>
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
    );
  };

  const columns: ColumnDef<any>[] = [
    {
      accessorFn: (row) => row,
      id: 'byUserId',
      cell: (info: any) => (school ? TutorLink(school, info.getValue().byUser) : ''),
      header: () => 'Tutor',
      filterFn: 'tutorNode',
    },
    {
      accessorFn: (row) => row.student,
      id: 'fullName',
      cell: (info: any) => StudentLink(info.getValue()),
      header: () => 'Student',
      filterFn: 'studentNode',
    },
    {
      accessorKey: 'lessonTime',
      cell: (info: any) => CompactFormatDateString(info.getValue()),
      id: 'lessonTime',
      header: () => 'Lesson Date',
      filterFn: 'date',
    },
    {
      accessorKey: 'startTime',
      accessorFn: (row) => row,
      cell: (info: any) => LessonPlanTimeString(info.getValue().sessionLessonPlan),
      id: 'startTime',
      header: () => 'Lesson Time',
      enableColumnFilter: false,
    },
    {
      accessorKey: 'observer',
      id: 'observer',
      cell: (info: any) => info.getValue(),
      header: () => 'Observed By',
      filterFn: 'observer',
    },
    {
      header: () => '',
      accessorFn: (row) => row,
      id: 'student',
      cell: (info: any) => ObservationLink(info.getValue(), info.getValue().school, info.getValue().byUser),
    },
  ];

  const sortedObservations = _.sortBy(observations, (lp) => lp.startDateTime);

  return (
    <Container maxWidth="100%">
      <Flex mb="1em">
        <Box flex={1}>
          <Heading color={'ff.blue'} mb="1em">
            All Observations
          </Heading>
        </Box>
        <Box textAlign="right" flex={0}>
          <DeleteButtonWithConfirmation />
        </Box>
      </Flex>
      {!isEmpty(observations) ? (
        <FfTable
          variant="card"
          columns={columns}
          data={sortedObservations}
          enableRowSelection={isEnabled}
          selectedRowIndexes={selectedRowIndexes}
          rowSelectedCb={(rows: any) => rowSelectCb(rows)}
        />
      ) : (
        <Empty description="No Observations." label="Return to dashboard" path={`/schools/${school?.id}/dashboard`} />
      )}
      <DebugCard
        data={[
          { name: 'selectedRowIndexes', data: selectedRowIndexes },
          { name: 'school', data: school },
          { name: 'navAtomProps', data: navAtomProps },
          { name: 'sortedObservations', data: sortedObservations },
        ]}
      />
    </Container>
  );
};
