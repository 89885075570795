/* eslint-disable no-nested-ternary */
import { FormControl, FormErrorMessage, FormLabel, Input, StyleProps } from '@chakra-ui/react';
import { Field, FieldProps } from 'formik';
import { ReactNode } from 'react';
import { setFieldValueDefaulted } from '../../utils/utils';

export interface FfFormikInputProps extends StyleProps {
  type: string;
  label?: ReactNode;
  id: string;
  variant?: string;
  placeholder?: string;
  isDisabled?: boolean;
  setDefaultToUndef?: boolean;
  onChange?: (e: any) => void;
}

export const FfFormikInput: React.FunctionComponent<FfFormikInputProps> = (props: FfFormikInputProps) => (
  <Field marginBottom="1em" id={props.id} name={props.id}>
    {({ field, form, meta }: FieldProps) => (
      <FormControl width={props.width} isDisabled={props.isDisabled || form.isSubmitting} isInvalid={!!meta.error && meta.touched}>
        {props.label && <FormLabel htmlFor={props.id}>{props.label}</FormLabel>}
        <Input
          variant={props.variant ? props.variant : ''}
          width={props.width}
          type={props.type}
          id={props.id}
          onBlur={form.handleBlur}
          alt={`${props.label} Input`}
          placeholder={props.placeholder ? props.placeholder : ''}
          value={field.value ?? ''}
          onChange={(e) => {
            setFieldValueDefaulted(form, props.id, e.target.value, '', props.setDefaultToUndef ? undefined : '');
            if (props.onChange) props.onChange(e);
          }}
        />
        {meta.error && meta.touched && <FormErrorMessage>{meta.error}</FormErrorMessage>}
      </FormControl>
    )}
  </Field>
);
