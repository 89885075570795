import { Button, Container, Heading, Text, useToast } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { StudentDto, GetOneRosterStudentDto, PostOneRosterStudentDto } from '@edanalytics/ff_be_se';
import { atom, useAtom, useAtomValue } from 'jotai';
import _ from 'lodash';
import { useUpdateAtom, RESET } from 'jotai/utils';
import { atomApiWithNavAndRead } from '../../utils/async-atom';
import { RowSelectedCb } from '../../components/FfTable/FfTable';
import { FfTable } from '../../components/FfTable';
import { GradeLevelLabel } from '../../components/Utils';
import { DebugCard } from '../../components/DebugCard';
import { Empty } from '../../components/Empty';
import { OneRosterService } from '../../services/OneRoster';
import { getFormattedDate } from '../../utils/utils';
import { SchoolServiceAtoms } from '../../services/School';
import { editEnrollmentAtomApi, enrollStudent, setEditStateAtom } from '../../services/EnrollmentAtoms';
import { EnrollmentDialog } from '../../components/Enrollment/EditEnrollmentDialog';

const studentsAtom = atomApiWithNavAndRead((get, nav) => {
  const schoolId = nav?.schoolId as number;
  return get(OneRosterService).getStudents(schoolId);
});

export const ActivateStudentsScreen: React.FunctionComponent = () => {
  const [orStudentIds, setOrStudentIds] = React.useState<string[]>([]);
  const [school] = useAtom(SchoolServiceAtoms.getCurrentSchool);
  const [selectedRowIndexes, setSelectedRowIndexes] = React.useState<number[]>([]);
  const [students, setStudents] = useAtom(studentsAtom);
  const setEditState = useUpdateAtom(setEditStateAtom);
  const validationMessage = useAtomValue(editEnrollmentAtomApi);
  const toast = useToast();

  const handleEnroll = () => {
    setEditState(enrollStudent({ orStudentIds, entryDate: new Date() } as PostOneRosterStudentDto, school?.id));
  };

  useEffect(() => {
    if (validationMessage === 'Ok') {
      setSelectedRowIndexes([]);
      setOrStudentIds([]);
      setStudents(RESET);

      toast({ description: `Students activated`, status: 'success' });
    }
  }, [validationMessage]);

  const sortedStudents = _.sortBy(students, [(s) => s.name]);
  const rowSelectCb = (rows: RowSelectedCb) => {
    setOrStudentIds([...Object.values(rows.data).map((r: GetOneRosterStudentDto) => r.id)]);
    setSelectedRowIndexes(Object.keys(rows.data).map((k) => Number(k)));
  };

  const columns: ColumnDef<StudentDto>[] = [
    {
      accessorKey: 'name',
      id: 'name',
      cell: (info: any) => info.getValue(),
      header: () => 'Student',
    },
    {
      accessorKey: 'grade',
      id: 'grade',
      cell: (info: any) => GradeLevelLabel(info.getValue()),
      header: () => 'Grade',
    },
    {
      accessorKey: 'teacher',
      id: 'teacher',
      cell: (info: any) => <>{info.getValue()}</>,
      header: () => 'Instructor',
    },
    {
      accessorKey: 'birthDate',
      id: 'birthDate',
      cell: (info: any) => <>{getFormattedDate(info.getValue(), 'MM/dd/yyyy')}</>,
      header: () => 'DOB',
    },
  ];

  return (
    <Container maxWidth="100%">
      <Heading color={'ff.blue'}>Activate Students</Heading>
      <Text>Select students from roster to assign them to the Future Forward program.</Text>
      {sortedStudents ? (
        <FfTable
          enableRowSelection={true}
          rowSelectedCb={(rows: any) => {
            rowSelectCb(rows);
          }}
          selectedRowIndexes={selectedRowIndexes}
          columns={columns}
          data={sortedStudents}
        />
      ) : (
        <Empty />
      )}
      <Button disabled={selectedRowIndexes.length === 0} variant={'cta'} onClick={() => handleEnroll()}>
        Activate Selected Students
      </Button>
      <EnrollmentDialog />
      <DebugCard data={[{ name: 'sortedStudents', data: sortedStudents }]} />
    </Container>
  );
};
