import { Button, Center, Flex, Heading, Img, Stack } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Spaceguy from '../../assets/images/spaceguyfalling.svg';

export const UnauthorizedScreen: React.FunctionComponent = () => {
  const nav = useNavigate();

  const handleTakeMeHome = () => {
    nav('/');
  };
  const handleLogBackIn = () => {
    nav('/login');
  };

  return (
    <>
      <Img src={Spaceguy} />
      <Center>
        <Flex direction="column" width="70%">
          <Heading color="#D6F2EF" as="h1" size="xl" noOfLines={2} textAlign="center">
            Uh oh! It looks like you're out of orbit.
          </Heading>
          <Heading color="#D6F2EF" width="100%" size="sm" noOfLines={2} textAlign="center" margin="30px">
            The page you're looking for either doesn't exist or you don't have permission to access it.
          </Heading>
        </Flex>
      </Center>
      <Center>
        <Stack direction={['row']} spacing="24px">
          <Button size="md" onClick={handleTakeMeHome}>
            Take Me Home
          </Button>
          <Button size="md" onClick={handleLogBackIn}>
            Log Back In
          </Button>
        </Stack>
      </Center>
    </>
  );
};
