import { EngagementRow, EngagementSummaryDto } from '@edanalytics/ff_be_se';
import { atom, Getter } from 'jotai';
import { apiClientAtom } from '../ffApi';
import { timeZoneMapAtom } from '../hooks/useTimezone';
import { atomApiWithNavAndRead } from '../utils/async-atom';

const mapEngagementVisits = (data: EngagementRow[], timeZoneMap: ((date: Date) => Date) | undefined) => {
  if (timeZoneMap === undefined) return data;
  const mapOne = ({ date, ...rest }: EngagementRow) => ({ date: timeZoneMap(date), ...rest });
  return data?.filter((a) => a !== undefined).map(mapOne) as typeof data;
};
const mapEngagements = (data: EngagementSummaryDto[], timeZoneMap: ((date: Date) => Date) | undefined) => {
  if (timeZoneMap === undefined) return data;
  const mapOne = ({ allVisits, ...rest }: EngagementSummaryDto) => ({ allVisits: mapEngagementVisits(allVisits, timeZoneMap), ...rest });
  return data?.filter((a) => a !== undefined).map(mapOne) as typeof data;
};

const mapTimeZoneAtom = atom((get) => {
  const { toUI, fromUi } = get(timeZoneMapAtom);
  return {
    toUI: (data: EngagementSummaryDto[]) => mapEngagements(data, toUI),
    fromUi: (data: EngagementSummaryDto[]) => mapEngagements(data, fromUi),
  };
});

const engagementServiceFactory = (get: Getter) => ({
  getAllEngagementSummariesBySchool: async (schoolId: number): Promise<EngagementSummaryDto[]> => {
    const response = await get(apiClientAtom).get(`/schools/${schoolId}/family-interactions/list-engagement-summaries`);
    return get(mapTimeZoneAtom).toUI(response.data.results) as EngagementSummaryDto[];
  },
  getAllEngagementSummariesByStudent: async (schoolId: number, studentId: number): Promise<EngagementSummaryDto[]> => {
    const response = await get(apiClientAtom).get(
      `/schools/${schoolId}/students/${studentId}/family-interactions/list-engagement-summaries`,
    );
    return get(mapTimeZoneAtom).toUI(response.data.results) as EngagementSummaryDto[];
  },
});

const EngagementService = atom(engagementServiceFactory);
export { EngagementService };

export const EngagementServiceAtoms = {
  getAllEngagementSummaries: atomApiWithNavAndRead(async (get, nav) => {
    if (!nav.schoolId) return undefined;
    const result = !nav.studentId
      ? await engagementServiceFactory(get).getAllEngagementSummariesBySchool(nav.schoolId)
      : await engagementServiceFactory(get).getAllEngagementSummariesByStudent(nav.schoolId, nav.studentId);

    return result;
  }),
};
