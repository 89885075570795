import { CalendarIcon } from '@chakra-ui/icons';
import {
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
  FormControl,
} from '@chakra-ui/react';
import React from 'react';
import { Appointment } from '../../models/Appointment';
import { Weekdays } from '../../utils/TimeHelper';
import { ClickOutside } from '../ClickOutside';
import { DaySelector } from '../DayOfWeekTimePicker/DaySelector';

const defaultDuration = 30;
export interface DayOfWeekTimePickerProps {
  dayOfWeek?: number;
  cb: (d: Appointment) => void;
}

export const DayPicker: React.FunctionComponent<DayOfWeekTimePickerProps> = (props: DayOfWeekTimePickerProps) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [dayOfWeek, setDayOfWeek] = React.useState(props.dayOfWeek);
  const now = new Date();

  React.useEffect(() => {
    let defaultValueSet = false;
    let dow;
    setDayOfWeek(props.dayOfWeek);
    if (props.dayOfWeek === undefined) {
      const day = now.getDay();
      // javascript date is not 0 based as our Day Enum (ISO 8601)
      if (day === 0) {
        dow = 7;
      } else {
        dow = now.getDay();
      }
      setDayOfWeek(dow);
      defaultValueSet = true;
    }
  }, [props.dayOfWeek]);

  return (
    <FormControl>
      <ClickOutside cb={onClose}>
        <Popover returnFocusOnClose={false} isOpen={isOpen} onClose={onClose} placement="bottom" closeOnBlur={false}>
          <PopoverTrigger>
            <InputGroup>
              <Input
                autoComplete="off"
                background="white"
                value={dayOfWeek === undefined ? '' : `${Weekdays[dayOfWeek as number]}`}
                onClick={onToggle}
                onChange={() => {}}
              />
              <InputRightElement color="gray.500" children={<CalendarIcon fontSize="sm" />} onClick={onToggle} />
            </InputGroup>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverBody>
              <DaySelector dayOfWeek={dayOfWeek as number} cb={(d) => props.cb({ dayOfWeek: d })} />
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </ClickOutside>
    </FormControl>
  );
};
