import { Button, Container, Img } from '@chakra-ui/react';
import React, { useState } from 'react';
import FutureForwardLogo from '../../assets/images/FutureForwardLogo.svg';
import config from '../../config';

export const LoginScreen: React.FunctionComponent = () => {
  const [, setIsLoading] = useState(false);

  const goAuth = () => {
    setIsLoading(true);
    window.location.href = `${config.ffApi}/auth/google/login`;
  };

  return (
    <Container className="body" centerContent={true}>
      <Img mb={'3em'} src={FutureForwardLogo} />
      <Button variant={'alternate'} size="md" onClick={goAuth}>
        Log In With Google
      </Button>
    </Container>
  );
};
