import { Container, Heading, Flex } from '@chakra-ui/react';
import React from 'react';
import _ from 'lodash';
import { useAtom, useAtomValue } from 'jotai';
import { FFRoles } from '@edanalytics/ff_be_se';
import { TutorBanner } from '../../components/TutorBanner';
import { TutorDetail } from '../../components/TutorDetail';
import { DebugCard } from '../../components/DebugCard';
import { FfDivider } from '../../components/FfDivider';
import { GroupedLessonPlans } from '../../components/GroupedLessonPlans';
import { RecentObservationsGroupedAccordion } from '../../components/RecentObservationsGroupedAccordion';
import { UserServiceAtoms } from '../../services/User';
import { LessonPlanServiceAtoms } from '../../services/LessonPlan/LessonPlanService';
import { getDateYearMonthKey } from '../../utils/utils';
import { SchoolServiceAtoms } from '../../services/School';
import { whoAmIatom } from '../../services/Identity';
import { CreateMetricsGrid, CreateMetricsGridStub } from '../../components/MetricCard';
import { TutorServiceAtoms } from '../../services/Tutor';
import { StudentScheduleTable } from '../../components/StudentScheduleTable';
import { ENABLE_STATS_WIDGETS } from '../../config/constants';

export const TutorDetailScreen: React.FunctionComponent = () => {
  const [school] = useAtom(SchoolServiceAtoms.getCurrentSchool);
  const [tutor] = useAtom(UserServiceAtoms.getCurrentTutor);
  const [studentSchedules] = useAtom(UserServiceAtoms.getStudentSchedules);
  const [upcomingLessonPlans] = useAtom(LessonPlanServiceAtoms.getTutorUpcomingLessonPlans);
  const [recentLessonPlans] = useAtom(LessonPlanServiceAtoms.getTutorRecentLessonPlans);
  const user = useAtomValue(whoAmIatom);
  const isEnabled = user?.roleId === FFRoles.Staff;

  const sortedRecentLessonPlans = _.sortBy(recentLessonPlans, [(o) => o.startDateTime]).reverse();
  const groupedRecentLessonPlans = _.groupBy(sortedRecentLessonPlans, (s) => getDateYearMonthKey(s, 'startDateTime'));
  const sortedUpcomingLessonPlans = _.sortBy(upcomingLessonPlans, [(o) => o.startDateTime]).reverse();
  const groupedUpcomingLessonPlans = _.groupBy(sortedUpcomingLessonPlans, (s) => getDateYearMonthKey(s, 'startDateTime'));
  const [tutorMetrics] = ENABLE_STATS_WIDGETS ? useAtom(TutorServiceAtoms.getTutorMetrics) : [];

  return (
    <Container maxWidth="100%" fontSize="12px">
      {school && tutor && (
        <>
          <Heading color={'ff.blue'}>Tutor Summary</Heading>
          <FfDivider />
          <TutorBanner school={school} user={tutor} isEditLinkEnabled={isEnabled} />
          <FfDivider />
          <Flex>
            <TutorDetail user={tutor} />
            {ENABLE_STATS_WIDGETS ? <CreateMetricsGrid metricData={tutorMetrics} /> : <CreateMetricsGridStub />}
          </Flex>
        </>
      )}

      {studentSchedules && school && tutor && <StudentScheduleTable studentUserSchedules={studentSchedules} school={school} user={tutor} />}

      {school && upcomingLessonPlans && (
        <GroupedLessonPlans school={school} groupedLessonPlans={groupedUpcomingLessonPlans} heading="Upcoming Sessions" link={true} />
      )}
      {school && groupedRecentLessonPlans && (
        <GroupedLessonPlans school={school} groupedLessonPlans={groupedRecentLessonPlans} heading="Past Sessions" link={true} />
      )}
      {user?.roleId !== FFRoles.FEC && tutor && school && <RecentObservationsGroupedAccordion school={school} tutor={tutor} />}

      <DebugCard
        data={[
          { name: 'tutor', data: tutor },
          { name: 'groupedLessonPlans', data: groupedRecentLessonPlans },
          // { name: 'studentUserSchedules', data: studentUserSchedules },
        ]}
      />
    </Container>
  );
};
