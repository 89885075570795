/* eslint-disable max-len */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { SchoolDto, SessionLessonPlanDto, UserDto } from '@edanalytics/ff_be_se';
import { useAtom, useAtomValue } from 'jotai';
import { Text, Box, Stack, Flex, useToast, Button } from '@chakra-ui/react';
import { Form } from 'formik';
import { IsOptional, IsString, IsNotEmpty, IsNumber } from 'class-validator';
import { FfButtonTypes } from '../models/Button';
import { FfFormikForm } from './Formik/FfFormikForm';
import { FfFormikCheckbox } from './Formik/FfFormikCheckbox';
import { FfFormikTextarea } from './Formik/FfFormikTextarea';
import { FfFormikRadioGroup } from './Formik/FfFormikRadioGroup';
import { FfFormikNumberInput } from './Formik/FfFormikNumberInput';
import { SessionObservationService as sessionObservationService } from '../services/SessionObservation';
import { whoAmIatom } from '../services/Identity';

const requiredFieldMessage: string = 'required field';

class FormSchema {
  @IsString()
  @IsNotEmpty({ message: requiredFieldMessage })
  public priorLessonPlan: string;

  @IsOptional()
  public chkWarmupActivity: boolean;

  @IsOptional()
  public chkRunningAround: boolean;

  @IsOptional()
  public chkWordplay: boolean;

  @IsOptional()
  public chkSort: boolean;

  @IsOptional()
  public chkMakingWords: boolean;

  @IsOptional()
  public chkFlipBook: boolean;

  @IsOptional()
  public chkPAActivity: boolean;

  @IsOptional()
  public chkInstructionalReading: boolean;

  @IsOptional()
  public chkWriting: boolean;

  @IsOptional()
  public chkTutorReadAloud: boolean;

  @IsString()
  @IsOptional()
  public txtSORTDescr: string;

  @IsString()
  @IsOptional()
  public txtMakingWordsDescr: string;

  @IsString()
  @IsOptional()
  public txtFlipBookDescr: string;

  @IsString()
  @IsOptional()
  public txtPAActivityDescr: string;

  @IsOptional()
  @IsString()
  public txtOtherNotes: string;

  @IsString()
  @IsOptional()
  public didTutorFollowPlan: string;

  @IsString()
  @IsOptional()
  public txtDescribeWhyPlanNotFollowed: string;

  @IsString()
  @IsOptional()
  public rdoHowWellPrepared: string;

  @IsString()
  @IsOptional()
  public rdoHowEngagedWasTutor: string;

  @IsOptional()
  @IsString()
  public rdoHowWellStudentParticipate: string;

  @IsString()
  @IsOptional()
  public txtTutorDidWell: string;

  @IsString()
  @IsOptional()
  public txtTutorNeedSupport: string;

  @IsOptional()
  @IsString()
  public txtAdditionalComments: string;

  @IsNumber()
  @IsNotEmpty({ message: requiredFieldMessage })
  public sessionLength: number;
}

const initialValues = {
  priorLessonPlan: '',
  chkWarmupActivity: '',
  chkRunningAround: '',
  chkWordplay: '',
  chkSort: '',
  chkMakingWords: '',
  chkFlipBook: '',
  chkPAActivity: '',
  chkInstructionalReading: '',
  chkWriting: '',
  chkTutorReadAloud: '',
  didTutorFollowPlan: '',
  txtSORTDescr: '',
  txtMakingWordsDescr: '',
  txtFlipBookDescr: '',
  txtPAActivityDescr: '',
  txtOtherNotes: '',
  txtDescribeWhyPlanNotFollowed: '',
  rdoHowWellPrepared: '',
  rdoHowEngagedWasTutor: '',
  rdoHowWellStudentParticipate: '',
  txtTutorDidWell: '',
  txtTutorNeedSupport: '',
  txtAdditionalComments: '',
  sessionLength: '',
};

export interface ITutorObservationFormProps {
  school: SchoolDto;
  lessonPlan: SessionLessonPlanDto;
  tutor: UserDto;
}

export const TutorObservationForm: React.FunctionComponent<ITutorObservationFormProps> = ({ school, lessonPlan, tutor }) => {
  const [SessionObservationService] = useAtom(sessionObservationService);

  const user = useAtomValue(whoAmIatom);
  const [disabled, setDisabled] = React.useState(false);
  const [initVals, setInitVals] = React.useState<FormSchema>();
  const [sessionObsId, setSessionObsId] = React.useState(0);
  const navigate = useNavigate();
  const toast = useToast();
  let onSubmitClick: boolean = false;

  const saveSessionObservation = async (formValues: any) => {
    if (sessionObsId > 0) {
      return;
    }

    try {
      const result = await SessionObservationService.postSessionObservation(school.id, tutor.id, lessonPlan.id, {
        sessionLessonPlanId: lessonPlan.id,
        observerId: user.id,
        json: formValues,
        observationContentCode: 1,
        observationContentVersion: 1,
      });

      if (result.id) {
        setSessionObsId(result.id);
      }

      toast({ description: 'Observation saved', status: 'success' });
    } catch (err) {
      toast({ description: 'Observation not saved', status: 'error' });
    }
  };

  const updateSessionObservation = async (formValues: any) => {
    if (sessionObsId < 1) {
      return;
    }

    try {
      const result = await SessionObservationService.updateSessionObservation(sessionObsId, school.id, tutor.id, lessonPlan.id, {
        sessionObservationId: sessionObsId,
        sessionLessonPlanId: lessonPlan.id,
        observerId: user.id,
        json: formValues,
        observationContentCode: 1,
        observationContentVersion: 1,
      });

      if (result.id) {
        setSessionObsId(result.id);
      }

      toast({ description: 'Observation saved', status: 'success' });
    } catch (err) {
      toast({ description: 'Observation not saved', status: 'error' });
    }
  };

  const completeObservation = async (formValues: any) => {
    if (!onSubmitClick) {
      return;
    }

    let obid = sessionObsId;

    try {
      if (sessionObsId < 1) {
        const result = await SessionObservationService.postSessionObservation(school.id, tutor.id, lessonPlan.id, {
          sessionLessonPlanId: lessonPlan.id,
          observerId: user.id,
          json: formValues,
          observationContentCode: 1,
          observationContentVersion: 1,
        });

        if (result.id) {
          obid = result.id;
          setSessionObsId(result.id);
        }
      }

      const result = await SessionObservationService.updateSessionObservation(obid, school.id, tutor.id, lessonPlan.id, {
        sessionObservationId: obid,
        sessionLessonPlanId: lessonPlan.id,
        observerId: user.id,
        json: formValues,
        observationContentCode: 1,
        observationContentVersion: 1,
        submittedByUserId: user.id,
      });

      if (lessonPlan && lessonPlan.observations && lessonPlan.observations[0]) {
        lessonPlan.observations[0].submittedByUserId = result.submittedByUserId;
      }

      setDisabled(true);

      toast({ description: 'Observation submitted', status: 'success' });
      navigate(`/schools/${school.id}/dashboard`);
    } catch (err) {
      toast({ description: 'Observation not submitted', status: 'error' });
    }
  };
  React.useEffect(() => {
    if (lessonPlan.observations && lessonPlan.observations.length > 0) {
      const los = lessonPlan.observations[0];
      setSessionObsId(los.id);

      const newModel = Object.assign(new FormSchema(), los.json);
      setInitVals(newModel);

      const d = _.filter(lessonPlan.observations, (o) => o.submittedByUserId && o.submittedByUserId > 0);
      if (d.length > 0) {
        setDisabled(true);
      }
    } else {
      const newModel = Object.assign(new FormSchema(), initialValues);
      setInitVals(newModel);
    }
  }, []);

  return (
    <>
      {initVals && (
        <FfFormikForm
          initialValues={initVals}
          validationClass={FormSchema}
          submit={(values) =>
            new Promise((resolve) => {
              if (onSubmitClick) {
                completeObservation(values);
              } else if (sessionObsId > 0) {
                updateSessionObservation(values);
              } else {
                saveSessionObservation(values);
              }

              resolve();
            })
          }
        >
          <Form>
            <Box color={'ff.blue'} fontWeight="bold">
              <Flex mb="1em" py="15px" borderTop="1px">
                <Box flex={1}>
                  <Box>
                    <Text fontSize="2em" display="inline-block" mr="15px">
                      1
                    </Text>
                    <Text display="inline-block">Was there a lesson plan prior to the beginning of the lesson?</Text>
                    <Box ml="30px">
                      <FfFormikRadioGroup
                        options={[
                          { key: 'Yes', value: '1' },
                          { key: 'No', value: '2' },
                        ]}
                        label=""
                        id="priorLessonPlan"
                        isDisabled={disabled}
                      />
                    </Box>
                  </Box>
                </Box>
              </Flex>

              <Flex pt="15px" borderTop="1px">
                <Box flex={1}>
                  <Box>
                    <Text fontSize="2em" display="inline-block" mr="15px">
                      2
                    </Text>
                    <Text display="inline-block">Which of the following happened in the lesson? </Text>
                  </Box>
                  <Box ml="30px">
                    <Stack direction="column">
                      <FfFormikCheckbox id="chkWarmupActivity" label="Warm up Activity" readonly={disabled} />
                      <FfFormikCheckbox id="chkRunningAround" label="Running Record" readonly={disabled} />
                      <FfFormikCheckbox id="chkWordplay" label="Wordplay" readonly={disabled} />
                      <FfFormikCheckbox id="chkSort" label="Sort (describe)" readonly={disabled} />
                      <FfFormikCheckbox id="chkMakingWords" label="Making Words (describe)" readonly={disabled} />
                      <FfFormikCheckbox id="chkFlipBook" label="Flip Book (describe)" readonly={disabled} />
                      <FfFormikCheckbox id="chkPAActivity" label="PA Activity (describe)" readonly={disabled} />
                      <FfFormikCheckbox id="chkInstructionalReading" label="Student Instructional Reading" readonly={disabled} />
                      <FfFormikCheckbox id="chkWriting" label="Writing" readonly={disabled} />
                      <FfFormikCheckbox id="chkTutorReadAloud" label="Tutor read-aloud" readonly={disabled} />
                    </Stack>
                  </Box>
                </Box>
                <Flex flex={1} flexDirection="column" justifyContent="space-between" pl="20px">
                  <Box mb="20px">
                    <FfFormikTextarea
                      noOfLines={10}
                      label="SORT Description"
                      id="txtSORTDescr"
                      bgColor="white"
                      borderColor="ff.pink !important !important"
                      border="2px"
                      h="150px"
                      readonly={disabled}
                    />
                  </Box>
                  <Box mb="20px">
                    <FfFormikTextarea
                      noOfLines={10}
                      label="Making Words Description"
                      id="txtMakingWordsDescr"
                      bgColor="white"
                      borderColor="ff.pink !important !important"
                      border="2px"
                      h="150px"
                      readonly={disabled}
                    />
                  </Box>
                  <Box mb="20px">
                    <FfFormikTextarea
                      noOfLines={10}
                      label="Flip Book Description"
                      id="txtFlipBookDescr"
                      bgColor="white"
                      borderColor="ff.pink !important !important"
                      border="2px"
                      h="150px"
                      readonly={disabled}
                    />
                  </Box>
                </Flex>
              </Flex>

              <Flex mb="1em" pb="15px">
                <Box flex={1}>
                  <Box ml="30px">
                    <FfFormikTextarea
                      noOfLines={10}
                      label="Other Notes"
                      id="txtOtherNotes"
                      bgColor="white"
                      borderColor="ff.pink !important !important"
                      border="2px"
                      h="150px"
                      readonly={disabled}
                    />
                  </Box>
                </Box>
                <Flex flex={1} flexDirection="column" justifyContent="space-between" pl="20px">
                  <Box>
                    <FfFormikTextarea
                      noOfLines={10}
                      label="PA Activity Description"
                      id="txtPAActivityDescr"
                      bgColor="white"
                      borderColor="ff.pink !important !important"
                      border="2px"
                      h="150px"
                      readonly={disabled}
                    />
                  </Box>
                </Flex>
              </Flex>

              <Flex mb="1em" py="15px" borderTop="1px">
                <Box flex={1}>
                  <Box>
                    <Text fontSize="2em" display="inline-block" mr="15px" fontWeight="bold">
                      3
                    </Text>
                    <Text display="inline-block">Did the tutor follow the lesson plan? </Text>
                    <FfFormikRadioGroup
                      options={[
                        { key: 'Yes', value: '1' },
                        { key: 'No', value: '2' },
                      ]}
                      label=""
                      id="didTutorFollowPlan"
                      isDisabled={disabled}
                    />
                  </Box>
                </Box>
                <Flex flex={1} flexDirection="column" justifyContent="space-between" pl="20px">
                  <Box>
                    <Text mb="10px" display="inline-block">
                      If the tutor did not follow the lesson plan,what did they do? Could you observe why the lesson plan wasn't followed?{' '}
                    </Text>
                    <FfFormikTextarea
                      noOfLines={10}
                      label=""
                      id="txtDescribeWhyPlanNotFollowed"
                      bgColor="white"
                      borderColor="ff.pink !important !important"
                      border="2px"
                      h="150px"
                      readonly={disabled}
                    />
                  </Box>
                </Flex>
              </Flex>

              <Flex mb="1em" pb="15px" borderTop="1px" flexDirection="column">
                <Box flex={1}>
                  <Text fontSize="2em" display="inline-block" mr="15px" fontWeight="bold">
                    4
                  </Text>
                  <Text display="inline-block">How well was the tutor prepared? </Text>
                </Box>

                <Flex w="65%" margin="auto" mt="10px">
                  <Box flex={1}>
                    <Text>Not at all prepared</Text>
                  </Box>
                  <Box flex={1}>
                    <FfFormikRadioGroup
                      options={[
                        { key: '1', value: '1' },
                        { key: '2', value: '2' },
                        { key: '3', value: '3' },
                        { key: '4', value: '4' },
                        { key: '5', value: '5' },
                      ]}
                      label=""
                      id="rdoHowWellPrepared"
                      isDisabled={disabled}
                    />
                  </Box>
                  <Box flex={1}>
                    <Text>Completely prepared</Text>
                  </Box>
                </Flex>
              </Flex>

              <Flex mb="1em" pb="15px" borderTop="1px" flexDirection="column">
                <Box flex={1}>
                  <Text fontSize="2em" display="inline-block" mr="15px" fontWeight="bold">
                    5
                  </Text>
                  <Text display="inline-block">How engaged was the tutor in the lesson? </Text>
                </Box>

                <Flex w="65%" margin="auto" mt="10px">
                  <Box flex={1}>
                    <Text>Not at all engaged</Text>
                  </Box>
                  <Box flex={1}>
                    <FfFormikRadioGroup
                      options={[
                        { key: '1', value: '1' },
                        { key: '2', value: '2' },
                        { key: '3', value: '3' },
                        { key: '4', value: '4' },
                        { key: '5', value: '5' },
                      ]}
                      label=""
                      id="rdoHowEngagedWasTutor"
                      isDisabled={disabled}
                    />
                  </Box>
                  <Box flex={1}>
                    <Text>Completely engaged</Text>
                  </Box>
                </Flex>
              </Flex>

              <Flex mb="1em" pb="15px" borderTop="1px" flexDirection="column">
                <Box flex={1}>
                  <Text fontSize="2em" display="inline-block" mr="15px" fontWeight="bold">
                    6
                  </Text>
                  <Text display="inline-block">How actively did the student participate in the lesson? </Text>
                </Box>

                <Flex w="65%" margin="auto" mt="10px">
                  <Box flex={1}>
                    <Text>Student didn't participate</Text>
                  </Box>
                  <Box flex={1}>
                    <FfFormikRadioGroup
                      options={[
                        { key: '1', value: '1' },
                        { key: '2', value: '2' },
                        { key: '3', value: '3' },
                        { key: '4', value: '4' },
                        { key: '5', value: '5' },
                      ]}
                      label=""
                      id="rdoHowWellStudentParticipate"
                      isDisabled={disabled}
                    />
                  </Box>
                  <Box flex={1}>
                    <Text>Totally engaged the entire time</Text>
                  </Box>
                </Flex>
              </Flex>

              <Flex mb="1em" pb="15px" borderTop="1px">
                <Box flex={1}>
                  <Box>
                    <Text fontSize="2em" display="inline-block" mr="15px" fontWeight="bold">
                      7
                    </Text>
                    <Text display="inline-block">How long was the lesson? </Text>
                    <Box ml="30px" mr="20px">
                      <FfFormikNumberInput
                        id="sessionLength"
                        bgColor="ff.white"
                        width="100px"
                        display="inline-block"
                        minNumber={1}
                        maxNumber={180}
                        readonly={disabled}
                        defaultValue={initVals.sessionLength}
                      ></FfFormikNumberInput>
                      <Text display="inline-block">Minutes</Text>
                    </Box>
                  </Box>
                </Box>
              </Flex>

              <Flex mb="1em" pb="15px" borderTop="1px">
                <Box w="50%">
                  <Box>
                    <Text fontSize="2em" display="inline-block" mr="15px" fontWeight="bold">
                      8
                    </Text>
                    <Text display="inline-block">What was at least one thing the tutor did well? </Text>
                  </Box>
                  <Box>
                    <FfFormikTextarea
                      noOfLines={10}
                      label=""
                      id="txtTutorDidWell"
                      bgColor="white"
                      borderColor="ff.pink !important !important"
                      border="2px"
                      h="150px"
                      readonly={disabled}
                    />
                  </Box>
                </Box>
              </Flex>

              <Flex mb="1em" pb="15px" borderTop="1px">
                <Box w="50%">
                  <Box>
                    <Text fontSize="2em" display="inline-block" mr="15px" fontWeight="bold">
                      9
                    </Text>
                    <Text display="inline-block">What is at least one area where the tutor needs support? </Text>
                  </Box>
                  <Box>
                    <FfFormikTextarea
                      noOfLines={10}
                      label=""
                      id="txtTutorNeedSupport"
                      bgColor="white"
                      borderColor="ff.pink !important !important"
                      border="2px"
                      h="150px"
                      readonly={disabled}
                    />
                  </Box>
                </Box>
              </Flex>

              <Flex mb="1em" pb="15px" borderTop="1px">
                <Box w="50%">
                  <Box>
                    <Text fontSize="2em" display="inline-block" mr="15px" fontWeight="bold">
                      10
                    </Text>
                    <Text display="inline-block">Anything else? </Text>
                  </Box>
                  <Box>
                    <FfFormikTextarea
                      noOfLines={10}
                      label=""
                      id="txtAdditionalComments"
                      bgColor="white"
                      borderColor="ff.pink !important !important"
                      border="2px"
                      h="150px"
                      readonly={disabled}
                    />
                  </Box>
                </Box>
              </Flex>
            </Box>

            <Box w="100%">
              <Stack direction="row" justifyContent="right">
                <Button type={FfButtonTypes.Submit} disabled={disabled}>
                  Save
                </Button>
                <Button
                  type={FfButtonTypes.Submit}
                  variant={'cta'}
                  disabled={disabled}
                  onClick={() => {
                    onSubmitClick = true;
                  }}
                  w="200px"
                >
                  Submit
                </Button>
              </Stack>
            </Box>
          </Form>
        </FfFormikForm>
      )}
    </>
  );
};
