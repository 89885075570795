import { Box, List, ListItem, SimpleGrid, Text } from '@chakra-ui/react';
import { UserDto } from '@edanalytics/ff_be_se';

export interface ITutorDetailProps {
  user: UserDto;
}

export const TutorDetail: React.FunctionComponent<ITutorDetailProps> = ({ user }) => (
  <SimpleGrid columns={2} spacingX="2em" spacingY="1em" mb="3em" pt="1em" w={'45em'}>
    <Box>
      <List spacing={3}>
        <ListItem>
          <Text as="b">Phone</Text>
          <Text>{user.phone}</Text>
        </ListItem>
      </List>
    </Box>
    <Box>
      <List spacing={3}>
        <ListItem>
          <Text as="b">Email</Text>
          <Text>{user.email}</Text>
        </ListItem>
      </List>
    </Box>
  </SimpleGrid>
);
