/* eslint-disable no-nested-ternary */
import { Box, Flex, Heading, List, ListItem, Link } from '@chakra-ui/react';
import { PrinterIcon } from './Icons/PrinterIcon';
import { FfRouterLink } from './FfRouterLink';

export interface IFrequentlyUsedListProps {
  items?: IFrequentlyUsedListItems[];
}

export interface IFrequentlyUsedListItems {
  isExternal?: boolean;
  showPrinter?: boolean;
  path?: string;
  name: string;
  clickCb?: () => void;
}

export const FrequentlyUsedList: React.FunctionComponent<IFrequentlyUsedListProps> = ({ items }) => (
  <Box>
    <Heading
      textAlign="left"
      size="xs"
      color={'ff.magenta'}
      textTransform="uppercase"
      p={0}
      pb=".5em"
      mb=".5em"
      borderBottom="1px solid black"
      width="270px"
    >
      Frequently Used
    </Heading>
    <List textAlign="left" fontSize="14px">
      {items?.map((item: any, index: any) => (
        <ListItem key={index}>
          <Flex pt={1} pb={1}>
            <Box flex={1}>
              {item.clickCb ? (
                <Box cursor="pointer" textDecoration="underline !important" color={'ff.magenta'} onClick={item.clickCb}>
                  {item.name}
                </Box>
              ) : item.path && item.isExternal ? (
                <Link href={item.path} isExternal textDecoration="underline !important">
                  {item.name}
                </Link>
              ) : (
                <FfRouterLink cursor="pointer" textDecoration="underline !important" color={'ff.magenta'} to={item.path} mt={0}>
                  {item.name}
                </FfRouterLink>
              )}
            </Box>
            {item?.showPrinter === true ? (
              <Box>
                <PrinterIcon fontSize="22px" />
              </Box>
            ) : (
              <></>
            )}
          </Flex>
        </ListItem>
      ))}
    </List>
  </Box>
);
