import { Button, Stack } from '@chakra-ui/react';
import { Weekdays } from '../../utils/TimeHelper';

export interface DaySelectorProps {
  dayOfWeek: number;
  cb: (dayOfWeek: number) => void;
}

// Javascript Date dayOfWeek is not zero based!!  so we are sending back i+1 here
export const DaySelector: React.FunctionComponent<DaySelectorProps> = (props: DaySelectorProps) => (
  <Stack direction={'row'} mb="0.5em">
    {Object.values(Weekdays).map((k, i) => (
      <Button
        key={i}
        value={i}
        isActive={i + 1 === props.dayOfWeek}
        size="sm"
        _active={{ bg: '#dddfe2', transform: 'scale(0.98)', borderColor: '#bec3c9' }}
        onClick={() => props.cb(i + 1)}
      >
        {k.substring(0, 3)}
      </Button>
    ))}
  </Stack>
);
