import { atom, Getter, PrimitiveAtom, Setter, WritableAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { IParams, persistNavAtom } from '../atoms/navAtom';

export function atomWithReset<Value>(read: (get: Getter) => Promise<Value>) {
  const baseAtom = atom<undefined | Value>(undefined);

  const asyncAtom = atom(
    async (get) => get(baseAtom) ?? (await read(get)),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async (get, set, newValue: typeof RESET) => set(baseAtom, await read(get)),
  );
  return asyncAtom;
}

const atomApiWithUpdateInternal = <SomeValue extends {} | undefined, SomeUpdate, Value, Update = Value>(
  someAtom: PrimitiveAtom<SomeValue> | WritableAtom<SomeValue, SomeUpdate> | undefined,
  Read: (get: Getter, someAtomValue: SomeValue extends undefined ? undefined : {}) => Promise<Value>,
  Write: (get: Getter, set: Setter, newValue: Update, someAtomValue: SomeValue extends undefined ? undefined : {}) => Promise<Value>,
) => {
  const refreshAtom = atom(0);

  const updateValueAtom = atom<Value | undefined>(undefined);

  const updatedAtom = atom(
    async (get) => {
      if (someAtom) get(someAtom);
      get(refreshAtom);
      const updateValue = get(updateValueAtom);
      if (updateValue) {
        // await delayMs(1);
        return Promise.resolve(updateValue);
      }
      const result: Value = someAtom
        ? await (Read as (get: Getter, someAtomValue: SomeValue) => Promise<Value>)(get, get(someAtom))
        : await (Read as (get: Getter) => Promise<Value>)(get);
      // await delayMs(1);
      return result;
    },
    async (get, set, newValue: Update | typeof RESET) => {
      if (someAtom) get(someAtom);
      if (newValue === RESET) {
        set(refreshAtom, (a) => a + 1);
        set(updateValueAtom, undefined);
        // await delayMs(1);
        return Promise.resolve(undefined);
      }
      const result: Value = someAtom
        ? await (Write as (get: Getter, set: Setter, newValue: Update, someAtomValue: SomeValue) => Promise<Value>)(
            get,
            set,
            newValue,
            get(someAtom),
          )
        : await (Write as (get: Getter, set: Setter, newValue: Update) => Promise<Value>)(get, set, newValue);
      set(updateValueAtom, result);
      // await delayMs(1);
      return Promise.resolve(undefined);
    },
  );
  return updatedAtom;
};

export const atomApiWithNavAndUpdate = <Value, Update = Value>(
  Read: (get: Getter, someAtomValue: IParams) => Promise<Value>,
  Write: (get: Getter, set: Setter, newValue: Update, someAtomValue: IParams) => Promise<Value>,
) => atomApiWithUpdateInternal(persistNavAtom, Read, Write);

export const atomApiWithUpdate = <Value, Update = Value>(
  Read: (get: Getter) => Promise<Value>,
  Write: (get: Getter, set: Setter, newValue: Update) => Promise<Value>,
) => atomApiWithUpdateInternal(undefined, Read, Write);

export const atomApiWithNavAndReadInternal = <SomeValue extends {} | undefined, SomeUpdate, Value>(
  someAtom: PrimitiveAtom<SomeValue> | WritableAtom<SomeValue, SomeUpdate> | undefined,
  Read: (get: Getter, someAtomValue: SomeValue extends undefined ? undefined : {}) => Promise<Value>,
  id?: string,
) => {
  const internalId = id;

  const refreshAtom = atom(0);

  const updateValueAtom = atom<Value | undefined>(undefined);

  const updateNewAtom = atom(
    async (get) => {
      // if (internalId) console.log('read', internalId);
      get(refreshAtom);
      const updateValue = get(updateValueAtom);
      if (updateValue) return updateValue;
      // if (internalId) console.log('read', internalId, 'from server');
      const result = someAtom
        ? await (Read as (get: Getter, someAtomValue: SomeValue) => Promise<Value>)(get, get(someAtom))
        : await (Read as (get: Getter) => Promise<Value>)(get);
      // if (internalId) console.log('read', internalId, 'from server', 'done', JSON.stringify(result).length);
      return result;
    },
    (get, set, newValue: typeof RESET) => {
      if (newValue === RESET) {
        // if (internalId)  console.log('reset', internalId);
        set(refreshAtom, (a) => a + 1);
        set(updateValueAtom, undefined);
      }
    },
  );
  return updateNewAtom;
};

export const atomApiWithNavAndRead = <Value>(Read: (get: Getter, someAtomValue: IParams) => Promise<Value>, id?: string) =>
  atomApiWithNavAndReadInternal(persistNavAtom, Read, id);

export const atomApiWithRead = <Value>(Read: (get: Getter) => Promise<Value>, id?: string) =>
  atomApiWithNavAndReadInternal(undefined, Read, id);
