import { Box, Link } from '@chakra-ui/react';
import React from 'react';
import { useDrag } from 'react-dnd';
import { Link as RouterLink } from 'react-router-dom';

export interface TutorDragProps {
  schoolId: number;
  id: number;
  name: string;
}

export const TutorDrag: React.FunctionComponent<TutorDragProps> = ({ id, name, schoolId }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ isDragging }, dragRef] = useDrag({
    type: 'tutor',
    item: { id, name },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  return (
    <Box ref={dragRef} cursor="pointer">
      <Link as={RouterLink} to={`/schools/${schoolId}/tutors/${id}/ `}>
        {name}
      </Link>
    </Box>
  );
};
