import { Container, Heading } from '@chakra-ui/react';
import React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { SchoolDto, UserDto } from '@edanalytics/ff_be_se';
import { useAtom } from 'jotai';
import { FfTable } from '../../components/FfTable';
import { DebugCard } from '../../components/DebugCard';
import { RoleLabel, TutorLink, SchoolUserLink } from '../../components/Utils';
import { Empty } from '../../components/Empty';
import { SchoolServiceAtoms } from '../../services/School';

export const SchoolDirectoryScreen: React.FunctionComponent = () => {
  const [users] = useAtom(SchoolServiceAtoms.getCurrentSchoolUsers);
  const [school] = useAtom(SchoolServiceAtoms.getCurrentSchool);
  const columns: ColumnDef<UserDto>[] = [
    {
      accessorFn: (row) => row,
      id: 'fullName',
      cell: (info: any) =>
        info.getValue().roleId === 4
          ? TutorLink(school as SchoolDto, info.getValue())
          : SchoolUserLink(school as SchoolDto, info.getValue()),
      header: () => 'Name',
    },
    {
      accessorKey: 'email',
      id: 'email',
      cell: (info: any) => info.getValue(),
      header: () => 'Email',
    },
    {
      accessorFn: (row) => row.role,
      id: 'roleId',
      cell: (info: any) => RoleLabel(info.getValue()),
      header: () => 'Role',
    },
  ];

  return (
    <Container maxWidth="100%">
      <Heading color={'ff.blue'}>Staff Directory</Heading>
      {/* <ButtonGroup>
          <Text>Role: </Text>
          <RoleSelect />
        </ButtonGroup> */}
      {users ? <FfTable columns={columns} data={users} enablePagination={true} /> : <Empty description="No users." />}
      <DebugCard data={[{ name: 'users', data: users }]} />
    </Container>
  );
};
