import { Flex, VStack } from '@chakra-ui/react';
import React, { Suspense } from 'react';
import { Loader } from '../Loader';
import { PreloadWhoAmI } from '../Preload/PreloadWhoAmI';
import { Footer } from './Footer';

export interface INoRoleLayoutProps {
  children: any;
  bg?: string;
}

export const NoRoleLayout: React.FunctionComponent<INoRoleLayoutProps> = (props) => (
  <Flex align="center" className="default" flexGrow={1} flexDir="column" bg={props.bg ?? 'ff.blue'}>
    <VStack w="100%" align="center" justify="center" flexGrow={1}>
      <Suspense fallback={<Loader />}>
        <PreloadWhoAmI>{props.children}</PreloadWhoAmI>
      </Suspense>
    </VStack>
    <Footer />
  </Flex>
);
