export enum ReadingLevelTypes {
  Unspecified = 0,
  AA = 1,
  A = 2,
  B = 3,
  C = 4,
  D = 5,
  E = 6,
  F = 7,
  G = 8,
  H = 9,
  I = 10,
  J = 11,
  K = 12,
  L = 13,
  M = 14,
  N = 15,
  O = 16,
  P = 17,
  Q = 18,
  R = 19,
  S = 20,
  T = 21,
  U = 22,
  V = 23,
  W = 24,
  X = 25,
  Y = 26,
  Z = 27,
}

export const getReadingLevelTypeString = (readingLevel: ReadingLevelTypes): string => {
  switch (readingLevel) {
    case ReadingLevelTypes.Unspecified:
      return '--';
    case ReadingLevelTypes.AA:
      return 'AA';
    case ReadingLevelTypes.A:
      return 'A';
    case ReadingLevelTypes.B:
      return 'B';
    case ReadingLevelTypes.C:
      return 'C';
    case ReadingLevelTypes.D:
      return 'D';
    case ReadingLevelTypes.E:
      return 'E';
    case ReadingLevelTypes.F:
      return 'F';
    case ReadingLevelTypes.G:
      return 'G';
    case ReadingLevelTypes.H:
      return 'H';
    case ReadingLevelTypes.I:
      return 'I';
    case ReadingLevelTypes.J:
      return 'J';
    case ReadingLevelTypes.K:
      return 'K';
    case ReadingLevelTypes.L:
      return 'L';
    case ReadingLevelTypes.M:
      return 'M';
    case ReadingLevelTypes.N:
      return 'N';
    case ReadingLevelTypes.O:
      return 'O';
    case ReadingLevelTypes.P:
      return 'P';
    case ReadingLevelTypes.Q:
      return 'Q';
    case ReadingLevelTypes.R:
      return 'R';
    case ReadingLevelTypes.S:
      return 'S';
    case ReadingLevelTypes.T:
      return 'T';
    case ReadingLevelTypes.U:
      return 'U';
    case ReadingLevelTypes.V:
      return 'V';
    case ReadingLevelTypes.W:
      return 'W';
    case ReadingLevelTypes.X:
      return 'X';
    case ReadingLevelTypes.Y:
      return 'Y';
    case ReadingLevelTypes.Z:
      return 'Z';
    default:
      throw new Error(`No defined value for readingLevel!:${readingLevel ?? 'undefined'}`);
  }
};
