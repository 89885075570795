import React, { forwardRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import { InputGroup, Input, InputRightElement, Text } from '@chakra-ui/react';
import { CalendarIcon } from '@chakra-ui/icons';
import 'react-datepicker/dist/react-datepicker.css';

const customDateInput = ({ value, onClick, onChange, placeholderText, isDisabled }: any, ref: any) => (
  <Input
    autoComplete="off"
    width="100%"
    background="white"
    value={value}
    ref={ref}
    onClick={onClick}
    onChange={onChange}
    disabled={isDisabled}
    placeholder={placeholderText}
  />
);
customDateInput.displayName = 'DateInput';

const CustomInput = forwardRef(customDateInput);

const icon = <CalendarIcon fontSize="sm" />;

export interface DateTimePickerProps {
  isClearable?: boolean;
  onChange: (date: Date) => any;
  selectedDate: Date | undefined;
  showPopperArrow?: boolean;
  isDisabled?: boolean;
  filterDate?: (date: Date) => boolean;
  timeIntervals?: number;
  showTimeSelect?: boolean;
  dateFormat?: string;
  showIcon?: boolean;
  wrapperClassName?: string;
  placeholderText?: string;
  minDate?: Date;
  maxDate?: Date;
  excludeDateIntervals?: any;
}

export const DateTimePicker: React.FunctionComponent<DateTimePickerProps> = ({ selectedDate, onChange, ...props }: DateTimePickerProps) => (
  <>
    <InputGroup className="dark-theme">
      <ReactDatePicker
        selected={selectedDate}
        showTimeSelect={props.showTimeSelect ? props.showTimeSelect : undefined}
        timeIntervals={props.timeIntervals}
        disabled={props.isDisabled}
        readOnly={props.isDisabled}
        filterDate={props.filterDate ? props.filterDate : undefined}
        onChange={onChange}
        className="react-datapicker__input-text"
        wrapperClassName={props.wrapperClassName}
        minDate={props.minDate}
        maxDate={props.maxDate}
        customInput={<CustomInput placeholderText={props.placeholderText} isDisabled={props.isDisabled} />}
        dateFormat={props.dateFormat ? props.dateFormat : 'MM/dd/yyyy   h:mm a'}
        excludeDateIntervals={props.excludeDateIntervals}
        {...props}
      />
      {props.showIcon !== false && <InputRightElement color="gray.500" children={icon} />}
    </InputGroup>
  </>
);
