import { Box, Flex } from '@chakra-ui/react';
import { EarlyWordplayModuleData } from '../LessonPlanContent/EarlyWordplayModule';
import { paSkillOptions, sortSkillOptions, unitNumberOptions, yesNoOptions } from '../LessonPlanContent/LessonPlanUtils';
import { PrintableInputs } from '../PrintableInputs';

export interface IPrintableEarlyWordplayModuleProps {
  data: EarlyWordplayModuleData;
}

export const PrintableEarlyWordplayModule: React.FunctionComponent<IPrintableEarlyWordplayModuleProps> = ({ data }) => (
  <>
    <Flex align="top" css={{ breakInside: 'avoid-page' }}>
      <PrintableInputs.Input
        css={{ flexGrow: 1 }}
        minWidth={6}
        label="Unit #"
        children={unitNumberOptions.find((option) => option.value === data.unitNumber)?.key}
      />
      <PrintableInputs.Input
        css={{ flexGrow: 1 }}
        minWidth={6}
        label="Sort Skill #"
        children={sortSkillOptions.find((option) => option.value === data.sortSkill)?.key}
      />
      <PrintableInputs.Input
        css={{ flexGrow: 1 }}
        minWidth={6}
        label="PA Skill"
        children={paSkillOptions.find((option) => option.value === data.paSkill)?.key}
      />
      <PrintableInputs.Options
        label="Sort pattern journal done?"
        options={yesNoOptions.map((option) => ({ ...option, checked: option.value === data.sortPatternJournalDone }))}
      />
    </Flex>
    <Box css={{ breakInside: 'avoid-page' }}>
      <PrintableInputs.Textarea minLines={5} label="Notes" children={data.notes} />
    </Box>
  </>
);
