import { Box } from '@chakra-ui/react';
import { SessionLessonPlanDto } from '@edanalytics/ff_be_se';
import React from 'react';
import { LessonPlanModuleAccordion } from './LessonPlanModuleAccordion';

export interface ILessonPlanContentProps {
  lessonPlan: SessionLessonPlanDto;
  isSubmitEnabled: boolean;
  isSaving: boolean;
  isSubmitting: boolean;
  onSave: (formData: any) => void;
  onSaveAll: () => void;
  onSubmit: () => void;
  onChange: () => void;
  formRefs: any[];
  isAbsentSelected: boolean;
  openDeleteModal: (state: boolean) => void;
}

export const LessonPlanContent: React.FunctionComponent<ILessonPlanContentProps> = ({
  lessonPlan,
  isSubmitEnabled,
  isSaving,
  isSubmitting,
  onSave,
  onSaveAll,
  onSubmit,
  onChange,
  formRefs,
  isAbsentSelected,
  openDeleteModal,
}) => (
  <Box>
    {lessonPlan.lessonPlanModules && (
      <LessonPlanModuleAccordion
        lessonPlan={lessonPlan}
        isSubmitEnabled={isSubmitEnabled}
        isSaving={isSaving}
        isSubmitting={isSubmitting}
        onSave={onSave}
        onSaveAll={onSaveAll}
        onSubmission={onSubmit}
        onChange={onChange}
        formRefs={formRefs}
        isAbsentSelected={isAbsentSelected}
        openDeleteModal={openDeleteModal}
      />
    )}
  </Box>
);
