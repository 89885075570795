import { Box, Flex, Grid, GridItem, Heading, HStack, Stack } from '@chakra-ui/react';
import { RunningRecordDto } from '@edanalytics/ff_be_se';
import { useAtomValue } from 'jotai';
import { maxBy, sortBy } from 'lodash';
import React, { Suspense } from 'react';
import { RunningRecordServiceAtoms } from '../../services/RunningRecord';
import { DebugCard } from '../DebugCard';
import { FfDivider } from '../FfDivider';
import { getScoreColor } from '../LessonPlanContent/LessonPlanUtils';
import { GradeLevelTypes } from '../LessonPlanContent/models/GradeLevelEnum';
import { getReadingLevelTypeString, ReadingLevelTypes } from '../LessonPlanContent/models/ReadingLevelEnum';
import { Loader } from '../Loader';
import { ShortFormatDate } from '../Utils';

interface IGradeLevelGridItemsProps {
  gradeLevel: IGradeLevel;
}

interface IGradeLevel {
  gradeLevel: number;
  label: string;
  color: string;
  readingLevels: string[];
}

interface IGetCellProps {
  runningRecord: RunningRecordDto;
  readingLevel: string;
}

const gradeLevels = [
  {
    gradeLevel: GradeLevelTypes.PreK,
    label: 'Pre K',
    color: 'ff.teal',
    readingLevels: ['AA'],
  },
  {
    gradeLevel: GradeLevelTypes.K,
    label: 'K',
    color: 'ff.green',
    readingLevels: ['A', 'B', 'C', 'D'],
  },
  {
    gradeLevel: GradeLevelTypes.G1,
    label: 'Gr 1',
    color: 'ff.darkBlue',
    readingLevels: ['E', 'F', 'G', 'H', 'I', 'J'],
  },
  {
    gradeLevel: GradeLevelTypes.G2,
    label: 'Gr 2',
    color: 'ff.yellow',
    readingLevels: ['K', 'L', 'M'],
  },
  {
    gradeLevel: GradeLevelTypes.G3,
    label: 'Gr 3',
    color: 'ff.orange',
    readingLevels: ['N', 'O', 'P'],
  },
  {
    gradeLevel: GradeLevelTypes.G4,
    label: 'Gr 4',
    color: 'ff.teal',
    readingLevels: ['Q', 'R', 'S'],
  },
  {
    gradeLevel: GradeLevelTypes.G5,
    label: 'Gr 5',
    color: 'ff.green',
    readingLevels: ['T', 'U', 'V'],
  },
  {
    gradeLevel: GradeLevelTypes.G6,
    label: 'Gr 6',
    color: 'ff.darkBlue',
    readingLevels: ['W', 'X', 'Y'],
  },
  {
    gradeLevel: GradeLevelTypes.G7,
    label: 'Gr 7-8',
    color: 'ff.yellow',
    readingLevels: ['Z'],
  },
] as IGradeLevel[];

export const RunningRecordChartInternal: React.FunctionComponent<{}> = () => {
  const runningRecords = useAtomValue(RunningRecordServiceAtoms.getRunningRecord);
  const sortedRunningRecords = sortBy(runningRecords, (rr) => rr.shortDate);

  // Determine scope of chart, default to show PreK through K sections if no data
  const maxReadingLevel = maxBy(runningRecords, 'sessionReadingLevel')?.sessionReadingLevel || ReadingLevelTypes.C;
  const maxReadingLevelString = getReadingLevelTypeString(maxReadingLevel);
  const maxChartedGradeLevel = gradeLevels.find((x) => x.readingLevels.includes(maxReadingLevelString))?.gradeLevel ?? GradeLevelTypes.G8;

  const cellWidth = 35;

  const baseHeaderProps = {
    fontSize: '16px',
    fontWeight: 'bold',
    display: 'flex',
    color: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    colSpan: 1,
    paddingY: '0.5em',
  };

  const gradeLevelHeaderProps = {
    ...baseHeaderProps,
    className: 'verticalTableHeader',
  };

  const readingLevelHeaderProps = {
    ...baseHeaderProps,
    color: 'ff.blue',
    w: `${cellWidth * 2}px`,
    h: `${cellWidth}px`,
  };

  const dateTitleHeaderProps = {
    ...baseHeaderProps,
    className: 'verticalTableHeader',
    bgColor: 'lightgray',
    color: 'ff.blue',
  };

  const baseCellProps = {
    colSpan: 1,
    fontSize: '16px',
    color: 'ff.blue',
    w: `${cellWidth}px`,
    h: `${cellWidth}px`,
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const dateCellProps = {
    ...baseCellProps,
    className: 'verticalTableHeader',
    h: 'auto',
    py: '0.5em',
  };

  const titleCellProps = {
    ...dateCellProps,
    py: '1em',
  };

  const gradeLevelGridItemProps = {
    borderTopWidth: '3px',
    borderTopColor: 'lightgray',
    borderBottomWidth: '1px',
    borderBottomColor: 'lightgray',
  };

  const readingLevelRowProps = {
    spacing: 0,
    borderBottom: '1px solid lightgray',
  };

  const GetCell: React.FunctionComponent<IGetCellProps> = ({ runningRecord, readingLevel }) => {
    const isMatch = runningRecord?.sessionReadingLevel && getReadingLevelTypeString(runningRecord.sessionReadingLevel) === readingLevel;

    return (
      <Box {...baseCellProps} bgColor={isMatch ? getScoreColor(runningRecord.sessionScore || 0) : 'white'}>
        {isMatch && runningRecord.sessionComprehension}
      </Box>
    );
  };

  const GradeLevelGridItems: React.FunctionComponent<IGradeLevelGridItemsProps> = ({ gradeLevel }) => (
    <React.Fragment>
      <GridItem {...gradeLevelHeaderProps} bg={gradeLevel.color} area={`G${gradeLevel.gradeLevel}Head`}>
        {gradeLevel.label}
      </GridItem>
      <GridItem {...gradeLevelGridItemProps} borderTopColor={gradeLevel.color} area={`G${gradeLevel.gradeLevel}Body`}>
        <Box>
          {[...(gradeLevel.readingLevels ?? [])].reverse().map((readingLevel, i) => (
            <HStack key={i} {...readingLevelRowProps} className="readingLevelRowHStack">
              <Box {...readingLevelHeaderProps}>{readingLevel}</Box>
              {sortedRunningRecords?.map((rr: RunningRecordDto, j) => (
                <GetCell key={j} runningRecord={rr} readingLevel={readingLevel} />
              ))}
            </HStack>
          ))}
        </Box>
      </GridItem>
    </React.Fragment>
  );

  const RunningRecordLegend: React.FunctionComponent<{}> = () => (
    <Flex mt="1em" color={'ff.blue'} fontSize="12px">
      <Box>
        <Heading size="sm" pr="2em">
          KEY:
        </Heading>
      </Box>
      <Box pr="3em">
        <Box mb="0.5em">Score:</Box>
        <HStack spacing={0}>
          <Box p="0.7em" bgColor={'ff.orange'}>
            Frustrational &lt; 90%
          </Box>
          <Box p="0.7em" bgColor={'ff.yellow'}>
            Instructional - 90-94%
          </Box>
          <Box p="0.7em" bgColor={'ff.green'}>
            Independent - 95%
          </Box>
        </HStack>
      </Box>
      <Box>
        <Box mb="0.5em">Comprehension:</Box>
        <HStack spacing={'3em'}>
          <Box p="0.7em">1 - Not Yet</Box>
          <Box p="0.7em">2 - Somewhat</Box>
          <Box p="0.7em">3 - Understanding</Box>
        </HStack>
      </Box>
    </Flex>
  );

  const templateAreasString = [...gradeLevels]
    .reverse()
    .map((gradeLevel) => `"G${gradeLevel.gradeLevel}Head G${gradeLevel.gradeLevel}Body"`)
    .join(' ');

  return (
    <Box p="2em" bgColor="white">
      <Grid
        templateAreas={`${templateAreasString} "dateHead dateBody" "titleHead titleBody"`}
        gridTemplateColumns={'30px 1fr'}
        overflowX="scroll"
      >
        {[...gradeLevels]
          .reverse()
          .filter((gradeLevel) => maxChartedGradeLevel >= gradeLevel.gradeLevel)
          .map((gradeLevel, i) => (
            <GradeLevelGridItems key={i} gradeLevel={gradeLevel} />
          ))}
        <GridItem {...dateTitleHeaderProps} area={'dateHead'}>
          Date
        </GridItem>
        <GridItem {...gradeLevelGridItemProps} area={'dateBody'}>
          <Box>
            <HStack {...readingLevelRowProps} border={0}>
              <Box {...readingLevelHeaderProps}></Box>
              {sortedRunningRecords?.map((rr: RunningRecordDto, i) => (
                <Box key={i} {...dateCellProps}>
                  {ShortFormatDate(rr.shortDate)}
                </Box>
              ))}
            </HStack>
          </Box>
        </GridItem>
        <GridItem {...dateTitleHeaderProps} area={'titleHead'}>
          Title
        </GridItem>
        <GridItem {...gradeLevelGridItemProps} borderTopWidth={0} area={'titleBody'}>
          <Box>
            <HStack {...readingLevelRowProps} border={0}>
              <Box {...readingLevelHeaderProps}></Box>
              {sortedRunningRecords?.map((rr: RunningRecordDto, i) => (
                <Box key={i} {...titleCellProps}>
                  {rr.sessionTitle}
                </Box>
              ))}
            </HStack>
          </Box>
        </GridItem>
      </Grid>
      <RunningRecordLegend />
      <DebugCard data={[{ name: 'sortedRunningRecords', data: sortedRunningRecords }]} />
    </Box>
  );
};

export const RunningRecordChart: React.FunctionComponent<{}> = (props) => (
  <Stack mb="3em">
    <Flex>
      <Box>
        <Heading color={'ff.blue'}>Running Record</Heading>
      </Box>
    </Flex>
    <FfDivider />
    <Suspense fallback={<Loader />}>
      <RunningRecordChartInternal {...props} />
    </Suspense>
  </Stack>
);
