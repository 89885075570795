import { Box, Flex } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { ShortFormatDateTime } from './Utils';
import { FfRouterLink as Link } from './FfRouterLink';

export interface IObservationSlugProps {
  observation: any;
}

export const ObservationSlug: React.FunctionComponent<IObservationSlugProps> = ({ observation }) => {
  const { schoolId, tutorId } = useParams();

  return (
    <>
      {observation && (
        <Box
          px="1em"
          py=".7em"
          marginLeft="5em"
          marginRight="5em"
          marginTop="1em"
          bg="white"
          shadow="md"
          borderWidth="1px"
          fontSize=".8em"
          rounded={6}
        >
          <Flex fontSize="12px">
            <Box flex={2}>{ShortFormatDateTime(observation.createdAt)}</Box>
            <Box flex={1} textAlign="right">
              <Link to={`/schools/${schoolId}/tutors/${tutorId}/observe/${observation.sessionLessonPlan.id}`}>See Details</Link>
            </Box>
          </Flex>
        </Box>
      )}
    </>
  );
};
