/* eslint-disable max-classes-per-file */
import { plainToClass } from 'class-transformer';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { IsDate } from 'class-validator';
import { DateTime as DT } from 'luxon';

export class RescheduleErrorData {
  constructor(values: { tutorName: string; studentName: string; startDateTime: string; endDateTime: string }) {
    const { startDateTime, endDateTime } = values;
    this.startDateTime = DT.fromISO(startDateTime).toJSDate();
    this.endDateTime = DT.fromISO(endDateTime).toJSDate();
    this.tutorName = values.tutorName;
    this.studentName = values.studentName;
  }

  tutorName: string;

  studentName: string;

  @IsDate()
  startDateTime: Date;

  @IsDate()
  endDateTime: Date;
}

interface ApiResult<T, Err> {
  result?: T;
  error?: Err;
}
/*
name: string, detail: string, data

*/
export class DefaultErrorModel<T = any> {
  public message: string;

  public status: number;

  public error: string;

  public timestamp: string;

  public path: string;

  public exception: string;

  public errors: any[];

  public detail: string;

  public data: T;
}

// type guard
export function isAxiosError<T>(error: unknown): error is AxiosError<DefaultErrorModel<T>> {
  return (
    typeof error === 'object' &&
    !!error &&
    'response' in (error as Record<string, unknown>) &&
    typeof (error as Record<string, unknown>).response === 'object'
  );
}
export async function callApi<T, U, UserErr = DefaultErrorModel>(
  axiosInstance: AxiosInstance,
  method: 'get' | 'post' | 'put' | 'delete',
  url: string,
  modelClass: new () => T,
  payload?: U,
  errorModelClass?: new () => UserErr,
): Promise<ApiResult<T, UserErr>> {
  try {
    const response = payload ? await axiosInstance[method]<T>(url, payload) : await axiosInstance[method]<T>(url);
    const result = plainToClass(modelClass, response.data);
    return { result };
  } catch (rawError: unknown) {
    if (!(rawError instanceof Error)) {
      throw new Error('rawError is not an instance of Error', rawError as any);
    }

    if (isAxiosError<UserErr>(rawError) && rawError.response?.data) {
      const ErrorModel = errorModelClass ?? (DefaultErrorModel.constructor as new () => UserErr);
      const error = plainToClass(ErrorModel, rawError.response.data);
      return { error };
    }
    // If rawError doesn't have a response, it might be a network error or a different kind of error.
    throw rawError;
  }
}
