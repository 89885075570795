/* eslint-disable no-nested-ternary */
import { Input, GridItem, Text, FormControl, FormErrorMessage, FormLabel, useFormErrorStyles } from '@chakra-ui/react';
import { ErrorMessage, Field, useField } from 'formik';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { Appointment } from '../models/Appointment';
import { AddTimeDuration, GetNearestIntervalDate } from '../utils/TimeHelper';

const defaultDuration = 30;
export interface TimePickerProps {
  label: any;
  id: string;

  endId?: string;
  startTime?: string;
  endTime?: string;
  // onChange: (d: { startTime: string; endTime?: string }) => void;
  apptDuration?: number;
  dontShowError?: boolean;
  rangeMode?: boolean;
  format?: string;
  isDisabled?: boolean;
}

// using luxon to format the time
const innerFormat = 'HH:mm';

const formatTime = (time: string | undefined, formatIn: string, formatOut: string = formatIn, initialDate?: string) => {
  const hasYear = formatIn.startsWith('Y') || formatIn.startsWith('y') || formatIn === 'ISO';

  const dateString =
    initialDate === undefined
      ? '2000-01-01'
      : formatIn === 'ISO'
      ? DateTime.fromISO(initialDate as string).toFormat('yyyy-MM-dd')
      : hasYear
      ? DateTime.fromFormat(initialDate as string, formatIn).toFormat('yyyy-MM-dd')
      : '2000-01-01';

  const fullFormat = hasYear ? formatIn : `${dateString} ${formatIn}`;
  const fullDateTimeString = hasYear ? time : `2000-01-01 ${time}`;

  if (!fullDateTimeString) {
    return undefined;
  }

  const dateTime = fullFormat === 'ISO' ? DateTime.fromISO(fullDateTimeString) : DateTime.fromFormat(fullDateTimeString, fullFormat);

  if (!dateTime.isValid) {
    return undefined;
  }

  if (formatOut === 'ISO') {
    dateTime.set({ year: 2000, month: 1, day: 1 });
    return dateTime.toISO() ?? undefined;
  }
  return dateTime.toFormat(formatOut);
};

const isTimeValid = (start: string | undefined, end: string | undefined, rangeMode: boolean | undefined) => {
  const startDate = new Date(`01/01/2000 ${start}`);
  const endDate = new Date(`01/01/2000 ${end}`);
  const startTimeMs = startDate.getTime();
  const endTimeMs = endDate.getTime();

  if (rangeMode && endTimeMs < startTimeMs) {
    return 'Please enter correct times';
  }

  return undefined;
};

const defaultStartTime = (outerFormat: string, startTime?: string) =>
  formatTime(startTime, outerFormat, innerFormat) ??
  GetNearestIntervalDate(30).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });

const defaultEndTime = (outerFormat: string, startTime?: string, endTime?: string, apptDuration?: number, rangeMode?: boolean) =>
  formatTime(endTime, outerFormat, innerFormat) ??
  (apptDuration && rangeMode ? AddTimeDuration(formatTime(startTime, outerFormat, innerFormat) as string, apptDuration) : undefined);

export const TimePicker: React.FunctionComponent<TimePickerProps> = ({
  rangeMode,
  dontShowError,
  label,
  id,
  endId,
  apptDuration = defaultDuration,
  format,
  isDisabled,
  ...rest
}) => {
  const [, { error: startError, value: initialStartTime, ...startMeta }, { setError: setStartError, setValue: setFormikStart }] = useField<
    string | undefined
  >(id);
  const [, { value: initialEndTime, ...endMeta }, { setError: setEndError, setValue: setFormikEnd }] = useField<string | undefined>(
    endId ?? '',
  );

  const outerFormat = format ?? innerFormat;
  const [startTime, setStartTime] = useState(defaultStartTime(outerFormat, initialStartTime));
  const [endTime, setEndTime] = useState(defaultEndTime(outerFormat, initialStartTime, initialEndTime, apptDuration, rangeMode));

  useEffect(() => {
    const errorMessage = isTimeValid(startTime, endTime, rangeMode);
    if (startError === errorMessage) return;
    setStartError(errorMessage);
  }, [startTime, endTime, rangeMode, setStartError, startError]);

  /*
  useEffect(() => {
    if (startError === '') {
      const needsFormatting = outerFormat !== innerFormat;
      const startTimeFormatted = needsFormatting ? formatTime(startTime, innerFormat, outerFormat) : startTime;
      const endTimeFormatted = needsFormatting && endTime ? formatTime(endTime, innerFormat, outerFormat) : endTime;

      if (initialStartTime !== startTimeFormatted) setFormikStart( startTimeFormatted );
      if (initialEndTime !== endTimeFormatted) setFormikEnd( endTimeFormatted );
    }
  }, [startError, startTime, endTime, setFormikStart, setFormikEnd ]);
  */

  const updateTime = () => {
    const errorMessage = isTimeValid(startTime, endTime, rangeMode);
    const startTimeFormatted = format ? formatTime(startTime, innerFormat, outerFormat, initialStartTime) : startTime;
    const endTimeFormatted = format && endTime ? formatTime(endTime, innerFormat, outerFormat, initialStartTime) : endTime;
    setFormikStart(startTimeFormatted);
    setFormikEnd(endTimeFormatted);
  };

  return (
    <FormControl isInvalid={!!startMeta.touched && !!startError} isDisabled={isDisabled}>
      {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
      <GridItem display={'flex'}>
        <Field name={id}>
          {({ meta }: any) => (
            <Input
              autoComplete="off"
              background="white"
              onChange={(e) => setStartTime(e.target.value)}
              value={startTime}
              width={'140px'}
              onBlur={() => {
                meta.touched = true;
                updateTime();
              }}
              type="time"
              isDisabled={isDisabled}
            />
          )}
        </Field>
        {rangeMode && (
          <>
            <Text fontWeight={'bold'} margin="5px">
              -
            </Text>
            <Field name={endId}>
              {({ meta }: any) => (
                <Input
                  autoComplete="off"
                  background="white"
                  onChange={(e) => setEndTime(e.target.value)}
                  value={endTime}
                  width={'140px'}
                  onBlur={() => {
                    meta.touched = true;
                    updateTime();
                  }}
                  type="time"
                  isDisabled={isDisabled}
                />
              )}
            </Field>
          </>
        )}
      </GridItem>
      {!dontShowError && (
        <FormErrorMessage>
          {' '}
          <ErrorMessage name={id} />{' '}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};
