export enum SortSkillTypes {
  SS1 = 1,
  SS2 = 2,
  SS3 = 3,
  SS4 = 4,
  SS5 = 5,
  SS6 = 6,
  SS7 = 7,
  SS8 = 8,
  SS9 = 9,
  SS10 = 10,
  SS11 = 11,
  SS12 = 12,
  SS13 = 13,
  SS14 = 14,
  SS15 = 15,
  SS16 = 16,
  SS17 = 17,
  SS18 = 18,
  SS19 = 19,
  SS20 = 20,
  SS21 = 21,
  SS22 = 22,
  SS23 = 23,
  SS24 = 24,
  SS25 = 25,
  SS26 = 26,
  SS27 = 27,
  SS28 = 28,
  SS29 = 29,
  SS30 = 30,
  SS31 = 31,
  SS32 = 32,
  SS33 = 33,
  SS34 = 34,
  SS35 = 35,
  SS36 = 36,
  SS37 = 37,
  SS38 = 38,
  SS39 = 39,
  SS40 = 40,
  SS41 = 41,
  SS42 = 42,
  SS43 = 43,
  SS44 = 44,
  SS45 = 45,
  SS46 = 46,
  SS47 = 47,
  SS48 = 48,
  SS49 = 49,
  SS50 = 50,
}

export const getSortSkillTypeString = (sortSkill: SortSkillTypes): string => {
  switch (sortSkill) {
    case SortSkillTypes.SS1:
      return 'Sort Skill 1';
    case SortSkillTypes.SS2:
      return 'Sort Skill 2';
    case SortSkillTypes.SS3:
      return 'Sort Skill 3';
    case SortSkillTypes.SS4:
      return 'Sort Skill 4';
    case SortSkillTypes.SS5:
      return 'Sort Skill 5';
    case SortSkillTypes.SS6:
      return 'Sort Skill 6';
    case SortSkillTypes.SS7:
      return 'Sort Skill 7';
    case SortSkillTypes.SS8:
      return 'Sort Skill 8';
    case SortSkillTypes.SS9:
      return 'Sort Skill 9';
    case SortSkillTypes.SS10:
      return 'Sort Skill 10';
    case SortSkillTypes.SS11:
      return 'Sort Skill 11';
    case SortSkillTypes.SS12:
      return 'Sort Skill 12';
    case SortSkillTypes.SS13:
      return 'Sort Skill 13';
    case SortSkillTypes.SS14:
      return 'Sort Skill 14';
    case SortSkillTypes.SS15:
      return 'Sort Skill 15';
    case SortSkillTypes.SS16:
      return 'Sort Skill 16';
    case SortSkillTypes.SS17:
      return 'Sort Skill 17';
    case SortSkillTypes.SS18:
      return 'Sort Skill 18';
    case SortSkillTypes.SS19:
      return 'Sort Skill 19';
    case SortSkillTypes.SS20:
      return 'Sort Skill 20';
    case SortSkillTypes.SS21:
      return 'Sort Skill 21';
    case SortSkillTypes.SS22:
      return 'Sort Skill 22';
    case SortSkillTypes.SS23:
      return 'Sort Skill 23';
    case SortSkillTypes.SS24:
      return 'Sort Skill 24';
    case SortSkillTypes.SS25:
      return 'Sort Skill 25';
    case SortSkillTypes.SS26:
      return 'Sort Skill 26';
    case SortSkillTypes.SS27:
      return 'Sort Skill 27';
    case SortSkillTypes.SS28:
      return 'Sort Skill 28';
    case SortSkillTypes.SS29:
      return 'Sort Skill 29';
    case SortSkillTypes.SS30:
      return 'Sort Skill 30';
    case SortSkillTypes.SS31:
      return 'Sort Skill 31';
    case SortSkillTypes.SS32:
      return 'Sort Skill 32';
    case SortSkillTypes.SS33:
      return 'Sort Skill 33';
    case SortSkillTypes.SS34:
      return 'Sort Skill 34';
    case SortSkillTypes.SS35:
      return 'Sort Skill 35';
    case SortSkillTypes.SS36:
      return 'Sort Skill 36';
    case SortSkillTypes.SS37:
      return 'Sort Skill 37';
    case SortSkillTypes.SS38:
      return 'Sort Skill 38';
    case SortSkillTypes.SS39:
      return 'Sort Skill 39';
    case SortSkillTypes.SS40:
      return 'Sort Skill 40';
    case SortSkillTypes.SS41:
      return 'Sort Skill 41';
    case SortSkillTypes.SS42:
      return 'Sort Skill 42';
    case SortSkillTypes.SS43:
      return 'Sort Skill 43';
    case SortSkillTypes.SS44:
      return 'Sort Skill 44';
    case SortSkillTypes.SS45:
      return 'Sort Skill 45';
    case SortSkillTypes.SS46:
      return 'Sort Skill 46';
    case SortSkillTypes.SS47:
      return 'Sort Skill 47';
    case SortSkillTypes.SS48:
      return 'Sort Skill 48';
    case SortSkillTypes.SS49:
      return 'Sort Skill 49';
    case SortSkillTypes.SS50:
      return 'Sort Skill 50';
    default:
      throw new Error('No defined value!');
  }
};
