/* eslint-disable no-nested-ternary */
import { DateTime as DT } from 'luxon';
import _ from 'lodash';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Heading, List, ListItem, Stack } from '@chakra-ui/react';
import { FFRoles, SchoolDto, SessionLessonPlanDto } from '@edanalytics/ff_be_se';
import React from 'react';
import { DebugCard } from './DebugCard';
import { CompactFormatDate, MonthYearFormat } from './Utils';
import { SessionCard } from './SessionCard';
import { SessionCardKey } from './SessionCardKey';
import { TutorAccordionSlug } from './TutorAccordionSlug';
import { FfRouterLink as Link } from './FfRouterLink';
import { Loader } from './Loader';
import { Asc, Desc } from '../utils/TimeHelper';

export type GroupType = 'tutor' | 'date';

export interface ISessionsAccordionProps {
  school: SchoolDto;
  lessonPlans: SessionLessonPlanDto[];
  groupType: GroupType;
  showObserveLinks?: boolean;
}

export interface IDetailSessionsAccordionProps extends ISessionsAccordionProps {
  index: number;
  key: string;
  showObserveLinks?: boolean;
}

const getTimeKey = (lessonPlan: SessionLessonPlanDto) =>
  new Date(lessonPlan.startDateTime).toISOString() < new Date().toISOString() ? 'Past Sessions' : 'Upcoming Sessions';
const getUserKey = (lessonPlan: SessionLessonPlanDto) => lessonPlan.byUserId;
const getDateKey = (lessonPlan: SessionLessonPlanDto) => DT.fromISO(lessonPlan.startDateTime).toFormat('yyyy-MM-dd');

const AccordionHeader = ({ isExpanded, children }: { isExpanded: boolean; children: any }) => (
  <Heading>
    <AccordionButton>
      <Box flex={0} marginRight="1em">
        {isExpanded ? <MinusIcon fontSize="12px" /> : <AddIcon fontSize="12px" />}
      </Box>
      <Box marginRight="1em">
        <Heading size="sm" style={{ fontWeight: '500' }}>
          {children}
        </Heading>
      </Box>
    </AccordionButton>
  </Heading>
);

const ListCards = ({ lessonPlans, showObserveLinks }: { lessonPlans: SessionLessonPlanDto[]; showObserveLinks: boolean | undefined }) => (
  <List style={{ marginLeft: '1.75em' }}>
    {lessonPlans?.map((lessonPlan, sIndex) => (
      <ListItem key={sIndex}>
        <SessionCard lessonPlan={lessonPlan} showObserveLinks={showObserveLinks} />
      </ListItem>
    ))}
  </List>
);

const DateAccordionItem = ({
  school,
  groupType,
  key: parentKey,
  lessonPlans,
  index: parentIndex,
  showObserveLinks,
}: IDetailSessionsAccordionProps) => {
  const groupedLessonPlans: { [key: string]: SessionLessonPlanDto[] } = _.groupBy(lessonPlans, getTimeKey);

  return (
    <AccordionItem key={parentIndex} borderBottom="1px solid #333">
      {({ isExpanded }) => (
        <>
          <AccordionHeader isExpanded={isExpanded}>
            {groupType === 'tutor' ? (
              <TutorAccordionSlug user={lessonPlans[0].byUser} />
            ) : (
              `${CompactFormatDate(DT.fromFormat(parentKey, 'yyyy-MM-dd').toJSDate())}`
            )}
          </AccordionHeader>
          {isExpanded && (
            <AccordionPanel style={{ paddingTop: 0, paddingBottom: 0 }}>
              {groupType === 'tutor' &&
                (lessonPlans[0].byUser && lessonPlans[0]?.byUser.roleId === FFRoles.Tutor ? (
                  <Stack direction="row" spacing={10} align="right">
                    <Link
                      cursor="pointer"
                      marginLeft="5.9em"
                      fontSize={13}
                      textDecoration="underline"
                      to={`/schools/${school.id}/tutors/${lessonPlans[0].byUserId}`}
                    >
                      See Tutor Summary
                    </Link>
                    <Link
                      cursor="pointer"
                      marginLeft="5.9em"
                      fontSize={13}
                      textDecoration="underline"
                      to={`/schools/${school.id}/tutors/assign`}
                    >
                      Assign Tutor
                    </Link>
                    {showObserveLinks && (
                      <Link
                        cursor="pointer"
                        marginLeft="5.9em"
                        fontSize={13}
                        textDecoration="underline"
                        to={`/schools/${school.id}/tutors/${lessonPlans[0].byUserId}/observe`}
                      >
                        Observe Tutor
                      </Link>
                    )}
                  </Stack>
                ) : (
                  <Link
                    cursor="pointer"
                    marginLeft="5.9em"
                    fontSize={13}
                    textDecoration="underline"
                    to={`/schools/${school.id}/users/${lessonPlans[0].byUserId}`}
                  >
                    See Staff Details
                  </Link>
                ))}
              {groupType === 'tutor' ? (
                Object.keys(groupedLessonPlans).map((key: string, index: number) => (
                  <React.Fragment key={index}>
                    <Box flex={0} marginRight="1em">
                      {key}
                    </Box>
                    <ListCards lessonPlans={groupedLessonPlans[key]} showObserveLinks={showObserveLinks} />
                  </React.Fragment>
                ))
              ) : (
                <ListCards lessonPlans={lessonPlans} showObserveLinks={showObserveLinks} />
              )}
              <SessionCardKey />
            </AccordionPanel>
          )}
        </>
      )}
    </AccordionItem>
  );
};

export const TimeBreakItem = ({
  school,
  groupType,
  key: parentKey,
  lessonPlans,
  index: parentIndex,
  showObserveLinks,
}: IDetailSessionsAccordionProps) => {
  const groupedLessonPlans: { [key: string]: SessionLessonPlanDto[] } = _.groupBy(lessonPlans, getDateKey);

  const getByYRMon = (lessonPlan: SessionLessonPlanDto) => MonthYearFormat(lessonPlan.startDateTime);
  const getByMon = (lessonPlan: SessionLessonPlanDto) => DT.fromISO(lessonPlan.startDateTime).toFormat('yyyy-MM-01');

  const lessonPlansByYRMon = _.groupBy(lessonPlans, getByMon);

  return (
    <Box mb="3em" key={parentIndex}>
      <Box flex={0} marginRight="1em">
        {parentKey}
      </Box>
      <Accordion allowMultiple={true} defaultIndex={groupType === 'date' ? [0] : []}>
        {parentKey === 'Upcoming Sessions' &&
          groupedLessonPlans &&
          Object.keys(groupedLessonPlans).map((key: string, index: number) =>
            DateAccordionItem({ school, groupType, key, lessonPlans: groupedLessonPlans[key], index, showObserveLinks }),
          )}

        {parentKey === 'Past Sessions' &&
          lessonPlansByYRMon &&
          Object.keys(lessonPlansByYRMon).map((key: string, index: number) => (
            <AccordionItem key={key} borderBottom="1px solid #333">
              {({ isExpanded }) => (
                <>
                  <AccordionHeader isExpanded={isExpanded}>{MonthYearFormat(key)}</AccordionHeader>
                  <AccordionPanel pb={4}>
                    {Object.keys(_.groupBy(lessonPlansByYRMon[key], getDateKey)).map((key2: string, index2: number, items: string[]) =>
                      DateAccordionItem({
                        school,
                        groupType,
                        key: key2,
                        lessonPlans: _.groupBy(lessonPlansByYRMon[key], getDateKey)[key2],
                        index: index2,
                        showObserveLinks,
                      }),
                    )}
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          ))}
      </Accordion>
      <DebugCard
        data={[
          { name: 'groupType', data: groupType },
          { name: 'lessonPlans', data: lessonPlans },
          { name: 'groupedLessonPlans', data: groupedLessonPlans },
        ]}
      />
    </Box>
  );
};

export const SessionsAccordion: React.FunctionComponent<ISessionsAccordionProps> = ({
  school,
  lessonPlans,
  groupType,
  showObserveLinks,
}) => {
  // sort by past, upcoming then by date
  const sortedLessonPlans = lessonPlans
    .map((lp) => ({ ...lp, timeKey: getTimeKey(lp) }))
    .sort(({ timeKey: timeKeyA, ...lpA }, { timeKey: timeKeyB, ...lpB }) =>
      timeKeyA === timeKeyB && timeKeyA === 'Past Sessions'
        ? Desc('startDateTime')(lpA, lpB)
        : timeKeyA === timeKeyB && timeKeyA === 'Upcoming Sessions'
        ? Asc('startDateTime')(lpA, lpB)
        : timeKeyA === 'Upcoming Sessions'
        ? -1
        : 1,
    );

  const groupedLessonPlans: [string, SessionLessonPlanDto[]][] =
    groupType === 'tutor'
      ? Object.entries(_.groupBy(sortedLessonPlans, getUserKey))
      : Object.entries(_.groupBy(sortedLessonPlans, getTimeKey));

  if (!groupType) return <Loader />;

  return (
    <Box mb="3em">
      <Accordion allowMultiple={true} defaultIndex={groupType === 'date' ? [0] : []}>
        {groupedLessonPlans &&
          groupedLessonPlans.map(([key, subGroupLessonPlans], index: number) =>
            groupType === 'tutor'
              ? DateAccordionItem({ school, groupType: 'tutor', key, lessonPlans: subGroupLessonPlans, index, showObserveLinks })
              : TimeBreakItem({ school, groupType: 'date', key, lessonPlans: subGroupLessonPlans, index, showObserveLinks }),
          )}
      </Accordion>
      <DebugCard
        data={[
          { name: 'groupType', data: groupType },
          { name: 'lessonPlans', data: lessonPlans },
          { name: 'groupedLessonPlans', data: groupedLessonPlans },
        ]}
      />
    </Box>
  );
};
