import { Link, useToast } from '@chakra-ui/react';
import { UserDto } from '@edanalytics/ff_be_se';
import { useUpdateAtom } from 'jotai/utils';
import { UserServiceAtoms } from '../../services/User';

export const UserActivateButton = (props: { user: UserDto }) => {
  const toast = useToast();
  const updateActiveState = useUpdateAtom(UserServiceAtoms.updateActiveState);
  return !props.user.active ? (
    <Link
      cursor="pointer"
      textDecoration="underline !important"
      color={'ff.pink'}
      onClick={async () => {
        await updateActiveState({ userId: props.user.id, active: true });
        toast({ description: `${props.user.fullName} activated`, status: 'success' });
      }}
    >
      Activate
    </Link>
  ) : (
    <></>
  );
};
