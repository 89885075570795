import { Box, Heading, HStack, Img, VStack } from '@chakra-ui/react';
import { useAtomValue } from 'jotai';
import React from 'react';
import Background from '../../assets/images/background-stars.svg';
import FutureForwardLogo from '../../assets/images/FutureForwardLogo.svg';
import { whoAmIatom } from '../../services/Identity';

export const NoRoleScreen: React.FunctionComponent = () => {
  const user = useAtomValue(whoAmIatom);

  return (
    <>
      <HStack w="100%" maxW="1200px" p="3em" justify="space-between">
        <Box flex="0% 1 1">
          <Img src={FutureForwardLogo} />
        </Box>
        {user?.firstName && user?.lastName && (
          <>
            <Heading color="#D6F2EF" as="h1" size="xl" noOfLines={1}>
              Welcome, {`${user.firstName} ${user.lastName}`}
            </Heading>
            <Box flex="0% 1 1" />
          </>
        )}
      </HStack>
      <VStack flexGrow={1} bg="#D7F2F0" w="100%">
        <VStack
          flexGrow={1}
          justify="center"
          maxW="1200px"
          w="100%"
          margin="auto"
          backgroundImage={Background}
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
        >
          <Heading color="#1C4559" as="h1" size="xl">
            We're Going Places.
          </Heading>
          <Heading color="#1C4559" textAlign="center" maxW="30em" size="sm" pb="4em">
            But it looks like we might need a little more time to finish setting up your account. If you think this is an error,
            <a href="mailto:futureforward@edanalytics.org">contact us</a>.
          </Heading>
        </VStack>
      </VStack>
    </>
  );
};
