import { Box, Text } from '@chakra-ui/react';
import { FfRouterLink as Link } from './FfRouterLink';

export interface IEmptyProps {
  description?: string;
  path?: string;
  label?: string;
}

export const Empty: React.FunctionComponent<IEmptyProps> = (props) => (
  <Box p={5} mt="1em" shadow="md" borderWidth="1px" fontSize=".8em" mb="2em" textAlign="center">
    <Text fontSize="md">
      {props.description ?? 'No data.'}
      {props.path && props.label && (
        <Link ml={'0.3em'} color={'ff.magenta'} to={props.path}>
          {props.label}
        </Link>
      )}
    </Text>
  </Box>
);
