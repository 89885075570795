import React, { useState } from 'react';
import { Search2Icon } from '@chakra-ui/icons';
import {
  Input,
  Stack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Divider,
  Box,
} from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { StudentServiceAtoms } from '../services/Student';
import { FfRouterLink as Link } from '../components/FfRouterLink';
import { persistNavAtom } from '../atoms/navAtom';

export interface ISearchWidgetProps {
  title?: string;
}

export const SearchWidget: React.FunctionComponent<ISearchWidgetProps> = () => {
  const [search, setSearch] = useState('');
  const [schoolNav] = useAtom(persistNavAtom);
  const [students] = useAtom(StudentServiceAtoms.getCurrentStudents);

  const studentArray = students?.filter((s) => (s.firstName + s.lastName).toLowerCase().includes(search.toLowerCase()));

  const isEnabled = !!schoolNav.schoolId;

  return (
    <Stack direction="row" align="center" minW="300px">
      <Search2Icon fontSize="24px" />
      <Popover autoFocus={false}>
        <PopoverTrigger>
          <Input
            borderColor="#00000000"
            onChange={(e) => setSearch(e.target.value)}
            variant="flushed"
            disabled={!isEnabled}
            placeholder={isEnabled ? 'Search Students' : 'Select a school first'}
          />
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody maxHeight={'400px'} overflow={'auto'}>
            {studentArray?.map((item) => (
              <Box key={item.id}>
                <Link to={`/schools/${item?.school?.id}/students/${item.id}`} color={'ff.magenta'}>
                  {item.firstName} {item.lastName}
                </Link>
                <Divider />
              </Box>
            ))}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Stack>
  );
};
