import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Box, Flex, Stack } from '@chakra-ui/react';
import { Form } from 'formik';
import { FfButtonTypes } from '../../models/Button';
import { FfFormikButton } from '../Formik/FfFormikButton';
import { FfFormikForm } from '../Formik/FfFormikForm';
import { FfFormikRadioGroup } from '../Formik/FfFormikRadioGroup';
import { FfFormikSelect } from '../Formik/FfFormikSelect';
import { FfFormikTextarea } from '../Formik/FfFormikTextarea';
import { paSkillOptions, sortSkillOptions, unitNumberOptions, yesNoOptions } from './LessonPlanUtils';
import { GenericModuleData } from './models/genericLessonModule';
import { LessonPlanModuleTitles, LessonPlanModuleTypes } from './models/LessonPlanModuleEnum';

export class EarlyWordplayModuleData extends GenericModuleData {
  constructor() {
    super(LessonPlanModuleTypes.EarlyWordplay, LessonPlanModuleTitles.EarlyWordplay, 480, 1);
  }

  public unitNumber?: number;

  public paSkill?: number;

  public notes?: string;

  public sortSkill?: number;

  public sortPatternJournalDone?: number;
}

export interface IEarlyWordplayModuleProps {
  data: EarlyWordplayModuleData;
  isDisabled?: boolean;
  formRef?: any;
  onSave: (values: any) => void;
  onChange: () => void;
}

export const EarlyWordplayModule: React.FunctionComponent<IEarlyWordplayModuleProps> = ({
  data,
  isDisabled,
  formRef,
  onSave,
  onChange,
}) => (
  <FfFormikForm
    formRef={formRef}
    initialValues={data}
    validationClass={EarlyWordplayModuleData}
    submit={(values) => Promise.resolve(onSave(values))}
    onChange={onChange}
  >
    <Form>
      <Stack>
        <Box mb="1em">
          <InfoOutlineIcon mr="1em" />
          Most activities done here will come from the bins.
        </Box>
        <Flex>
          <Box flex={1} mr="2em">
            <FfFormikSelect id="unitNumber" label="Unit #" isDisabled={isDisabled} options={unitNumberOptions} />
            <FfFormikSelect id="paSkill" label="PA Skill" isDisabled={isDisabled} options={paSkillOptions} />
            <FfFormikTextarea id="notes" label="Notes" placeholder="Add your notes here" noOfLines={10} isDisabled={isDisabled} />
          </Box>
          <Box flex={1}>
            <FfFormikSelect id="sortSkill" label="Sort Skill #" isDisabled={isDisabled} options={sortSkillOptions} />
            <FfFormikRadioGroup
              id="sortPatternJournalDone"
              label="Sort pattern journal done?"
              isDisabled={isDisabled}
              options={yesNoOptions}
            />
          </Box>
        </Flex>
      </Stack>
      <Flex textAlign="right" mt="1em">
        <FfFormikButton type={FfButtonTypes.Submit} variant={'outline'} isDisabled={isDisabled}>
          Save and Continue
        </FfFormikButton>
      </Flex>
    </Form>
  </FfFormikForm>
);
