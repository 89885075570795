import { useToast, Link } from '@chakra-ui/react';
import { UserDto } from '@edanalytics/ff_be_se';
import { atom } from 'jotai';
import { RESET, useUpdateAtom } from 'jotai/utils';
import { UserService as userService, UserServiceAtoms } from '../../services/User';

const deactivateUserAtom = atom(null, async (get, set, newValue: number) => {
  await get(userService).deactivateUser(newValue);
  // set(suspendActivate, delayMs(5000));
  set(UserServiceAtoms.getSchoolUsers, RESET);
});

export const UserDeactivateButton = (props: { user: UserDto }) => {
  const deactivateUser = useUpdateAtom(deactivateUserAtom);
  const toast = useToast();

  return props.user.active ? (
    <Link
      cursor="pointer"
      textDecoration="underline !important"
      color={'ff.pink'}
      onClick={async () => {
        await deactivateUser(props.user.id);
        toast({ description: `${props.user.fullName} deactivated`, status: 'success' });
      }}
    >
      Deactivate
    </Link>
  ) : (
    <></>
  );
};
