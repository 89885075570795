import { useAtom } from 'jotai';
import { useUpdateAtom } from 'jotai/utils';
import { useEffect } from 'react';
import { IdentityServiceAtoms, whoAmIatom } from '../../services/Identity';

export const PreloadWhoAmI = ({ children }: any) => {
  const setWhoAmI = useUpdateAtom(whoAmIatom);
  const [whoami] = useAtom(IdentityServiceAtoms.getOneRosterStudents);
  useEffect(() => {
    setWhoAmI(whoami);
  }, []);
  return <>{children}</>;
};
