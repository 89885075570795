import { Box, Button, Container, Flex, Heading } from '@chakra-ui/react';
import { useAtomValue, useAtom } from 'jotai';
import { RESET, useUpdateAtom } from 'jotai/utils';
import React, { useEffect } from 'react';
import { FFRoles } from '@edanalytics/ff_be_se';
import { DebugCard } from '../../components/DebugCard';
import { FfDivider } from '../../components/FfDivider';
import { Loader } from '../../components/Loader';
import { StudentBanner } from '../../components/StudentBanner';
import { activateStudent, deactivateStudent, enrollmentDialogState, editEnrollmentAtomApi } from '../../services/EnrollmentAtoms';
import { StudentServiceAtoms } from '../../services/Student';
import { Desc } from '../../utils/TimeHelper';
import { DeleteEnrollmentDialog } from '../../components/Enrollment/DeleteEnrollmentDialog';
import { EnrollmentDialog } from '../../components/Enrollment/EditEnrollmentDialog';
import { StudentEnrollmentTable } from '../../components/Enrollment/StudentEnrollmentTable';
import { userInfoAtom } from '../../ffApi';

/*
// Here are the cases:
// - From a list of students
//  - From links in the table
//    1. Activate a specific student, with the id of the student
//    2. Deactivate a specific student, with the id of the student and the id of the enrollment
//    3. Edit the enrollment dates of a specific student, with the id of the student and the ids of the enrollments
//  - From the bulk edit buttons, with student checkboxes
//    4. Activate a list of students, with the ids of the students. Only enter one date
//    5. Deactivate a list of students, with the ids of the students. Only enter one date
// - From a single student enrollment (from the student details page)
//  - From links in the table
//    6. Edit the enrollment dates of a specific student, with the id of the student 
//    7. Only if it is the last enrollment, allow to remove the exit date
//    8. Only if it is the last enrollment, add a delete link to remove the enrollment
//  - From the top buttons
//    9. Show Activate button if the student is deactivated. Only enter one date
//    10. Show Deactivate button if the student is activated. Only enter one date
*/

export const StudentEnrollmentScreen: React.FunctionComponent = () => {
  const setDialogState = useUpdateAtom(enrollmentDialogState);
  const [current, setStudentEnrollment] = useAtom(StudentServiceAtoms.getCurrentStudentEnrollment);
  const updateEnrollment = useUpdateAtom(editEnrollmentAtomApi);
  const student = useAtomValue(StudentServiceAtoms.getCurrentStudent);
  const [userInfo] = useAtom(userInfoAtom);

  if (!student) return <Loader />;

  const sorted = (current ?? [])?.sort(Desc('entryDate'));
  const latest = sorted?.[0];
  const buttonEnabled = (latest?.exitDate ?? latest?.entryDate ?? new Date()) < new Date();

  const handleClick = () =>
    // case 9 or 10
    setDialogState(student.active ? deactivateStudent(latest, student) : activateStudent(student, false));

  useEffect(() => {
    // onload effect
    // console.log('onload effect');
    setStudentEnrollment(RESET);
    setDialogState(RESET);
    updateEnrollment(RESET);
  }, []);

  const buttonText = student.active ? 'Deactivate' : 'Activate';
  return (
    <Container maxWidth="100%">
      <StudentBanner student={student} isEditLinkEnabled={true} showEnrollmentLink={false} />
      <FfDivider />
      <Box mb="3em">
        <Flex>
          <Box flex={1}>
            <Heading color={'ff.blue'}>Enrollment History</Heading>
          </Box>
          {userInfo.roleId !== FFRoles.Tutor && (
            <Box textAlign="right" flex={0}>
              <Button variant={'cta'} onClick={handleClick} disabled={!buttonEnabled}>
                {buttonText}
              </Button>
            </Box>
          )}
        </Flex>
        <StudentEnrollmentTable />
        <EnrollmentDialog />
        <DeleteEnrollmentDialog />
      </Box>
      <DebugCard data={[{ name: 'student', data: student }]} />
    </Container>
  );
};
