/* eslint-disable max-len */
import { Icon } from '@chakra-ui/react';

export interface EaLogoProps {
  cb?: () => void;
}

export const EaLogo = (props: any) => (
  <Icon viewBox="0 0 392 46" onClick={props.cb || props.cb} {...props}>
    <defs>
      <polygon id="path-1" points="0.5358 0.672 21.4188 0.672 21.4188 33.7102 0.5358 33.7102"></polygon>
    </defs>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group-31">
        <path
          d="M17.275,19.7009 C17.275,16.4059 14.693,13.9129 10.864,13.9129 C7.08,13.9129 3.962,16.7179 3.295,20.6799 C4.408,20.4129 6.055,20.2789 7.969,20.2789 L17.231,20.2789 C17.275,20.1009 17.275,19.8789 17.275,19.7009 M20.17,23.3069 L3.206,23.3069 C3.651,28.1159 7.48,30.9209 12.645,30.9209 C15.138,30.9209 17.32,30.4309 18.656,30.0309 L18.879,30.1199 L18.879,33.0579 C17.631,33.5039 14.916,34.0379 12.289,34.0379 C4.942,34.0379 1.0658141e-13,29.2289 1.0658141e-13,22.3279 C1.0658141e-13,15.3819 5.254,10.7959 11.042,10.7959 C17.231,10.7959 20.481,15.0699 20.481,20.2789 C20.481,21.4369 20.258,22.7729 20.17,23.3069"
          id="Fill-1"
          fill="#FEFEFE"
        ></path>
        <g id="Group-5" transform="translate(24.443900, 0.328100)">
          <mask id="mask-2" fill="white">
            <use xlinkHref="#path-1"></use>
          </mask>
          <g id="Clip-4"></g>
          <path
            d="M18.3018,24.3152 L18.3018,14.6532 C17.0108,14.1192 14.7838,13.5852 12.4688,13.5852 C7.1258,13.5852 3.7418,17.0122 3.7418,22.3562 C3.7418,27.4762 6.8148,30.5922 11.0888,30.5922 C15.1408,30.5922 18.3018,28.0992 18.3018,24.3152 L18.3018,24.3152 Z M21.4188,0.6712 L21.4188,33.2642 L19.7718,33.2642 L18.7918,29.6582 C17.9458,31.5722 15.1408,33.7102 10.8218,33.7102 C4.8108,33.7102 0.5358,29.2572 0.5358,22.4892 C0.5358,14.9192 5.3008,10.4672 12.2458,10.4672 C14.8288,10.4672 17.0108,11.0022 18.3018,11.5812 L18.3018,0.6712 L21.4188,0.6712 Z"
            id="Fill-3"
            fill="#FEFEFE"
            mask="url(#mask-2)"
          ></path>
        </g>
        <path
          d="M71.7785,11.241 L71.7785,33.593 L70.1315,33.593 L69.1515,30.12 C68.2605,32.034 66.0345,34.038 61.7595,34.038 C56.4615,34.038 53.1665,30.966 53.1665,25.667 L53.1665,11.241 L56.3285,11.241 L56.3285,24.51 C56.3285,28.828 58.3765,30.921 61.9375,30.921 C65.9905,30.921 68.6615,28.605 68.6615,24.51 L68.6615,11.241 L71.7785,11.241 Z"
          id="Fill-6"
          fill="#FEFEFE"
        ></path>
        <path
          d="M77.6124,22.4617 C77.6124,15.6047 82.4664,10.7957 89.3674,10.7957 C91.2814,10.7957 93.2854,11.1517 94.7104,11.7307 L94.7104,14.7587 L94.4874,14.8477 C93.3744,14.2247 91.7714,13.9127 89.6334,13.9127 C84.2914,13.9127 80.8184,17.2067 80.8184,22.3717 C80.8184,27.5817 84.3364,30.9207 89.7674,30.9207 C91.5934,30.9207 93.5084,30.6087 95.1554,30.0747 L95.3784,30.1637 L95.3784,33.1477 C93.5964,33.7267 91.5494,34.0377 89.5454,34.0377 C82.4664,34.0377 77.6124,29.3627 77.6124,22.4617"
          id="Fill-8"
          fill="#FEFEFE"
        ></path>
        <path
          d="M114.6583,24.7761 L114.6583,23.1731 L107.4903,23.1731 C103.9723,23.1731 102.1023,24.5531 102.1023,27.0911 C102.1023,29.6301 104.2403,31.0541 107.4013,31.0541 C112.2993,31.0541 114.6583,28.2501 114.6583,24.7761 M117.7303,19.1671 L117.7303,33.5921 L116.0843,33.5921 L115.1033,30.0751 C114.2583,32.1681 111.8983,34.0381 107.1793,34.0381 C102.5033,34.0381 98.8963,31.8111 98.8963,27.0911 C98.8963,22.6841 102.0573,20.1901 107.6243,20.1901 L114.6583,20.1901 L114.6583,19.5671 C114.6583,16.0051 112.5663,13.9131 108.3363,13.9131 C105.4863,13.9131 102.1473,14.5361 100.9453,14.8921 L100.7223,14.8021 L100.7223,11.8201 C101.8803,11.4631 105.3973,10.7961 108.6473,10.7961 C114.3913,10.7961 117.7303,13.8681 117.7303,19.1671"
          id="Fill-10"
          fill="#FEFEFE"
        ></path>
        <path
          d="M135.6779,30.7878 L135.6779,33.7698 C134.9649,33.9038 134.0309,34.0378 132.3379,34.0378 C127.4409,34.0378 124.3679,31.6338 124.3679,26.2008 L124.3679,6.4328 L127.4849,6.4328 L127.4849,11.6418 C128.4649,11.4638 130.1119,11.2408 131.8489,11.2408 L135.1439,11.2408 L135.1439,14.3568 L127.4849,14.3568 L127.4849,25.8898 C127.4849,29.3628 129.3109,30.9208 132.5169,30.9208 C133.8079,30.9208 134.6539,30.8318 135.4549,30.6978 L135.6779,30.7878 Z"
          id="Fill-12"
          fill="#FEFEFE"
        ></path>
        <path
          d="M141.1099,33.5931 L144.2709,33.5931 L144.2709,11.2411 L141.1099,11.2411 L141.1099,33.5931 Z M140.4869,4.7401 C140.4869,3.4941 141.4209,2.6031 142.6679,2.6031 C143.9149,2.6031 144.8059,3.4941 144.8059,4.7401 C144.8059,5.9421 143.9589,6.8331 142.6679,6.8331 C141.2879,6.8331 140.4869,5.8981 140.4869,4.7401 L140.4869,4.7401 Z"
          id="Fill-14"
          fill="#FEFEFE"
        ></path>
        <path
          d="M169.8742,22.4168 C169.8742,17.4748 166.6682,13.9128 161.8152,13.9128 C156.9622,13.9128 153.5782,17.4748 153.5782,22.4168 C153.5782,27.2708 156.8282,30.9208 161.7702,30.9208 C166.7132,30.9208 169.8742,27.2708 169.8742,22.4168 M150.3722,22.4168 C150.3722,15.6488 155.3592,10.7958 161.8592,10.7958 C168.6282,10.7958 173.0802,16.0048 173.0802,22.3718 C173.0802,29.0068 168.4492,34.0378 161.7272,34.0378 C155.2262,34.0378 150.3722,29.0958 150.3722,22.4168"
          id="Fill-16"
          fill="#FEFEFE"
        ></path>
        <path
          d="M197.5714,19.1668 L197.5714,33.5928 L194.4544,33.5928 L194.4544,20.3238 C194.4544,15.9158 192.3174,13.9128 188.7114,13.9128 C184.6144,13.9128 182.1214,16.4058 182.1214,20.3238 L182.1214,33.5928 L178.9604,33.5928 L178.9604,11.2408 L180.6074,11.2408 L181.5874,14.7138 C182.3874,12.8888 184.5254,10.7958 189.0664,10.7958 C194.2764,10.7958 197.5714,13.8678 197.5714,19.1668"
          id="Fill-17"
          fill="#FEFEFE"
        ></path>
        <path
          d="M234.2069,26.7762 L234.2069,25.1732 L227.0379,25.1732 C223.5199,25.1732 221.6499,26.5532 221.6499,29.0912 C221.6499,31.6302 223.7879,33.0542 226.9489,33.0542 C231.8469,33.0542 234.2069,30.2502 234.2069,26.7762 M237.2779,21.1672 L237.2779,35.5922 L235.6309,35.5922 L234.6509,32.0752 C233.8059,34.1682 231.4459,36.0382 226.7269,36.0382 C222.0509,36.0382 218.4439,33.8112 218.4439,29.0912 C218.4439,24.6842 221.6049,22.1902 227.1719,22.1902 L234.2069,22.1902 L234.2069,21.5672 C234.2069,18.0052 232.1139,15.9132 227.8839,15.9132 C225.0339,15.9132 221.6949,16.5362 220.4929,16.8922 L220.2699,16.8022 L220.2699,13.8202 C221.4279,13.4632 224.9449,12.7962 228.1949,12.7962 C233.9389,12.7962 237.2779,15.8682 237.2779,21.1672"
          id="Fill-18"
          fill="#FEFEFE"
        ></path>
        <path
          d="M263.1053,21.1668 L263.1053,35.5928 L259.9883,35.5928 L259.9883,22.3238 C259.9883,17.9158 257.8513,15.9128 254.2453,15.9128 C250.1483,15.9128 247.6553,18.4058 247.6553,22.3238 L247.6553,35.5928 L244.4943,35.5928 L244.4943,13.2408 L246.1413,13.2408 L247.1213,16.7138 C247.9213,14.8888 250.0593,12.7958 254.6003,12.7958 C259.8103,12.7958 263.1053,15.8678 263.1053,21.1668"
          id="Fill-19"
          fill="#FEFEFE"
        ></path>
        <path
          d="M284.346,26.7762 L284.346,25.1732 L277.178,25.1732 C273.66,25.1732 271.79,26.5532 271.79,29.0912 C271.79,31.6302 273.928,33.0542 277.089,33.0542 C281.987,33.0542 284.346,30.2502 284.346,26.7762 M287.418,21.1672 L287.418,35.5922 L285.772,35.5922 L284.791,32.0752 C283.946,34.1682 281.586,36.0382 276.867,36.0382 C272.191,36.0382 268.584,33.8112 268.584,29.0912 C268.584,24.6842 271.745,22.1902 277.312,22.1902 L284.346,22.1902 L284.346,21.5672 C284.346,18.0052 282.254,15.9132 278.024,15.9132 C275.174,15.9132 271.835,16.5362 270.633,16.8922 L270.41,16.8022 L270.41,13.8202 C271.568,13.4632 275.085,12.7962 278.335,12.7962 C284.079,12.7962 287.418,15.8682 287.418,21.1672"
          id="Fill-20"
          fill="#FEFEFE"
        ></path>
        <polygon id="Fill-21" fill="#FEFEFE" points="294.5002 35.593 297.6612 35.593 297.6612 3 294.5002 3"></polygon>
        <polygon
          id="Fill-22"
          fill="#FEFEFE"
          points="323.6204 13.241 310.4854 45.834 307.2354 45.834 311.8214 34.747 301.9814 13.241 305.4094 13.241 313.3804 30.918 320.3254 13.241"
        ></polygon>
        <path
          d="M339.5179,32.7879 L339.5179,35.7699 C338.8049,35.9039 337.8709,36.0379 336.1779,36.0379 C331.2809,36.0379 328.2079,33.6339 328.2079,28.2009 L328.2079,8.4329 L331.3249,8.4329 L331.3249,13.6419 C332.3049,13.4639 333.9519,13.2409 335.6889,13.2409 L338.9839,13.2409 L338.9839,16.3569 L331.3249,16.3569 L331.3249,27.8899 C331.3249,31.3629 333.1509,32.9209 336.3569,32.9209 C337.6479,32.9209 338.4939,32.8319 339.2949,32.6979 L339.5179,32.7879 Z"
          id="Fill-23"
          fill="#FEFEFE"
        ></path>
        <path
          d="M344.9502,35.593 L348.1112,35.593 L348.1112,13.241 L344.9502,13.241 L344.9502,35.593 Z M344.3272,6.74 C344.3272,5.494 345.2622,4.603 346.5092,4.603 C347.7562,4.603 348.6472,5.494 348.6472,6.74 C348.6472,7.942 347.8002,8.833 346.5092,8.833 C345.1292,8.833 344.3272,7.898 344.3272,6.74 L344.3272,6.74 Z"
          id="Fill-24"
          fill="#FEFEFE"
        ></path>
        <path
          d="M354.2122,24.4617 C354.2122,17.6047 359.0662,12.7957 365.9672,12.7957 C367.8812,12.7957 369.8852,13.1517 371.3102,13.7307 L371.3102,16.7587 L371.0872,16.8477 C369.9742,16.2247 368.3712,15.9127 366.2332,15.9127 C360.8912,15.9127 357.4182,19.2067 357.4182,24.3717 C357.4182,29.5817 360.9362,32.9207 366.3672,32.9207 C368.1932,32.9207 370.1082,32.6087 371.7552,32.0747 L371.9782,32.1637 L371.9782,35.1477 C370.1962,35.7267 368.1492,36.0377 366.1452,36.0377 C359.0662,36.0377 354.2122,31.3627 354.2122,24.4617"
          id="Fill-25"
          fill="#FEFEFE"
        ></path>
        <path
          d="M376.0755,35.1473 L376.0755,32.1643 L376.2985,32.0753 C378.0795,32.6093 380.7065,32.9203 383.1105,32.9203 C386.3155,32.9203 388.0085,31.8523 388.0085,29.9383 C388.0085,28.1123 387.2955,27.4893 382.3975,25.3073 C377.4115,23.0813 376.2095,21.3893 376.2095,18.8513 C376.2095,15.0663 379.0585,12.7953 383.8225,12.7953 C385.7375,12.7953 388.3645,13.1963 390.1005,13.7303 L390.1005,16.7583 L389.8785,16.8473 C388.0965,16.3133 385.2035,15.9133 383.4665,15.9133 C380.7505,15.9133 379.4145,16.9813 379.4145,18.7623 C379.4145,20.1423 380.1275,21.0773 384.6685,23.0813 C389.9225,25.3513 391.2145,26.9553 391.2145,29.7593 C391.2145,33.8113 388.2755,36.0383 383.0205,36.0383 C380.7955,36.0383 377.7665,35.6373 376.0755,35.1473"
          id="Fill-26"
          fill="#FEFEFE"
        ></path>
      </g>
    </g>
  </Icon>
);
