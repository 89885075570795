export enum GradeLevelTypes {
  PreK = -1,
  K = 0,
  G1 = 1,
  G2 = 2,
  G3 = 3,
  G4 = 4,
  G5 = 5,
  G6 = 6,
  G7 = 7,
  G8 = 8,
}

export const getGradeLevelTypeString = (gradeLevel: GradeLevelTypes): string => {
  switch (gradeLevel) {
    case GradeLevelTypes.PreK:
      return 'Pre K';
    case GradeLevelTypes.K:
      return 'K';
    case GradeLevelTypes.G1:
      return '1';
    case GradeLevelTypes.G2:
      return '2';
    case GradeLevelTypes.G3:
      return '3';
    case GradeLevelTypes.G4:
      return '4';
    case GradeLevelTypes.G5:
      return '5';
    case GradeLevelTypes.G6:
      return '6';
    case GradeLevelTypes.G7:
      return '7';
    case GradeLevelTypes.G8:
      return '8';
    default:
      throw new Error('No defined value!');
  }
};

export const getGradeLevelTypeLongString = (gradeLevel: GradeLevelTypes): string => {
  switch (gradeLevel) {
    case GradeLevelTypes.PreK:
      return 'Pre Kinder';
    case GradeLevelTypes.K:
      return 'Kinder';
    default:
      return `Grade ${getGradeLevelTypeString(gradeLevel)}`;
  }
};
