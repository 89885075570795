import React from 'react';
import { Column, Table as ReactTable } from '@tanstack/react-table';
import { Box, HStack } from '@chakra-ui/react';
import { DateTime as DT } from 'luxon';
import { isValidDateTimeString } from '../Utils';
import { DateTimePicker } from '../DateTimePicker';
import { DebouncedInput } from './DebouncedInput';

export const Filter = ({ column, table }: { column: Column<any, unknown>; table: ReactTable<any> }) => {
  const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id);
  const columnFilterValue = column.getFilterValue();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const filterDate = (date: Date) => {
    const min = column.getFacetedMinMaxValues()?.[0].toString();
    const max = column.getFacetedMinMaxValues()?.[1].toString();
    const dateLocal = DT.fromJSDate(date).toLocal().toFormat('yyyy-MM-dd');
    const minDateLocal = min ? DT.fromISO(min).toLocal().toFormat('yyyy-MM-dd') : undefined;
    const maxDateLocal = max ? DT.fromISO(max).toLocal().toFormat('yyyy-MM-dd') : undefined;

    return (!minDateLocal || dateLocal >= minDateLocal) && (!maxDateLocal || dateLocal <= maxDateLocal);
  };

  return isValidDateTimeString(firstValue as string) ? (
    <HStack>
      <DateTimePicker
        wrapperClassName="filter-date"
        isClearable={true}
        showIcon={false}
        showTimeSelect={false}
        dateFormat="MM/dd/yyyy"
        placeholderText="Start Date..."
        // This populates date based on filtered min value:
        // selectedDate={(columnFilterValue as [Date, Date])?.[0] || (column.getFacetedMinMaxValues()?.[0] ? DT.fromISO((column.getFacetedMinMaxValues()?.[0].toString()) as string).toJSDate() : undefined) }
        selectedDate={(columnFilterValue as [Date, Date])?.[0]}
        // filterDate={filterDate}
        onChange={(value) => column.setFilterValue((old: [Date, Date]) => [value, old?.[1]])}
      />
      <Box>&ndash;</Box>
      <DateTimePicker
        wrapperClassName="filter-date"
        isClearable={true}
        showIcon={false}
        showTimeSelect={false}
        dateFormat="MM/dd/yyyy"
        placeholderText="End Date..."
        // This populates date based on filtered max value:
        // selectedDate={(columnFilterValue as [Date, Date])?.[1] || (column.getFacetedMinMaxValues()?.[1] ? DT.fromISO((column.getFacetedMinMaxValues()?.[1].toString()) as string).toJSDate() : undefined) }
        selectedDate={(columnFilterValue as [Date, Date])?.[1]}
        // filterDate={filterDate}
        onChange={(value) => column.setFilterValue((old: [Date, Date]) => [old?.[0], value])}
      />
    </HStack>
  ) : (
    <DebouncedInput
      type="text"
      value={(columnFilterValue ?? '') as string}
      onChange={(value: any) => column.setFilterValue(value)}
      placeholder={`Start typing here... (${column.getFacetedUniqueValues().size})`}
    />
  );
};
