import { useEffect } from 'react';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';
import { userInfoAtom } from '../../ffApi';

export const NavigateToHomePage = () => {
  const [user] = useAtom(userInfoAtom);
  const nav = useNavigate();

  const schoolsAvail = (user?.schoolsIds ?? []).length;
  useEffect(() => {
    // always redirect to schools selector if user has more than one school
    if (schoolsAvail > 1) {
      nav('/schools');
    }
    // else switch redirect depending on the user's role
    switch (user?.role) {
      case 'Staff':
        nav(`/schools`);
        break;
      case 'IC':
        nav(`/schools/${user!.schoolsIds![0]}/dashboard`);
        break;
      case 'FEC':
        nav(`/schools/${user!.schoolsIds![0]}/dashboard`);
        break;
      case 'Tutor':
        nav(`/schools/${user!.schoolsIds![0]}/tutors/${user.id}/dashboard`);
        break;
      default:
        break;
    }
    // schoolsAvail === 0 to /unauthorized  is handled in PreloadUser
  }, [user, nav]);

  return null;
};
