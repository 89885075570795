import { Flex } from '@chakra-ui/react';
import { EmergentWordplayModuleData } from '../LessonPlanContent/EmergentWordplayModule';
import { lessonNumber26Options, unitNumberOptions } from '../LessonPlanContent/LessonPlanUtils';
import { PrintableInputs } from '../PrintableInputs';

export interface IPrintableEmergentWordplayModuleProps {
  data: EmergentWordplayModuleData;
}

export const PrintableEmergentWordplayModule: React.FunctionComponent<IPrintableEmergentWordplayModuleProps> = ({ data }) => (
  <>
    <Flex wrap="wrap" align="top" width="100%" css={{ breakInside: 'avoid-page' }}>
      <PrintableInputs.Input
        css={{ flex: '20% 1 1' }}
        minWidth={10}
        label="Activities"
        children={unitNumberOptions.find((option) => option.value === data.unitNumber)?.key}
      />
      <PrintableInputs.Input
        css={{ flex: '20% 1 1' }}
        minWidth={10}
        label="Sight Words"
        children={lessonNumber26Options.find((option) => option.value === data.lessonNumber)?.key}
      />
      <PrintableInputs.Input minWidth={10} css={{ width: '100%' }} label="Notes" children={data.notes} />
    </Flex>
  </>
);
