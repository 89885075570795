/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
import { GetStudentTutorAssignmentDto, SchoolDto, UserDto } from '@edanalytics/ff_be_se';
import { atom, Getter } from 'jotai';
import { apiClientAtom } from '../ffApi';
import { persistNavPropsAtom } from '../atoms/navAtom';
import { atomApiWithNavAndRead } from '../utils/async-atom';
import { getEnumKeys } from '../utils/utils';
import { GradeLevelTypes } from '../components/LessonPlanContent/models/GradeLevelEnum';

const schoolServiceFactory = (get: Getter) => ({
  getSchools: async (districtId: number): Promise<SchoolDto[]> => {
    const response = await get(apiClientAtom).get(`/districts/${districtId}/schools`);
    return response.data.results as SchoolDto[];
  },
  getSchool: async (districtId: number, schoolId: number): Promise<SchoolDto> => {
    const response = await get(apiClientAtom).get(`/districts/${districtId}/schools`);
    return (response.data.results as SchoolDto[])?.filter((s) => s?.id === schoolId)?.[0];
  },
  intentionalError: async (): Promise<SchoolDto> => {
    const response = await get(apiClientAtom).get(`/does/not/exist/`);
    return response.data as SchoolDto;
  },
  getTutorAssignments: async (schoolId: number, gradeLevel?: number): Promise<GetStudentTutorAssignmentDto[]> => {
    const response = await get(apiClientAtom).get(
      `/schools/${schoolId}/tutor-assignments/${gradeLevel !== undefined ? `?gradeLevel=${gradeLevel}` : ''}`,
    );
    return response.data.results as GetStudentTutorAssignmentDto[];
  },
  getSchoolUsers: async (schoolId: number): Promise<UserDto[]> => {
    const response = await get(apiClientAtom).get(`/schools/${schoolId}/users`);
    return response.data.results as UserDto[];
  },
});

const SchoolService = atom(schoolServiceFactory);
export { SchoolService };

export const SchoolServiceAtoms = {
  getCurrentSchools: persistNavPropsAtom(async (get, nav) => {
    if (!nav.districtId) return undefined;
    return schoolServiceFactory(get).getSchools(nav.districtId);
  }),
  getCurrentSchool: persistNavPropsAtom(async (get, nav) => {
    if (!nav.districtId || !nav.schoolId) return undefined;
    return schoolServiceFactory(get).getSchool(nav.districtId, nav.schoolId);
  }),
  getCurrentSchoolUsers: persistNavPropsAtom(async (get, nav) => {
    if (!nav.districtId || !nav.schoolId) return undefined;
    return schoolServiceFactory(get).getSchoolUsers(nav.schoolId);
  }),
  intentionalErrorEndpoint: persistNavPropsAtom(async (get, nav) => schoolServiceFactory(get).intentionalError()),
  getCurrentSchoolTutorAssignments: atomApiWithNavAndRead(async (get, nav) => {
    if (!nav.schoolId) return undefined;
    // return schoolServiceFactory(get).getTutorAssignments(nav.schoolId);
    // The result will be cached by the atomApiWithNavAndRead, we could solve this differently in the future
    const results = await Promise.all(
      getEnumKeys(GradeLevelTypes).map((grade) => schoolServiceFactory(get).getTutorAssignments(nav.schoolId!, grade)),
    );
    return results.flatMap((a) => a);
  }),
};
