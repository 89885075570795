/* eslint-disable no-nested-ternary */
import { Box, Link, Text } from '@chakra-ui/react';
import { FFRoles, IStudentEnrollment, StudentEnrollmentDto } from '@edanalytics/ff_be_se';
import { ColumnDef } from '@tanstack/react-table';
import { useAtom, useAtomValue } from 'jotai';
import { useUpdateAtom } from 'jotai/utils';
import React from 'react';
import { DebugCard } from '../DebugCard';
import { FfTable } from '../FfTable';
import { ShortFormatDate, SlugFormatTime } from '../Utils';
import { deleteEnrollmentAtom, enrollmentDialogState, editStudentEnrollment } from '../../services/EnrollmentAtoms';
import { StudentServiceAtoms } from '../../services/Student';
import { Desc } from '../../utils/TimeHelper';
import { userInfoAtom } from '../../ffApi';

/*
// Here are the cases:
// - From a list of students
//  - From links in the table
//    1. Activate a specific student, with the id of the student
//    2. Deactivate a specific student, with the id of the student and the id of the enrollment
//    3. Edit the enrollment dates of a specific student, with the id of the student and the ids of the enrollments
//  - From the bulk edit buttons, with student checkboxes
//    4. Activate a list of students, with the ids of the students. Only enter one date
//    5. Deactivate a list of students, with the ids of the students. Only enter one date
// - From a single student enrollment (from the student details page)
//  - From links in the table
//    6. Edit the enrollment dates of a specific student, with the id of the student 
//    7. Only if it is the last enrollment, allow to remove the exit date
//    8. Only if it is the last enrollment, add a delete link to remove the enrollment
//  - From the top buttons
//    9. Show Activate button if the student is deactivated. Only enter one date
//    10. Show Deactivate button if the student is activated. Only enter one date
*/

export interface IDateLimit {
  date?: Date | string;
  updateDate?: Date | string;
  userName?: string;
}
const EnrollmentToDateLimit: React.FunctionComponent<IDateLimit> = ({ date, updateDate, userName }) => (
  <Box>
    <Text>{ShortFormatDate(date)}</Text>
    {userName && updateDate && (
      <Text fontSize="0.8em">
        by {userName} on {ShortFormatDate(updateDate)} at {SlugFormatTime(updateDate.toString())}
      </Text>
    )}
  </Box>
);

export const LeftEnrollment = ({ entryDate, updatedEntryDate, updatedEntryUser }: IStudentEnrollment) => (
  <EnrollmentToDateLimit
    date={entryDate}
    updateDate={updatedEntryDate}
    userName={`${updatedEntryUser?.firstName} ${updatedEntryUser?.lastName}` ?? 'unknown'}
  />
);
export const RightEnrollment = ({ exitDate, updatedExitDate, updatedExitUser }: IStudentEnrollment) => (
  <EnrollmentToDateLimit
    date={exitDate}
    updateDate={exitDate ? updatedExitDate : ''}
    userName={`${updatedExitUser?.firstName} ${updatedExitUser?.lastName}` ?? 'unknown'}
  />
);

export const StudentEnrollmentTable: React.FunctionComponent = () => {
  const setDialogState = useUpdateAtom(enrollmentDialogState);
  const setDeleteHook = useUpdateAtom(deleteEnrollmentAtom);
  const enrollmentsRaw = useAtomValue(StudentServiceAtoms.getCurrentStudentEnrollment);
  const [userInfo] = useAtom(userInfoAtom);
  const enrollments = enrollmentsRaw?.sort(Desc('entryDate'));
  if (!enrollments) return <></>;

  const setDelete = ({ entryDate, exitDate, id }: StudentEnrollmentDto) => {
    setDeleteHook({ data: { entryDate, exitDate }, id });
  };

  const columns: ColumnDef<any>[] = [
    {
      id: 'entry',
      accessorFn: (o) => o,
      cell: (info: any) => <LeftEnrollment {...info.getValue()} />,
      header: () => 'Activation',
      enableSorting: false,
    },
    {
      id: 'exit',
      accessorFn: (o) => o,
      cell: (info: any) => <RightEnrollment {...info.getValue()} />,
      header: () => 'Deactivation',
      enableSorting: false,
    },
    {
      id: 'edit',
      accessorFn: (o) => o,
      cell: (info: any) =>
        userInfo.roleId !== FFRoles.Tutor && (
          <Link
            onClick={() =>
              setDialogState(
                // enrollment case 3
                editStudentEnrollment(info.getValue() as StudentEnrollmentDto, info.row.index === 0),
              )
            }
          >
            Edit
          </Link>
        ),
      header: () => '',
    },
    {
      id: 'delete',
      accessorFn: (o) => o,
      cell: (info: any) =>
        userInfo.roleId !== FFRoles.Tutor &&
        (info.row.index === 0 ? <Link onClick={() => setDelete(info.getValue())}>Delete</Link> : <></>),
      header: () => '',
    },
  ];

  return (
    <>
      <Box>
        <FfTable columns={columns} data={enrollments} />
        <DebugCard data={[{ name: 'tableData', data: enrollments }]} />
      </Box>
    </>
  );
};
