export class GenericModuleData {
  public moduleKey: string;

  public moduleName: string;

  public moduleDurationSecs: number;

  public moduleVersion: number;

  constructor(moduleKey: string, moduleName: string, moduleDurationSecs: number, moduleVersion: number) {
    this.moduleKey = moduleKey;
    this.moduleName = moduleName;
    this.moduleDurationSecs = moduleDurationSecs;
    this.moduleVersion = moduleVersion;
  }
}
