/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  ButtonGroup,
  ExpandedIndex,
  Flex,
  Heading,
  HStack,
} from '@chakra-ui/react';
import { FFRoles, LessonPlanModuleDto, LessonStatusTypes, SessionLessonPlanDto } from '@edanalytics/ff_be_se';
import { useAtom } from 'jotai';
import _ from 'lodash';
import React from 'react';
import { FfRouterLink as Link } from '../../components/FfRouterLink';
import { userInfoAtom } from '../../ffApi';
import { LessonPlanPrint } from '../../screens/Tutor/LessonPlanPrint';
import { SchoolServiceAtoms } from '../../services/School';
import { delayMs, secondsToDurationString } from '../../utils/utils';
import { DebugCard } from '../DebugCard';
import { FfDivider } from '../FfDivider';
import { FfButtonTypes } from '../../models/Button';
import { LessonPlanModuleConfig } from '../LessonPlanContent/LessonPlanContentConfig';
import { GenericModuleData } from '../LessonPlanContent/models/genericLessonModule';
import { LessonPlanModuleTypes } from '../LessonPlanContent/models/LessonPlanModuleEnum';
import { isAbsenceLessonPlan, ObservationLink } from '../Utils';
import { lessonPlanCopyDialogAtom, useLessonPlanCopyDialog } from './LessonPlanResheduleAtoms';

export interface ILessonPlanModuleAccordionProps {
  lessonPlan: SessionLessonPlanDto;
  isSubmitEnabled: boolean;
  isSaving: boolean;
  isSubmitting: boolean;
  onSave: (values: any) => void;
  onSaveAll: () => void;
  onSubmission: () => void;
  onChange: () => void;
  formRefs: any[];
  isAbsentSelected: boolean;
  openDeleteModal: (state: boolean) => void;
}

const GenericModule = (props: {
  data: GenericModuleData;
  isDisabled: boolean;
  onSave: (values: any) => void;
  onChange: () => void;
  formRef: any;
}) => {
  const { data } = props;
  const LessonPlanModule = LessonPlanModuleConfig[data.moduleKey as LessonPlanModuleTypes][data.moduleVersion];
  if (LessonPlanModule) {
    return <LessonPlanModule {...props} />;
  }

  return (
    <Box>
      "{props.data.moduleKey}" v{props.data.moduleVersion} module not found
    </Box>
  );
};

const ModuleAccordionItem = (
  module: LessonPlanModuleDto,
  index: number,
  isDisabled: boolean,
  onSave: (values: any) => void,
  onChange: () => void,
  formRef: any,
) => {
  const data = module.json;
  return (
    <AccordionItem key={index} borderTop="1px" borderBottom="1px solid #333">
      {({ isExpanded }) => (
        <>
          <Heading>
            <AccordionButton bg={isExpanded ? 'white' : 'inherit'}>
              <Box flex={0} marginRight="1em">
                {isExpanded ? <MinusIcon fontSize="12px" /> : <AddIcon fontSize="12px" />}
              </Box>
              <Box marginRight="1em">
                <Heading size="sm" style={{ fontWeight: '500' }}>
                  {data.moduleName} {secondsToDurationString(data.moduleDurationSecs)}
                </Heading>
              </Box>
            </AccordionButton>
          </Heading>
          <AccordionPanel py="1em" bg="white">
            <Box mb="1em" px="2.3em">
              <GenericModule {...{ data, isDisabled, onSave, onChange, formRef }} />
            </Box>
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
};

export const LessonPlanModuleAccordion: React.FunctionComponent<ILessonPlanModuleAccordionProps> = ({
  lessonPlan,
  isSubmitEnabled,
  isSubmitting,
  isSaving,
  onSave,
  onSaveAll,
  onSubmission,
  onChange,
  formRefs,
  isAbsentSelected,
  openDeleteModal,
}) => {
  const [, , { startDuplicate }] = useLessonPlanCopyDialog();
  const [expandedIndex, setExpandedIndex] = React.useState<ExpandedIndex>([]);
  const expandAll = () => setExpandedIndex(Array.from(Array(lessonPlan.lessonPlanModules?.length).keys()));
  const collapseAll = () => setExpandedIndex([]);
  const onAccordionChange = (changed: ExpandedIndex) => setExpandedIndex(changed);
  const onNext = async (values: any, index: number) => {
    onSave(values);
    await delayMs(400); // show spinner briefly for feedback
    // Close this accordion item and open the next (if one exists)
    const newExpandedIndex = (expandedIndex as number[]).filter((x) => x !== index);
    setExpandedIndex(newExpandedIndex);
    if (
      lessonPlan.lessonPlanModules?.length &&
      index < lessonPlan.lessonPlanModules?.length &&
      newExpandedIndex.indexOf(index + 1) === -1
    ) {
      await delayMs(400);
      setExpandedIndex([index + 1]);
    }
  };

  const [school] = useAtom(SchoolServiceAtoms.getCurrentSchool);
  const [userInfo] = useAtom(userInfoAtom);
  const canObserve = userInfo?.roleId === FFRoles.IC || userInfo?.roleId === FFRoles.Staff;

  return (
    <Box mb="3em">
      <Flex alignItems="center">
        <Box>
          <Heading color={'ff.blue'}>Lesson Plan Content</Heading>
        </Box>
        <HStack ml="2em" display="flex" alignItems={'center'}>
          <Heading size="xxs" textDecoration="underline" fontWeight="normal" cursor="pointer" mr="1em" onClick={expandAll}>
            Expand All
          </Heading>
          <Heading size="xxs" textDecoration="underline" fontWeight="normal" cursor="pointer" onClick={collapseAll}>
            Collapse All
          </Heading>
        </HStack>
        <Box textAlign="right" flex={1}>
          {!!canObserve && !!school && !!lessonPlan.byUser && ObservationLink(lessonPlan, school, lessonPlan.byUser)}|
          <Link cursor="pointer" margin={'1'} textDecoration="underline !important" to={''} onClick={() => startDuplicate(lessonPlan)}>
            Duplicate
          </Link>
          |
          <LessonPlanPrint>
            <Link cursor="pointer" margin={'1'} textDecoration="underline !important" to={''}>
              Print
            </Link>
          </LessonPlanPrint>
          |
          <Link cursor="pointer" margin={'1'} textDecoration="underline !important" to={''} onClick={() => openDeleteModal(true)}>
            Delete
          </Link>
        </Box>
      </Flex>
      <FfDivider marginBottom="0" />
      <Accordion allowMultiple={true} index={expandedIndex} onChange={onAccordionChange}>
        {_.sortBy(lessonPlan.lessonPlanModules, (m) => m.lessonContentCode).map((module, index) =>
          ModuleAccordionItem(
            module,
            index,
            !!lessonPlan.submittedUserId || isSaving || isSubmitting || isAbsenceLessonPlan(lessonPlan) || isAbsentSelected,
            (values) => onNext(values, index),
            onChange,
            formRefs[index],
          ),
        )}
      </Accordion>
      <Flex py="2em">
        <LessonPlanPrint>
          <Button minW="5em" type={FfButtonTypes.Button} variant={'outline'}>
            Print
          </Button>
        </LessonPlanPrint>
        <Box textAlign="right" flex={1}>
          <ButtonGroup>
            <Button
              disabled={
                !lessonPlan.attendanceStatus ||
                !!lessonPlan.submittedUserId ||
                isSubmitting ||
                !isSubmitEnabled ||
                isAbsenceLessonPlan(lessonPlan)
              }
              isLoading={isSubmitting}
              variant={'outline'}
              onClick={onSaveAll}
            >
              Save Lesson Plan
            </Button>
            <Button
              disabled={
                !lessonPlan.attendanceStatus ||
                !!lessonPlan.submittedUserId ||
                isSubmitting ||
                !isSubmitEnabled ||
                isAbsenceLessonPlan(lessonPlan) ||
                isAbsentSelected
              }
              isLoading={isSubmitting}
              variant={'cta'}
              onClick={onSubmission}
            >
              {lessonPlan.lessonStatus === LessonStatusTypes.Submitted ? 'Submitted' : 'Submit Lesson Plan'}
            </Button>
          </ButtonGroup>
        </Box>
      </Flex>
      <DebugCard data={[{ name: 'lessonPlan', data: lessonPlan }]} />
    </Box>
  );
};
