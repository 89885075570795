/* eslint-disable @typescript-eslint/no-unused-vars */
import { Checkbox, FormControl, FormErrorMessage, FormLabel, StyleProps } from '@chakra-ui/react';

import { Field } from 'formik';
import { ReactNode } from 'react';

export interface FfFormikCheckboxProps extends StyleProps {
  label: ReactNode;
  id: string;
  isDisabled?: boolean;
  readonly?: boolean;
}

export const FfFormikCheckbox: React.FunctionComponent<FfFormikCheckboxProps> = (props: FfFormikCheckboxProps) => {
  const { id, isDisabled, label, readonly, ...rest } = props;
  return (
    <Field id={id} name={id}>
      {({ field, form, meta }: { field: any; form: any; meta: any }) => (
        <FormControl isDisabled={isDisabled || form.isSubmitting} isInvalid={meta.error && meta.touched}>
          <FormLabel htmlFor={id}>{label}</FormLabel>
          <Checkbox
            size="md"
            onBlur={form.handleBlur}
            isDisabled={isDisabled || form.isSubmitting}
            isChecked={form.values[id]}
            onChange={(e) => form.setFieldValue(id, !form.values[id])}
            readOnly={readonly}
            {...rest}
          />
          {meta.error && meta.touched && <FormErrorMessage>{meta.error}</FormErrorMessage>}
        </FormControl>
      )}
    </Field>
  );
};
