import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { atomStorageSession } from '../utils/atom-storage-internal';

export type Debug = 'true' | 'false';

const debugAtom = atomStorageSession('debug', 'false');

export function useDebug() {
  const [debug, setDebug] = useAtom(debugAtom);
  useEffect(() => {
    document.body.classList.remove('debug-show');
    if (debug === 'true') document.body.classList.add('debug-show');
  }, [debug]);

  return [debug, setDebug] as const;
}
