/* eslint-disable max-classes-per-file */
import { Box, Flex, HStack, Stack } from '@chakra-ui/react';
import { Form } from 'formik';
import { FfButtonTypes } from '../../models/Button';
import { FfTooltip } from '../FfTooltip';
import { FfFormikButton } from '../Formik/FfFormikButton';
import { FfFormikForm } from '../Formik/FfFormikForm';
import { FfFormikRadioGroup } from '../Formik/FfFormikRadioGroup';
import { FfFormikSelect } from '../Formik/FfFormikSelect';
import { FfFormikTextarea } from '../Formik/FfFormikTextarea';
import { linkedToOptions, yesNoOptions } from './LessonPlanUtils';
import { GenericModuleData } from './models/genericLessonModule';
import { LessonPlanModuleTitles, LessonPlanModuleTypes } from './models/LessonPlanModuleEnum';

export class EarlyWritingModuleData extends GenericModuleData {
  constructor() {
    super(LessonPlanModuleTypes.EarlyWriting, LessonPlanModuleTitles.EarlyWriting, 360, 1);
  }

  public linkedTo?: number;

  public graphicOrganizerUsed?: number;

  public activity?: string;
}

export interface IEarlyWritingModuleProps {
  data: EarlyWritingModuleData;
  isDisabled?: boolean;
  formRef?: any;
  onSave: (values: any) => void;
  onChange: () => void;
}

export const EarlyWritingModule: React.FunctionComponent<IEarlyWritingModuleProps> = ({ data, isDisabled, formRef, onSave, onChange }) => (
  <FfFormikForm
    formRef={formRef}
    initialValues={data}
    validationClass={EarlyWritingModuleData}
    submit={(values) => Promise.resolve(onSave(values))}
    onChange={onChange}
  >
    <Form>
      <Stack>
        <Flex>
          <Box flex={1} mr="2em">
            <FfFormikSelect id="linkedTo" label="Linked To" isDisabled={isDisabled} options={linkedToOptions} />
            <FfFormikRadioGroup id="graphicOrganizerUsed" label="Graphic Organizer Used?" isDisabled={isDisabled} options={yesNoOptions} />
          </Box>
          <Box flex={1}>
            <FfFormikTextarea
              id="activity"
              label={
                <HStack>
                  <Box>Activity</Box>
                  <FfTooltip label="Instruction should center on letter formation, punctuation, spelling and grammar." />
                </HStack>
              }
              placeholder="Add your notes here"
              noOfLines={10}
              isDisabled={isDisabled}
            />
          </Box>
        </Flex>
      </Stack>
      <Flex textAlign="right" mt="1em">
        <FfFormikButton type={FfButtonTypes.Submit} variant={'outline'} isDisabled={isDisabled}>
          Save and Continue
        </FfFormikButton>
      </Flex>
    </Form>
  </FfFormikForm>
);
