import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react';
import { Field } from 'formik';

export interface FfFormikNumberInputProps {
  id: string;
  label?: string;
  bgColor?: string;
  borderColor?: string;
  border?: string;
  height?: string;
  width?: string;
  display?: string;
  minNumber?: number;
  maxNumber?: number;
  step?: number;
  readonly?: boolean;
  defaultValue?: string | number;
  value?: number;
  isDisabled?: boolean;
  onChange?: (valueAsString: string, valueAsNumber: number) => void;
}

export const FfFormikNumberInput: React.FunctionComponent<FfFormikNumberInputProps> = (props: FfFormikNumberInputProps) => (
  <Field id={props.id} name={props.id}>
    {({ form, meta }: { form: any; meta: any }) => (
      <FormControl isDisabled={props.isDisabled || form.isSubmitting} isInvalid={meta.error && meta.touched}>
        <FormLabel htmlFor={props.id}>{props.label}</FormLabel>
        <NumberInput
          w={props.width}
          display={props.display}
          min={props.minNumber}
          max={props.maxNumber}
          defaultValue={props.defaultValue || 0}
          value={props.value}
          step={props.step}
          onChange={(valueAsString, valueAsNumber) => {
            form.setFieldValue(props.id, valueAsNumber);
            if (props.onChange) props.onChange(valueAsString, valueAsNumber);
          }}
          isReadOnly={props.readonly}
        >
          <NumberInputField bg={props.bgColor} borderColor={'ff.magenta'} borderWidth="2px" />
          <NumberInputStepper>
            <NumberIncrementStepper m="1px" />
            <NumberDecrementStepper m="1px" />
          </NumberInputStepper>
        </NumberInput>

        {meta.error && meta.touched && <FormErrorMessage>{meta.error}</FormErrorMessage>}
      </FormControl>
    )}
  </Field>
);
