import { Box } from '@chakra-ui/react';
import { EarlyWarmupModuleData } from '../LessonPlanContent/EarlyWarmupModule';
import { PrintableInputs } from '../PrintableInputs';

export interface IPrintableEarlyWarmupModuleProps {
  data: EarlyWarmupModuleData;
}

export const PrintableEarlyWarmupModule: React.FunctionComponent<IPrintableEarlyWarmupModuleProps> = ({ data }) => (
  <Box css={{ breakInside: 'avoid-page' }}>
    <PrintableInputs.Input width="100%" minWidth={10} label="Activity" children={data.activity} />
    <PrintableInputs.Input width="100%" minWidth={10} label="Notes" children={data.notes} />
  </Box>
);
