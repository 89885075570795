/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Container, Flex, Heading, List, ListItem, Text } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import _ from 'lodash';
import React from 'react';
import { DebugCard } from '../../components/DebugCard';
import { FfDivider } from '../../components/FfDivider';
import { FfRouterLink as Link } from '../../components/FfRouterLink';
import { GradeLevelLabel, LessonPlanStartEndTime, MonthYearFormat, ObservationLink, ReadingLevelLabel } from '../../components/Utils';
import { LessonPlanServiceAtoms } from '../../services/LessonPlan/LessonPlanService';
import { SchoolServiceAtoms } from '../../services/School';
import { UserServiceAtoms } from '../../services/User';
import { getDateYearMonthKey } from '../../utils/utils';

export const ObserveTutorSelectionScreen: React.FunctionComponent = () => {
  const [school] = useAtom(SchoolServiceAtoms.getCurrentSchool);
  const [tutor] = useAtom(UserServiceAtoms.getCurrentTutor);
  const [lessonPlans] = useAtom(LessonPlanServiceAtoms.getTutorUpcomingLessonPlans);
  const sortedLessonPlans = _.sortBy(lessonPlans, [(o) => o.startDateTime]).reverse();
  const groupedLessonPlans = _.groupBy(sortedLessonPlans, (s) => getDateYearMonthKey(s, 'startDateTime'));

  return (
    <Container maxWidth="100%">
      {school && tutor && (
        <>
          <Heading color={'ff.blue'}>Observation Summary</Heading>
          <FfDivider />
          <Flex mb="3em" borderBottom="2px">
            <Box flex={1}>
              <Heading size="sm">Observe</Heading>
              <Box>
                <Text color={'ff.blue'} fontSize="2em" display="inline-block" mr="15px" fontWeight="bold">
                  {tutor.fullName}
                </Text>
                <Text display="inline-block">Tutor since {MonthYearFormat(tutor.createdAt)}</Text>
              </Box>
            </Box>
            <Flex flex={1}>
              <Box display="inline-block" alignSelf="flex-end" textAlign="right" fontSize="14px" width="100%">
                {school?.id && <Link to={`/schools/${school.id}/tutors`}>See All Tutors</Link>}
              </Box>
            </Flex>
          </Flex>

          <Box mb="2em" fontWeight="bold">
            Select a session to Observe:
          </Box>

          <List>
            {sortedLessonPlans?.map((lessonPlan, sIndex) => (
              <ListItem key={sIndex}>
                <Box
                  px="1em"
                  py=".7em"
                  marginLeft="1em"
                  marginRight="1em"
                  marginTop="1em"
                  bg="white"
                  shadow="md"
                  borderWidth="1px"
                  fontSize=".8em"
                  rounded={6}
                  h="7em"
                  p="1.5em"
                >
                  <Flex>
                    <Box flex={2}>
                      <Text color={'ff.blue'} display="inline-block" mr="15px" fontWeight="bold">
                        {`${lessonPlan.student?.firstName} ${lessonPlan.student?.lastName}`}
                      </Text>
                      <Text>
                        Grade {lessonPlan.student && GradeLevelLabel(lessonPlan?.student.gradeLevel)} - Reading Level{' '}
                        {lessonPlan.student && ReadingLevelLabel(lessonPlan?.student.readingLevel)}
                      </Text>
                    </Box>
                    <Box flex={1}>{LessonPlanStartEndTime(lessonPlan)}</Box>
                    <Box textAlign="right" flex={1}>
                      {tutor?.id && school?.id && ObservationLink(lessonPlan, school, tutor)}
                    </Box>
                  </Flex>
                </Box>
              </ListItem>
            ))}
          </List>
        </>
      )}
      <DebugCard data={[{ name: 'tutor', data: tutor }]} />
    </Container>
  );
};
