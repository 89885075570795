export enum LessonNumberTypes {
  LN1 = 1,
  LN2 = 2,
  LN3 = 3,
  LN4 = 4,
  LN5 = 5,
  LN6 = 6,
  LN7 = 7,
  LN8 = 8,
  LN9 = 9,
  LN10 = 10,
  LN11 = 11,
  LN12 = 12,
  LN13 = 13,
  LN14 = 14,
  LN15 = 15,
  LN16 = 16,
  LN17 = 17,
  LN18 = 18,
  LN19 = 19,
  LN20 = 20,
  LN21 = 21,
  LN22 = 22,
  LN23 = 23,
  LN24 = 24,
  LN25 = 25,
  LN26 = 26,
  LN27 = 27,
  LN28 = 28,
  LN29 = 29,
  LN30 = 30,
  LN31 = 31,
  LN32 = 32,
  LN33 = 33,
  LN34 = 34,
  LN35 = 35,
  LN36 = 36,
  LN37 = 37,
  LN38 = 38,
  LN39 = 39,
  LN40 = 40,
  LN41 = 41,
  LN42 = 42,
  LN43 = 43,
  LN44 = 44,
  LN45 = 45,
  LN46 = 46,
  LN47 = 47,
  LN48 = 48,
  LN49 = 49,
  LN50 = 50,
}

export const getLessonNumberTypeString = (lessonNumber: LessonNumberTypes): string => {
  switch (lessonNumber) {
    case LessonNumberTypes.LN1:
      return '1';
    case LessonNumberTypes.LN2:
      return '2';
    case LessonNumberTypes.LN3:
      return '3';
    case LessonNumberTypes.LN4:
      return '4';
    case LessonNumberTypes.LN5:
      return '5';
    case LessonNumberTypes.LN6:
      return '6';
    case LessonNumberTypes.LN7:
      return '7';
    case LessonNumberTypes.LN8:
      return '8';
    case LessonNumberTypes.LN9:
      return '9';
    case LessonNumberTypes.LN10:
      return '10';
    case LessonNumberTypes.LN11:
      return '11';
    case LessonNumberTypes.LN12:
      return '12';
    case LessonNumberTypes.LN13:
      return '13';
    case LessonNumberTypes.LN14:
      return '14';
    case LessonNumberTypes.LN15:
      return '15';
    case LessonNumberTypes.LN16:
      return '16';
    case LessonNumberTypes.LN17:
      return '17';
    case LessonNumberTypes.LN18:
      return '18';
    case LessonNumberTypes.LN19:
      return '19';
    case LessonNumberTypes.LN20:
      return '20';
    case LessonNumberTypes.LN21:
      return '21';
    case LessonNumberTypes.LN22:
      return '22';
    case LessonNumberTypes.LN23:
      return '23';
    case LessonNumberTypes.LN24:
      return '24';
    case LessonNumberTypes.LN25:
      return '25';
    case LessonNumberTypes.LN26:
      return '26';
    case LessonNumberTypes.LN27:
      return '27';
    case LessonNumberTypes.LN28:
      return '28';
    case LessonNumberTypes.LN29:
      return '29';
    case LessonNumberTypes.LN30:
      return '30';
    case LessonNumberTypes.LN31:
      return '31';
    case LessonNumberTypes.LN32:
      return '32';
    case LessonNumberTypes.LN33:
      return '33';
    case LessonNumberTypes.LN34:
      return '34';
    case LessonNumberTypes.LN35:
      return '35';
    case LessonNumberTypes.LN36:
      return '36';
    case LessonNumberTypes.LN37:
      return '37';
    case LessonNumberTypes.LN38:
      return '38';
    case LessonNumberTypes.LN39:
      return '39';
    case LessonNumberTypes.LN40:
      return '40';
    case LessonNumberTypes.LN41:
      return '41';
    case LessonNumberTypes.LN42:
      return '42';
    case LessonNumberTypes.LN43:
      return '43';
    case LessonNumberTypes.LN44:
      return '44';
    case LessonNumberTypes.LN45:
      return '45';
    case LessonNumberTypes.LN46:
      return '46';
    case LessonNumberTypes.LN47:
      return '47';
    case LessonNumberTypes.LN48:
      return '48';
    case LessonNumberTypes.LN49:
      return '49';
    case LessonNumberTypes.LN50:
      return '50';
    default:
      throw new Error('No defined value!');
  }
};
