export enum LessonPlanTypes {
  EarlyReader = 1,
  EmergentReader = 2,
}

export const getLessonPlanTypeString = (planType: LessonPlanTypes): string => {
  switch (planType) {
    case LessonPlanTypes.EarlyReader:
      return 'Early Reader';
    case LessonPlanTypes.EmergentReader:
      return 'Emergent Reader';
    default:
      throw new Error('No defined value!');
  }
};
