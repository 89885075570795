/* eslint-disable max-len */
import { Icon } from '@chakra-ui/react';

export const PrinterIcon = (props: any) => (
  <Icon viewBox="0 0 63 67" {...props}>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icons/icon-print">
        <polyline
          id="Stroke-7"
          stroke="#014363"
          strokeWidth="3.5"
          fillOpacity="0.5"
          fill="#FFFFFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="46 37 46 66 16 66 16 37"
        ></polyline>
        <polyline
          id="Stroke-9"
          stroke="#014363"
          strokeWidth="3.5"
          fillOpacity="0.5"
          fill="#FFFFFF"
          points="16 17 16 2 46 2 46 17"
        ></polyline>
        <path
          d="M11.2287641,48 L5.01363799,48 C3.35714164,48 2,46.667828 2,45.0418091 L2,20.9581909 C2,19.332172 3.35714164,18 5.01363799,18 L57.986362,18 C59.6428584,18 61,19.332172 61,20.9581909 L61,45.0418091 C61,46.667828 59.6428584,48 57.986362,48 L51.662745,48"
          id="Stroke-5"
          stroke="#014363"
          strokeWidth="3.5"
          strokeLinejoin="round"
        ></path>
        <path
          d="M12,26 C12,26.5545571 11.5555556,27 11.0019268,27 C10.4495825,27 10,26.5545571 10,26 C10,25.4454429 10.4495825,25 11.0019268,25 C11.5555556,25 12,25.4454429 12,26"
          id="Fill-11"
          fill="#014363"
        ></path>
        <path
          d="M12,26 C12,26.5545571 11.5555556,27 11.0019268,27 C10.4495825,27 10,26.5545571 10,26 C10,25.4454429 10.4495825,25 11.0019268,25 C11.5555556,25 12,25.4454429 12,26 Z"
          id="Stroke-13"
          stroke="#014363"
          strokeWidth="3.5"
        ></path>
        <line x1="53" y1="37" x2="10" y2="37" id="Stroke-2" stroke="#014363" strokeWidth="3.5"></line>
        <line
          x1="24"
          y1="49"
          x2="38"
          y2="49"
          id="Stroke-1"
          stroke="#014363"
          strokeWidth="3.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></line>
        <line
          x1="24"
          y1="57"
          x2="38"
          y2="57"
          id="Stroke-1"
          stroke="#014363"
          strokeWidth="3.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></line>
      </g>
    </g>
  </Icon>
);
