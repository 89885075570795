import { JSONTree } from 'react-json-tree';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Heading, Text } from '@chakra-ui/react';

export interface IDebugCardProps {
  data?: any[];
  children?: any;
}

interface IDebugCardItemProps {
  name: string;
  data: any;
  index: number;
}

const MyAccordionItem: React.FunctionComponent<IDebugCardItemProps> = ({ name, data, index }) => (
  <AccordionItem key={index} marginTop="10px" borderTop="1px solid #ccc" borderBottom="1px solid #ccc" fontSize="12px">
    {({ isExpanded }) => (
      <>
        <Heading>
          <AccordionButton>
            <Box flex={0} marginRight="1em">
              {isExpanded ? <MinusIcon fontSize="10px" /> : <AddIcon fontSize="10px" />}
            </Box>
            <Box flex={0} marginRight="1em">
              <Text fontSize=".9em">{name}</Text>
            </Box>
          </AccordionButton>
        </Heading>
        {isExpanded ? (
          <AccordionPanel>
            <JSONTree data={data} />
          </AccordionPanel>
        ) : (
          <></>
        )}
      </>
    )}
  </AccordionItem>
);

export const DebugCard: React.FunctionComponent<IDebugCardProps> = ({ data, children }) => (
  <Accordion className="debug-hide" allowMultiple={true} defaultIndex={[]}>
    {(data ?? [])?.map((d: any, index: number) => (
      <MyAccordionItem key={index} name={d.name} data={d.data} index={index} />
    ))}
    <div key={-1}>{children}</div>
  </Accordion>
);
