export const fixDates = <T extends {}>(rows: T[], dateFields: (keyof T)[]) =>
  rows.map((row) => {
    dateFields.forEach((field) => {
      if (row[field]) {
        // eslint-disable-next-line no-param-reassign
        row[field] = typeof row[field] === 'string' ? (new Date(row[field] as unknown as string) as any) : row[field];
      }
    });
    return row;
  }) as T[];
