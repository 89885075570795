import { Box } from '@chakra-ui/react';
import { EarlyWritingModuleData } from '../LessonPlanContent/EarlyWritingModule';
import { linkedToOptions, yesNoOptions } from '../LessonPlanContent/LessonPlanUtils';
import { PrintableInputs } from '../PrintableInputs';

export interface IPrintableEarlyWritingModuleProps {
  data: EarlyWritingModuleData;
}

export const PrintableEarlyWritingModule: React.FunctionComponent<IPrintableEarlyWritingModuleProps> = ({ data }) => (
  <Box css={{ breakInside: 'avoid-page' }}>
    <PrintableInputs.Options
      label="Linked to"
      options={linkedToOptions.map((option) => ({ ...option, checked: option.value === data.linkedTo }))}
    />
    <PrintableInputs.Input width="100%" minWidth={10} label="Activity" children={data.activity} />
    <PrintableInputs.Options
      label="Graphic Organizer Used?"
      options={yesNoOptions.map((option) => ({ ...option, checked: option.value === data.graphicOrganizerUsed }))}
    />
  </Box>
);
