import { ColumnDef } from '@tanstack/react-table';
import { useAtom, useAtomValue } from 'jotai';
import { EngagementRow, EngagementSummaryDto, EngagementTypes, StudentDto } from '@edanalytics/ff_be_se';
import { Text } from '@chakra-ui/react';
import { FfTable } from '../FfTable';
import { Empty } from '../Empty';
import { persistNavAtom } from '../../atoms/navAtom';
import { FamilyEventLink, FamilyInteractionLink, StudentColumn, TableFormatDateTimeString } from '../Utils';
import { EngagementServiceAtoms } from '../../services/FamilyEngagement';
import { useTimezoneFormat } from '../../hooks/useTimezone';

export interface IFamilyInteractionListProps {
  children?: any;
  student?: StudentDto;
}

const successRate = ({ homeVisits: h, contacts: c, familyEvents: f }: EngagementSummaryDto) => {
  const successful = h.successful + c.successful + f.successful;
  const total = h.total + c.total + f.total;
  return total === 0 ? 'N/A' : `${Math.round((100 * successful) / total)}% Success Rate`;
};

export const EngagementsTotalsCell = (d: EngagementSummaryDto) => (
  <Text>
    {d.homeVisits.successful}/{d.homeVisits.total} Home Visit <br />
    {d.familyEvents.successful}/{d.familyEvents.total} Family Event <br />
    {d.contacts.successful}/{d.contacts.total} Contact <br />
  </Text>
);

export const EngagementLastDateCell = (d: EngagementSummaryDto) => {
  const formatDate = useTimezoneFormat(TableFormatDateTimeString);
  return (
    <>
      Last Contact
      <br />
      {formatDate(d.lastContactDate.date as unknown as string)}
      <br />
    </>
  );
};

export const LastEngagementLinkCell = (d: EngagementSummaryDto) => (
  <>
    {d.lastEngagementType === EngagementTypes.FamilyEvent
      ? FamilyEventLink(d.lastEngagementId, d.student?.schoolId)
      : FamilyInteractionLink(d.lastEngagementId, d.student?.schoolId)}
  </>
);

export const EngagementLinkCell = (d: EngagementRow) => (
  <>
    {d.engagementType === EngagementTypes.FamilyEvent
      ? FamilyEventLink(d.id, d.student.schoolId)
      : FamilyInteractionLink(d.id, d.student.schoolId)}
  </>
);

export const SuccessRateCell = (d: EngagementSummaryDto) => <Text>{successRate(d)}</Text>;

export const EngagementListColumns = [
  StudentColumn<EngagementSummaryDto>(),
  {
    accessorFn: (row) => row,
    cell: (info: any) => EngagementsTotalsCell(info.getValue() as EngagementSummaryDto),
    id: 'rates',
    header: () => 'rates',
  },
  {
    accessorFn: (row) => row,
    cell: (info: any) => SuccessRateCell(info.getValue() as EngagementSummaryDto),
    id: 'successRate',
    header: () => 'success rate',
  },
  {
    accessorFn: (row) => row,
    cell: (info: any) => EngagementLastDateCell(info.getValue() as EngagementSummaryDto),
    id: 'lastContact',
    header: () => 'Last contact',
  },
  {
    accessorFn: (row) => row,
    cell: (info: any) => LastEngagementLinkCell(info.getValue() as EngagementSummaryDto),
    header: () => '',
    id: 'link',
    enableColumnFilter: false,
  },
] as ColumnDef<EngagementSummaryDto>[];

export const EngagementSummaryList: React.FunctionComponent<IFamilyInteractionListProps> = () => {
  const [engagementSummariesData] = useAtom(EngagementServiceAtoms.getAllEngagementSummaries);
  const navAtomProps = useAtomValue(persistNavAtom);
  const engagementSummaries = engagementSummariesData ?? [];
  const columns: ColumnDef<EngagementSummaryDto>[] = EngagementListColumns;

  return (
    <>
      {engagementSummaries.length ? (
        <FfTable columns={columns} data={engagementSummaries} />
      ) : (
        <Empty
          description="No family interactions"
          label="Add Family Interaction"
          path={`/schools/${navAtomProps.schoolId}/family-interaction/create`}
        />
      )}
    </>
  );
};
