import { Box, List, ListItem, SimpleGrid, Text } from '@chakra-ui/react';
import { UserDto } from '@edanalytics/ff_be_se';

export interface IUserDetailProps {
  user: UserDto;
}

export const UserDetail: React.FunctionComponent<IUserDetailProps> = ({ user }) => (
  <SimpleGrid columns={4} spacingX="40px" spacingY="20px">
    <Box>
      <List spacing={3}>
        <ListItem>
          <Text as="b">Phone</Text>
          <Text>{user.phone}</Text>
        </ListItem>
      </List>
    </Box>
    <Box>
      <List spacing={3}>
        <ListItem>
          <Text as="b">Email</Text>
          <Text>{user.email}</Text>
        </ListItem>
      </List>
    </Box>
  </SimpleGrid>
);
