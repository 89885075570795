import { getOptions } from '../../../utils/utils';

export enum UnitNumberTypes {
  LNA1 = 1,
  LNA2 = 2,
  LNA3 = 3,
  LNA4 = 4,
  LNA5 = 5,
  LNA6 = 6,
  LNA7 = 7,
  LNA8 = 8,
  WW1 = 101,
  WW2 = 102,
  WW3 = 103,
  WW4 = 104,
  WW5 = 105,
  WW6 = 106,
  WW7 = 107,
  WW8 = 108,
  WW9 = 109,
  WW10 = 120,
}

export const UnitNumberOptions = getOptions(UnitNumberTypes, {
  LNA1: 'LNA 1',
  LNA2: 'LNA 2',
  LNA3: 'LNA 3',
  LNA4: 'LNA 4',
  LNA5: 'LNA 5',
  LNA6: 'LNA 6',
  LNA7: 'LNA 7',
  LNA8: 'LNA 8',
  WW1: 'WW 1',
  WW2: 'WW 2',
  WW3: 'WW 3',
  WW4: 'WW 4',
  WW5: 'WW 5',
  WW6: 'WW 6',
  WW7: 'WW 7',
  WW8: 'WW 8',
  WW9: 'WW 9',
  WW10: 'WW 10',
});

export enum UnitNumberTypes2 {
  PICTURE_SORT = 1,
  SIGHT_WORD_PRACTICE = 2,
  SHARED_READING_PASSAGE = 3,
}

export const UnitNumberOptions2 = getOptions(UnitNumberTypes2, {
  PICTURE_SORT: 'PICTURE_SORT',
  SIGHT_WORD_PRACTICE: 'SIGHT_WORD_PRACTICE',
  SHARED_READING_PASSAGE: 'SHARED_READING_PASSAGE',
});
