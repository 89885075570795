import { Box, Flex, HStack, Stack } from '@chakra-ui/react';
import { Form } from 'formik';
import { FfButtonTypes } from '../../models/Button';
import { FfTooltip } from '../FfTooltip';
import { FfFormikButton } from '../Formik/FfFormikButton';
import { FfFormikForm } from '../Formik/FfFormikForm';
import { FfFormikInput } from '../Formik/FfFormikInput';
import { FfFormikRadioGroup } from '../Formik/FfFormikRadioGroup';
import { FfFormikSelect } from '../Formik/FfFormikSelect';
import { FfFormikTextarea } from '../Formik/FfFormikTextarea';
import { readingLevelOptions, yesNoOptions } from './LessonPlanUtils';
import { GenericModuleData } from './models/genericLessonModule';
import { LessonPlanModuleTitles, LessonPlanModuleTypes } from './models/LessonPlanModuleEnum';

export class EarlySirModuleData extends GenericModuleData {
  constructor() {
    super(LessonPlanModuleTypes.EarlyStudentInstructionalReading, LessonPlanModuleTitles.EarlyStudentInstructionalReading, 540, 1);
  }

  public title?: string;

  public completedABookWalk?: number;

  public vocabWords?: string;

  public notes?: string;

  public readingLevel?: number;

  public readCount?: number;

  public compQuestion1?: string;

  public compQuestion2?: string;

  public compQuestion3?: string;
}

export const readCountOptions = [
  { value: 1, key: '1st' },
  { value: 2, key: '2nd' },
];

export interface IEarlySirModuleProps {
  data: EarlySirModuleData;
  isDisabled?: boolean;
  formRef?: any;
  onSave: (values: any) => void;
  onChange: () => void;
}

export const EarlySirModule: React.FunctionComponent<IEarlySirModuleProps> = ({ data, isDisabled, formRef, onSave, onChange }) => (
  <FfFormikForm
    formRef={formRef}
    initialValues={data}
    validationClass={EarlySirModuleData}
    submit={(values) => Promise.resolve(onSave(values))}
    onChange={onChange}
  >
    <Form>
      <Stack>
        <Flex>
          <Box flex={1} mr="2em">
            <FfFormikInput id="title" label="Title" type="text" placeholder="Insert title here" isDisabled={isDisabled} />
            <FfFormikRadioGroup
              id="completedABookWalk"
              label={
                <HStack>
                  <Box>Completed a Book Walk?</Box>
                  <FfTooltip label="Starting a new book? Make sure to walk through it first." />
                </HStack>
              }
              isDisabled={isDisabled}
              options={yesNoOptions}
            />
            <FfFormikTextarea
              id="vocabWords"
              label="Vocab Words"
              placeholder="Add your notes here"
              noOfLines={10}
              isDisabled={isDisabled}
            />
            <FfFormikTextarea id="notes" label="Notes" placeholder="Add your notes here" noOfLines={10} isDisabled={isDisabled} />
          </Box>
          <Box flex={1}>
            <FfFormikSelect id="readingLevel" label="Reading Level" isDisabled={isDisabled} options={readingLevelOptions} />
            <FfFormikRadioGroup id="readCount" label="First or Second Read?" isDisabled={isDisabled} options={readCountOptions} />
            <FfFormikInput
              id="compQuestion1"
              label="Comprehension Questions"
              type="text"
              placeholder="Question 1"
              isDisabled={isDisabled}
            />
            <FfFormikInput id="compQuestion2" type="text" placeholder="Question 2" isDisabled={isDisabled} />
            <FfFormikInput id="compQuestion3" type="text" placeholder="Question 3" isDisabled={isDisabled} />
          </Box>
        </Flex>
      </Stack>
      <Flex textAlign="right" mt="1em">
        <FfFormikButton type={FfButtonTypes.Submit} variant={'outline'} isDisabled={isDisabled}>
          Save and Continue
        </FfFormikButton>
      </Flex>
    </Form>
  </FfFormikForm>
);
