import { Container, Heading, Text, Image } from '@chakra-ui/react';
import React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { FfTable } from '../components/FfTable';
import PrintIcon from '../assets/images/PrintIcon.svg';
import { Forms } from '../assets/forms/forms';

const openForm = (formUrl: string) => {
  window.open(formUrl);
};

const columns: ColumnDef<any>[] = [
  {
    accessorKey: 'formName',
    id: 'formName',
    cell: (info: any) => <Text>{info.getValue()}</Text>,
    header: () => 'Form Name',
  },
  {
    accessorKey: 'formType',
    id: 'formType',
    cell: (info: any) => <Text>{info.getValue()}</Text>,
    header: () => 'Form Type',
  },
  {
    accessorKey: 'formUrl',
    id: 'formUrl',
    cell: (info: any) => (
      <Image onClick={() => openForm(info.getValue())} _hover={{ cursor: 'pointer' }} height={'30px'} width={'30px'} src={PrintIcon} />
    ),
    header: () => '',
  },
];

const data = [
  {
    formName: 'Lesson Plan (Emergent Reader)',
    formType: 'Lesson Plan',
    formUrl: Forms.EmergentReader,
  },
  {
    formName: 'Lesson Plan (Early Reader)',
    formType: 'Lesson Plan',
    formUrl: Forms.EarlyReader,
  },
  {
    formName: 'Observation Form',
    formType: 'Observation',
    formUrl: Forms.Observation,
  },
  {
    formName: 'Home Visit',
    formType: 'Family Engagement',
    formUrl: Forms.HomeVisit,
  },
  {
    formName: 'Home Visit - Attendance',
    formType: 'Family Engagement',
    formUrl: Forms.HomeVisitAttendance,
  },
  {
    formName: 'Home Visit - Family Request',
    formType: 'Family Engagement',
    formUrl: Forms.HomeVisitFamilyRequest,
  },
  {
    formName: 'Home Visit - Level of Engagement',
    formType: 'Family Engagement',
    formUrl: Forms.HomeVisitLevelOfEngagement,
  },
  {
    formName: 'Home Visit - New Student',
    formType: 'Family Engagement',
    formUrl: Forms.HomeVisitNewStudent,
  },
  {
    formName: 'Home Visit - Teacher Request',
    formType: 'Family Engagement',
    formUrl: Forms.HomeVisitTeacherRequest,
  },
  {
    formName: 'Phone Scripts',
    formType: 'Family Engagement',
    formUrl: Forms.PhoneScripts,
  },
  {
    formName: 'Contact Log',
    formType: 'Family Engagement',
    formUrl: Forms.ContactLog,
  },
];

export const FormsScreen: React.FunctionComponent = () => (
  <Container maxWidth="100%">
    <Heading color={'ff.blue'} mb="1em">
      Forms
    </Heading>
    <FfTable columns={columns} data={data} />
  </Container>
);
