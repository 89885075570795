import { Box, Flex, FormLabel, HStack } from '@chakra-ui/react';
import { EmergentSirModuleData, readCountOptions } from '../LessonPlanContent/EmergentSirModule';
import { readingLevelOptions, yesNoOptions } from '../LessonPlanContent/LessonPlanUtils';
import { PrintableInputs } from '../PrintableInputs';

export interface IPrintableEmergentSirModuleProps {
  data: EmergentSirModuleData;
}

export const PrintableEmergentSirModule: React.FunctionComponent<IPrintableEmergentSirModuleProps> = ({ data }) => (
  <>
    <Flex flexWrap="wrap" direction="row" css={{ breakInside: 'avoid-page' }} align="top">
      <PrintableInputs.Input css={{ flexGrow: 0.75 }} minWidth={20} label="Title" children={data.title} />
      <PrintableInputs.Input
        minWidth={7}
        label="Reading Level"
        children={readingLevelOptions.find((option) => option.value === data.readingLevel)?.key}
      />
      <PrintableInputs.Options
        css={{ flexGrow: 0.5 }}
        label="Completed a book walk?"
        options={yesNoOptions.map((option) => ({ ...option, checked: option.value === data.completedABookWalk }))}
      />
      <PrintableInputs.Options
        css={{ flexGrow: 0.5 }}
        label="First or Second Read?"
        options={readCountOptions.map((option) => ({ ...option, checked: option.value === data.readCount }))}
      />
      <PrintableInputs.Input minWidth={10} css={{ width: '100%' }} label="Vocabulary Words" children={data.vocabWords} />
    </Flex>
    <Box css={{ breakInside: 'avoid-page' }}>
      <HStack align="top">
        <FormLabel whiteSpace="nowrap" color={'ff.blue'} fontSize="1em" m="0" ml="1em">
          Comprehension Questions
        </FormLabel>
        <Box flexGrow={1} boxSizing="content-box">
          <PrintableInputs.Input w="100%" minWidth={10} label="1." children={data.compQuestion1} />
          <PrintableInputs.Input w="100%" minWidth={10} label="2." children={data.compQuestion2} />
          <PrintableInputs.Input w="100%" minWidth={10} label="3." children={data.compQuestion3} />
        </Box>
      </HStack>
      <PrintableInputs.Textarea minLines={3} label="Notes" children={data.notes} />
    </Box>
  </>
);
