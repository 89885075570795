import { Accordion, Box, Button, Flex, Heading } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { SchoolDto, SessionLessonPlanDto } from '@edanalytics/ff_be_se';
import { DebugCard } from './DebugCard';
import { FfDivider } from './FfDivider';
import { Empty } from './Empty';
import { isEmpty } from '../utils/utils';
import { DateAccordionItem } from './RecentLessonPlansList';

export interface IRecentLessonPlansListProps {
  school: SchoolDto;
  groupedLessonPlans: { [key: string]: SessionLessonPlanDto[] };
  heading: string;
  link: boolean;
}

export const GroupedLessonPlans: React.FunctionComponent<IRecentLessonPlansListProps> = ({ school, groupedLessonPlans, heading, link }) => {
  const nav = useNavigate();

  return (
    <Box mb="3em">
      <Flex>
        <Box>
          <Heading color={'ff.blue'}>{heading}</Heading>
        </Box>
        {link && (
          <Box textAlign="right" flex={1}>
            <Button onClick={() => nav(`/schools/${school.id}/lesson-plans`)} disabled={!groupedLessonPlans}>
              See All Lesson Plans
            </Button>
          </Box>
        )}
      </Flex>
      <FfDivider />
      <Accordion allowMultiple={true} defaultIndex={[0]}>
        {groupedLessonPlans &&
          Object.keys(groupedLessonPlans).map((dateStr, index) => DateAccordionItem(school, dateStr, groupedLessonPlans[dateStr], index))}
      </Accordion>
      {isEmpty(groupedLessonPlans) && <Empty description="No lesson plans." />}
      <DebugCard data={[{ name: 'groupedLessonPlans', data: groupedLessonPlans }]} />
    </Box>
  );
};
