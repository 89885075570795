import { EditIcon as ChakraEditIcon } from '@chakra-ui/icons';

export interface EditIconProps {
  cb?: () => void;
}
export const EditIcon = (props: any) => (
  <div style={{ display: 'inline-block', cursor: 'pointer' }} onClick={props.cb || props.cb}>
    <ChakraEditIcon />
  </div>
);
