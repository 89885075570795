import { Box, chakra, ChakraProps, FormLabel, HStack, List, ListItem, StackDivider, StyleProps } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import React from 'react';

const ContentBox = (props: { children?: React.ReactNode } & StyleProps) => {
  const { children, ...rest } = props;
  return (
    <Box textAlign="left" borderBottomWidth="1px" borderStyle="solid" borderColor={'ff.lightGray'} display="inline-block" {...rest}>
      {children}&nbsp;
    </Box>
  );
};

const WithLabel =
  <T extends object>(Component: (props: T) => JSX.Element) =>
  (props: T & { label: string; className?: string; css?: ChakraProps['css'] }): JSX.Element =>
    (
      <HStack mb={2} ml="1em" align="start" className={props.className} css={props.css}>
        <FormLabel whiteSpace="nowrap" color={'ff.blue'} fontSize="1em" m="0">
          {props.label}
        </FormLabel>
        <Component {...props} />
      </HStack>
    );

export const PrintableInputs = {
  Input: WithLabel((props: Parameters<typeof ContentBox>[0] & { minWidth: number }) => (
    <ContentBox {...props} minW={`${props.minWidth}em`} />
  )),
  Textarea: WithLabel((props: Parameters<typeof ContentBox>[0] & { minLines: number }) => (
    <ContentBox {...props} border="none" minH={`${props.minLines * 1.5}em`} />
  )),
  DatePicker: WithLabel((props: Omit<Parameters<typeof ContentBox>[0], 'children'> & { value?: Date }) => {
    let displayString: React.ReactNode;
    if (props.value) {
      const timestamp = DateTime.fromJSDate(props.value);

      displayString = timestamp.toLocaleString(DateTime.DATETIME_SHORT);
    } else {
      displayString = (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <chakra.span textAlign="right" flexGrow="0.5" w="3em">
            /
          </chakra.span>
          <chakra.span textAlign="center" flexGrow="0.5" w="6em">
            /
          </chakra.span>
          <chakra.span my="-0.75em" flexGrow={1} textAlign="right" mr={4}>
            <div>am</div>
            <div>pm</div>
          </chakra.span>
        </Box>
      );
    }
    return <ContentBox {...props} children={displayString} />;
  }),
  Checkboxes: WithLabel((props: { options: { value: number; key: string; checked: boolean }[] }) => (
    <List spacing={2}>
      {props.options.map((value, i) => (
        <ListItem display="flex" alignItems="center" gap={4} key={i}>
          <Box
            textAlign="center"
            verticalAlign="middle"
            borderRadius="md"
            borderColor={'ff.magenta'}
            borderWidth="2px"
            w="1.75em"
            h="1.75em"
            fontWeight="bold"
          >
            {value.checked && <>&#10004;</>}
          </Box>
          {value.key}
        </ListItem>
      ))}
    </List>
  )),
  Radios: WithLabel((props: { options: { value: number; key: string; checked: boolean }[] }) => (
    <List gap={8} orientation="horizontal" display="flex" flexDirection="row">
      {props.options.map((value, i) => (
        <ListItem display="flex" alignItems="center" gap={3} key={i}>
          <Box
            textAlign="center"
            verticalAlign="middle"
            borderRadius="0.75em"
            borderColor={'ff.magenta'}
            borderWidth="2px"
            w="1.5em"
            h="1.5em"
            padding="0.1em"
            background={value.checked ? 'ff.magenta' : 'none'}
          />
          {value.key}
        </ListItem>
      ))}
    </List>
  )),
  Options: WithLabel((props: { options: { value: number; key: string; checked: boolean }[] }) => (
    <HStack pl="0.5em" divider={<StackDivider borderColor={'ff.lightGray'} />}>
      {props.options.map((value, i) => (
        <Box p="0.4em" m="-0.4em" borderRadius="50%" borderColor="black" borderWidth={value.checked ? '1px' : '0'} key={i}>
          {value.key}
        </Box>
      ))}
    </HStack>
  )),
};
