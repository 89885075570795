import { Flex, VStack } from '@chakra-ui/react';
import React, { Suspense } from 'react';
import Background from '../../assets/images/background-stars-sparse.svg';
import { Loader } from '../Loader';
import { Footer } from './Footer';

export interface ILoginLayoutProps {
  children: any;
  bg?: string;
}

export const LoginLayout: React.FunctionComponent<ILoginLayoutProps> = (props) => (
  <Flex align="center" className="default" flexGrow={1} flexDir="column" bg={props.bg ?? 'ff.blue'}>
    <VStack
      w="100%"
      align="center"
      justify="center"
      flexGrow={1}
      maxW="1200px"
      backgroundImage={Background}
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
    >
      <Suspense fallback={<Loader />}>{props.children}</Suspense>
    </VStack>
    <Footer />
  </Flex>
);
