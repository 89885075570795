import { useState } from 'react';
import { Box, Button } from '@chakra-ui/react';

const FfErrorButton = () => (
  <Box textAlign="right" flex={1}>
    <Button
      onClick={() => {
        throw new Error('Event Error');
      }}
    >
      Event Error
    </Button>
  </Box>
);

const FfRenderErrorButton = () => {
  const [hasError, setHasError] = useState<boolean>(false);

  if (hasError) throw new Error('Render, No me digas!!!');

  return (
    <Box textAlign="right" flex={1}>
      <Button
        onClick={() => {
          setHasError((a) => !a);
        }}
      >
        Render Error
      </Button>
    </Box>
  );
};

const FfPromiseErrorButton = () => (
  <Box textAlign="right" flex={1}>
    <Button
      onClick={() =>
        new Promise(() => {
          setTimeout(() => {
            throw new Error('Promise Error');
          }, 100);
        })
      }
    >
      Promise Error
    </Button>
  </Box>
);

export const FfErrorButtons = () => (
  <>
    <FfErrorButton />
    <FfRenderErrorButton />
    <FfPromiseErrorButton />
  </>
);
