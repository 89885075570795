import React from 'react';
import { Box, Container, Heading, Stack } from '@chakra-ui/react';

export const ErrorFallback = ({ error, resetErrorBoundary }: { error: Error; resetErrorBoundary: () => void }) => (
  <Container>
    <Stack>
      <Box>
        <Heading>Something went wrong!</Heading>
      </Box>
      <Box>
        <pre>{error.message}</pre>
      </Box>
      <Box>
        <button onClick={resetErrorBoundary}>Try again</button>
      </Box>
    </Stack>
  </Container>
);
