import React from 'react';
import { Box, List, ListItem, SimpleGrid, Text } from '@chakra-ui/react';
import { StudentDto } from '@edanalytics/ff_be_se';

export interface IStudentDetailProps {
  student: StudentDto;
}

export const StudentDetailBasic: React.FunctionComponent<IStudentDetailProps> = ({ student }) => (
  <SimpleGrid columns={2} spacingX="40px" spacingY="20px" mb="3em">
    <Box>
      <List spacing={3}>
        <ListItem>
          <Text as="b">Photo Consent?</Text>
          <Text>{student.photoConsent ? 'Yes' : 'No'}</Text>
        </ListItem>
        <ListItem>
          <Text as="b">Languages</Text>
          <Text>{student.languages}</Text>
        </ListItem>
      </List>
    </Box>
    <Box>
      <List spacing={3}>
        <ListItem>
          <Text as="b">Allergies</Text>
          <Text>{student.allergies}</Text>
        </ListItem>
        <ListItem>
          <Text as="b">Program Consent?</Text>
          <Text>{student.programConsent ? 'Yes' : 'No'}</Text>
        </ListItem>
      </List>
    </Box>
  </SimpleGrid>
);
