import { FormControl, FormErrorMessage, FormLabel, Textarea, StyleProps } from '@chakra-ui/react';
import { Field } from 'formik';
import { ReactNode } from 'react';
import { setFieldValueDefaulted } from '../../utils/utils';

export interface FfFormikTextareaProps extends StyleProps {
  label: ReactNode;
  id: string;
  value?: string;
  noOfLines: number;
  placeholder?: string;
  isDisabled?: boolean;
  readonly?: boolean;
  setDefaultToUndef?: boolean;
  onChange?: (val: string) => void;
}
export const FfFormikTextarea: React.FunctionComponent<FfFormikTextareaProps> = (props: FfFormikTextareaProps) => {
  const { label, id, noOfLines, placeholder, isDisabled, readonly, value, setDefaultToUndef, ...rest } = props;
  const dataDefault = setDefaultToUndef ? undefined : '';
  return (
    <Field id={props.id} name={props.id}>
      {({ field, form, meta }: { field: any; form: any; meta: any }) => (
        <FormControl isDisabled={props.isDisabled || form.isSubmitting} isInvalid={meta.error && meta.touched}>
          <FormLabel htmlFor={props.id}>{props.label}</FormLabel>
          <Textarea
            {...rest}
            readOnly={readonly}
            onBlur={form.handleBlur}
            noOfLines={props.noOfLines}
            id={props.id}
            placeholder={props.placeholder ? props.placeholder : ''}
            value={value ?? field.value ?? ''}
            onChange={(e) => setFieldValueDefaulted(form, props.id, e.target.value, '', dataDefault)}
          />
          {meta.error && meta.touched && <FormErrorMessage>{meta.error}</FormErrorMessage>}
        </FormControl>
      )}
    </Field>
  );
};
