import { Box, Flex, Stack } from '@chakra-ui/react';
import { Form } from 'formik';
import { FfButtonTypes } from '../../models/Button';
import { FfFormikButton } from '../Formik/FfFormikButton';
import { FfFormikForm } from '../Formik/FfFormikForm';
import { FfFormikSelect } from '../Formik/FfFormikSelect';
import { lessonNumber26Options, paSkillOptions } from './LessonPlanUtils';
import { GenericModuleData } from './models/genericLessonModule';
import { LessonPlanModuleTitles, LessonPlanModuleTypes } from './models/LessonPlanModuleEnum';

export class EmergentPaSkillModuleData extends GenericModuleData {
  constructor() {
    super(LessonPlanModuleTypes.EmergentPaSkill, LessonPlanModuleTitles.EmergentPaSkill, 300, 1);
  }

  public unitNumber?: number;

  public lessonNumber?: number;
}

export interface IEmergentPaSkillModuleProps {
  data: EmergentPaSkillModuleData;
  isDisabled?: boolean;
  formRef?: any;
  onSave: (values: any) => void;
  onChange: () => void;
}

export const EmergentPaSkillModule: React.FunctionComponent<IEmergentPaSkillModuleProps> = ({
  data,
  isDisabled,
  formRef,
  onSave,
  onChange,
}) => (
  <FfFormikForm
    formRef={formRef}
    initialValues={data}
    validationClass={EmergentPaSkillModuleData}
    submit={(values) => Promise.resolve(onSave(values))}
    onChange={onChange}
  >
    <Form>
      <Stack>
        <Flex>
          <Box flex={1} mr="2em">
            <FfFormikSelect id="unitNumber" label="Unit #" isDisabled={isDisabled} options={paSkillOptions} />
          </Box>
          <Box flex={1}>
            <FfFormikSelect id="lessonNumber" label="Lesson #" isDisabled={isDisabled} options={lessonNumber26Options} />
          </Box>
        </Flex>
      </Stack>
      <Flex textAlign="right" mt="1em">
        <FfFormikButton type={FfButtonTypes.Submit} variant={'outline'} isDisabled={isDisabled}>
          Save and Continue
        </FfFormikButton>
      </Flex>
    </Form>
  </FfFormikForm>
);
