export enum LinkedToTypes {
  WordPlay = 1,
  LeveledBook = 2,
  StudentInterest = 3,
}

export const getLinkedToTypeString = (linkedTo: LinkedToTypes): string => {
  switch (linkedTo) {
    case LinkedToTypes.WordPlay:
      return 'Word Play';
    case LinkedToTypes.LeveledBook:
      return 'Leveled Book';
    case LinkedToTypes.StudentInterest:
      return 'Student Interest';
    default:
      throw new Error('No defined value!');
  }
};
