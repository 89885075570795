import { Box, Flex, Heading, Stack } from '@chakra-ui/react';
import { FamilyEventDto } from '@edanalytics/ff_be_se';
import { useAtom } from 'jotai';
import _ from 'lodash';
import { DebugCard } from '../DebugCard';
import { FfRouterLink as Link } from '../FfRouterLink';

import { SchoolServiceAtoms } from '../../services/School';

export interface IFamilyEventCardProps {
  familyEvent: FamilyEventDto;
}

export const FamilyEventCard: React.FunctionComponent<IFamilyEventCardProps> = ({ familyEvent }) => {
  const [school] = useAtom(SchoolServiceAtoms.getCurrentSchool);

  const getRegisteredCount = () => _.sumBy(familyEvent.attendance, (a) => (a.numberRegistered > 0 ? a.numberRegistered + 1 : 0));
  const getStudentAttendedCount = () => _.sumBy(familyEvent.attendance, (a) => (a.studentAttended ? 1 : 0));
  const getNumberAttendedCount = () => _.sumBy(familyEvent.attendance, (a) => a.numberAttended);

  const getTotalAttendance = getStudentAttendedCount() + getNumberAttendedCount();

  const isSubmitted = !!familyEvent.submittedAt;

  return (
    <Box p={5} marginTop="1em" bg="white" shadow="md" borderWidth="1px" fontSize=".8em">
      <Flex>
        <Box flex={1}>
          <Stack>
            <Heading size="xs" textTransform="uppercase">
              Event Description
            </Heading>
            <Box fontSize="12px">{familyEvent.description}</Box>
          </Stack>
        </Box>
        <Box flex={1}>
          <Stack>
            <Heading size="xs" textTransform="uppercase">
              {isSubmitted ? 'Final Attendance' : 'Current RSVPS'}
            </Heading>
            <Box>
              {isSubmitted ? getTotalAttendance : getRegisteredCount()}&nbsp;
              <Link to={`/schools/${school?.id}/family-events/${familyEvent.id}`}>See list</Link>
            </Box>
          </Stack>
        </Box>
        <Box flex={1}>
          <Stack>
            <Heading size="xs" textTransform="uppercase">
              Post-Event Notes
            </Heading>
            <Box>{familyEvent.notes}</Box>
          </Stack>
        </Box>
      </Flex>
      <Stack>
        <DebugCard data={[{ name: 'familyEvent', data: familyEvent }]} />
      </Stack>
    </Box>
  );
};
