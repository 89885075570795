/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import { RunningRecordDto } from '@edanalytics/ff_be_se';
import { atom, Getter } from 'jotai';
import { apiClientAtom } from '../ffApi';
import { atomApiWithNavAndRead } from '../utils/async-atom';

const RunningRecordServiceFactory = (get: Getter) => ({
  getAll_____: async (schoolId: number): Promise<RunningRecordDto[]> => {
    const response = await get(apiClientAtom).get(`/schools/${schoolId}/________`);
    return response.data.results as RunningRecordDto[];
  },
  getRunningRecord: async (schoolId: number, studentId: number): Promise<RunningRecordDto[]> => {
    const response = await get(apiClientAtom).get(`/schools/${schoolId}/students/${studentId}/lesson-plans/running-record`);
    // /schools/:schoolId/students/:studentId/lesson-plans/running-record,
    return response.data.results as RunningRecordDto[];
  },
});

const runningRecordService = atom(RunningRecordServiceFactory);
export { runningRecordService as EngagementService };

export const RunningRecordServiceAtoms = {
  getAll_____Summaries: atomApiWithNavAndRead(async (get, nav) => {
    if (!nav.schoolId) return undefined;
    const result = await RunningRecordServiceFactory(get).getAll_____(nav.schoolId);
    return result;
  }),
  getRunningRecord: atomApiWithNavAndRead(async (get, nav) => {
    if (!nav.schoolId || !nav.studentId) return undefined;
    const result = await RunningRecordServiceFactory(get).getRunningRecord(nav.schoolId, nav.studentId);
    return result;
  }),
};
