import { Box, Button, Container, Flex, Heading } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import _ from 'lodash';
import { DebugCard } from '../../components/DebugCard';
import { SchoolServiceAtoms } from '../../services/School';
import { FamilyEventsAccordion } from '../../components/FamilyEvents/FamilyEventsAccordion';
import { FamilyEventServiceAtoms } from '../../services/FamilyEvent/FamilyEventService';
import { FfDivider } from '../../components/FfDivider';

export const FamilyEventsScreen: React.FunctionComponent = () => {
  const nav = useNavigate();
  const [school] = useAtom(SchoolServiceAtoms.getCurrentSchool);
  const [familyEvents] = useAtom(FamilyEventServiceAtoms.getAllFamilyEvents);

  const nowDateString = new Date().toISOString();
  const upcomingFamilyEvents = _.sortBy(familyEvents, (fe) => fe.startDate).filter((fe) => nowDateString <= fe.endDate);
  const pastFamilyEvents = _.sortBy(familyEvents, (fe) => fe.startDate)
    .reverse()
    .filter((fe) => nowDateString > fe.endDate);

  return (
    <Container maxWidth="100%">
      <Flex mb="1em">
        <Box flex={1}>
          <Heading color={'ff.blue'} mb="1em">
            All Events
          </Heading>
        </Box>
        <Box textAlign="right" flex={0}>
          <Button variant={'cta'} onClick={() => nav(`/schools/${school?.id}/family-events/create`)}>
            Add An Event
          </Button>
        </Box>
      </Flex>
      <Box>
        <Heading color={'ff.blue'} size="sm">
          Upcoming Events
        </Heading>
      </Box>
      <FfDivider marginBottom="0" height="1px" borderBottomWidth="0" />
      <FamilyEventsAccordion familyEvents={upcomingFamilyEvents} isFirstItemOpen={true} />
      <Box>
        <Heading color={'ff.blue'} size="sm">
          Past Events
        </Heading>
      </Box>
      <FfDivider marginBottom="0" height="1px" borderBottomWidth="0" />
      <FamilyEventsAccordion familyEvents={pastFamilyEvents} />
      <DebugCard data={[{ name: 'familyEvents', data: familyEvents }]} />
    </Container>
  );
};
