/* eslint-disable no-console */
interface SWConfig {
  ffApi: string;
  ffStoryBookApi: string;
  storyBookOn: boolean;
  collectApiCalls: boolean;
  debugging: boolean;
}

const config: SWConfig = {
  ffApi: process.env.REACT_APP_FF_API ?? 'http://localhost:3000',
  ffStoryBookApi: process.env.REACT_APP_FF_STORYBOOK_API ?? 'http://localhost:6006',
  collectApiCalls: process.env.REACT_APP_FF_COLLECT_API_CALLS?.toLowerCase() === 'true',
  storyBookOn: process.env.STORYBOOK?.toLowerCase() === 'true',
  debugging: process.env.REACT_APP_DEBUGGING?.toLowerCase() === 'true',
};

// alternatively we can stash the git commit in a window object, instead of sending it to the console.
// (global as any).ff_commit_id = process.env.REACT_APP_GIT_HASH;

console.info(`Client commit id:${process.env.REACT_APP_GIT_HASH}. Click here for backend info:${process.env.REACT_APP_FF_API}/healthcheck`);

export default config;
