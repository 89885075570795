/* eslint-disable max-len */
import { Box, Flex, HStack, Stack } from '@chakra-ui/react';
import { Form } from 'formik';
import { FfButtonTypes } from '../../models/Button';
import { FfTooltip } from '../FfTooltip';
import { FfFormikButton } from '../Formik/FfFormikButton';
import { FfFormikForm } from '../Formik/FfFormikForm';
import { FfFormikInput } from '../Formik/FfFormikInput';
import { FfFormikTextarea } from '../Formik/FfFormikTextarea';
import { GenericModuleData } from './models/genericLessonModule';
import { LessonPlanModuleTitles, LessonPlanModuleTypes } from './models/LessonPlanModuleEnum';

export class EarlyTutorReadAloudModuleData extends GenericModuleData {
  constructor() {
    super(LessonPlanModuleTypes.EarlyTutorReadAloud, LessonPlanModuleTitles.EarlyTutorReadAloud, 60, 1);
  }

  public title?: string;

  public materialsSentHome?: string;

  public additionalNotes?: string;
}

export interface IEarlyTutorReadAloudModuleProps {
  data: EarlyTutorReadAloudModuleData;
  isDisabled?: boolean;
  formRef?: any;
  onSave: (values: any) => void;
  onChange: () => void;
}

export const EarlyTutorReadAloudModule: React.FunctionComponent<IEarlyTutorReadAloudModuleProps> = ({
  data,
  isDisabled,
  formRef,
  onSave,
  onChange,
}) => (
  <FfFormikForm
    formRef={formRef}
    initialValues={data}
    validationClass={EarlyTutorReadAloudModuleData}
    submit={(values) => Promise.resolve(onSave(values))}
    onChange={onChange}
  >
    <Form>
      <Stack>
        <Flex>
          <Box flex={1} mr="2em">
            <FfFormikInput
              id="title"
              label={
                <HStack>
                  <Box>Title</Box>
                  <FfTooltip label="Read with expression and fluency. The student should not be working on anything at this time; just listening." />
                </HStack>
              }
              placeholder="Insert title here"
              type="text"
              isDisabled={isDisabled}
            />
            <FfFormikTextarea
              id="additionalNotes"
              label="Additional Notes"
              placeholder="Add your notes here"
              noOfLines={10}
              isDisabled={isDisabled}
            />
          </Box>
          <Box flex={1}>
            <FfFormikTextarea
              id="materialsSentHome"
              label="Materials Sent Home"
              placeholder="Add your notes here"
              noOfLines={10}
              isDisabled={isDisabled}
            />
          </Box>
        </Flex>
      </Stack>
      <Flex textAlign="right" mt="1em">
        <FfFormikButton type={FfButtonTypes.Submit} variant={'outline'} isDisabled={isDisabled}>
          Save and Continue
        </FfFormikButton>
      </Flex>
    </Form>
  </FfFormikForm>
);
