import React from 'react';
import { Flex, Heading, SimpleGrid } from '@chakra-ui/react';

export interface MetricDto {
  title: string;
  value: string | number | null;
  subtitle: string | null;
}

const MetricCard: React.FunctionComponent<MetricDto> = (props: MetricDto) => (
  <Flex
    flexDirection={'column'}
    justifyContent={'center'}
    bgColor={'white'}
    borderRadius={'15px'}
    padding={'1em'}
    minHeight={'15rem'}
    minWidth={'10rem'}
    maxWidth="18em"
    textAlign={'center'}
    marginBottom={'1em'}
  >
    <Heading fontSize={'x-large'} color={'ff.magenta'}>
      {props.title}
    </Heading>
    <Heading fontSize={'xxx-large'} color={'ff.blue'} py={'0.25em'}>
      {props.value}
    </Heading>
    <Heading fontWeight={500} fontSize={'large'} color={'ff.magenta'}>
      {props.subtitle}
    </Heading>
  </Flex>
);

export const CreateMetricsGrid = (props: { metricData: MetricDto[] }) => (
  <SimpleGrid columns={3} ml={'5em'} spacingX="1.5em">
    {props.metricData.map((metric: MetricDto, index: number) => (
      <MetricCard key={index} title={metric.title} value={metric.value || '-'} subtitle={metric.subtitle || ''} />
    ))}
  </SimpleGrid>
);

export const CreateMetricsGridStub = () => <SimpleGrid columns={3} ml={'5em'} minHeight={'20rem'} spacingX="1.5em"></SimpleGrid>;
