import { Button, FormControl, ResponsiveValue, StyleProps } from '@chakra-ui/react';
import { Field, FieldProps } from 'formik';
import { FfButtonTypes } from '../../models/Button';

export interface FfFormikButtonProps extends StyleProps {
  children: any;
  isDisabled?: boolean;
  isLoading?: boolean;
  // onClick: () => void;
  size?: string;
  type?: FfButtonTypes | undefined;
  variant?: ResponsiveValue<(string & {}) | 'outline' | 'link' | 'ghost' | 'solid' | 'unstyled'> | undefined;
  onClick?: (fieldProps: FieldProps) => void;
}

export const FfFormikButton: React.FunctionComponent<FfFormikButtonProps> = (props: FfFormikButtonProps) => {
  const { children, isDisabled, isLoading, size, type, variant, bg, onClick, ...rest } = props;

  return (
    <FormControl>
      <Field>
        {({ form, ...other }: FieldProps) => (
          <>
            <Button
              isLoading={isLoading || form.isSubmitting}
              type={type}
              variant={variant}
              size={size}
              bg={bg}
              _hover={bg && { backgroundColor: bg }}
              disabled={isDisabled ?? (!form.isValid || form.isSubmitting)}
              onClick={() => (onClick ?? (() => {}))({ form, ...other })}
              {...rest}
            >
              {children}
            </Button>
          </>
        )}
      </Field>
    </FormControl>
  );
};
