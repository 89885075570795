import { Box, Flex, Stack } from '@chakra-ui/react';
import { Form } from 'formik';
import { FfButtonTypes } from '../../models/Button';
import { FfFormikButton } from '../Formik/FfFormikButton';
import { FfFormikForm } from '../Formik/FfFormikForm';
import { FfFormikInput } from '../Formik/FfFormikInput';
import { FfFormikTextarea } from '../Formik/FfFormikTextarea';
import { GenericModuleData } from './models/genericLessonModule';
import { LessonPlanModuleTitles, LessonPlanModuleTypes } from './models/LessonPlanModuleEnum';

export class EmergentLetterFocusModuleData extends GenericModuleData {
  constructor() {
    super(LessonPlanModuleTypes.EmergentLetterFocus, LessonPlanModuleTitles.EmergentLetterFocus, 180, 1);
  }

  public letters?: string;

  public notes?: string;
}

export interface IEmergentLetterFocusModuleProps {
  data: EmergentLetterFocusModuleData;
  isDisabled?: boolean;
  formRef?: any;
  onSave: (values: any) => void;
  onChange: () => void;
}

export const EmergentLetterFocusModule: React.FunctionComponent<IEmergentLetterFocusModuleProps> = ({
  data,
  isDisabled,
  formRef,
  onSave,
  onChange,
}) => (
  <FfFormikForm
    formRef={formRef}
    initialValues={data}
    validationClass={EmergentLetterFocusModuleData}
    submit={(values) => Promise.resolve(onSave(values))}
    onChange={onChange}
  >
    <Form>
      <Stack>
        <Flex>
          <Box flex={1} mr="2em">
            <FfFormikInput id="letters" label="Letter/s" type="text" isDisabled={isDisabled} />
          </Box>
          <Box flex={1}>
            <FfFormikTextarea id="notes" label="Notes" placeholder="Add your notes here" noOfLines={10} isDisabled={isDisabled} />
          </Box>
        </Flex>
      </Stack>
      <Flex textAlign="right" mt="1em">
        <FfFormikButton type={FfButtonTypes.Submit} variant={'outline'} isDisabled={isDisabled}>
          Save and Continue
        </FfFormikButton>
      </Flex>
    </Form>
  </FfFormikForm>
);
