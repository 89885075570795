import { Box, HStack, Text, useToast } from '@chakra-ui/react';
import { DistrictDto, FFRoles, getFFRolesString, PutUserDto, SchoolDto, UserDto } from '@edanalytics/ff_be_se';
import { Form, FormikProps } from 'formik';
import { useAtom } from 'jotai';
import _ from 'lodash';
import React from 'react';
import { useUpdateAtom } from 'jotai/utils';
import { userInfoAtom } from '../ffApi';
import { SchoolService } from '../services/School';
import { UserServiceAtoms } from '../services/User';
import { getEnumKeys } from '../utils/utils';
import { FfButtonTypes } from '../models/Button';
import { FfFormikButton } from './Formik/FfFormikButton';
import { FfFormikCheckbox } from './Formik/FfFormikCheckbox';
import { FfFormikForm } from './Formik/FfFormikForm';
import { FfFormikInput } from './Formik/FfFormikInput';
import { FfFormikMultiSelect } from './Formik/FfFormikMultiSelect';
import { FfFormikOption } from './Formik/FfFormikOption';
import { FfFormikPhone } from './Formik/FfFormikPhone';
import { FfFormikSelect } from './Formik/FfFormikSelect';

interface EditUserFormProps {
  user: UserDto;
  districts: DistrictDto[];
}

class EditUser extends PutUserDto {
  public districtId?: number;

  public schools: SchoolDto[];
}

const roleOptions = getEnumKeys(FFRoles).map((r) => ({
  value: r,
  key: `${getFFRolesString(r)}`,
}));

export const EditUserForm: React.FunctionComponent<EditUserFormProps> = (props) => {
  const [initVals, setInitVals] = React.useState<EditUser>();
  const putUser = useUpdateAtom(UserServiceAtoms.putUser);
  const [currentUser] = useAtom(userInfoAtom);
  const [schoolService] = useAtom(SchoolService);
  const [schoolOptions, setSchoolOptions] = React.useState<FfFormikOption[]>([]);
  const districtsSorted = _.sortBy(props.districts, (d) => d.name);
  const districtOptions = districtsSorted?.map((d) => ({ value: d.id, key: d.name }));
  const [processing, setProcessing] = React.useState(false);
  const toast = useToast();

  const getSchools = async (districtId: number) => {
    setProcessing(true);
    const district = await schoolService.getSchools(districtId);
    setSchoolOptions(district.map((sch) => ({ value: sch.id, key: sch.name } as FfFormikOption)));
    setProcessing(false);
  };

  const setInitialModel = () => {
    const newModel = Object.assign(new EditUser(), props.user);
    newModel.schoolIds = props.user.schools && props.user.schools.length ? props.user.schools.map((s) => s.id) : [];
    newModel.districtId = props.user.schools && props.user.schools.length ? props.user.schools[0].districtId : undefined;
    newModel.schools = props.user.schools && props.user.schools.length ? props.user.schools : [];
    setInitVals(newModel);
  };

  const clearSchools = () => {
    const newModel = Object.assign(new EditUser(), props.user);
    newModel.schools = [];
    setInitVals(newModel);
  };

  React.useEffect(() => {
    const execute = async () => {
      // we have the districts and user on load
      if (props.user?.schools && props.user?.schools.length) {
        // the user already has schools so lets get the schools for that district
        await getSchools(props.user?.schools[0].districtId);
      }
      setInitialModel();
    };
    execute();
  }, []);

  return (
    <>
      {initVals && (
        <FfFormikForm
          initialValues={initVals as PutUserDto}
          validationClass={PutUserDto}
          submit={async (values) => {
            await putUser({
              id: props.user.id,
              firstName: values.firstName,
              lastName: values.lastName,
              phone: values.phone,
              languages: values.languages,
              active: values.active,
              schoolIds: values.schoolIds ? values.schoolIds : undefined,
              workHours: values.workHours,
              roleId: values.roleId,
            } as PutUserDto);
            toast({ description: 'User details saved', status: 'success' });
          }}
        >
          {(formik: FormikProps<PutUserDto>) => (
            <Form>
              <HStack gap={7} align="top">
                <Box flex="50% 1 1">
                  <FfFormikInput type="text" label="First Name" id="firstName" />
                  <FfFormikInput type="text" label="Email" id="email" isDisabled={true} />
                  {formik.values.roleId !== FFRoles.Staff && (
                    <FfFormikSelect
                      id="districtId"
                      label="District (NIM)"
                      options={districtOptions}
                      onChange={(option: FfFormikOption) => {
                        clearSchools();
                        getSchools(option.value as number);
                      }}
                    />
                  )}
                  <FfFormikInput type="text" label="Languages" id="languages" />

                  <FfFormikButton isDisabled={processing || currentUser.id === props.user.id} type={FfButtonTypes.Submit}>
                    Save{currentUser.id === props.user.id}
                  </FfFormikButton>
                  {currentUser.id === props.user.id && (
                    <Box>
                      <Text as="i" fontSize="sm">
                        You may not edit yourself
                      </Text>
                    </Box>
                  )}
                </Box>
                <Box flex="50% 1 1">
                  <FfFormikInput type="text" label="Last Name" id="lastName" />
                  <FfFormikSelect
                    id="roleId"
                    label="Role"
                    onChange={(o: FfFormikOption) => {
                      if (o.value === FFRoles.Staff) {
                        // staff should not have school ids
                        formik.setFieldValue('schoolIds', []);
                        formik.setFieldValue('districtId', null);
                      }
                    }}
                    options={roleOptions}
                  />
                  {formik.values.roleId !== FFRoles.Staff && (
                    <FfFormikMultiSelect
                      width="100%"
                      id="schoolIds"
                      label="Schools"
                      placeholder="Select Schools..."
                      options={schoolOptions}
                    />
                  )}
                  <HStack align="top" gap={7}>
                    <FfFormikPhone label="Phone" id="phone" />
                    <FfFormikCheckbox label="Active" id="active" />
                  </HStack>
                </Box>
              </HStack>
            </Form>
          )}
        </FfFormikForm>
      )}
    </>
  );
};
