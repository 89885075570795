/* eslint-disable no-console */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { UserRoleDto } from '@edanalytics/ff_be_se';
import { isUndefined } from 'lodash';
import { RESET } from 'jotai/utils';
import { useAtom } from 'jotai';
import { storedAccessTokenAtom, storedUserInfoAtom } from '../../ffApi';
import config from '../../config';

export const PreloadBootstrap = ({ children }: any) => {
  const [isBootstrapping, setIsBootstrapping] = React.useState(true);
  const [storedToken, setStoredToken] = useAtom(storedAccessTokenAtom);
  const [storedUser, setStoredUser] = useAtom(storedUserInfoAtom);
  const nav = useNavigate();

  const handleLoadUser = (userInfo: UserRoleDto | undefined) => {
    const noUser = !userInfo?.accessToken;
    const inactive = userInfo?.id && !userInfo.active;
    const unauthorized = (userInfo?.role ?? '') === '' || ((userInfo?.schoolsIds?.length ?? 0) === 0 && userInfo?.role !== 'Staff');
    if (noUser) {
      nav('/login');
    } else if (inactive) {
      nav('/inactive');
    } else if (unauthorized) {
      nav('/unauthorized');
    }
    setIsBootstrapping(false);
  };

  React.useEffect(() => {
    // do bootstrapping for auth
    const storedValue = { accessToken: storedToken, ...storedUser };
    if (config.debugging) console.info('Refresh user info', JSON.stringify({ storedValue, pathname: window.location.pathname }));
    //    CALLBACK
    if (window.location.pathname.startsWith('/callback')) {
      //   MISSING PARAMS TRY AGAIN
      if (window.location.search.length === 0) {
        console.warn("Not redirected, can't get token! Redirecting to /auth/google/login");
        window.location.href = `${config.ffApi}/auth/google/login`;
      }

      try {
        const fetchUserInfo = async () => {
          const userInfoResponse = await axios.get<(UserRoleDto & { accessToken: string }) | undefined>(
            `${config.ffApi}/auth/google/callback${window.location.search}`,
            { validateStatus: (status) => status === 200 },
          );
          const { accessToken, ...userData } = userInfoResponse.data ?? {};
          if (isUndefined(accessToken)) console.error('Could not get token from callback');
          // else console.info('Callback to API successful', JSON.stringify(userInfoResponse.data));
          setStoredToken((userInfoResponse.data as any).accessToken);
          setStoredUser(userData);
          handleLoadUser(userInfoResponse.data);
        };
        fetchUserInfo();
      } catch (error) {
        //   ERROR VERIFYING TOKEN GO TO UNAUTHORIZED
        console.error({ token_verification_error: error });
        console.info(JSON.stringify({ token_verification_error: error })); // Other errors can make it difficult to see on the browser console
        setStoredToken(RESET);
        setStoredUser(RESET);
        setIsBootstrapping(false);
        nav('/unauthorized');
      }
    } else if (storedToken && storedUser && Object.keys(storedUser).length > 0) {
      console.info('found a token and user, loading app');
      handleLoadUser(storedValue as any);
    } else {
      console.info('No token stored, redirect to /login');
      nav(`/login`);
      setIsBootstrapping(false);
    }
    return () => {};
  }, []);

  return (
    <>
      {isBootstrapping === false && <>{children}</>}
      {isBootstrapping === true && <></>}
    </>
  );
};
