import { Box, Center, ChakraProvider } from '@chakra-ui/react';
import { Provider } from 'jotai';
import { Suspense } from 'react';
import { globalProviderScope } from './atoms/symbols';
import theme from './common/theme';
import { GlobalErrorHandler } from './components/ErrorHandling/GlobalErrorHandler';
import { FfErrorBoundary } from './components/ErrorHandling/FfErrorBoundary';
import { Loader } from './components/Loader';
import { PreloadBootstrap } from './components/Preload/PreloadBootstrap';
import { FfRoutes } from './Routes';
import { FallbackDefaultLayout } from './components/ErrorHandling/DefaultLayoutFallback';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <GlobalErrorHandler level="top">
        <Provider scope={globalProviderScope}>
          <Suspense
            fallback={
              <Center bg={'ff.lightPink'} h="100vh" w="100vw">
                <Loader size="xl" />
              </Center>
            }
          >
            <FfErrorBoundary fallback={FallbackDefaultLayout}>
              <GlobalErrorHandler level="middle">
                <PreloadBootstrap>
                  <Box id={'pageContainer'} w={'100%'} minW={'1024'} minH={'100vh'} display={'flex'} flexDir={'column'}>
                    <FfRoutes />
                  </Box>
                </PreloadBootstrap>
              </GlobalErrorHandler>
            </FfErrorBoundary>
          </Suspense>
        </Provider>
      </GlobalErrorHandler>
    </ChakraProvider>
  );
}

export default App;
