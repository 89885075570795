import { Input, InputGroup, InputRightElement, FormControl } from '@chakra-ui/react';
import React from 'react';
import { useDrop } from 'react-dnd';
import { CloseIcon } from '@chakra-ui/icons';

export interface TutorTargetProps {
  tutorId?: number;
  tutorName?: string;
  cb: (id?: number, name?: string) => void;
}

export const TutorTarget: React.FunctionComponent<TutorTargetProps> = ({ tutorId, tutorName, cb }) => {
  const [{ isOver }, dropRef] = useDrop({
    accept: 'tutor',
    drop: ({ id, name }: { id: number; name: string }) => {
      cb(id, name);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });
  return (
    <FormControl>
      <InputGroup ref={dropRef}>
        <Input value={tutorName || ''} placeholder={isOver ? 'Drop Here' : ''} onChange={() => {}} />
        {tutorId !== undefined && tutorName !== undefined && (
          <InputRightElement color="gray.500" children={<CloseIcon cursor="pointer" onClick={() => cb(undefined, undefined)} />} />
        )}
      </InputGroup>
    </FormControl>
  );
};
