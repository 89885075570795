import { Input, GridItem, Text, FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { AddTimeDuration, GetNearestIntervalDate } from '../utils/TimeHelper';

const defaultDuration = 30;
export interface TimePickerProps {
  startTime?: string;
  endTime?: string;
  id?: string;
  apptDuration?: number;
  rangeMode?: boolean;
  label?: any;
  format?: string;
  dateFormat?: string;
  onChange: (timeFrame: { startTime?: string; endTime?: string }) => void;
  onError?: (error: string) => void;
}

// using luxon to format the time
const innerFormat = 'HH:mm';

const formatTime = (time: string | undefined, formatIn: string, formatOut: string = formatIn) => {
  if (!time) return undefined;
  const dateTime = DateTime.fromFormat(`2000-01-01 ${time}`, `yyyy-MM-dd ${formatIn}`);
  return dateTime.toFormat(formatOut);
};

const formatDate = (date: string | undefined, formatIn: string, formatOut: string = formatIn) => {
  if (!date) return undefined;
  const dateTime = DateTime.fromFormat(date, formatIn);
  return dateTime.toFormat(formatOut);
};

const isTimeValid = (start: string | undefined, end: string | undefined, rangeMode: boolean | undefined) => {
  const startDate = new Date(`01/01/2000 ${start}`);
  const endDate = new Date(`01/01/2000 ${end}`);
  const startTimeMs = startDate.getTime();
  const endTimeMs = endDate.getTime();

  console.debug('isTimeValid', { start, end, rangeMode, startTimeMs, endTimeMs });
  if (rangeMode && endTimeMs < startTimeMs) {
    return 'Please enter correct times';
  }

  return 'Ok';
};

const defaultStartTime = (outerFormat: string, startTime?: string) =>
  formatTime(startTime, outerFormat, innerFormat) ??
  GetNearestIntervalDate(30).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });

const defaultEndTime = (outerFormat: string, startTime?: string, endTime?: string, apptDuration?: number, rangeMode?: boolean) =>
  formatTime(endTime, outerFormat, innerFormat) ??
  (apptDuration && rangeMode ? AddTimeDuration(formatTime(startTime, outerFormat, innerFormat) as string, apptDuration) : undefined);

export const TimePicker: React.FunctionComponent<TimePickerProps> = ({
  startTime: initialStartTime,
  endTime: initialEndTime,
  rangeMode,
  label,
  id,
  apptDuration = defaultDuration,
  format,
  onChange,
  onError,
}) => {
  const outerFormat = format ?? innerFormat;

  const [startTime, setStartTime] = useState(defaultStartTime(outerFormat, initialStartTime));
  const [endTime, setEndTime] = useState(defaultEndTime(outerFormat, initialStartTime, initialEndTime, apptDuration, rangeMode));
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const message = isTimeValid(startTime, endTime, rangeMode);
    setIsError(message !== 'Ok');
    const errorMessageNoOk = message === 'Ok' ? '' : message;
    setErrorMessage(errorMessageNoOk);
    if (onError) onError(errorMessageNoOk);
  }, [startTime, endTime]);

  const updateTime = () => {
    if (!isError) {
      // switch to A ??= cond && B
      let startTimeFormatted: string | undefined = format && formatTime(startTime, innerFormat, outerFormat);
      let endTimeFormatted: string | undefined = format && endTime && formatTime(endTime, innerFormat, outerFormat);

      startTimeFormatted ??= startTime;
      endTimeFormatted ??= endTime;

      onChange({
        startTime: startTimeFormatted,
        endTime: endTimeFormatted,
      });
    } else {
      console.log('error', `:${errorMessage}`, startTime);
    }
  };

  return (
    <FormControl isInvalid={isError}>
      {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
      <GridItem display={'flex'}>
        <Input
          autoComplete="off"
          background="white"
          onChange={(e) => setStartTime(e.target.value)}
          value={startTime}
          width={'140px'}
          onBlur={updateTime}
          type="time"
        />
        {rangeMode && (
          <>
            <Text fontWeight={'bold'} margin="5px">
              -
            </Text>
            <Input
              autoComplete="off"
              background="white"
              onChange={(e) => setEndTime(e.target.value)}
              value={endTime}
              width={'140px'}
              onBlur={updateTime}
              type="time"
            />
          </>
        )}
      </GridItem>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};
