import { Button, Text } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import React, { useEffect } from 'react';
import { FfAlertDialog } from '../FfAlertDialog';
import { ShortFormatDate } from '../Utils';
import { deleteEnrollmentAtomApi, deleteEnrollmentAtom } from '../../services/EnrollmentAtoms';

export const DeleteEnrollmentDialog: React.FunctionComponent<{}> = () => {
  const [deleteEnrollment, setDeleteEnrollment] = useAtom(deleteEnrollmentAtom);
  const [validationMessage, deletedEnrollment] = useAtom(deleteEnrollmentAtomApi);

  const onClose = () => {
    setDeleteEnrollment(undefined);
    deletedEnrollment(RESET);
  };

  const onDelete = (id: number) => {
    deletedEnrollment(id);
  };

  useEffect(() => {
    if (validationMessage === 'Ok') {
      setDeleteEnrollment(undefined);
      deletedEnrollment(RESET);
    }
  }, [deleteEnrollment, validationMessage]);

  return (
    <FfAlertDialog
      header="Delete Enrollment Period"
      open={!!deleteEnrollment}
      onClose={() => onClose()}
      showCloseButton={true}
      body={
        <>
          <Text>
            {ShortFormatDate(deleteEnrollment?.data.entryDate)} to{' '}
            {deleteEnrollment?.data.exitDate ? ShortFormatDate(deleteEnrollment?.data.exitDate) : 'Current'}
          </Text>
          <br />
          <Text>Are you sure? You can't undo this action. If you want to change the dates, click cancel and edit the enrollment.</Text>
          {validationMessage !== 'Ok' && <Text color="red.500">{validationMessage}</Text>}
        </>
      }
      footer={
        <>
          <Button onClick={() => onClose()}>Cancel</Button>
          <Button colorScheme="red" ml={3} onClick={() => onDelete(deleteEnrollment?.id ?? 0)}>
            Save
          </Button>
        </>
      }
    />
  );
};
