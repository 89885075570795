import createValidator from 'class-validator-formik';
import { Class } from 'class-validator-formik/dist/types';
import { Formik, FormikHelpers, FormikProps, FormikValues, useFormikContext } from 'formik';
import { isFunction } from 'lodash';
import React from 'react';

export interface FfFormikFormProps<Values> {
  initialValues: Values;
  validationClass: Class;
  validateOnBlur?: boolean;
  validateOnChange?: boolean;
  validateOnMount?: boolean;
  submit: (values: Values, actions?: FormikHelpers<Values>) => Promise<void>;
  reset?: (values: Values) => Promise<void>;
  onChange?: (values: Values) => void;
  component?: React.ComponentType<FormikProps<Values>> | React.ReactNode;
  children?: ((props: FormikProps<Values>) => React.ReactNode) | React.ReactNode;
  formRef?: React.Ref<FormikProps<Values>>;
}

export const FfFormikForm = <Values extends FormikValues = FormikValues, ExtraProps = {}>(
  props: FfFormikFormProps<Values> & ExtraProps,
): JSX.Element => {
  const HandleChange = () => {
    // TODO: Prevent firing on accordion open/close
    const { values, errors, isValid, dirty, touched } = useFormikContext<Values & FormikValues>();
    React.useEffect(() => {
      if (props.onChange) props.onChange(values);
    }, [values, errors, isValid, dirty, touched]);
    return null;
  };

  return (
    <Formik
      enableReinitialize={true}
      innerRef={props.formRef}
      initialValues={props.initialValues}
      validate={createValidator(props.validationClass)}
      validateOnBlur={props.validateOnBlur}
      validateOnChange={props.validateOnChange}
      validateOnMount={props.validateOnMount}
      onReset={(v, actions) => {
        if (props.reset) {
          actions.setSubmitting(true);
          props.reset(v).then(() => actions.setSubmitting(false));
        }
      }}
      onSubmit={(v, actions) => {
        props.submit(v, actions).then(() => {
          actions.setSubmitting(false);
        });
      }}
    >
      {(formik) => (
        <>
          {isFunction(props.children) ? props.children(formik) : props.children}
          <HandleChange />
        </>
      )}
    </Formik>
  );
};
