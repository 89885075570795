import { extendTheme } from '@chakra-ui/react';
// Regenerate Theme - npx @chakra-ui/cli tokens src/common/theme/index.ts

const overrides = {
  styles: {
    global: {
      html: {
        fontSize: '1em',
      },
      body: {
        margin: 0,
        fontFamily: ['IBMPlexSans-Medium', 'sans-serif'],
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
      },
      code: {
        fontFamily: "source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace",
      },
    },
  },
  colors: {
    ff: {
      white: '#FFFFFF',
      blue: '#1C4559',
      darkBlue: '#013751',
      darkGray: '#545454',
      green: '#5FBB56',
      lightGray: '#9F9F9F',
      lightPink: '#F7E2E1',
      magenta: '#B23877',
      orange: '#EA823D',
      palePink: '#F2A2B6',
      palePalePink: '#F8F2FF',
      pink: '#c55f8f',
      teal: '#0FB9A3',
      water: '#D6F2EF',
      yellow: '#CDD620',
      // Functional names
      goals: '#D6F2EF', // water,
    },
  },
  components: {
    Heading: {
      defaultProps: {
        size: 'md',
      },
      baseStyle: {
        color: 'ff.blue',
      },
    },
    Select: {
      defaultProps: {
        size: 'md',
        variant: null,
      },
      baseStyle: {
        field: {
          borderWidth: '2px',
          backgroundColor: 'white',
          borderColor: 'ff.magenta',
          _hover: {
            borderColor: 'ff.magenta',
          },
          _focus: {
            zIndex: 1,
            boxShadow: '0 0 0 1px #B23877',
          },
          _disabled: {
            opacity: 0.8,
          },
        },
      },
    },
    Input: {
      baseStyle: {
        field: {
          width: '100%',
          borderWidth: '2px',
          backgroundColor: 'ff.white',
          borderColor: 'ff.magenta',
          _hover: {
            borderColor: 'ff.magenta',
          },
          _focus: {
            zIndex: 1,
            boxShadow: '0 0 0 1px #B23877',
            borderWidth: '2px',
            borderColor: 'ff.magenta',
          },
          _disabled: {
            borderWidth: '2px',
            borderColor: 'ff.magenta',
            opacity: 0.8,
          },
        },
      },
      defaultProps: {
        // Bug workaround: theme styles are not set unless a variant is set/reset, overriding default props
        // Ref: https://github.com/chakra-ui/chakra-ui/issues/2347
        variant: null,
        focusBorderColor: 'ff.magenta',
      },
    },
    Button: {
      defaultProps: {
        variant: 'outline',
      },
      baseStyle: {
        _disabled: {
          opacity: 0.8,
        },
      },
      variants: {
        outline: {
          border: '2px solid',
          borderColor: 'ff.blue',
          color: 'ff.blue',
        },
        outlineWater: {
          border: '2px solid',
          borderColor: 'ff.water',
          color: 'ff.water',
        },
        alternate: {
          border: '0',
          backgroundColor: 'ff.water',
          color: 'ff.blue',
        },
        cta: {
          border: '0',
          backgroundColor: 'ff.magenta',
          color: 'ff.white',
          '&:hover': {
            '&:disabled': {
              background: 'ff.magenta',
            },
          },
        },
        ghost: {
          color: 'ff.blue',
          _hover: {
            backgroundColor: '#00000010',
          },
        },
      },
    },
    FormErrorMessage: {
      baseStyle: {
        container: {
          backgroundColor: 'ff.white',
          color: '#ffff00',
        },
      },
      defaultProps: {
        variant: null, // Chakra bug workaround
      },
    },
    Checkbox: {
      baseStyle: {
        parts: ['control'],
        control: {
          borderColor: 'ff.magenta',
          _disabled: {
            borderWidth: '2px',
            borderColor: 'ff.magenta',
            opacity: 0.8,
          },
          _checked: {
            borderColor: 'ff.magenta',
            backgroundColor: 'ff.magenta',
            _hover: {
              borderColor: 'ff.magenta',
              backgroundColor: 'ff.magenta',
            },
            _disabled: {
              borderWidth: '2px',
              borderColor: 'ff.magenta',
              backgroundColor: 'ff.magenta',
              opacity: 0.8,
              color: 'white',
            },
          },
        },
      },
      defaultProps: {
        variant: null, // Chakra bug workaround
      },
    },
    Form: {
      parts: ['container'],
      baseStyle: {
        container: {
          marginBottom: '1em',
        },
      },
    },
    FormLabel: {
      baseStyle: {
        marginBottom: '.3em',
        fontWeight: 'bold',
        _disabled: {
          opacity: 0.8,
        },
      },
    },
    Field: {
      baseStyle: {
        field: {
          marginBottom: '3em',
        },
        _disabled: {
          opacity: 0.8,
        },
      },
    },
    Textarea: {
      defaultProps: {
        size: 'md',
        variant: null,
      },
      baseStyle: {
        borderWidth: '2px',
        // marginBottom: '1em',
        backgroundColor: 'white',
        // borderColor: 'ff.magenta',
        _focus: {
          boxShadow: '0 0 0 1px #B23877',
          borderWidth: '2px',
          borderColor: 'ff.magenta',
        },
        _disabled: {
          borderWidth: '2px',
          borderColor: 'ff.magenta',
          opacity: 0.8,
        },
      },
    },
    NumberInput: {
      defaultProps: {
        focusBorderColor: 'ff.magenta',
      },
    },
    Radio: {
      baseStyle: {
        parts: ['control'],
        control: {
          borderColor: 'ff.magenta',
          _checked: {
            borderColor: 'ff.magenta',
            backgroundColor: 'ff.magenta',
            _hover: {
              borderColor: 'ff.magenta',
              backgroundColor: 'ff.magenta',
            },
          },
          _hover: {
            backgroundColor: 'ff.magenta',
          },
        },
        _disabled: {
          opacity: 0.8,
        },
      },
    },
    Avatar: {
      baseStyle: {
        container: {
          overflow: 'hidden', // prevent Spaceguy's legs from escaping circle :)
          img: {
            objectFit: 'contain',
            position: 'relative',
            right: '0.4em',
            top: '0.2em',
            borderRadius: 0,
          },
        },
      },
    },
    Table: {
      baseStyle: {
        tfoot: {
          tr: {
            th: {
              textTransform: 'inherit',
            },
          },
        },
      },
      variants: {
        card: {
          table: {
            borderCollapse: 'separate',
            borderSpacing: '0 10px',
          },
          tbody: {
            tr: {
              background: 'white',
            },
            td: {
              _first: {
                borderRadius: '8px 0 0 8px',
              },
              _last: {
                borderRadius: '0 8px 8px 0',
              },
            },
          },
        },
      },
    },
    Link: {
      baseStyle: {
        color: 'ff.magenta',
      },
      variants: {
        disabled: {
          color: 'ff.palePalePink',
          opacity: 0.8,
        },
        enabled: {
          color: 'ff.pink',
        },
      },
    },
    Accordion: {
      baseStyle: {
        container: {
          border: 0,
          _first: {
            borderTop: '1px solid #333',
          },
        },
      },
    },
    Menu: {
      baseStyle: {
        list: {
          zIndex: 3,
        },
      },
    },
  },
};

export default extendTheme(overrides);
