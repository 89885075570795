import { Link, StyleProps } from '@chakra-ui/react';
import { Link as RouterLink, LinkProps } from 'react-router-dom';

type AllProps = StyleProps & LinkProps;

export interface FfRouterLinkProps extends AllProps {
  to: string;
  children: any;
  resetDistrictId?: boolean;
}

export const FfRouterLink: React.FunctionComponent<FfRouterLinkProps> = (props: FfRouterLinkProps) => {
  const { to, children, state, ...rest } = props;

  return (
    <Link as={RouterLink} to={to} {...rest}>
      {props.children}
    </Link>
  );
};
