import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Heading, HStack, Spacer } from '@chakra-ui/react';
import { FamilyEventDto } from '@edanalytics/ff_be_se';
import { DebugCard } from '../DebugCard';
import { Empty } from '../Empty';
import { isEmpty } from '../../utils/utils';
import { FamilyEventCard } from './FamilyEventCard';
import { EditIcon } from '../Icons/EditIcon';
import { FfRouterLink as Link } from '../FfRouterLink';
import { ShortFormatDateTime, LongFormatTime } from '../Utils';

export interface IFamilyEventsAccordionProps {
  familyEvents: FamilyEventDto[];
  isFirstItemOpen?: boolean;
}

const TitleAccordionItem = (familyEvent?: FamilyEventDto, index?: number) =>
  familyEvent && (
    <AccordionItem key={index} borderBottom="1px solid #333">
      {({ isExpanded }) => (
        <>
          <Heading>
            <AccordionButton>
              <Box flex={0} w="25px" marginRight="1em">
                {isExpanded ? <MinusIcon fontSize="12px" /> : <AddIcon fontSize="12px" />}
              </Box>
              <Box w="100%">
                <HStack>
                  <Heading size="md" style={{ fontWeight: '500' }}>
                    {familyEvent.name}
                  </Heading>
                  <Box fontSize="12px">
                    {ShortFormatDateTime(familyEvent.startDate)} - {LongFormatTime(familyEvent.endDate)}, {familyEvent.location}
                  </Box>
                  <Spacer />
                  <Box textAlign="right">
                    <Link
                      fontSize="14px"
                      textDecoration="underline"
                      to={`/schools/${familyEvent.schoolId}/family-events/${familyEvent?.id}`}
                    >
                      {familyEvent.submittedAt ? 'See Event' : <EditIcon />}
                      <span className="debug-hide dim">&nbsp;(#{familyEvent?.id})</span>
                    </Link>
                  </Box>
                </HStack>
              </Box>
            </AccordionButton>
          </Heading>
          <AccordionPanel pl="2.75em">
            <FamilyEventCard familyEvent={familyEvent} />
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );

export const FamilyEventsAccordion: React.FunctionComponent<IFamilyEventsAccordionProps> = ({ familyEvents, isFirstItemOpen }) => (
  <Box mb="3em">
    <Accordion allowMultiple={true} defaultIndex={isFirstItemOpen ? [0] : []}>
      {familyEvents.map((familyEvent, index) => TitleAccordionItem(familyEvent, index))}
    </Accordion>
    {isEmpty(familyEvents) && <Empty description="No events." />}
    <DebugCard data={[{ name: 'familyEvents', data: familyEvents }]} />
  </Box>
);
