import { EditIcon } from '@chakra-ui/icons';
import { Avatar, Box, Flex, Heading, Spacer, Stack } from '@chakra-ui/react';
import { SchoolDto, UserDto } from '@edanalytics/ff_be_se';
import Spaceguy from '../assets/images/Spaceguy.svg';
import { TutorSlug } from './TutorSlug';
import { FfRouterLink as Link } from './FfRouterLink';

export interface ITutorBannerProps {
  school: SchoolDto;
  user: UserDto;
  isEditLinkEnabled?: boolean;
}

export const TutorBanner: React.FunctionComponent<ITutorBannerProps> = ({ school, user, isEditLinkEnabled }) => (
  <Flex gap={5} align="center" fontSize="12px">
    <Box flex={0} textAlign="center" alignItems="center">
      <Avatar bg={'ff.blue'} size="lg" src={Spaceguy} mb=".2em" />
      <Heading color={'ff.blue'} style={{ fontSize: '.9em' }}>
        TUTOR
      </Heading>
    </Box>
    <Box>
      <Stack direction="row" spacing={5}>
        <Heading mb=".25em">{user.fullName}</Heading>
        {isEditLinkEnabled && (
          <Link to={`/users/${user.id}/edit`}>
            <EditIcon fontSize={'1.3em'} />
          </Link>
        )}
      </Stack>
      <TutorSlug user={user} />
    </Box>
    <Spacer />
    <Box>
      <Link color={'ff.pink'} fontSize="14px" textDecoration="underline" to={`/schools/${school.id}/tutors`}>
        See All Tutors
      </Link>
    </Box>
  </Flex>
);
