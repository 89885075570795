/* eslint-disable @typescript-eslint/no-unused-vars */
import { FormControl, FormErrorMessage, FormLabel, StyleProps } from '@chakra-ui/react';
import { Field } from 'formik';
import { Select } from 'chakra-react-select';
import { ReactNode } from 'react';
import { FfFormikOption } from './FfFormikOption';

export interface FfFormikMultiSelectProps extends StyleProps {
  label: ReactNode;
  options: FfFormikOption[];
  id: string;
  isDisabled?: boolean;
  placeholder?: string;
}

export const FfFormikMultiSelect: React.FunctionComponent<FfFormikMultiSelectProps> = (props: FfFormikMultiSelectProps) => {
  const { label, options, id, isDisabled, ...rest } = props;
  return (
    <Field id={props.id} name={props.id}>
      {({ field, form, meta }: { field: any; form: any; meta: any }) => (
        <FormControl isDisabled={props.isDisabled ? props.isDisabled : false} isInvalid={meta.error && meta.touched}>
          <FormLabel htmlFor={props.id}>{props.label}</FormLabel>
          <Select
            isMulti={true}
            onBlur={form.handleBlur}
            name={props.id}
            value={props.options
              .filter((o) => (form.values[props.id] && form.values[props.id].length ? form.values[props.id].includes(o.value) : false))
              .map((o) => ({ label: o.key, value: o.value }))}
            placeholder={props.placeholder ?? 'Select...'}
            onChange={(e) => {
              form.setFieldValue(
                props.id,
                e.map((v) => v.value),
              );
            }}
            options={props.options.map((o) => ({ label: o.key, value: o.value }))}
            {...rest}
          />
          {meta.error && meta.touched && <FormErrorMessage>{meta.error}</FormErrorMessage>}
        </FormControl>
      )}
    </Field>
  );
};
