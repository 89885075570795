import { Divider, StyleProps } from '@chakra-ui/react';

export interface FfDividerProps extends StyleProps {
  bg?: string;
  marginTop?: string;
  marginBottom?: string;
  height?: string;
}

export const FfDivider: React.FunctionComponent<FfDividerProps> = (props: FfDividerProps) => {
  const { bg, marginTop, marginBottom, height, ...rest } = props;

  return (
    <Divider
      marginTop={marginTop ? `${marginTop}` : `1em`}
      marginBottom={marginBottom ? `${marginBottom}` : `1em`}
      height={height ? `${height}` : `2px`}
      bg={bg ? `${bg}` : `ff.blue`}
      orientation="horizontal"
      {...rest}
    />
  );
};
