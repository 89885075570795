import { Link, Box, Container, Flex, Heading, useToast } from '@chakra-ui/react';
import { atom, useAtomValue } from 'jotai';
import { RESET, useUpdateAtom } from 'jotai/utils';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { persistNavAtom } from '../../atoms/navAtom';
import { ConfirmAlert } from '../../components/ConfirmationDialog';
import { FamilyInteractionForm } from '../../components/FamilyInteraction/FamilyInteractionForm';
import { FamilyInteractionService, FamilyInteractionServiceAtoms } from '../../services/FamilyInteraction';
import { useFfErrorToast } from '../../utils/utils';

const deleteFamilyInteractionsAtom = atom(null, async (get, set, newValue: { schoolId: number; familyInteractionId: number }) => {
  await get(FamilyInteractionService).deleteFamilyInteractions(newValue.schoolId, newValue.familyInteractionId);
  set(FamilyInteractionServiceAtoms.getAllFamilyInteractionsBySchool, RESET);
  set(FamilyInteractionServiceAtoms.getFamilyInteractionsById, RESET);
});

export const DeleteFamilyInteractionComponent: React.FunctionComponent<{ familyInteractionId?: number }> = () => {
  const nav = useNavigate();
  const navProps = useAtomValue(persistNavAtom);
  const toast = useToast();
  const errToast = useFfErrorToast();
  const familyInteraction = useAtomValue(FamilyInteractionServiceAtoms.getFamilyInteractionsById);
  const deleteFamilyInteraction = useUpdateAtom(deleteFamilyInteractionsAtom);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);

  const performDelete = async () => {
    try {
      if ((familyInteraction?.id ?? -1) > 0 && navProps.schoolId) {
        await deleteFamilyInteraction({ schoolId: navProps.schoolId, familyInteractionId: familyInteraction!.id });
        nav(`/schools/${navProps.schoolId}/family-interactions`, { replace: true });
        toast({ description: 'Family interaction deleted', status: 'success' });
      } else {
        toast({ description: 'Family interaction not deleted', status: 'error' });
      }
    } catch (error: any) {
      errToast(error);
    } finally {
      setIsDeleteModalOpen(false);
    }
  };

  return (
    <>
      {!!familyInteraction?.id && (
        <>
          <Link onClick={() => setIsDeleteModalOpen(true)} textDecoration="underline">
            Delete Entire Interaction
          </Link>
          <ConfirmAlert
            active={isDeleteModalOpen}
            headerText="Delete Family Event"
            bodyText="Are you sure? You can't undo this action afterwards."
            okHandle={performDelete}
            cancelHandle={() => setIsDeleteModalOpen(false)}
            continueText="Delete"
          />
        </>
      )}
    </>
  );
};

export const FamilyInteractionScreen: React.FunctionComponent = () => (
  <Container maxWidth="100%">
    <Flex mb="1em">
      <Box flex={1}>
        <Heading display="inline-block" mr="1em" color={'ff.blue'}>
          Family Interaction
        </Heading>
        <DeleteFamilyInteractionComponent />
      </Box>
      <Box textAlign="right" flex={0}></Box>
    </Flex>
    <FamilyInteractionForm />
  </Container>
);
