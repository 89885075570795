import { FormControl, FormErrorMessage, FormLabel, Radio, RadioGroup } from '@chakra-ui/react';
import { Field } from 'formik';
import { ReactNode } from 'react';
import { FfFormikOption } from './FfFormikOption';

export interface FfFormikRadioGroupProps {
  label: ReactNode;
  options: FfFormikOption[];
  id: string;
  isDisabled?: boolean;
}

export const FfFormikRadioGroup: React.FunctionComponent<FfFormikRadioGroupProps> = (props: FfFormikRadioGroupProps) => (
  <Field id={props.id} name={props.id}>
    {({ form, meta }: { form: any; meta: any }) => (
      <FormControl isDisabled={props.isDisabled || form.isSubmitting} isInvalid={meta.error && meta.touched}>
        <FormLabel htmlFor={props.id}>{props.label}</FormLabel>
        <RadioGroup onBlur={form.handleBlur} name={props.id} isDisabled={props.isDisabled}>
          {props.options.map((o, i) => (
            <Radio
              mr="1em"
              isChecked={form.values[props.id] === o.value}
              isDisabled={props.isDisabled || form.isSubmitting}
              key={i}
              onChange={() => {
                form.setFieldValue(props.id, o.value);
              }}
            >
              {o.key}
            </Radio>
          ))}
        </RadioGroup>
        {meta.error && meta.touched && <FormErrorMessage>{meta.error}</FormErrorMessage>}
      </FormControl>
    )}
  </Field>
);
