/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
import { FFRoles, PutUserDto, UserDto, StudentUserScheduleDto } from '@edanalytics/ff_be_se';
import { atom, Getter, Setter } from 'jotai';
import { RESET } from 'jotai/utils';
import { apiClientAtom } from '../ffApi';
import { persistNavPropsAtom } from '../atoms/navAtom';
import { atomApiWithNavAndRead } from '../utils/async-atom';

const userServiceFactory = (get: Getter) => ({
  getUser: async (userId: number): Promise<UserDto> => {
    const response = await get(apiClientAtom).get(`/users/${userId}/`);
    return response.data as UserDto;
  },
  getUsers: async (): Promise<UserDto[]> => {
    const response = await get(apiClientAtom).get(`/users/`);
    return response.data.results as UserDto[];
  },
  putUser: async (userId: number, put: PutUserDto): Promise<void> => {
    // Follow
    await get(apiClientAtom).put(`/users/${userId}/`, put);
  },
  activateUser: async (userId: number): Promise<void> => get(apiClientAtom).put(`/users/${userId}/activate`), // Follow
  deactivateUser: async (userId: number): Promise<void> => get(apiClientAtom).put(`/users/${userId}/deactivate`), // Follow
  getTutor: async (schoolId: number, tutorId: number): Promise<UserDto> => {
    const response = await get(apiClientAtom).get(`/schools/${schoolId}/users/${tutorId}/`);
    return response.data as UserDto;
  },
  getSchoolUsers: async (schoolId: number): Promise<UserDto[]> => {
    const response = await get(apiClientAtom).get(`/schools/${schoolId}/users/`);
    return response.data.results as UserDto[];
  },
  getTutors: async (schoolId: number): Promise<UserDto[]> => {
    const response = await get(apiClientAtom).get(`/schools/${schoolId}/users/?roleId=${FFRoles.Tutor}`);
    return response.data.results as UserDto[];
  },
  getICs: async (schoolId: number): Promise<UserDto[]> => {
    const response = await get(apiClientAtom).get(`/schools/${schoolId}/users/?roleId=${FFRoles.IC}`);
    return response.data.results as UserDto[];
  },
  getFECs: async (schoolId: number): Promise<UserDto[]> => {
    const response = await get(apiClientAtom).get(`/schools/${schoolId}/users/?roleId=${FFRoles.FEC}`);
    return response.data.results as UserDto[];
  },
  getStudentSchedules: async (userId: number): Promise<StudentUserScheduleDto[]> => {
    const response = await get(apiClientAtom).get(`/users/${userId}/student-schedules`);
    return response.data.results as StudentUserScheduleDto[];
  },
});

const UserService = atom(userServiceFactory);
export { UserService };

let localResetUsers = (set: Setter) => {};

export const UserServiceAtoms = {
  getCurrentUser: persistNavPropsAtom(async (get, nav) => {
    if (!nav.userId) return undefined;
    return userServiceFactory(get).getUser(nav.userId);
  }),
  getCurrentUsers: atomApiWithNavAndRead(async (get, nav) => userServiceFactory(get).getUsers()),
  getSchoolUsers: atomApiWithNavAndRead(async (get, nav) => {
    if (!nav.schoolId) return undefined;
    return userServiceFactory(get).getSchoolUsers(nav.schoolId);
  }),
  getCurrentTutor: persistNavPropsAtom(async (get, nav) => {
    if (!nav.schoolId || !nav.tutorId) return undefined;
    return userServiceFactory(get).getTutor(nav.schoolId, nav.tutorId);
  }),
  getCurrentStaff: persistNavPropsAtom(async (get, nav) => {
    if (!nav.staffId) return undefined;
    return userServiceFactory(get).getUser(nav.staffId);
  }),
  getCurrentTutors: persistNavPropsAtom(async (get, nav) => {
    if (!nav.schoolId) return undefined;
    return userServiceFactory(get).getTutors(nav.schoolId);
  }),
  getCurrentICs: persistNavPropsAtom(async (get, nav) => {
    if (!nav.schoolId) return undefined;
    return userServiceFactory(get).getICs(nav.schoolId);
  }),
  getCurrentFECs: persistNavPropsAtom(async (get, nav) => {
    if (!nav.schoolId) return undefined;
    return userServiceFactory(get).getFECs(nav.schoolId);
  }),
  getStudentSchedules: persistNavPropsAtom(async (get, nav) => {
    if (!nav.tutorId) return undefined;
    return userServiceFactory(get).getStudentSchedules(nav.tutorId);
  }),
  putUser: atom(null, async (get, set, newValue: PutUserDto) => {
    await userServiceFactory(get).putUser(newValue.id, newValue);
    localResetUsers(set);
  }),
  updateActiveState: atom(null, async (get, set, newValue: { userId: number; active: boolean }) => {
    if (newValue.active === true) {
      await userServiceFactory(get).activateUser(newValue.userId);
    } else {
      await userServiceFactory(get).deactivateUser(newValue.userId);
    }
    return localResetUsers(set);
  }),
};

localResetUsers = (set: Setter) => {
  set(UserServiceAtoms.getSchoolUsers, RESET);
  set(UserServiceAtoms.getCurrentUsers, RESET);
};

export const resetUsers = localResetUsers;
