import { useToast } from '@chakra-ui/react';
import React, { useEffect } from 'react';

export const GlobalErrorHandler: React.FunctionComponent<{ children: any; level?: string }> = ({ children }) => {
  const toast = useToast();

  const errorHandler = (event: ErrorEvent) => {
    if (event.error.hasBeenCaught !== undefined) {
      return;
    }
    event.error.hasBeenCaught = true;
    toast({ description: event.message });
  };

  useEffect(
    () =>
      // ('error', errorHandler);
      () =>
        window.removeEventListener('error', errorHandler),
    [],
  );

  return <>{children}</>;
};
