import { Box, Stack } from '@chakra-ui/react';
import { SchoolDto } from '@edanalytics/ff_be_se';
import { Info } from 'luxon';
import { luxonDays, TutorLink } from './Utils';

export interface ISessionScheduleSlugProps {
  school: SchoolDto;
  session: any;
}

export const SessionScheduleSlug: React.FunctionComponent<ISessionScheduleSlugProps> = ({ school, session }) => (
  <Stack>
    <Box>
      {Info.weekdays()[luxonDays(session.dayOfWeek)]}, {session.startTime}
    </Box>
    {school && <Box>{TutorLink(school, session.tutor)}</Box>}
  </Stack>
);
