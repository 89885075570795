/* eslint-disable max-classes-per-file */
import { FamilyInteractionTypes, getFamilyInteractionString } from '@edanalytics/ff_be_se';
import { IsOptional, IsString } from 'class-validator';
import { getEnumKeys } from '../../utils/utils';

export const familyInteractionTypeOptions = getEnumKeys(FamilyInteractionTypes).map((r) => ({
  value: r,
  key: `${getFamilyInteractionString(r)}`,
}));

export const ContactType = [
  { key: 'Primary Contact', value: '1' },
  { key: 'Secondary Contact', value: '2' },
  { key: 'Other', value: '3' },
];

export const InteractionSuccess = [
  { key: 'Yes', value: '1' },
  { key: 'No', value: '2' },
];

export class InformationShared {
  @IsOptional()
  @IsString()
  public feedback: string;

  @IsOptional()
  @IsString()
  public strengths: string;

  @IsOptional()
  @IsString()
  public improvements: string;

  @IsOptional()
  @IsString()
  public communication: string;
}
