import { UserDto } from '@edanalytics/ff_be_se';
import { atom, Getter } from 'jotai';
import { apiClientAtom } from '../ffApi';

const identityServiceFactory = (get: Getter) => ({
  get: async () => {
    const response = await get(apiClientAtom).get<UserDto>(`/whoami/`);
    return response.data;
  },
});

const IdentityService = atom(identityServiceFactory);

// THIS GETS SET UP PRELOADWHOAMI
export const whoAmIatom = atom<UserDto>({} as UserDto);

export { IdentityService };

export const IdentityServiceAtoms = {
  getOneRosterStudents: atom(async (get) => identityServiceFactory(get).get()),
};
