import { Box, Container, Heading } from '@chakra-ui/react';
import React from 'react';
import { useAtom } from 'jotai';
import { DebugCard } from '../../components/DebugCard';
import { SchoolServiceAtoms } from '../../services/School';
import { FfRouterLink as Link } from '../../components/FfRouterLink';

export const SchoolDetailScreen: React.FunctionComponent = () => {
  const [school] = useAtom(SchoolServiceAtoms.getCurrentSchool);

  return (
    <Container maxWidth="100%">
      <Heading size="xs">Placeholder school landing page</Heading>
      <Heading color={'ff.blue'}>{school?.name}</Heading>
      <Box>
        <pre>school: {JSON.stringify(school, null, 2)}</pre>
      </Box>
      <Link to="/schools">See All Schools</Link>
      <DebugCard data={[{ name: 'school', data: school }]} />
    </Container>
  );
};
