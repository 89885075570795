import { Atom, atom, Getter } from 'jotai';
import axios, { AxiosInstance, AxiosError, AxiosResponse } from 'axios';
import { UserRoleDto } from '@edanalytics/ff_be_se';
import config from './config';
import { atomStorageSession } from './utils/atom-storage-internal';
import { UserInfo } from './models/UserInfo';
import { COLLECT_API_CALLS, STORYBOOK_ON, STORYBOOK_API } from './config/constants';
import { atomWithReset } from './utils/async-atom';

export const storedAccessTokenAtom = atomStorageSession('access_token');

export const storedUserInfoAtom = atomStorageSession<Partial<Omit<UserRoleDto, 'accessToken'>>>('user_info');

export const userInfoAtom = atom(async (get) => {
  const storedToken = get(storedAccessTokenAtom);
  const storedUser = get(storedUserInfoAtom);
  const storedValue = { accessToken: storedToken, ...storedUser };

  return storedValue as UserInfo;
});

export const tokenAtom = atom((get) => get(userInfoAtom)?.accessToken);

function collectApiCallsCode(response: AxiosResponse<any>) {
  const requestData = response.config;
  const responseData = response.data;
  // take out the base url and the query params
  const urlIntoIdentifier = requestData.url?.replace(requestData.baseURL ?? '', '')?.replaceAll(/([/ .-])*/g, '') ?? '';
  const codeSnippet = `
// ${requestData.url}
const ${urlIntoIdentifier} = ${JSON.stringify(responseData, null, 2)};

`;
  (window as any).typescript += codeSnippet;

  return response;
}

const storyBookBaseAtom = atom<AxiosInstance | undefined>(undefined);
export const storyBookWritableAtom = atom(
  (get) => {
    console.log('STORYBOOK_API', STORYBOOK_API);
    const baseAtom = get(storyBookBaseAtom);
    return baseAtom;
  },
  (get, set, newValue: AxiosInstance | undefined) => {
    set(storyBookBaseAtom, newValue);
  },
);

// Import me to make Axios requests
export const apiClientAtom = !STORYBOOK_ON
  ? atom(async (get) => {
      const client = axios.create({
        baseURL: config.ffApi,
        headers: {
          Authorization: `Bearer ${get(tokenAtom)}`,
        },
      });
      client.interceptors.response.use(COLLECT_API_CALLS ? collectApiCallsCode : undefined, (error) => {
        const axiosError = error as AxiosError<any>;
        const errorCode = axiosError.response?.data?.statusCode ?? -1;
        if (errorCode === 401) {
          window.location.href = `${config.ffApi}/auth/google/login`;
        }
        if (errorCode === 403) {
          window.location.href = `/unauthorized`;
        }
        throw error;
      });
      return client;
    })
  : atom(async (get) => {
      const client = axios.create({
        baseURL: STORYBOOK_API,
      });

      client.interceptors.response.use(COLLECT_API_CALLS ? collectApiCallsCode : undefined, (error) => {
        // console.log('error we see!', error);
        throw error;
      });
      return Promise.resolve(client);
    });

apiClientAtom.debugLabel = 'apiClientAtom';
