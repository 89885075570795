import { FamilyEventDto, PostFamilyEventDto, PutFamilyEventDto } from '@edanalytics/ff_be_se';
import { atom, Getter } from 'jotai';
import { persistNavAtom, persistNavPropsAtom } from '../../atoms/navAtom';
import { apiClientAtom } from '../../ffApi';
import { atomApiWithNavAndRead, atomApiWithNavAndUpdate, atomApiWithUpdate } from '../../utils/async-atom';
import { GetAllForSchoolInput } from './dto/GetAllForSchoolInput';

const familyEventServiceFactory = (get: Getter) => ({
  get: async (schoolId: number, id: number): Promise<FamilyEventDto> => {
    const response = await get(apiClientAtom).get(`/schools/${schoolId}/family-events/${id}/`);

    return response.data as FamilyEventDto;
  },

  getAllForSchool: async (schoolId: number, params?: GetAllForSchoolInput): Promise<FamilyEventDto[]> => {
    const response = await get(apiClientAtom).get(`/schools/${schoolId}/family-events`, { params });
    // console.log('familyEventServiceFactory: getAllForSchool(): response.data.results: ', response.data.results);
    return response.data.results as FamilyEventDto[];
  },

  postFamilyEvent: async (schoolId: number, post: PostFamilyEventDto): Promise<FamilyEventDto> => {
    const response = await get(apiClientAtom).post(`/schools/${schoolId}/family-events/`, post);

    return response.data as FamilyEventDto;
  },

  putFamilyEvent: async (schoolId: number, familyEventId: number, put: PutFamilyEventDto): Promise<FamilyEventDto> => {
    const response = await get(apiClientAtom).put(`/schools/${schoolId}/family-events/${familyEventId}`, put);
    return response.data;
  },
  // TODO: delete API endpoint not implemented yet
  deleteFamilyEvent: async (schoolId: number, familyEventId: number): Promise<void> => {
    await get(apiClientAtom).delete(`/schools/${schoolId}/family-events/${familyEventId}`);
  },
});

const FamilyEventService = atom(familyEventServiceFactory);

export { FamilyEventService };

export const FamilyEventServiceAtoms = {
  getCurrentFamilyEvent: persistNavPropsAtom(async (get, nav) => {
    if (!nav.schoolId || !nav.familyEventId) return undefined;
    return familyEventServiceFactory(get).get(nav.schoolId, nav.familyEventId);
  }),
  postFamilyEvent: (post: PostFamilyEventDto) =>
    persistNavPropsAtom(async (get, nav) => familyEventServiceFactory(get).postFamilyEvent(nav.schoolId!, post)),
  putFamilyEvent: (put: PutFamilyEventDto) =>
    persistNavPropsAtom(async (get, nav) => familyEventServiceFactory(get).putFamilyEvent(nav.schoolId!, put.id, put)),
  deleteFamilyEvent: (del: FamilyEventDto) =>
    persistNavPropsAtom(async (get, nav) => familyEventServiceFactory(get).deleteFamilyEvent(nav.schoolId!, del.id)),
  getUpdateFamilyEvent: atomApiWithUpdate(
    async (get) => {
      const { schoolId, familyEventId } = get(persistNavAtom);
      if (!schoolId || !familyEventId) return undefined;

      return get(FamilyEventService).get(schoolId, familyEventId);
    },
    (get, set, newFamilyEvent: PutFamilyEventDto) => {
      const { schoolId, familyEventId } = get(persistNavAtom);
      if (!schoolId || !familyEventId) return Promise.resolve(undefined);

      return get(FamilyEventService).putFamilyEvent(schoolId, familyEventId, newFamilyEvent);
    },
  ),
  getCreateOrUpdateFamilyEvent: atomApiWithNavAndUpdate(
    async (get, { schoolId, familyEventId }) => {
      if (!schoolId || !familyEventId) return Promise.resolve(undefined);
      return get(FamilyEventService).get(schoolId, familyEventId);
    },
    (get, set, saveValues: PostFamilyEventDto | PutFamilyEventDto, { schoolId, familyEventId }) => {
      if (!schoolId) return Promise.resolve(undefined);
      return familyEventId
        ? get(FamilyEventService).putFamilyEvent(schoolId, familyEventId, { ...saveValues } as PutFamilyEventDto)
        : get(FamilyEventService).postFamilyEvent(schoolId, { ...saveValues } as PostFamilyEventDto);
    },
  ),
  getAllFamilyEvents: atomApiWithNavAndRead(async (get, nav) => {
    if (!nav.schoolId) return [];

    return familyEventServiceFactory(get).getAllForSchool(nav.schoolId);
  }),
};
