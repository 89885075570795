/* eslint-disable no-nested-ternary */
import { PostOneRosterStudentDto, StudentDto, StudentEnrollmentDto } from '@edanalytics/ff_be_se';
import { AxiosError } from 'axios';
import { RESET, atomWithReset } from 'jotai/utils';
import { atom } from 'jotai';
import { StudentService, StudentServiceAtoms } from './Student';
import { atomApiWithNavAndUpdate } from '../utils/async-atom';
import { OneRosterService } from './OneRoster';

export class EntryDate {
  entryDate?: Date;

  exitDate?: Date;
}

interface AtomState {
  mode: 'activate' | 'deactivate' | 'edit' | 'closed';
  updateIds: ({ studentId: number; enrollmentId?: number; schoolId?: number } | { orStudentId: string; schoolId?: number })[];
  studentName?: string;
  entryDate?: Date;
  exitDate?: Date;
  isLastEnrollment: boolean;
}

// Here are the cases:
// - From a list of students
//  - From links in the table
//    1. Activate a specific student, with the id of the student
//    2. Deactivate a specific student, with the id of the student and the id of the enrollment
//    3. Edit the enrollment dates of a specific student, with the id of the student and the ids of the enrollments
//  - From the bulk edit buttons, with student checkboxes
//    4. Activate a list of students, with the ids of the students. Only enter one date
//    5. Deactivate a list of students, with the ids of the students. Only enter one date
// - From a single student enrollment (from the student details page)
//  - From links in the table
//    6. Edit the enrollment dates of a specific student, with the id of the student
//    7. Only if it is the last enrollment, allow to remove the exit date
//    8. Only if it is the last enrollment, add a delete link to remove the enrollment
//  - From the top buttons
//    9. Show Activate button if the student is deactivated. Only enter one date
//    10. Show Deactivate button if the student is activated. Only enter one date
// - From the one roster page
//    11. Enroll a student for the first time. Only enter one date

const isStudentEnrollmentDto = (obj: any): obj is StudentEnrollmentDto => 'entryDate' in obj;
const undefinedIfInvalid = (date: Date) => (date && date.toString() !== 'Invalid Date' ? date : undefined);

// cases 1 4
export const activateStudents = (studentUpdates: StudentDto[]): AtomState => ({
  mode: 'activate',
  updateIds: studentUpdates.map((studentUpdate) => ({ studentId: studentUpdate.id })),
  studentName: studentUpdates.length === 1 ? studentUpdates[0].fullName : undefined,
  isLastEnrollment: true,
});

// cases 2 5
export const deactivateStudents = (studentUpdates: StudentDto[]): AtomState => ({
  mode: 'deactivate',
  updateIds: studentUpdates.map((studentUpdate) => ({ studentId: studentUpdate.id, enrollmentId: studentUpdate.currentEnrollmentId })),
  studentName: studentUpdates.length === 1 ? studentUpdates[0].fullName : undefined,
  isLastEnrollment: false,
});

// cases 3 6 7 8
export const editStudentEnrollment = (
  studentData: StudentEnrollmentDto | StudentDto,
  isLastEnrollment: boolean,
  studentName?: string,
): AtomState =>
  isStudentEnrollmentDto(studentData)
    ? {
        mode: 'edit',
        updateIds: [{ studentId: studentData.studentId, enrollmentId: studentData.id }],
        entryDate: studentData.entryDate,
        exitDate: studentData.exitDate,
        studentName,
        isLastEnrollment,
      }
    : {
        mode: 'edit',
        updateIds: [{ studentId: studentData.id, enrollmentId: studentData.currentEnrollmentId }],
        entryDate: undefinedIfInvalid(new Date(studentData.activatedAt)),
        exitDate: undefinedIfInvalid(new Date(studentData.deactivatedAt)),
        studentName: studentData.fullName,
        isLastEnrollment,
      };

// cases 9
export const activateStudent = (student: StudentDto, isLastEnrollment: boolean): AtomState => ({
  mode: 'activate',
  updateIds: [{ studentId: student.id }],
  studentName: student.fullName,
  isLastEnrollment,
});

// cases 10
export const deactivateStudent = (enrollment: StudentEnrollmentDto, student: StudentDto): AtomState => ({
  mode: 'deactivate',
  updateIds: [{ studentId: enrollment.studentId, enrollmentId: enrollment.id }],
  studentName: student.fullName,
  isLastEnrollment: false,
});

export const enrollStudent = (enrollmentMsg: PostOneRosterStudentDto, schoolId?: number): AtomState => ({
  mode: 'activate',
  updateIds: enrollmentMsg.orStudentIds.map((orStudentId) => ({ orStudentId, schoolId })),
  isLastEnrollment: false,
});

const initialState = {
  mode: 'closed',
  updateIds: [],
  isLastEnrollment: false,
} as AtomState;

export const enrollmentDialogState = atomWithReset<AtomState>(initialState);
enrollmentDialogState.debugLabel = 'editEnrollmentAtom';

type DeleteAtomState =
  | {
      data: EntryDate;
      id: number;
    }
  | undefined;

export const deleteEnrollmentAtom = atomWithReset<DeleteAtomState>(undefined);

const validateEnrollmentUpdate = (newValue: AtomState) => {
  const { entryDate, exitDate, isLastEnrollment, mode } = newValue;
  const editingEntryDate = mode === 'activate' || mode === 'edit';
  const editingExitDate = mode === 'deactivate' || mode === 'edit';
  const removingExitDate = mode === 'edit' && isLastEnrollment;
  const editingDates = editingEntryDate && editingExitDate;
  const idsArray = newValue.updateIds;
  let message: string | undefined;

  if (editingDates && !entryDate && !exitDate) {
    message = 'Please enter valid dates.';
  }

  if (editingEntryDate && !entryDate) {
    message = 'Please enter valid activation date.';
  }

  if (!removingExitDate && editingExitDate && !exitDate) {
    message = 'Please enter valid deactivation date.';
  }

  if (editingDates && entryDate && exitDate && entryDate > exitDate) {
    message = 'Deactivation date cannot be before activation date.';
  }
  if (
    idsArray.length === 0 ||
    idsArray.some(
      (v) => (!('orStudentId' in v) && !v.schoolId) || (!('orStudentId' in v) && !v.studentId) || ('orStudentId' in v && !v.orStudentId),
    )
  ) {
    message = 'Error';
  }
  return message;
};

const isOneRosterUpdateArray = (obj: any[]): obj is { orStudentId: string; schoolId: number }[] =>
  obj.length > 0 && obj.every((o) => 'orStudentId' in o);

export const editEnrollmentAtomApi = atomApiWithNavAndUpdate(
  (get, nav) => {
    const { schoolId, studentId } = nav;
    return Promise.resolve('');
  },

  // to type this and remove undefined, we need to use a type guard
  async (get, set, newValue: AtomState | typeof RESET, nav) => {
    if (newValue === RESET || !newValue) {
      return Promise.resolve('');
    }
    newValue.updateIds.forEach((v) => {
      v.schoolId ??= nav.schoolId;
    });

    const finish = (message: string | undefined) => {
      set(StudentServiceAtoms.getCurrentStudent, RESET);
      set(StudentServiceAtoms.getCurrentStudents, RESET);
      set(StudentServiceAtoms.getCurrentStudentEnrollment, RESET);
      return Promise.resolve(message);
    };

    let message = validateEnrollmentUpdate(newValue);

    // if we have a message, we can return early
    if (message !== undefined) return Promise.resolve(message);

    message = 'Ok';
    const { orUpdateIds, updateIds } = isOneRosterUpdateArray(newValue.updateIds)
      ? { orUpdateIds: newValue.updateIds, updateIds: undefined }
      : { orUpdateIds: undefined, updateIds: newValue.updateIds as { schoolId?: number; studentId: number; enrollmentId?: number }[] };

    if (orUpdateIds) {
      try {
        await get(OneRosterService).activateStudents(orUpdateIds[0].schoolId, {
          orStudentIds: orUpdateIds.map((s) => s.orStudentId),
          entryDate: newValue.entryDate || new Date(),
        });
      } catch (error) {
        if (error instanceof AxiosError) {
          if (error.response?.status === 404) {
            message = 'Enrollment(s) no longer exists.';
          }
          const msg = error.response?.data.message;
          message = msg || 'Error';
        } else message = (error as any)?.message || 'Error';
      }
      return finish(message);
    }

    const updates = updateIds.map((v) => {
      const { schoolId, studentId, enrollmentId } = v;
      const { entryDate, exitDate } = newValue;
      return { schoolId, studentId, id: enrollmentId, entryDate, exitDate };
    });

    const { schoolId } = newValue.updateIds[0] as { schoolId: number }; // We know this is defined because of the validateEnrollmentUpdate function
    try {
      await get(StudentService).patchStudentEnrollment(schoolId, updates);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 404) {
          message = 'Enrollment(s) no longer exists.';
        }
        const msg = error.response?.data.message;
        message = msg || 'Error';
      } else message = (error as any)?.message || 'Error';
    }

    return finish(message);
  },
);

export const deleteEnrollmentAtomApi = atomApiWithNavAndUpdate(
  (get, nav) => {
    const { schoolId, studentId } = nav;
    return Promise.resolve('');
  },
  async (get, set, newValue: number | typeof RESET, nav) => {
    if (newValue === RESET) {
      return Promise.resolve('');
    }
    const { schoolId, studentId } = nav;
    if (!schoolId || !studentId) return Promise.resolve('');

    let message: string = 'Ok';
    try {
      await get(StudentService).deleteStudentEnrollment(schoolId, studentId, newValue);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 404) {
          message = 'Enrollment(s) no longer exists.';
        }
        const msg = error.response?.data.message;
        message = msg || 'Error';
      } else message = (error as any)?.message || 'Error';
    }

    set(StudentServiceAtoms.getCurrentStudent, RESET);
    set(StudentServiceAtoms.getCurrentStudentEnrollment, RESET);
    return Promise.resolve(message);
  },
);

export const setEditStateAtom = atom(null, (get, set, newState: AtomState) => {
  set(enrollmentDialogState, newState);
});
