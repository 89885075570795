import { Container, Heading, Link, useToast } from '@chakra-ui/react';
import React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { UserDto } from '@edanalytics/ff_be_se';
import { useAtom } from 'jotai';
import { useUpdateAtom } from 'jotai/utils';
import _ from 'lodash';
import { userSort } from '../../components/FfTable/Filters';
import { FfTable } from '../../components/FfTable';
import { DebugCard } from '../../components/DebugCard';
import { UserServiceAtoms } from '../../services/User';
import { UserLink } from '../../components/Utils';

export const StaffActivateUsersScreen: React.FunctionComponent = () => {
  const [users] = useAtom(UserServiceAtoms.getCurrentUsers);
  const updateActiveState = useUpdateAtom(UserServiceAtoms.updateActiveState);
  const toast = useToast();
  const columns: ColumnDef<UserDto>[] = [
    {
      accessorFn: (row) => row,
      id: 'fullName',
      cell: (info: any) => {
        const user = info.getValue() as UserDto;
        return user.active ? UserLink(user) : user.fullName;
      },
      header: () => 'Name',
      filterFn: 'user',
      sortingFn: userSort, // cell value not sortable with default
    },
    {
      accessorKey: 'email',
      id: 'email',
      cell: (info: any) => info.getValue(),
      header: () => 'Email',
      filterFn: 'email',
    },
    {
      accessorFn: (row) => row,
      id: 'activate',
      cell: (info: any) => {
        const user = info.getValue() as UserDto;
        return (
          <Link
            cursor="pointer"
            textDecoration="underline !important"
            color={'ff.pink'}
            onClick={() => {
              updateActiveState({ userId: user.id, active: !user.active }).then(() =>
                toast({ description: user.fullName + (user.active ? ` deactivated` : ` activated`), status: 'success' }),
              );
            }}
          >
            {user.active ? 'Deactivate' : 'Activate'}
          </Link>
        );
        // return user.active ? <DeactivateButton user={info.getValue() as UserDto} /> : <ActivateButton user={info.getValue() as UserDto} />;
      },
      header: '',
    },
  ];

  const sortedUsers = _.sortBy(users, [(u) => u.lastName, (u) => u.createdAt]);

  return (
    <Container maxWidth="100%">
      <Heading color={'ff.blue'} mb="1em">
        Activate Users
      </Heading>
      <FfTable columns={columns} data={sortedUsers} />
      <DebugCard data={[{ name: 'sortedUsers', data: sortedUsers }]} />
    </Container>
  );
};
