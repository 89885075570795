import { Box, Container, Heading, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { SessionLessonPlanDto, UserDto } from '@edanalytics/ff_be_se';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { useAtom, useAtomValue } from 'jotai';
import { UserServiceAtoms } from '../../services/User';
import { MonthYearFormat } from '../../components/Utils';
import { TutorObservationForm } from '../../components/TutorObservationForm';
import { FfRouterLink as Link } from '../../components/FfRouterLink';
import { LessonPlanServiceAtoms } from '../../services/LessonPlan/LessonPlanService';
import { SchoolServiceAtoms } from '../../services/School';
import { SessionCard } from '../../components/SessionCard';
import { FfDivider } from '../../components/FfDivider';

export const ObserveTutorFormHeader: React.FunctionComponent<{ tutor?: UserDto; message?: string }> = ({ tutor, message }) => {
  const school = useAtomValue(SchoolServiceAtoms.getCurrentSchool);

  if (!school || !tutor)
    return (
      <>
        <Heading color={'ff.blue'}>Observation</Heading>
        <FfDivider />
      </>
    );

  const flexCss = message ? { mb: '1em' } : { mb: '3em' };
  return (
    <>
      <Heading color={'ff.blue'}>Observation</Heading>
      <FfDivider />
      <Flex {...flexCss} borderBottom="2px">
        <Box flex={1}>
          <Heading size="sm">Observing</Heading>
          <Box>
            <Text color={'ff.blue'} fontSize="2em" display="inline-block" mr="15px" fontWeight="bold">
              {tutor.fullName}
            </Text>
            <Text display="inline-block">Tutor since {MonthYearFormat(tutor.createdAt)}</Text>
          </Box>
        </Box>
        <Flex flex={1}>
          <Box display="inline-block" alignSelf="flex-end" textAlign="right" width="100%">
            <Link to={`/schools/${school.id}/tutors/${tutor.id}/observe`}>Select a different session to observe</Link>
          </Box>
        </Flex>
      </Flex>
      {message && (
        <Box mb={'3em'}>
          <Text>{message}</Text>
        </Box>
      )}
    </>
  );
};

export const ObserveTutorFormScreen: React.FunctionComponent = () => {
  const [lessonPlans] = useAtom(LessonPlanServiceAtoms.getTutorLessonPlans);
  const { lessonPlanId } = useParams();
  const school = useAtomValue(SchoolServiceAtoms.getCurrentSchool);
  const tutor = useAtomValue(UserServiceAtoms.getCurrentTutor);

  if (!school || !tutor)
    return (
      <Container maxWidth="100%">
        <ObserveTutorFormHeader />
      </Container>
    );

  const currentLessonPlan = _.filter(lessonPlans, (p) => p.id === Number(lessonPlanId) * 1).pop() as SessionLessonPlanDto;

  return (
    <Container maxWidth="100%">
      <ObserveTutorFormHeader tutor={tutor} />
      <SessionCard lessonPlan={currentLessonPlan} showObserveLinks={false} />
      <Box mt="20px" />
      <TutorObservationForm school={school} lessonPlan={currentLessonPlan} tutor={tutor} />
    </Container>
  );
};
