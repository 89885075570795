import React from 'react';
import { Input, InputGroup, InputRightElement, CloseButton } from '@chakra-ui/react';

export const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 250,
  placeholder,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
  placeholder?: string;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>): JSX.Element => {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <InputGroup>
      <Input {...props} size="sm" placeholder={placeholder} value={value} onChange={(e) => setValue(e.target.value)} />
      <InputRightElement h="100%" children={<CloseButton size="sm" onClick={() => setValue('')} />} />
    </InputGroup>
  );
};
