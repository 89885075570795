import { Box, Stack } from '@chakra-ui/react';
import { UserDto } from '@edanalytics/ff_be_se';

export interface IUserSlugProps {
  user: UserDto;
}

export const UserSlug: React.FunctionComponent<IUserSlugProps> = ({ user }) => (
  <Stack isInline spacing={4} align="center">
    <Box>{user.role?.name}</Box>
    <Box>{user.languages}</Box>
  </Stack>
);
