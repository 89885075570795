import { Box, Flex, Stack } from '@chakra-ui/react';
import { Form } from 'formik';
import { FfButtonTypes } from '../../models/Button';
import { FfFormikButton } from '../Formik/FfFormikButton';
import { FfFormikForm } from '../Formik/FfFormikForm';
import { FfFormikTextarea } from '../Formik/FfFormikTextarea';
import { GenericModuleData } from './models/genericLessonModule';
import { LessonPlanModuleTitles, LessonPlanModuleTypes } from './models/LessonPlanModuleEnum';

export class EmergentWritingModuleData extends GenericModuleData {
  constructor() {
    super(LessonPlanModuleTypes.EmergentWriting, LessonPlanModuleTitles.EmergentWriting, 300, 1);
  }

  public activity?: string;

  public notes?: string;
}

export interface IEmergentWritingModuleProps {
  data: EmergentWritingModuleData;
  isDisabled?: boolean;
  formRef?: any;
  onSave: (values: any) => void;
  onChange: () => void;
}

export const EmergentWritingModule: React.FunctionComponent<IEmergentWritingModuleProps> = ({
  data,
  isDisabled,
  formRef,
  onSave,
  onChange,
}) => (
  <FfFormikForm
    formRef={formRef}
    initialValues={data}
    validationClass={EmergentWritingModuleData}
    submit={(values) => Promise.resolve(onSave(values))}
    onChange={onChange}
  >
    <Form>
      <Stack>
        <Flex>
          <Box flex={1} mr="2em">
            <FfFormikTextarea id="activity" label="Activity" placeholder="Add your notes here" noOfLines={10} isDisabled={isDisabled} />
          </Box>
          <Box flex={1}>
            <FfFormikTextarea id="notes" label="Notes" placeholder="Add your notes here" noOfLines={10} isDisabled={isDisabled} />
          </Box>
        </Flex>
      </Stack>
      <Flex textAlign="right" mt="1em">
        <FfFormikButton type={FfButtonTypes.Submit} variant={'outline'} isDisabled={isDisabled}>
          Save and Continue
        </FfFormikButton>
      </Flex>
    </Form>
  </FfFormikForm>
);
