import React, { Key, RefObject, Suspense } from 'react';
import { Box, Container } from '@chakra-ui/react';
import { Footer } from './Footer';
import { Header } from './Header';
import { Loader } from '../Loader';
import { PreloadNav } from '../Preload/PreloadNav';
import { PreloadWhoAmI } from '../Preload/PreloadWhoAmI';

export interface IDefaultLayoutProps {
  providerKey: Key;
  children: any;
  bg?: string;
}

export const DefaultLayoutInternal: React.FunctionComponent<IDefaultLayoutProps> = (props) => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const footerRef = React.useRef<HTMLDivElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [containerHeight, setContainerHeight] = React.useState('');

  const getRefBoundingRectHeight = (ref: RefObject<HTMLDivElement>): number => {
    if (ref.current) {
      return ref.current.getBoundingClientRect().height;
    }
    return 0;
  };

  const getContainerHeight = () => `calc(100vh - (${getRefBoundingRectHeight(headerRef)}px + ${getRefBoundingRectHeight(footerRef)}px))`;

  React.useEffect(() => {
    setContainerHeight(getContainerHeight());
  });
  return (
    <>
      <div ref={headerRef}>
        <Header providerKey={100} />
      </div>
      <Box ref={containerRef} minHeight={containerHeight} bg={props.bg ?? 'ff.water'} className="body">
        <Container maxW={'1200px'}>
          <PreloadWhoAmI>
            <PreloadNav providerKey={props.providerKey} consoleOn={true}>
              {props.children}
            </PreloadNav>
          </PreloadWhoAmI>
        </Container>
      </Box>
      <div ref={footerRef}>
        <Footer />
      </div>
    </>
  );
};

export const DefaultLayout: React.FunctionComponent<IDefaultLayoutProps> = (props) => (
  <Box className="default">
    <Suspense
      fallback={
        <DefaultLayoutInternal {...props}>
          <Loader />
        </DefaultLayoutInternal>
      }
    >
      <DefaultLayoutInternal {...props}>{props.children}</DefaultLayoutInternal>
    </Suspense>
  </Box>
);
