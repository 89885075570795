import { Box, HStack } from '@chakra-ui/react';
import { AttendanceStatusTypes, LessonStatusTypes } from '@edanalytics/ff_be_se';
import { LessonPlanStatus } from './LessonPlan/LessonPlanStatus';

export const SessionCardKey: React.FunctionComponent = () => (
  <HStack fontSize={14} justify="center" margin="1em">
    <Box>KEY:</Box>
    <Box>
      <LessonPlanStatus tooltipHidden={true} lessonStatus={LessonStatusTypes.Scheduled} />
    </Box>
    <Box>No Lesson Plan</Box>
    <LessonPlanStatus tooltipHidden={true} lessonStatus={LessonStatusTypes.InProgress} />
    <Box>Lesson Plan In Progress</Box>
    <LessonPlanStatus
      tooltipHidden={true}
      lessonStatus={LessonStatusTypes.Absence}
      attendanceStatus={AttendanceStatusTypes.StudentAbsence}
    />
    <Box>Absence</Box>
    <LessonPlanStatus tooltipHidden={true} lessonStatus={LessonStatusTypes.Submitted} attendanceStatus={AttendanceStatusTypes.Attended} />
    <Box>Lesson Plan Complete</Box>
    <LessonPlanStatus tooltipHidden={true} lessonStatus={LessonStatusTypes.Absence} attendanceStatus={AttendanceStatusTypes.Holiday} />
    <Box>Holiday</Box>
  </HStack>
);
