/* eslint-disable @typescript-eslint/naming-convention */
import { GetOneRosterStudentDto, PostOneRosterStudentDto } from '@edanalytics/ff_be_se';
import { atom, Getter } from 'jotai';
import { apiClientAtom } from '../ffApi';
import { persistNavPropsAtom } from '../atoms/navAtom';

const oneRosterServiceFactory = (get: Getter) => ({
  getStudents: async (schoolId: number): Promise<GetOneRosterStudentDto[]> => {
    const response = await get(apiClientAtom).get(`/one-roster/schools/${schoolId}/students/`);
    return response.data.results as GetOneRosterStudentDto[];
  },
  activateStudents: async (
    schoolId: number,
    body: PostOneRosterStudentDto,
  ): Promise<void> => // Follow
    get(apiClientAtom).post(`/one-roster/schools/${schoolId}/students/`, body),
});

const OneRosterService = atom(oneRosterServiceFactory);
export { OneRosterService };

export const OneRosterServiceAtoms = {
  getOneRosterStudents: persistNavPropsAtom(async (get, nav) => {
    if (!nav.schoolId) return undefined;
    return oneRosterServiceFactory(get).getStudents(nav.schoolId);
  }),
};
