import { Flex } from '@chakra-ui/react';
import * as React from 'react';
import { EmergentPaSkillModuleData } from '../LessonPlanContent/EmergentPaSkillModule';
import { lessonNumber26Options, paSkillOptions } from '../LessonPlanContent/LessonPlanUtils';
import { PrintableInputs } from '../PrintableInputs';

export interface IPrintableEmergentPaSkillModuleProps {
  data: EmergentPaSkillModuleData;
}

export const PrintableEmergentPaSkillModule: React.FunctionComponent<IPrintableEmergentPaSkillModuleProps> = ({ data }) => (
  <Flex align="center" gap={7} css={{ breakInside: 'avoid-page' }}>
    <PrintableInputs.Input
      css={{ flex: '50% 1 1' }}
      minWidth={10}
      label="Unit #"
      children={paSkillOptions.find((option) => option.value === data.unitNumber)?.key}
    />
    <PrintableInputs.Input
      css={{ flex: '50% 1 1' }}
      minWidth={10}
      label="Sort Skill #"
      children={lessonNumber26Options.find((option) => option.value === data.lessonNumber)?.key}
    />
  </Flex>
);
