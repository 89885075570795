export enum ReadingComprehensionTypes {
  NotYet = 1,
  Somewhat = 2,
  Understanding = 3,
}

export const getReadingComprehensionTypeString = (rc: ReadingComprehensionTypes): string => {
  switch (rc) {
    case ReadingComprehensionTypes.NotYet:
      return 'Not Yet - 1';
    case ReadingComprehensionTypes.Somewhat:
      return 'Somewhat - 2';
    case ReadingComprehensionTypes.Understanding:
      return 'Understanding - 3';
    default:
      throw new Error('No defined value!');
  }
};
