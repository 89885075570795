import { Box, Flex, Stack, Tooltip } from '@chakra-ui/react';
import { FFRoles, getAttendanceStatusTypeString, getLessonStatusTypeString, SchoolDto, SessionLessonPlanDto } from '@edanalytics/ff_be_se';
import { useAtom } from 'jotai';
import { DebugCard } from './DebugCard';
import {
  CompactFormatDateString,
  CreateLessonLink,
  LessonPlanStartEndTime,
  MakeUpLessonPlanLink,
  ObservationLink,
  SlugFormatTime,
} from './Utils';
import { getLessonPlanHighlights } from './LessonPlanContent/LessonPlanUtils';
import { userInfoAtom } from '../ffApi';

export interface ISessionSlugProps {
  school: SchoolDto;
  lessonPlan: SessionLessonPlanDto;
}

export const SessionSlug: React.FunctionComponent<ISessionSlugProps> = ({ lessonPlan, school }) => {
  const [userInfo] = useAtom(userInfoAtom);
  return (
    <Box
      px="1em"
      py=".7em"
      marginLeft="5em"
      marginRight="5em"
      marginTop="1em"
      bg="white"
      shadow="md"
      borderWidth="1px"
      fontSize=".8em"
      rounded={6}
    >
      <Flex fontSize="12px">
        <Box flex={3}>
          <Stack direction="column">
            <Box>
              {CompactFormatDateString(lessonPlan.startDateTime)}
              {lessonPlan.isMakeUp && (
                <Box display={'inline'} ml={'1em'}>
                  ({MakeUpLessonPlanLink(lessonPlan)})
                </Box>
              )}
            </Box>
            <Box>
              {SlugFormatTime(lessonPlan.startDateTime)} - {SlugFormatTime(lessonPlan.endDateTime)}
            </Box>
          </Stack>
        </Box>
        <Box flex={2}>{lessonPlan.student?.fullName}</Box>
        <Box flex={2}>
          {lessonPlan?.attendanceStatus ? getAttendanceStatusTypeString(lessonPlan?.attendanceStatus) : 'No Attendance Record'}
        </Box>
        <Box flex={2}>{lessonPlan?.lessonStatus && getLessonStatusTypeString(lessonPlan?.lessonStatus)}</Box>
        <Box flex={2}>
          <span>
            <i>
              <Tooltip
                bg="white"
                color={'ff.blue'}
                p="1em"
                placement="top"
                hasArrow
                rounded="sm"
                label={getLessonPlanHighlights(lessonPlan)}
              >
                <Box maxW={300} textOverflow="ellipsis" overflow="hidden" fontStyle="italic" cursor="pointer">
                  {getLessonPlanHighlights(lessonPlan)}
                </Box>
              </Tooltip>
            </i>
          </span>
        </Box>
        <Box flex={2} textAlign="right">
          {userInfo.roleId !== FFRoles.Tutor && ObservationLink(lessonPlan, school, lessonPlan.byUser!)}
        </Box>
        <Box flex={2} textAlign="right">
          {CreateLessonLink(lessonPlan)}
        </Box>
      </Flex>
    </Box>
  );
};
