import { DateTime as DT } from 'luxon';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { AccordionButton, AccordionItem, AccordionPanel, Box, Heading, List, ListItem } from '@chakra-ui/react';
import { SchoolDto, SessionLessonPlanDto } from '@edanalytics/ff_be_se';
import { SessionSlug } from './SessionSlug';
import { Desc } from '../utils/TimeHelper';

export const DateAccordionItem = (school: SchoolDto, key: any, lessonPlans: SessionLessonPlanDto[], index: number) => (
  <AccordionItem key={index} borderBottom="1px solid #333" pb="0em">
    {({ isExpanded }) => (
      <>
        <Heading>
          <AccordionButton>
            <Box flex={0} marginRight="1em">
              {isExpanded ? <MinusIcon fontSize="12px" /> : <AddIcon fontSize="12px" />}
            </Box>
            <Box marginRight="1em">
              <Heading size="sm" style={{ fontWeight: '500' }}>
                {DT.fromFormat(key, 'yyyy-MM').toFormat('MMMM yyyy')}
              </Heading>
            </Box>
          </AccordionButton>
        </Heading>
        {isExpanded && (
          <AccordionPanel style={{ paddingTop: 0, paddingBottom: 0 }}>
            <List pb="2em" ml="1.75em">
              {lessonPlans?.sort(Desc('startDateTime')).map((lessonPlan, sIndex) => (
                <ListItem key={sIndex}>
                  <SessionSlug school={school} lessonPlan={lessonPlan} />
                </ListItem>
              ))}
            </List>
          </AccordionPanel>
        )}
      </>
    )}
  </AccordionItem>
);
