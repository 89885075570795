import { Container } from '@chakra-ui/react';
import React from 'react';
import { useAtom } from 'jotai';
import { FFRoles } from '@edanalytics/ff_be_se';
import { UserBanner } from '../../components/User/UserBanner';
import { UserDetail } from '../../components/User/UserDetail';
import { DebugCard } from '../../components/DebugCard';
import { FfDivider } from '../../components/FfDivider';
import { UserServiceAtoms } from '../../services/User';
import { userInfoAtom } from '../../ffApi';

export const UserDetailScreen: React.FunctionComponent = () => {
  const [user] = useAtom(UserServiceAtoms.getCurrentStaff);
  const [userInfo] = useAtom(userInfoAtom);

  return (
    <Container maxWidth="100%" fontSize="12px">
      {user && (
        <>
          <UserBanner user={user} isEditLinkEnabled={userInfo.roleId === FFRoles.Staff} />
          <FfDivider />
          <UserDetail user={user} />
        </>
      )}
      <DebugCard data={[{ name: 'user', data: user }]} />
    </Container>
  );
};
