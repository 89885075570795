import React, { ComponentType } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { ErrorFallback } from './ErrorFallback';

export interface FfErrorBoundaryProps {
  children: any;
  fallback?: ComponentType<FallbackProps>;
  reset?: (args?: unknown) => void;
}

// We don't handle errors here but in the global handler.
// This is only used to reset rendering errors state.
export const FfErrorBoundary: React.FunctionComponent<FfErrorBoundaryProps> = (props: FfErrorBoundaryProps) => (
  <ErrorBoundary FallbackComponent={props.fallback ? props.fallback : ErrorFallback} onReset={props.reset}>
    {props.children}
  </ErrorBoundary>
);
