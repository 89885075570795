import { Box, Button, Container, Flex, Heading, Menu, MenuButton, MenuItem, MenuList, Stack, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { isEmpty } from 'lodash';
import { useAtom, useAtomValue } from 'jotai';
import { FFRoles } from '@edanalytics/ff_be_se';
import { DebugCard } from '../../components/DebugCard';
import { GroupType, SessionsAccordion } from '../../components/SessionsAccordion';
import { FrequentlyUsedList } from '../../components/FrequentlyUsedList';
import { LessonPlanServiceAtoms } from '../../services/LessonPlan/LessonPlanService';
import { SchoolServiceAtoms } from '../../services/School';
import { whoAmIatom } from '../../services/Identity';

export const LessonPlansByTutorScreen: React.FunctionComponent = () => {
  const [school] = useAtom(SchoolServiceAtoms.getCurrentSchool);
  const [lessonPlans] = useAtom(LessonPlanServiceAtoms.getScheduledSchoolLessonPlans);

  const [groupType, setGroupType] = useState<GroupType>('tutor');
  const user = useAtomValue(whoAmIatom);
  const isEnabled = user?.roleId !== FFRoles.FEC && user?.roleId !== FFRoles.Tutor;
  const handleChange = (e: any) => {
    setGroupType(e.target.value);
  };

  const groupTypes = [
    {
      value: 'date' as GroupType,
      name: 'Date',
    },
    {
      value: 'tutor' as GroupType,
      name: 'Tutor',
    },
  ];

  return (
    <Container maxWidth="100%">
      <Flex mb="1em">
        <Box flex={1}>
          <Heading color={'ff.blue'}>What's On Deck</Heading>
        </Box>
        <Box textAlign="right" flex={0}>
          <FrequentlyUsedList
            items={[
              {
                path: '/users',
                name: 'Staff Directory',
              },
              {
                path: `#`,
                name: 'Blank Lesson Plan',
              },
            ]}
          />
        </Box>
      </Flex>
      {school && lessonPlans && (
        <>
          <Stack direction="row">
            <Box mb="1em">See schedule by</Box>
            <Box>
              <Menu autoSelect={true}>
                <MenuButton as={Button} rightIcon={<ChevronDownIcon />} bg="white" size="sm">
                  {groupTypes.find((x) => x.value === groupType)?.name}
                </MenuButton>
                <MenuList color="gray" fontSize="sm">
                  {groupTypes.map((type, index) => (
                    <MenuItem
                      key={index}
                      defaultChecked={type.value === groupType}
                      id={type.value}
                      value={type.value}
                      onClick={handleChange}
                    >
                      {type.name}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </Box>
          </Stack>
          <SessionsAccordion
            school={school}
            lessonPlans={lessonPlans}
            groupType={groupType}
            showObserveLinks={isEnabled}
            // eslint-disable-next-line no-console
          />
        </>
      )}
      {isEmpty(lessonPlans) && <Text>Nothing on deck</Text>}
      <DebugCard data={[{ name: 'lessonPlans', data: lessonPlans }]} />
    </Container>
  );
};
